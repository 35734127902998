import axios from 'axios'
import find from 'lodash-es/find'
import get from 'lodash-es/get'
import map from 'lodash-es/map'

import {getTimeBySecond} from '@/utils/time'

/**
 * RFC3339 포맷팅
 * @param {String} Date '2022-09-01'
 * @returns {String} RFC3339 '2022-09-01T00:00:00.000Z'
 */
const parseDateToRFC3339 = date => {
  const _date = new Date(date)
  return _date.toISOString()
}

const formatNumber = num => {
  return parseInt(num).toLocaleString()
}

const requestYoutube = (url, params, method = 'get') => {
  const config = {
    method,
    url,
    transformRequest: (data, headers) => {
      delete headers.common['Authorization']
      return data
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }
  if (method === 'get') {
    config.params = params
  } else if (method === 'post') {
    config.data = params
  }
  return axios(config)
    .then(res => res.data)
    .catch(err => {
      console.error(err)
      throw new Error(err)
    })
}
/**
 * 채널정보
 * @return {String} channelName 채널명
 * @return {String} channelThumbnail 유튜브 썸네일
 * @return {Number} subscriberCount 총 구독자 수
 * @return {Number} viewCount 총 조회 수
 * @return {Number} videoCount 총 영상 수
 */
const queryChannelSummary = async token => {
  try {
    const {items} = await requestYoutube('https://www.googleapis.com/youtube/v3/channels', {
      mine: true,
      part: 'snippet,statistics',
      access_token: token,
      fields:
        'items(snippet(title, thumbnails(default), publishedAt), statistics(subscriberCount, viewCount, videoCount))',
    })

    const {
      snippet: {
        title: channelName,
        thumbnails: {
          default: {url: channelThumbnail},
        },
        publishedAt,
      },
      statistics: {subscriberCount, viewCount, videoCount},
    } = items[0]

    return {
      channelName,
      channelThumbnail,
      statistics: [
        {title: 'subscriberCount', desc: formatNumber(subscriberCount), unit: '명'},
        {title: 'videoCount', desc: formatNumber(videoCount), unit: '개'},
        {title: 'viewCount', desc: formatNumber(viewCount), unit: '회'},
      ],
      publishedAt,
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 내 채널의 비디오 정보 (id, title, publishedAt, thumbnails, likeCount, viewCount)
 * @param {Timestamp} publishedAfter format: RFC3339
 * @return {Array} videoList [{id, title, publishedAt, thumbnail}] order by viewCount
 * @return {Array} videoIds
 */
const queryVideoList = async (token, channelId, startDate, endDate) => {
  // 기간별 조회
  const {items} = await requestYoutube('https://www.googleapis.com/youtube/v3/search', {
    channelId,
    part: 'snippet',
    type: 'video',
    publishedAfter: startDate.format('YYYY-MM-DD') + 'T00:00:00Z',
    publishedBefore: endDate.format('YYYY-MM-DD') + 'T23:59:59Z',
    maxResults: 50,
    order: 'date',
    access_token: token,
  })

  if (!items.length) {
    return {videoList: []}
  }

  const videoIdList = map(items, 'id.videoId').join(',')
  const videoList = map(items, ({id, snippet}) => ({
    id: id.videoId,
    title: snippet.title,
    publishedAt: snippet.publishedAt,
    thumbnail: snippet.thumbnails.default.url,
  }))

  // 비디오별 통계 조회
  const {items: videoInfoList} = await requestYoutube('https://www.googleapis.com/youtube/v3/videos', {
    part: 'statistics',
    id: videoIdList,
    access_token: token,
  })

  return {
    videoList: map(videoList, video => {
      const videoInfo = find(videoInfoList, {id: get(video, 'id')})
      return {
        ...video,
        likeCount: videoInfo.statistics.likeCount,
        viewCount: videoInfo.statistics.viewCount,
      }
    }),
  }
}

/**
 * 기간별 채널의 시청시간, 구독자수, 조회 수
 * @param {String} startDate,endDate '2022-09-01'
 * @returns {*} views 조회 수
 * @returns {*} duration 시청시간
 * @returns {*} subscribers 구독자수
 */
const queryChannelAnalytics = async (token, {startDate, endDate}, isStatistics) => {
  const metrics = isStatistics ? 'comments,likes' : 'views,estimatedMinutesWatched,subscribersGained,subscribersLost'
  try {
    const {rows} = await requestYoutube('https://youtubeanalytics.googleapis.com/v2/reports', {
      metrics,
      ids: 'channel==MINE',
      startDate,
      endDate,
      access_token: token,
      fields: 'rows',
    })

    if (!rows.length) {
      rows[0] = [0, 0, 0, 0, 0, 0]
    }
    const row = rows[0]

    if (isStatistics) {
      return [formatNumber(row[0] || 0), formatNumber(row[1] || 0)]
    }
    return [
      {title: 'views', desc: formatNumber(row[0] || 0), unit: '회'},
      {
        title: 'duration',
        desc: getTimeBySecond(Number(row[1] || 0) * 60, {
          addZeroOneDigit: true,
          noSpacing: true,
          showZeroHours: true,
          hours: ':',
          minutes: ':',
          seconds: '',
        }),
      },
      {title: 'subscribers', desc: formatNumber(row[2] - row[3] || 0), unit: '명'},
    ]
  } catch (error) {
    console.error(error)
  }
}

const queryPlayList = async token => {
  // https://developers.google.com/youtube/v3/docs/playlists/list?hl=ko
  const {items} = await requestYoutube('https://www.googleapis.com/youtube/v3/playlists', {
    channelId: token.channel_id,
    access_token: token.access_token,
    part: 'id,snippet',
  })
  return items
}

const insertPlayList = async (token, snippet, status) => {
  const params = {
    snippet,
    status,
  }
  const response = await requestYoutube(
    `https://www.googleapis.com/youtube/v3/playlists?part=snippet,status&access_token=${token.access_token}`,
    JSON.stringify(params),
    'post',
  )

  return {id: response.id, title: response.snippet.title}
}

export {queryChannelSummary, queryChannelAnalytics, parseDateToRFC3339, queryVideoList, queryPlayList, insertPlayList}
