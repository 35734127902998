import Vue from 'vue'
import * as dayjs from 'dayjs'

const REFRESH_TOKEN_THRESHOLD = 5 * 60 * 1000 // Refresh token 5 minutes before expiration
const INTERVAL = 60 * 1000

class ProactiveAuth {
  static _timerId = 0

  tokenExpiredAt = 0

  constructor() {
    //
  }

  init(exp) {
    this.setTokenExpiredAt(exp)
    this.proactiveTokenRefresh()
    this.triggerTimer()
  }

  setTokenExpiredAt(value) {
    this.tokenExpiredAt = value
  }

  triggerTimer() {
    this._timerId = setTimeout(async () => {
      this.proactiveTokenRefresh()
      this.triggerTimer()
    }, INTERVAL)
  }

  cancelTimer() {
    clearTimeout(this._timerId)
  }

  async proactiveTokenRefresh() {
    const now = dayjs().unix()
    const threshold = dayjs(this.tokenExpiredAt - REFRESH_TOKEN_THRESHOLD).unix()

    if (!this.tokenExpiredAt || now < threshold) {
      return
    }

    try {
      Vue.prototype.$auth.refreshCustomToken()
    } catch (error) {
      console.error('Token refresh failed:', error)
    }
  }
}

export default ProactiveAuth
