import CONSTANTS from '@/config/constants'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'

class EmailLogin {
  static _instance = null
  constructor() {}

  signIn({email, password}) {
    return signInWithEmailAndPassword(getAuth(), email, password)
  }

  signOut() {}

  getProviderInstance() {
    return CONSTANTS.PROVIDER.PASSWORD
  }

  getLoginStatus() {
    return
  }

  getCredential() {
    return {}
  }

  static getInstance() {
    if (!this._instance) {
      this._instance = new EmailLogin()
    }
    return this._instance
  }
}

export default EmailLogin
