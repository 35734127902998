import { config } from '@/config';
import { editContainer } from '@/context';
export const dispatchEvent = (eventName, options) => {
    const customEvent = createCustomEvent(eventName, options);
    const needDispatchToOuterContainer = eventName === 'contextmenu';
    if (needDispatchToOuterContainer) {
        const { outerContainer } = config;
        outerContainer.dispatchEvent(customEvent);
        return;
    }
    editContainer.dispatchEvent(customEvent);
};
const createCustomEvent = (eventName, options) => {
    const detail = {
        data: options === null || options === void 0 ? void 0 : options.data,
        originEvent: options === null || options === void 0 ? void 0 : options.originEvent,
    };
    return new CustomEvent(eventName, { detail, bubbles: true });
};
