import {getAuth, signInWithCredential} from 'firebase/auth'
import CONSTANTS from '@/config/constants'
import GoogleLogin from '@/libs/modules/Account/GoogleLogin'
import EmailLogin from '@/libs/modules/Account/EmailLogin'
import FacebookLogin from '@/libs/modules/Account/FacebookLogin'

class AccountInstance {
  static _instance = null
  provider = ''
  $auth
  constructor($auth) {
    this.$auth = $auth
  }

  async getLoginInstance(provider) {
    switch (provider) {
      case CONSTANTS.PROVIDER.GOOGLE:
        return await GoogleLogin.getInstance()
      case CONSTANTS.PROVIDER.FACEBOOK:
        return await FacebookLogin.getInstance()
      case CONSTANTS.PROVIDER.PASSWORD:
        return await EmailLogin.getInstance()
      default:
        throw new Error('not allowd login type')
    }
  }
  /**
   * 로그인
   * @param {CONSTANTS.PROVIDER} provider google.com, facebook.com, password
   * @param {Object} data for password
   */
  async signIn(provider, data) {
    const loginInstance = await this.getLoginInstance(provider)
    const formattedData = await this.signInThirdParty({loginInstance, data})
    return this.signInCallback(provider, formattedData)
  }
  async signInCallback(provider, formattedData) {
    if (provider === CONSTANTS.PROVIDER.PASSWORD) {
      return formattedData
    }

    let isExistUserInFirebase = []
    const isExistUserData = {
      provider: formattedData.provider,
      idToken: provider === CONSTANTS.PROVIDER.GOOGLE ? formattedData.token : null,
      providerUid: provider === CONSTANTS.PROVIDER.FACEBOOK ? formattedData.providerUid : null,
    }
    const isExistUser = await this.isExistUser(isExistUserData)
    const loginInstance = await this.getLoginInstance(provider)
    if (!isExistUser.exists) {
      if (formattedData.email) {
        isExistUserInFirebase = await this.getProviderByEmail({email: formattedData.email})
      }
      if (isExistUserInFirebase.providers.length === 0) {
        throw {
          code: 'auth/not-exist-social-user',
        }
      } else if (!isExistUserInFirebase.providers.includes(provider)) {
        throw {
          code: 'auth/already-signup-different-provider',
          customData: {providers: isExistUserInFirebase.providers},
        }
      }
    }

    const credential = await this.getCredential({
      loginInstance,
      token: formattedData.token,
    })

    return this.signInUsingCredential(credential)
  }
  async signInThirdParty({loginInstance, provider, data}) {
    let _loginInstance = loginInstance
    if (!loginInstance && provider) {
      _loginInstance = await this.getLoginInstance(provider)
    }
    return _loginInstance.signIn(data)
  }
  async getLastLoginStatus(provider) {
    const loginInstance = await this.getLoginInstance(provider)
    return loginInstance.getLoginStatus()
  }
  async getCredential({loginInstance, provider, token}) {
    let _loginInstance = loginInstance
    if (!loginInstance && provider) {
      _loginInstance = await this.getLoginInstance(provider)
    }
    return _loginInstance.getCredential(token)
  }
  signInUsingCredential(credential) {
    // Sign in with credential from the Google user.
    return signInWithCredential(getAuth(), credential)
  }
  async signup({user, invitationToken, data, provider}) {
    try {
      const signupAuthData = await this._createSignUpAuthData(data, provider)
      const signupData = {
        user,
        invitationToken,
        auth: signupAuthData,
      }
      return this.$auth.signupV3(signupData)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  async signupAnonymous({language}) {
    return this.$auth.signupAnonymous({language})
  }
  async _createSignUpAuthData(data, provider) {
    try {
      const auth = await this.getLastLoginStatus(provider)
      const authData = {
        provider: provider,
      }
      switch (provider) {
        case CONSTANTS.PROVIDER.GOOGLE:
          authData.id_token = auth.token
          break
        case CONSTANTS.PROVIDER.FACEBOOK:
          authData.access_token = auth.token
          break
        case CONSTANTS.PROVIDER.PASSWORD:
          authData.email = data.email
          authData.password = data.password
      }
      return authData
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  async promoteAnonymous({user, invitationToken, data, provider, firebaseIdToken}) {
    try {
      const signupAuthData = await this._createSignUpAuthData(data, provider)
      const promoteData = {
        user,
        invitationToken,
        auth: signupAuthData,
        firebase_id_token: firebaseIdToken,
      }
      return this.$auth.promoteAnonymous(promoteData)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  async signOut(provider) {
    if (!provider) {
      return
    }
    const loginInstance = await this.getLoginInstance(provider)
    loginInstance.signOut()
  }

  isExistUser({provider, providerUid, idToken}) {
    return this.$auth.isExistAccount({provider, providerUid, idToken})
  }
  getProviderByEmail({email}) {
    return this.$auth.getProviderByEmail({email: encodeURIComponent(email)})
  }

  static getInstance($auth) {
    if (!this._instance) {
      this._instance = new AccountInstance($auth)
    }
    return this._instance
  }
  async getProviderInstance(provider) {
    const loginInstance = await this.getLoginInstance(provider)
    return loginInstance.getProviderInstance()
  }
}

export default AccountInstance
