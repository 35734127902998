export const styles = [
    'normal',
    'sad',
    'happy',
    'angry',
    'toneup',
    'tonemid',
    'tonedown',
    'regret',
    'urgent',
    'whisper',
    'scream',
    'shout',
    'trustful',
    'soft',
    'cold',
    'sarcasm',
    'inspire',
    'cute',
    'cheer',
    'hitune',
    'lowtune',
    'tunelv1',
    'tunelv2',
    'casual',
    'styletag',
]
    .map(style => Array.from({ length: 8 }, (_, i) => `${style}-${i + 1}`))
    .flatMap(style => style);
