export const URL_BASE = `${process.env.VUE_APP_VIDEO_API_URL}/api`
export const URL_VIDEO_SHOW = `${URL_BASE}/video/show`
export const URL_VIDEO_SHOW_CLONE = `${URL_BASE}/video/show/clone`
export const URL_IMAGE_ASSET_LIST = `${URL_BASE}/asset/list`
export const URL_IMAGE_ASSET = `${URL_BASE}/asset`
export const URL_IMAGE_ASSET_URL = `${URL_BASE}/asset-url`

export const URL_ASSET = `${process.env.VUE_APP_API_URL}/api/asset`
export const URL_SAMPLE_BGM_LIST = `${process.env.VUE_APP_API_URL}/api/asset-sample`
export const URL_SAMPLE_RECOMMENDED_BGM = `${process.env.VUE_APP_API_URL}/api/asset-sample/recommended-bgm`
export const URL_USER_ASSET_META = `${process.env.VUE_APP_API_URL}/api/user-asset-meta`

export const V3_URL_BASE = `${process.env.VUE_APP_VIDEO_API_V3_URL}/api/v3`
export const URL_CHARACTER_LIST = `api/video/virtual-actor-template/preview`
export const URL_VIDEO_SHOW_DOWNLOADING = `${V3_URL_BASE}/video/show/downloading`

export const ACTOR_URL = `${process.env.VUE_APP_API_URL}/api/actor`
export const ANONYMOUS_TEMPLATE_URL = `${process.env.VUE_APP_API_URL}/api/project/template/category`
