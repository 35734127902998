import Spinner from '@/components/v3/Spinner.vue'

export default {
  install(Vue) {
    const HIDE = 'hide'
    const REMOVE = 'remove'
    const ADD = 'add'
    let spinnerCount = 0
    let spinnerElement = null

    const mountSpinner = root => {
      root._spinner = new (Vue.extend(Spinner))({parent: root})
      root._spinner.$mount()
      root.$el.prepend(root._spinner.$el)
      spinnerElement = root._spinner.$refs.spinner
    }

    const setSpinnerClassName = () => {
      const action = spinnerCount > 0 ? REMOVE : ADD
      spinnerElement.classList[action](HIDE)
    }

    Vue.prototype.$showSpinner = function showSpinner() {
      if (!spinnerElement) {
        mountSpinner(this.$root)
      }

      // console.log('component name for debugging: ', this._self.$vnode.data.key)
      spinnerCount += 1
      setSpinnerClassName()
    }

    Vue.prototype.$hideSpinner = function hideSpinner() {
      if (!spinnerElement) {
        mountSpinner(this.$root)
      }

      // console.log('component name for debugging: ', this._self.$vnode.data.key)
      spinnerCount = Math.max(0, spinnerCount - 1)
      setSpinnerClassName()
    }
    Vue.prototype.$existSpinner = function existSpinner() {
      return spinnerCount > 0
    }
  },
}
