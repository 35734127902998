var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g({staticClass:"t-button",class:_setup.buttonVariants({
      size: _vm.size,
      xsmall: _vm.xsmall,
      small: _vm.small,
      medium: _vm.medium,
      large: _vm.large,
      type: _vm.type,
      primary: _vm.primary,
      secondary: _vm.secondary,
      primaryOutline: _vm.primaryOutline,
      secondaryOutline: _vm.secondaryOutline,
      primaryText: _vm.primaryText,
      secondaryText: _vm.secondaryText,
      noPadding: _vm.noPadding,
    }),attrs:{"disabled":_vm.disabled}},_vm.$listeners),[_c('span',{staticClass:"t-button-container"},[(_vm.leftIcon)?_c(_setup.TIcon,{attrs:{"icon-name":_vm.leftIcon}}):_vm._e(),_vm._t("default"),(_vm.rightIcon)?_c(_setup.TIcon,{staticClass:"right-icon",attrs:{"icon-name":_vm.rightIcon}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }