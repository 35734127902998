import { resetTargets } from '@/context';
import { emitEvent } from '@/emitEvent';
import { getPassedOrSelectedIdList } from '@/share/virtualDOM';
import { getVPropsById } from '@/share/virtualDOM/getVPropsById';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
export const orderSlideChildren = (orderMethod, maybeIdList) => {
    const orderIdList = getPassedOrSelectedIdList(maybeIdList);
    if (!orderIdList.length) {
        return;
    }
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex } = editorOptionsStore;
    const currentSlide = slideList[slideIndex];
    const slideChildren = currentSlide.children;
    const notChanged = slideChildren.length === orderIdList.length;
    if (notChanged) {
        return;
    }
    const groupedIndexList = getIndexListGroupByOrderTargets(slideChildren, orderIdList);
    orderGroupedIndexList(orderMethod, groupedIndexList);
    const movedIndexList = flatten(groupedIndexList);
    const reorderedSlideChildren = getReorderedSlideChildren(slideChildren, movedIndexList);
    currentSlide.children = reorderedSlideChildren;
    resetTargets();
    emitEvent('update-element');
};
const getIndexListGroupByOrderTargets = (slideChildren, orderIdList) => {
    const orderSlideItemList = orderIdList.map(getVPropsById);
    let groupedIndexIndex = 0;
    const groupedIndexList = slideChildren.reduce((groupedIndexList, slideItem, index) => {
        const isTarget = orderSlideItemList.includes(slideItem);
        if (!isTarget) {
            groupedIndexIndex++;
            groupedIndexList.push(index);
            return groupedIndexList;
        }
        const prevValue = groupedIndexList[groupedIndexIndex - 1];
        const isContinuousGroupedIndex = prevValue instanceof Array;
        if (isContinuousGroupedIndex) {
            prevValue.push(index);
            return groupedIndexList;
        }
        groupedIndexIndex++;
        groupedIndexList.push([index]);
        return groupedIndexList;
    }, []);
    return groupedIndexList;
};
const orderGroupedIndexList = (orderMethod, groupedIndexList) => {
    const clonedGroupedIndexList = [...groupedIndexList];
    let backIndex = 0;
    clonedGroupedIndexList.forEach(groupedIndex => {
        const isTarget = groupedIndex instanceof Array;
        if (!isTarget) {
            return;
        }
        const index = groupedIndexList.indexOf(groupedIndex);
        if (orderMethod === 'back') {
            move(groupedIndexList, index, backIndex++);
            return;
        }
        if (orderMethod === 'backward') {
            move(groupedIndexList, index, Math.max(index - 1, 0));
            return;
        }
        if (orderMethod === 'forward') {
            move(groupedIndexList, index, index + 1);
            return;
        }
        move(groupedIndexList, index, groupedIndexList.length);
    });
};
const move = (list, oldIndex, newIndex) => {
    const movedValue = list.splice(oldIndex, 1)[0];
    list.splice(newIndex, 0, movedValue);
    return list;
};
const getReorderedSlideChildren = (slideChildren, movedIndexList) => movedIndexList.reduce((reorderedSlideChildren, movedIndex, index) => {
    const reorderedSlideChild = slideChildren[movedIndex];
    reorderedSlideChildren[index] = reorderedSlideChild;
    return reorderedSlideChildren;
}, []);
const flatten = (list) => list.reduce((result, element) => {
    if (element instanceof Array) {
        result.push(...element);
        return result;
    }
    result.push(element);
    return result;
}, []);
