import CONSTANTS from '@/config/constants';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AdjustStyleNameParams(_target, _propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor.value = function (...args) {
        const params = args[0];
        const { styleName } = params;
        const ERROR_MESSAGE = `AdjustStyleNameParams Invalid styleName value: ${styleName}`;
        if (typeof styleName === 'number' && styleName !== 0) {
            console.error(ERROR_MESSAGE);
            throw new Error(ERROR_MESSAGE);
        }
        if (typeof styleName === 'string' && styleName.includes('-')) {
            const parts = styleName.split('-');
            if (parts.length > 1 && (isNaN(Number(parts[1])) || Number(parts[1]) < 1)) {
                console.error(ERROR_MESSAGE);
                throw new Error(ERROR_MESSAGE);
            }
        }
        // 'normal-1' 으로 재 설정
        if (CONSTANTS.DEFAULT_STYLE_TAGS.includes(styleName)) {
            params.styleName = CONSTANTS.DEFAULT_STYLE_TAGS[2]; // 'normal-1'
        }
        return originalMethod.apply(this, args);
    };
    return descriptor;
}
