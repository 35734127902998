function floatingTooltip() {
  const tooltip = document.createElement('div')
  const tooltipStyle = tooltip.style
  tooltip.setAttribute('id', 'mouse-floating-tooltip')
  document.body.appendChild(tooltip)

  const hideTooltip = () => {
    tooltip.style.visibility = 'hidden'
  }

  return {
    inserted: function (el, {value}) {
      if (!(value?.text || value)) {
        return
      }
      const textValue = value?.text || value

      el.addEventListener('mouseenter', () => {
        if (value.style) {
          tooltip.setAttribute('style', '')
          Object.assign(tooltipStyle, value.style)
        }
        tooltip.innerHTML = textValue
      })

      el.addEventListener('mousemove', ({pageX, pageY}) => {
        const style = {
          top: pageY + 12 + 'px',
          left: pageX + 12 + 'px',
          visibility: 'visible',
        }
        Object.assign(tooltipStyle, style)
      })

      el.addEventListener('mouseleave', () => {
        tooltip.removeAttribute('style')
        hideTooltip()
      })

      document.addEventListener('touchmove', hideTooltip, {passive: false})
    },
    unbind: function () {
      hideTooltip()
      document.removeEventListener('touchmove', hideTooltip)
    },
  }
}
export default floatingTooltip()
