/**
 * 최대 길이를 넘지 않도록 붙여넣어질 텍스트를 자름
 *
 * @param originText 원본 텍스트
 * @param selectionStart 붙여넣기 시작할 위치
 * @param selectionEnd 붙여넣기 끝낼 위치
 * @param pasteText 붙여넣기 될 텍스트
 * @param maxLength 최대 길이
 */
export const pasteWithMaxLength = (originText, selectionStart, selectionEnd, pasteText, maxLength) => {
    const selectionRange = selectionEnd - selectionStart;
    const remainLength = maxLength - originText.length + selectionRange;
    const slicedPastedText = pasteText.slice(0, remainLength);
    const newText = originText.slice(0, selectionStart) +
        slicedPastedText +
        originText.slice(selectionEnd);
    return newText;
};
