import Vue from 'vue'
import Vuex from 'vuex'
import create from './modules/create'
import payment from './modules/payment'
import screen from './modules/screen'
import userStore from '@/store/modules/user'
import queryCacheStore from '@/store/modules/queryCache'
import editorStore from '@/store/modules/editor'
import actorStore from '@/store/modules/actor'
import createStore from '@/store/modules/create'
import projectStore from '@/store/modules/project'
import videoEditorStore from '@/store/modules/videoEditor/index.js'
import youtubeStore from '@/store/modules/youtube'

Vue.use(Vuex)
const store = new Vuex.Store({
  devtools: false,
  modules: {
    create,
    payment,
    screen,
    typecast: {
      namespaced: true,
      modules: {
        user: userStore,
        queryCache: queryCacheStore,
        editor: editorStore,
        actor: actorStore,
        download: {
          namespaced: true,
          state: () => ({}),
        },
        create: createStore,
        project: projectStore,
        videoEditor: videoEditorStore,
        youtube: youtubeStore,
      },
    },
  },
})

export default store
