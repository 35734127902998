function nsHash(s) {
  let h
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
  }
  return h
}

function createQueryHash({
  text,
  actor,
  style,
  speed,
  customSpeed,
  pitch,
  tempo,
  lastPitch,
  id,
  styleVersion,
  styleTag,
  takeNumber = 0,
}) {
  let hashData = `${text}${actor}${style}${speed}${pitch}${tempo}${lastPitch}${id}${takeNumber}`
  if (styleTag) {
    hashData = `${hashData}${styleTag}`
  }
  if (customSpeed) {
    hashData = `${hashData}${customSpeed}`
  }
  if (styleVersion) {
    hashData = `${hashData}${styleVersion}`
  }
  return nsHash(hashData)
}

export default nsHash

export {createQueryHash}
