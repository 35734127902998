import * as TypeGuards from './ProjectV4.guard';
import Project from '@/projects/Project';
import DetailV4 from '@/projects/v4/DetailV4';
import ProjectV10 from '@/projects/v10/ProjectV10';
export default class ProjectV4 extends Project {
    constructor(project) {
        super(project);
        this.version = project.version;
        this.v4 = new DetailV4(project.v4);
    }
    isProject(project) {
        return TypeGuards.isProjectV4(project);
    }
    migrate() {
        const paragraphs = this.v4.paragraphs.map(paragraph => createParagraph(paragraph));
        return new ProjectV10(Object.assign(Object.assign({}, this), { version: 10, v10: {
                tiptap: {
                    type: 'doc',
                    content: paragraphs,
                },
            } }), []).migrate();
    }
}
const createParagraph = (paragraph) => {
    return {
        type: 'paragraph',
        attrs: {
            actor: paragraph.actor_id,
            rest: paragraph.pre_silence,
        },
        content: paragraph.data
            .filter(node => node.type === 'sentence')
            .map((sentence, i) => createSentence(sentence, i === 0 ? paragraph.pre_silence : 0)),
    };
};
const createSentence = (sentence, preSilence) => {
    const isCustomSpeedEnabled = sentence.force_length === '1';
    const speed = isCustomSpeedEnabled ? 1.6 : sentence.speed;
    const customSpeed = isCustomSpeedEnabled ? sentence.max_seconds : 0;
    return {
        type: 'text',
        text: sentence.text,
        marks: [
            {
                type: 'query',
                attrs: {
                    style: sentence.style,
                    speed: speed,
                    silence: sentence.silence,
                    preSilence,
                    customSpeed,
                    pitch: sentence.pitch,
                    tempo: sentence.tempo,
                },
            },
        ],
    };
};
export { TypeGuards };
