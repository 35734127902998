const mountedHooks = {};
export const OnPiniaMountedPlugin = ({ store: { $id }, }) => {
    if (!mountedHooks[$id]) {
        return;
    }
    mountedHooks[$id].forEach(hook => hook());
    delete mountedHooks[$id];
};
export const onPiniaMounted = (storeId, hook) => {
    if (!mountedHooks[storeId]) {
        mountedHooks[storeId] = [];
    }
    mountedHooks[storeId].push(hook);
};
