let cnt = 0;
let storedTime = Date.now();
export const uniqueId = () => {
    const now = Date.now();
    if (storedTime !== now) {
        storedTime = now;
        cnt = 0;
    }
    return `${storedTime}-${cnt++}`;
};
