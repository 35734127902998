import { config } from '@/config';
export const resizeWithParentElement = (container) => {
    const outerContainer = container.parentElement;
    if (!outerContainer) {
        return;
    }
    const { isShortsProject } = config;
    const { offsetWidth: containerWidth, offsetHeight: containerHeight } = container;
    const { offsetWidth: outerContainerWidth, offsetHeight: outerContainerHeight, } = outerContainer;
    if (isShortsProject) {
        const resolution = outerContainerHeight / containerHeight;
        container.style.setProperty('transform', `scale(${resolution})`);
        outerContainer.style.setProperty('width', `${containerWidth * resolution}px`);
        return;
    }
    const resolution = outerContainerWidth / containerWidth;
    container.style.setProperty('transform', `scale(${resolution})`);
    outerContainer.style.setProperty('height', `${containerHeight * resolution}px`);
};
