<template>
  <div>
    <Component
      v-for="{id, component, props, resolve, reject, emits} in modalList"
      v-bind="props"
      :key="id"
      :is="component"
      @resolve="result => resolveHandler(resolve, {id, res: result, props})"
      @reject="({err, close}) => rejectHandler(reject, {id, err, close})"
      v-on="emits"
    />
  </div>
</template>

<script setup>
import {useModal} from '@/composables/useModal'

const {modalList, closeModal} = useModal()

const resolveHandler = (resolve, params) => {
  resolve(params)
  closeModal(params.id)
}

const rejectHandler = (reject, params) => {
  reject(params)

  if (params.close) {
    closeModal(params.id)
  }
}
</script>
