const convertNamingConvention = (stringConvertor) => (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
    const convertedKey = `${key[0]}${stringConvertor(key.slice(1))}`;
    let convertedValue = value;
    if (value instanceof Array) {
        convertedValue = value.map(el => el instanceof Object
            ? convertNamingConvention(stringConvertor)(el)
            : el);
    }
    else if (value instanceof Object) {
        convertedValue = convertNamingConvention(stringConvertor)(value);
    }
    acc[convertedKey] = convertedValue;
    return acc;
}, {});
const snakeStringToCamelString = str => str
    .split('_')
    .reduce((acc, str) => `${acc}${str[0].toUpperCase()}${str.slice(1)}`);
export const snakeToCamel = convertNamingConvention(snakeStringToCamelString);
const camelStringToSnakeString = str => str.replace(/([A-Z])/g, '_$1').toLowerCase();
export const camelToSnake = convertNamingConvention(camelStringToSnakeString);
