// @ts-ignore
import store from '@/store';
import { useEditorOptionsStore } from 'store/editor';
import { isTiptapTextNode } from 'utils/typecast/isTiptapTextNode';
export const getCachedQuery = (queryId) => {
    if (!queryId) {
        return;
    }
    const queries = store.getters['typecast/queryCache/queries'];
    return queries[queryId];
};
/** @public */
export const getCurrentCachedQuery = () => {
    const queryId = store.state.typecast.editor.currentTextNodeMarkAttrs.id || undefined;
    const query = getCachedQuery(queryId);
    if (!query) {
        return;
    }
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex: currentSlideIndex, currentSlideId } = editorOptionsStore;
    const isRealCurrentQuery = query.paragraphIndex === currentSlideIndex;
    if (isRealCurrentQuery) {
        return query;
    }
    const tiptapContentDoc = store.state.typecast.editor.tiptapContent;
    const lastQuery = getLastQueryFromCurrentSlide(tiptapContentDoc, currentSlideId);
    return lastQuery;
};
const getLastQueryFromCurrentSlide = ({ content }, slideId) => {
    var _a;
    const currentParagraphIndex = content.findIndex(({ attrs }) => attrs.id === slideId);
    const currentParagraph = content[currentParagraphIndex];
    const currentParagraphContent = (_a = currentParagraph === null || currentParagraph === void 0 ? void 0 : currentParagraph.content) !== null && _a !== void 0 ? _a : [];
    const hasNoText = !currentParagraphContent.length;
    if (hasNoText) {
        return;
    }
    const lastText = [...currentParagraphContent]
        .reverse()
        .find(isTiptapTextNode);
    if (!lastText) {
        return;
    }
    return Object.assign(Object.assign({}, lastText.marks[0].attrs), { text: lastText.text, paragraphIndex: currentParagraphIndex });
};
export const getCurrentPlayBufferItemQueryId = () => { var _a; return (_a = store.state.typecast.queryCache.currentPlayBufferItem) === null || _a === void 0 ? void 0 : _a.queryId; };
