import libs from './libs'

/**
 * @module typecast
 */

/**
 * @function install
 * @param {Object} options install 파라미터
 * @param {Object} options.store Vuex Store
 * @param {Object} options.router Vue Router
 * @example
 * // main.js
 * import TypecastSDK from '@neosapience/typecast'
 * Vue.use(TypecastSDK, {
 *  // Vue Router
 *  router: router,
 *
 *  // Vuex Store
 *  store: $store
 * })
 */
function install(Vue, options = {}) {
  Vue.prototype.$typecast = Vue.$typecast = libs({
    Vue: Vue,
    router: options.router,
    store: options.store,
  })

  Vue.prototype.$http = Vue.prototype.$typecast.$http
  Vue.prototype.$auth = Vue.prototype.$typecast.$auth
}

export default {
  install,
}
