var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _DotsTakeCache_cache;
var _a;
import { ValidateObjectParams } from '@/types/decorators/ValidateObjectParams';
import { AdjustStyleNameParams } from '@/types/decorators/AdjustStyleNameParams';
import nsHash, { createQueryHash } from '@/utils/nsHash';
import store from '@/store';
import { getActorStyleLabel } from '@/libs/modules/actor/actor-version-helpers';
export const isValidQueryCacheItem = (queryCacheItemHash) => {
    const queryCacheItems = store.getters['typecast/queryCache/queryCacheItems'];
    const queryCacheItem = queryCacheItems[queryCacheItemHash];
    return !!(queryCacheItem === null || queryCacheItem === void 0 ? void 0 : queryCacheItem.speak);
};
export class DotsTakeCache {
    constructor() {
        _DotsTakeCache_cache.set(this, {});
        //Singleton
    }
    static getInstance() {
        if (!DotsTakeCache.instance) {
            DotsTakeCache.instance = new DotsTakeCache();
        }
        return DotsTakeCache.instance;
    }
    getCache() {
        return __classPrivateFieldGet(this, _DotsTakeCache_cache, "f");
    }
    createCacheFromTiptapDoc(tiptap, styleLabelVersionList) {
        var _a, _b;
        if (!tiptap || !((_a = tiptap.content) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        (_b = tiptap.content) === null || _b === void 0 ? void 0 : _b.forEach(paragraph => {
            var _a;
            (_a = paragraph.content) === null || _a === void 0 ? void 0 : _a.forEach(node => {
                var _a, _b;
                if (node.type === 'text' && ((_a = paragraph.attrs) === null || _a === void 0 ? void 0 : _a.actor) && styleLabelVersionList) {
                    this.createCacheFromTiptapText(node, (_b = paragraph.attrs) === null || _b === void 0 ? void 0 : _b.actor, styleLabelVersionList);
                }
            });
        });
    }
    createCacheFromTiptapText(tiptapText, actor, styleLabelVersionList) {
        var _a;
        (_a = tiptapText.marks) === null || _a === void 0 ? void 0 : _a.forEach(mark => {
            try {
                const text = tiptapText.text;
                const attrs = mark.attrs || {};
                const styleVersion = styleLabelVersionList[actor];
                const queryCacheItemHash = String(createQueryHash(Object.assign(Object.assign({}, attrs), { styleVersion, text, actor })));
                if (!isValidQueryCacheItem(queryCacheItemHash)) {
                    return;
                }
                const params = {
                    queryId: attrs.id,
                    text,
                    actorId: actor,
                    styleName: attrs.style,
                    pitch: attrs.pitch,
                    tempo: attrs.tempo,
                    queryCacheItemHash,
                    takeNumber: attrs.takeNumber,
                };
                this.addToCache(params);
            }
            catch (e) {
                console.error('createCacheFromTiptapText', e);
            }
        });
    }
    transportingTiptapDocWithTakeNumber(params) {
        var _a;
        const { tiptap, queryCacheItems } = params;
        if (!tiptap || !queryCacheItems) {
            return tiptap;
        }
        (_a = tiptap.content) === null || _a === void 0 ? void 0 : _a.forEach(paragraph => {
            var _a;
            (_a = paragraph.content) === null || _a === void 0 ? void 0 : _a.forEach(node => {
                var _a, _b;
                if (node.type === 'text') {
                    if (!((_a = node.marks) === null || _a === void 0 ? void 0 : _a[0])) {
                        return;
                    }
                    const textNodeAttrs = node.marks[0].attrs;
                    const queryId = textNodeAttrs.id;
                    const matchedQueryCacheItem = Object.values(queryCacheItems).find(queryCacheItem => queryCacheItem.queryId === queryId);
                    if (!(matchedQueryCacheItem === null || matchedQueryCacheItem === void 0 ? void 0 : matchedQueryCacheItem.speak.query.actor_id) ||
                        typeof matchedQueryCacheItem.speak.query.takeNumber !== 'number') {
                        return;
                    }
                    textNodeAttrs.takeNumber = (_b = matchedQueryCacheItem.speak.query.takeNumber) !== null && _b !== void 0 ? _b : 0;
                }
            });
        });
        return tiptap;
    }
    createStyle(styleName, pitch, tempo, globalTakeNumber, queryCacheItemHash) {
        return {
            name: styleName,
            pitch: pitch,
            tempo: tempo,
            takeNumber: globalTakeNumber,
            isLastTakeNumber: true,
            queryCacheItemHash,
        };
    }
    generateKey(queryId, text, actorId) {
        const styleLabelVersionList = store.getters['typecast/queryCache/styleLabelVersionList'];
        const version = styleLabelVersionList[actorId];
        return String(nsHash(`${queryId}&${text.trim()}&${actorId}&${version}`));
    }
    addToCache(params) {
        var _a;
        const { queryId, text, actorId, styleName, pitch, tempo, queryCacheItemHash } = params;
        const key = this.generateKey(queryId, text, actorId);
        let style = null;
        const cacheItem = __classPrivateFieldGet(this, _DotsTakeCache_cache, "f")[key];
        const takeNumber = (_a = params.takeNumber) !== null && _a !== void 0 ? _a : 0;
        if (!cacheItem) {
            // actorId 기준으로 최초 생성
            const globalTakeNumber = takeNumber;
            style = this.createStyle(styleName, pitch, tempo, globalTakeNumber, queryCacheItemHash);
            __classPrivateFieldGet(this, _DotsTakeCache_cache, "f")[key] = {
                globalTakeNumber,
                styles: [style],
            };
        }
        else {
            // 기존 cacheItem에 style 항목 추가
            const globalTakeNumber = takeNumber > cacheItem.globalTakeNumber ? takeNumber : cacheItem.globalTakeNumber + 1;
            cacheItem.globalTakeNumber = globalTakeNumber;
            style = this.createStyle(styleName, pitch, tempo, globalTakeNumber, queryCacheItemHash);
            cacheItem.styles.push(style);
        }
        this.updateLastTakeNumber({ queryId, text, actorId, styleName, pitch, tempo, takeNumber: style.takeNumber });
        return style;
    }
    isDotsModelActorFromSpeak(speak) {
        const { actor_id: actorId, style_label_version: version } = speak.query;
        const actors = store.getters['typecast/actor/actors'];
        const actor = actors.find(actor => actor.actor_id === actorId);
        const actorStyleLabel = getActorStyleLabel(actor, version);
        return !!(actorStyleLabel === null || actorStyleLabel === void 0 ? void 0 : actorStyleLabel.flags.includes('modeltype-ssfm'));
    }
    addToCacheWithSpeak(params) {
        const { queryId, speak, hash } = params;
        if (!this.isDotsModelActorFromSpeak(speak)) {
            return;
        }
        const query = speak.query;
        const text = query.text;
        const actorId = query.actor_id;
        const styleName = query.style_label;
        const takeNumber = query.takeNumber;
        const pitch = query.pitch;
        const tempo = query.tempo;
        const cacheParams = {
            queryId,
            text,
            actorId,
            styleName,
            pitch,
            tempo,
            queryCacheItemHash: hash,
            takeNumber,
        };
        this.addToCache(cacheParams);
    }
    getFromCache(params) {
        var _a;
        const { queryId, text, actorId } = params;
        const key = this.generateKey(queryId, text, actorId);
        return (_a = __classPrivateFieldGet(this, _DotsTakeCache_cache, "f")[key]) !== null && _a !== void 0 ? _a : null;
    }
    getStylesWithLastTakeNumber(params) {
        const cacheItem = this.getFromCache(params);
        if (!cacheItem) {
            throw new Error('Cache item not found');
        }
        return cacheItem.styles.filter(style => style.isLastTakeNumber);
    }
    getLastTakeNumberFromStyle(params) {
        var _a;
        const cacheItem = this.getFromCache(params);
        if (!cacheItem) {
            return null;
        }
        if (!cacheItem.styles.length) {
            return null;
        }
        const targetStyle = cacheItem.styles
            .filter(style => style.name === params.styleName && style.pitch === params.pitch && style.tempo === params.tempo)
            .find(style => style.isLastTakeNumber);
        return (_a = targetStyle === null || targetStyle === void 0 ? void 0 : targetStyle.takeNumber) !== null && _a !== void 0 ? _a : -1;
    }
    updateLastTakeNumber(params) {
        const cacheItem = this.getFromCache(params);
        if (!cacheItem) {
            throw new Error('Cache item not found');
        }
        const { styleName, pitch, tempo, takeNumber } = params;
        const lastTakeStyle = cacheItem.styles.find(style => style.takeNumber === takeNumber && style.name === styleName && style.pitch === pitch && style.tempo === tempo);
        if (!lastTakeStyle) {
            throw new Error('takeNumber not found');
        }
        cacheItem.styles.forEach(style => {
            if (style.name === styleName && style.pitch === pitch && style.tempo === tempo) {
                style.isLastTakeNumber = style.takeNumber === takeNumber;
            }
        });
    }
    updateSpeakTakeNumber(params) {
        const queries = store.getters['typecast/queryCache/queries'];
        const query = queries[params.queryId];
        const takeNumber = query.takeNumber;
        if (typeof takeNumber === 'number' && takeNumber >= 0) {
            params.speak.query['takeNumber'] = takeNumber;
        }
        return params.speak;
    }
    hasSameStyleCache(cacheItem, styleName) {
        return cacheItem.styles.findIndex(style => style.name.split('-')[0] === styleName) !== -1;
    }
    updateTextNodeLastTakeNumber(textNode, actorId) {
        var _a, _b;
        const attrs = (_a = textNode === null || textNode === void 0 ? void 0 : textNode.marks) === null || _a === void 0 ? void 0 : _a[0].attrs;
        if (!attrs) {
            return;
        }
        const params = {
            queryId: attrs.id,
            text: textNode.text,
            actorId,
            styleName: attrs.style,
            pitch: attrs.pitch,
            tempo: attrs.tempo,
        };
        const lastTakeNumber = this.getLastTakeNumberFromStyle(params);
        if (attrs.takeNumber === lastTakeNumber) {
            return;
        }
        // NOTE: cache가 없는 경우
        if (lastTakeNumber === null) {
            attrs.takeNumber = 0;
            return;
        }
        // NOTE: 못찾은 경우 최신 takeNumber로 발급해준다
        // 최신 정보는 globalTakeNumber + 1 이거나 0
        if (lastTakeNumber === -1) {
            const cacheItem = this.getFromCache(params);
            const globalTakeNumber = (_b = cacheItem === null || cacheItem === void 0 ? void 0 : cacheItem.globalTakeNumber) !== null && _b !== void 0 ? _b : -1;
            attrs.takeNumber = globalTakeNumber + 1;
            return;
        }
        // NOTE: 찾은 경우 lastTakeNumber 로 업데이트
        attrs.takeNumber = lastTakeNumber;
    }
    updateTiptapStateWithLastTakeNumber(newState, params) {
        const { queryId, actorId, paragraphId } = params;
        if (!queryId || !actorId || !paragraphId) {
            return;
        }
        const targetParagraphNode = newState.doc.content.content.find((paragraph) => paragraph.attrs.id === paragraphId && paragraph.attrs.actor === actorId);
        const targetTextNode = targetParagraphNode === null || targetParagraphNode === void 0 ? void 0 : targetParagraphNode.content.content.find((node) => {
            var _a;
            return node.type.name === 'text' && ((_a = node.marks) === null || _a === void 0 ? void 0 : _a.find((mark) => mark.attrs.id === queryId));
        });
        this.updateTextNodeLastTakeNumber(targetTextNode, actorId);
    }
    updateTiptapStateWithLastTakeNumberForAll(newState) {
        newState.doc.descendants((paragraph) => {
            paragraph.descendants((node) => {
                if (node.type.name === 'text') {
                    this.updateTextNodeLastTakeNumber(node, paragraph.attrs.actor);
                }
                return false;
            });
            // NOTE: https://prosemirror.net/docs/ref/#model.Node.descendants
            // descendants 사용중 1 depth 사용함
            return false;
        });
    }
    clear() {
        __classPrivateFieldSet(this, _DotsTakeCache_cache, {}, "f");
    }
}
_DotsTakeCache_cache = new WeakMap();
__decorate([
    ValidateObjectParams,
    AdjustStyleNameParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], DotsTakeCache.prototype, "addToCache", null);
__decorate([
    ValidateObjectParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], DotsTakeCache.prototype, "getFromCache", null);
__decorate([
    ValidateObjectParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], DotsTakeCache.prototype, "getStylesWithLastTakeNumber", null);
__decorate([
    ValidateObjectParams,
    AdjustStyleNameParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], DotsTakeCache.prototype, "getLastTakeNumberFromStyle", null);
__decorate([
    ValidateObjectParams,
    AdjustStyleNameParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof UpdateToCacheParams !== "undefined" && UpdateToCacheParams) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], DotsTakeCache.prototype, "updateLastTakeNumber", null);
__decorate([
    ValidateObjectParams,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DotsTakeCache.prototype, "updateSpeakTakeNumber", null);
