import Vue from 'vue';
import VueI18n from 'vue-i18n';
const I18nPlugin = {
    install(Vue) {
        const _$t = Vue.prototype.$t;
        Vue.prototype._$t = _$t;
        Vue.prototype.$t = function (...args) {
            if (this.$i18n) {
                return _$t.apply(this, args);
            }
            else {
                return _$t.apply(this.$root, args);
            }
        };
    },
};
Vue.use(VueI18n);
Vue.use(I18nPlugin);
export const LANGUAGE = {
    KOREAN: 'ko',
    ENGLISH: 'en',
};
export const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || LANGUAGE.ENGLISH,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || LANGUAGE.ENGLISH,
    messages: {},
    // silentTranslationWarn: process.env.NODE_ENV === 'production',
    silentTranslationWarn: true,
});
const loadedLanguages = [];
export function loadLanguageAsync(lang) {
    if (loadedLanguages.includes(lang)) {
        return;
    }
    return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}.json`).then(messages => {
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang);
    });
}
