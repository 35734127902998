import { cloneDeep, requiredProps } from 'utils';
import { createVElementId } from '@/share/virtualDOM';
import { config } from '@/config';
import { moveable, selecto, setTargets, updateTargetRect } from '@/context';
import { frameToTransform, transformToFrame } from '@/share/context';
import { nextRenderTick } from '@/render/nextRenderTick';
import { getSlide } from '@/share/virtualDOM/getSlide';
import { emitEvent } from '@/emitEvent';
const ADDED_ELEMENT_MOVE_OFFSET = 20;
/** @public */
export const addElement = (props, addOptions) => {
    const { type } = props;
    if (type === 'image') {
        return addImageElement(props, addOptions);
    }
    console.warn('Invalid element type', type);
};
/** @public */
export const addImageElement = (vProps, addOptions) => {
    const imageElementVProps = cloneDeep(vProps);
    requiredProps(imageElementVProps, ['assetId']);
    imageElementVProps.type = 'image';
    setId(imageElementVProps);
    setMaxSize(imageElementVProps);
    setDefaultSize(imageElementVProps);
    if (!(addOptions === null || addOptions === void 0 ? void 0 : addOptions.noShiftOffset)) {
        shiftOffsetIfElementAlreadyExists(imageElementVProps);
    }
    moveElementInsideCanvas(imageElementVProps);
    selectElementAfterRender(imageElementVProps);
    const slide = getSlide(imageElementVProps.slideIndex);
    // TODO: VProps 타입에서 slideIndex 제거 (slideIndex는 옵션으로 전달받도록)
    delete imageElementVProps.slideIndex;
    slide.children.push(imageElementVProps);
    emitEvent('update-element');
};
const setId = (vProps) => {
    if (vProps.id) {
        return;
    }
    vProps.id = createVElementId();
};
const setMaxSize = (vProps) => {
    if (!vProps.style) {
        vProps.style = {};
    }
    vProps.style.maxWidth = '100%';
    vProps.style.maxHeight = '100%';
};
const cachedAssetSizeMap = {};
const setDefaultSize = (vProps) => {
    const { assetId, assetWidth, assetHeight } = vProps;
    if (assetWidth && assetHeight) {
        cachedAssetSizeMap[assetId] = getValidSize({
            width: assetWidth,
            height: assetHeight,
        });
    }
    const cachedAssetSize = cachedAssetSizeMap[assetId];
    if (!cachedAssetSize) {
        nextRenderTick(vProps, element => element.addEventListener('load', () => {
            const { naturalWidth, naturalHeight } = element;
            cachedAssetSizeMap[assetId] = getValidSize({
                width: naturalWidth,
                height: naturalHeight,
            });
        }));
        return;
    }
    if (!vProps.style) {
        vProps.style = {};
    }
    if (!vProps.style.width) {
        vProps.style.width = `${cachedAssetSize.width}px`;
    }
    if (!vProps.style.height) {
        vProps.style.height = `${cachedAssetSize.height}px`;
    }
};
const getValidSize = (size) => {
    const { originHeight, originWidth } = config;
    const { width, height } = size;
    const isWidthOverflow = width > originWidth;
    const isHeightOverflow = height > originHeight;
    if (!isWidthOverflow && !isHeightOverflow) {
        return size;
    }
    const widthRatio = originWidth / width;
    const heightRatio = originHeight / height;
    if (isHeightOverflow) {
        return {
            width: width * heightRatio,
            height: originHeight,
        };
    }
    if (isWidthOverflow) {
        return {
            width: originWidth,
            height: height * widthRatio,
        };
    }
    const isHeightBigger = height > width;
    if (isHeightBigger) {
        return {
            width: width * heightRatio,
            height: originHeight,
        };
    }
    return {
        width: originWidth,
        height: height * widthRatio,
    };
};
const shiftOffsetIfElementAlreadyExists = (vProps) => {
    var _a;
    const selectedElements = selecto.getSelectedTargets();
    const isSelected = selectedElements.length;
    if (isSelected) {
        const moveableArea = moveable.getRect();
        const { left: translateX, top: translateY } = moveableArea;
        const baseTranslate = [translateX, translateY];
        moveRightBottom(vProps, baseTranslate);
        return;
    }
    const targetSlide = getSlide(vProps.slideIndex);
    const [lastSlideChild] = targetSlide.children.slice(-1);
    if (!lastSlideChild) {
        return;
    }
    const baseTranslate = transformToFrame((_a = lastSlideChild.style) === null || _a === void 0 ? void 0 : _a.transform).translate;
    moveRightBottom(vProps, baseTranslate);
};
const moveRightBottom = (vProps, baseTranslate) => {
    const [baseTranslateX, baseTranslateY] = baseTranslate;
    const newTranslateX = baseTranslateX + ADDED_ELEMENT_MOVE_OFFSET;
    const newTranslateY = baseTranslateY + ADDED_ELEMENT_MOVE_OFFSET;
    move(vProps, [newTranslateX, newTranslateY]);
};
const move = (vProps, translate) => {
    if (!vProps.style) {
        vProps.style = {};
    }
    const originFrame = transformToFrame(vProps.style.transform);
    originFrame.translate = translate;
    const newTransform = frameToTransform(originFrame);
    vProps.style.transform = newTransform;
};
const moveElementInsideCanvas = (vProps) => {
    const { style } = vProps;
    if (!(style === null || style === void 0 ? void 0 : style.transform)) {
        return;
    }
    const { originWidth, originHeight } = config;
    const { transform, width, height } = style;
    const frame = transformToFrame(transform);
    const { translate } = frame;
    const [translateX, translateY] = translate;
    const isWidthOverflow = translateX + parseInt(width !== null && width !== void 0 ? width : '0') < 0 || translateX > originWidth;
    const isHeightOverflow = translateY + parseInt(height !== null && height !== void 0 ? height : '0') < 0 || translateY > originHeight;
    if (!isWidthOverflow && !isHeightOverflow) {
        return;
    }
    move(vProps, [0, 0]);
};
const selectElementAfterRender = (vProps) => nextRenderTick(vProps, element => {
    setTargets([element]);
    element.addEventListener('load', updateTargetRect);
});
