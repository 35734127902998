/*
 * Generated type guards for "TiptapDetail.ts".
 * WARNING: Do not manually change this file.
 */
import { isDetail } from "./Detail.guard";
import { isNode } from "../types/tiptap.guard";
export function isTiptapDetail(obj) {
    const typedObj = obj;
    return (isDetail(typedObj) &&
        (typeof typedObj["tiptap"] === "undefined" ||
            typedObj["tiptap"] === null ||
            (typedObj["tiptap"] !== null &&
                typeof typedObj["tiptap"] === "object" ||
                typeof typedObj["tiptap"] === "function") &&
                typedObj["tiptap"]["type"] === "doc" &&
                Array.isArray(typedObj["tiptap"]["content"]) &&
                typedObj["tiptap"]["content"].every((e) => (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                    e["type"] === "paragraph" &&
                    (typeof e["content"] === "undefined" ||
                        Array.isArray(e["content"]) &&
                            e["content"].every((e) => isNode(e))) &&
                    (e["attrs"] !== null &&
                        typeof e["attrs"] === "object" ||
                        typeof e["attrs"] === "function") &&
                    (typeof e["attrs"]["id"] === "undefined" ||
                        e["attrs"]["id"] === null ||
                        typeof e["attrs"]["id"] === "string") &&
                    (typeof e["attrs"]["actor"] === "undefined" ||
                        e["attrs"]["actor"] === null ||
                        typeof e["attrs"]["actor"] === "string") &&
                    (typeof e["attrs"]["rest"] === "undefined" ||
                        e["attrs"]["rest"] === null ||
                        typeof e["attrs"]["rest"] === "number"))));
}
