/**
 * @param {string} url
 * @param {[string, string] | [string, string][]} params
 */
import {snakeToCamel} from 'utils/src/convertNamingConvention'

export const encodeUrlParams = (url, params) => {
  const encodedParams = []

  if (params[0] instanceof Array) {
    // eslint-disable-next-line no-unused-vars
    const filteredParams = params.filter(([, value]) => value)
    encodedParams.push(...filteredParams.map(([name, value]) => `${name}=${value}`))
  } else {
    const [name, value] = params

    if (value) {
      encodedParams.push(`${name}=${value}`)
    }
  }

  return `${url}?${encodedParams.join('&')}`
}

export const getCamelResult = resp => snakeToCamel(resp.data.result)
