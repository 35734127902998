const state = {
  choosedActorIdx: null,
}

// getters
const getters = {
  choosedActorIdx: state => state.choosedActorIdx,
}

const mutations = {
  setChoosedActor(state, val) {
    state.choosedActorIdx = val
  },
}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
}
