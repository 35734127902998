import jwt_decode from 'jwt-decode'

const VueAuthStore = {
  signup(user) {
    const url = `/api/auth/signup`
    return this.$http.post(url, user).then(response => {
      this.setAccessToken(response.data.result.access_token)
      const url = `/api/me`
      return this.$http.get(url).then(response => response.data)
    })
  },

  logout() {
    const url = `/api/auth/logout`
    this.$http.post(url)
    this.cleanAuth()
  },

  login(user) {
    const url = `/api/auth/login`
    return this.$http.post(url, user).then(response => {
      this.setAccessToken(response.data.result.access_token)
      const url = `/api/me`
      return this.$http.get(url).then(response => response.data)
    })
  },

  resetPassword(user) {
    const url = `/api/auth/reset_password`
    return this.$http.post(url, user).then(response => {
      this.setAccessToken(response.data.result.access_token)
      const url = `/api/me`
      return this.$http.get(url).then(response => response.data)
    })
  },

  isAnonymous() {
    const token = this.getAccessToken()
    if (!token) {
      return false
    }
    const decoded = jwt_decode(token)
    return decoded.authtype === 'anonymous'
  },

  isAccessTokenExist() {
    return !!this.$http.defaults.headers.common.Authorization
  },

  setAccessToken(token) {
    this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },

  getAccessToken() {
    return this.$http.defaults.headers.common.Authorization?.replace('Bearer ', '')
  },

  cleanAccessToken() {
    delete this.$http.defaults.headers.common.Authorization
  },

  invitation(token) {
    const url = `/api/invitation`
    return this.$http
      .get(url, {
        params: {
          token: token,
        },
      })
      .then(res => res.data.result)
  },

  fbCustomToken(idToken) {
    const url = `/api/auth-fb/custom-token`
    return this.$http.post(url, {token: idToken}).then(response => {
      return response.data.result
    })
  },

  fbCustomTokenUsingSignature({signature, timestamp, company}) {
    const url = `/api/auth-fb/custom-token`
    return this.$http.post(url, {signature, timestamp, company}).then(response => {
      return response.data.result
    })
  },

  fbResetPassword(email) {
    const url = `/api/auth-fb/email/reset-password`
    return this.$http.post(url, {email}).then(response => {
      return response.data.result
    })
  },

  fbVerifyEmail({idToken, callbackUrl, fromPlatform, from}) {
    const url = `/api/auth-fb/email/verify-email`
    return this.$http
      .post(url, {token: idToken, callback_url: callbackUrl, from_platform: fromPlatform, from})
      .then(response => {
        return response.data.result
      })
  },

  fbGetEmail(email) {
    const url = `/api/auth-fb/email`
    return this.$http.get(url, {params: {email}}).then(response => {
      return response.data.result
    })
  },

  fbCallbackUrl(idToken) {
    const url = `/api/auth-fb/callback-url`
    return this.$http
      .get(url, {
        params: {
          token: idToken,
        },
      })
      .then(res => res.data.result)
  },

  isExistAccount({provider, providerUid, idToken}) {
    const url = `/api/auth-fb/v2/exists`
    return this.$http
      .get(url, {
        params: {
          provider,
          provider_uid: providerUid,
          id_token: idToken,
        },
      })
      .then(res => res.data.result)
  },
  getProviderByEmail({email}) {
    const url = `/api/auth-fb/v2/provider`
    return this.$http
      .get(url, {
        params: {
          email,
        },
      })
      .then(res => res.data.result)
  },
  signupV3(data) {
    const url = `/api/auth-fb/v3/signup`
    return this.$http.post(url, data).then(res => res.data.result)
  },
  signupAnonymous({language}) {
    const url = `/api/auth-fb/v3/signup/anonymous`
    return this.$http.post(url, {language}).then(res => res.data.result)
  },
  promoteAnonymous(data) {
    const url = `/api/auth-fb/v3/signup/promotion`
    return this.$http.post(url, data).then(res => res.data.result)
  },
}

export default function ($http) {
  VueAuthStore.$http = $http
  return VueAuthStore
}
