<template>
  <i :class="className" v-on="eventListeners" />
</template>

<script>
import {defineComponent, isVue2} from 'vue-demi'

export default defineComponent({
  props: {
    iconName: {
      type: String,
      default: '',
      required: true,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className() {
      return `t-icon-${this.iconName} ${this.bold ? 'bold' : ''}`
    },
    eventListeners() {
      if (isVue2) {
        return this.$listeners
      }

      return this.$attrs
    },
  },
})
</script>
