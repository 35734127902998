/* eslint-disable camelcase */
import backend from '@/backend/backend-api'
import deepFreeze from '@/utils/deepFreeze'
import {nanoid} from 'nanoid'
import CONSTANTS from '@/config/constants'
import {isValidEditorText} from '@/utils/isValidEditorText'
import {createQueryHash} from '@/utils/nsHash'
import {camelToSnake} from 'utils/src/convertNamingConvention'
import {useVideoDomStore} from 'store/editor'
import store from '..'
import * as Sentry from '@sentry/vue'

/**
 * @module typecast/store/editor
 */

export const PLAYING_STATUS = {
  PLAYING: 'playing',
  LOADING: 'loading',
  STOPPED: 'stopped',
}
/**
 * 에디터 store state
 * @name State
 * @type {object}
 * @property {Object} tiptapContent 에디터 tiptap documents
 * @property {Object} loadedContent 프로젝트 로딩 시 이용
 * @property {String} projectTitle 프로젝트 명
 * @property {String} projectId 프로젝트 고유 ID
 * @property {String} fileName 파일명
 */
const state = {
  newFile: 0,
  tiptapContent: {type: 'doc', content: []},
  loadedContent: null,
  projectTitle: '',
  projectId: null,
  projectRevision: null,
  fileName: '',
  showMenuForMobile: false,
  showDownloadProgress: false,
  showPhoneticSymbolModal: false,
  showPlanUpgradeModal: false,
  isOpenEditorInspector: false,
  isOpenCommentInspector: false,
  volume: 0.05,
  creditId: null,
  isEnableWatermark: false,
  isSaving: false,
  projectShare: {},
  projectLastModified: null,
  anonymous: {
    showTutorialModal: false,
    showLoginModal: false,
    anonymousProject: {},
    loginModalStatus: '',
  },
  inspectorMenu: '',
  paragraphIdList: [],
  projectFolderId: '',
  projectVersionName: '',
  showProjectVersionList: false,
  enableForceRoute: false,
  disableAutoSave: false,
  currentParagraphId: '',
  currentActorId: '',
  currentQueryText: '',
  currentTextNodeMarkAttrs: {},
  savedProjectPayloadString: '',
  isProjectLoading: false,
  playingStatusDots: PLAYING_STATUS.STOPPED,
  playingStatusPlayerBar: PLAYING_STATUS.STOPPED,
}

/**
 * 에디터 state Getter
 * @name Getters
 * @type {object}
 * @getter {Object} tiptapContent=tiptapContent
 * @getter {Object} loadedContent=loadedContent
 * @getter {String} projectTitle=projectTitle
 * @getter {String} projectId=projectId
 * @getter {String} fileName=fileName
 * @example
 * import {mapGetters} from 'vuex'
 * // in Vue computed
 * {
 *  ...
 *  ...mapGetters('typecast/editor', ['showActorselection'])
 * }
 * // return user information
 * console.log(this.showActorselection)
 */
const getters = {
  newFile: state => state.newFile,
  tiptapContent: state => state.tiptapContent,
  loadedContent: state => state.loadedContent,
  projectTitle: state => state.projectTitle,
  projectId: state => state.projectId,
  projectRevision: state => state.projectRevision,
  fileName: state => state.fileName,
  showMenuForMobile: state => state.showMenuForMobile,
  showDownloadProgress: state => state.showDownloadProgress,
  showPhoneticSymbolModal: state => state.showPhoneticSymbolModal,
  showPlanUpgradeModal: state => state.showPlanUpgradeModal,
  isOpenEditorInspector: state => state.isOpenEditorInspector,
  volume: state => state.volume,
  creditId: state => state.creditId,
  isEnableWatermark: state => state.isEnableWatermark,
  isSaving: state => state.isSaving,
  projectShare: state => state.projectShare,
  projectLastModified: state => state.projectLastModified,
  showAnonymousTutorialModal: state => state.anonymous.showTutorialModal,
  showAnonymousLoginModal: state => state.anonymous.showLoginModal,
  anonymousProject: state => state.anonymous.anonymousProject,
  getAnonymousloginModalStatus: state => state.anonymous.loginModalStatus,
  isSharedProject: state => state.projectShare.user_list.length > 0,
  paragraphIdList: state => state.paragraphIdList,
  isHistoryMode: state => state.inspectorMenu === 'history',
  savedProjectPayloadString: state => state.savedProjectPayloadString,
  filteredQueryCacheItem: (state, getters, rootState, rootGetters) => {
    const filteredQuery = {}

    const queryCacheFilteredItems = rootGetters['typecast/queryCache/queryCacheItems']

    for (const [, item] of Object.entries(queryCacheFilteredItems)) {
      if (item.audio) {
        delete item.audio
      }
      if (item.speak) {
        const includeKeys = new Set(['query', 'audio', 'speak_url', 'duration'])
        const allKeys = Object.keys(item.speak)
        for (const key of allKeys) {
          if (!includeKeys.has(key)) {
            delete item.speak[key]
          }
        }
      }
    }
    const styleLabelVersionList = rootGetters['typecast/queryCache/styleLabelVersionList']
    const queries = rootGetters['typecast/queryCache/queries']
    Object.entries(queries).map(([queryId, query]) => {
      const hash = createQueryHash({
        ...query,
        id: queryId,
        styleVersion: styleLabelVersionList[query.actor],
      })
      if (queryCacheFilteredItems[hash]) {
        filteredQuery[hash] = queryCacheFilteredItems[hash]
      }
    })
    return filteredQuery
  },
  projectPayload: (state, getters, rootState, rootGetters) => {
    const name = getters['projectTitle'] ?? ''
    const folderId = state.projectFolderId
    const mediaType = rootGetters['typecast/videoEditor/mediaType']
    const tiptap = getters['tiptapContent']
    const queryCacheItems = getters['filteredQueryCacheItem']
    const styleLabelVersionList = rootGetters['typecast/queryCache/styleLabelVersionList']
    const timelineVideoList = rootState.typecast.videoEditor.timelineAssetLists.video
      ?.filter(video => !video.preview)
      .map(cleanseAssetPayload)
    const timelineBgmList = rootState.typecast.videoEditor.timelineAssetLists.bgm
      ?.filter(bgm => !bgm.preview)
      .map(cleanseAssetPayload)

    const videoDomStore = useVideoDomStore()

    return {
      name,
      folder_id: folderId,
      media_type: mediaType,
      v10: {
        tiptap,
        query_cache_items: queryCacheItems,
        style_label_version_list: styleLabelVersionList,
        slide_list: [], // not used anymore, remain for backward compatibility
        additional_data: {
          timeline: {
            video: timelineVideoList,
            bgm: timelineBgmList,
          },
          version: 1,
          slide_list_v2: videoDomStore.slideList,
        },
      },
      version: 10,
      subtitle: camelToSnake(videoDomStore.subtitle),
      bg_color: videoDomStore.globalBackgroundColor,
    }
  },
  isEditorModified(state, getters) {
    if (state.isProjectLoading) {
      return false
    }

    const savedProjectPayloadString = getters['savedProjectPayloadString']
    const curProject = getters['projectPayload']

    // 성능에 문제가 생기면 구조분해 할당으로 일부를 먼저 비교하자
    if (JSON.stringify(curProject) !== savedProjectPayloadString) {
      return true
    }
    return false
  },
}

// actions
const actions = {
  buildContentV1({commit}, project) {
    const actorList = this.getters['typecast/actor/actors']
    const result = {
      type: 'doc',
      content: [],
    }

    const isDisabledSpeedSetting = actor => !(actor.tuning && actor.tuning.includes('speed'))
    const isDisabledStyleSetting = actor => !(actor.tuning && actor.tuning.includes('style'))

    for (let i = 0; i < project.query_list.length; i++) {
      const query = project.query_list[i]
      if (query.text.length > 0) {
        const actorId = project.style_list[query.style_idx].actor_id
        const speak = query.speak_list[query.style_idx]
        let style = null
        let speed = null
        if (speak) {
          style = query.speak_list[query.style_idx].style_cluster_idx
          speed = query.speak_list[query.style_idx].speed_x
        }
        const silence = query.silence_sec

        const text = {
          type: 'text',
          marks: [
            {
              type: 'query',
              attrs: {
                id: query.queryId,
                style: style ? style : 0,
                speed: speed ? speed : 1,
                silence: silence ? Math.round(silence * 1000) : CONSTANTS.DEFAULT_SILENCE_MILLI_SEC,
              },
            },
          ],
          text: query.text,
        }
        const separator = {
          type: 'separator',
        }
        if (i === 0 || query.style_idx !== project.query_list[i - 1].style_idx) {
          const paragraph = {
            type: 'paragraph',
            attrs: {
              id: nanoid(),
              actor: actorId,
            },
            content: [text, separator],
          }
          result.content.push(paragraph)
        } else {
          result.content[result.content.length - 1].content.push(text)
          result.content[result.content.length - 1].content.push(separator)
        }
      }
    }
    commit('setLoadedContent', result)
  },
  async getProjectRevision({state, getters}) {
    if (!state.projectId || !getters.isSharedProject) {
      return
    }

    try {
      const $http = this._vm.$http
      const {current_revision} = await backend.getProjectRevision($http, state.projectId, state.projectRevision)
      return current_revision
    } catch (error) {
      console.error(error)
    }
  },
  endHistoryMode({commit}) {
    const {$editor} = this._vm.$typecast
    const editor = $editor.getEditor()
    $editor.setIsAutoSave(true)
    editor && editor.setOptions({editable: true})
    commit('setInspectorMenu', 'audio')
    commit('setProjectVersionName', '')
  },
  async migrationAnonymousProject({state, commit}) {
    const project = state.anonymous.anonymousProject
    try {
      const newProject = await store.dispatch('typecast/project/createProject', project)
      commit('removeAnonymousProject')
      return newProject
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    }
  },
}

const mutations = {
  toggleMenuForMobile(state) {
    state.showMenuForMobile = !state.showMenuForMobile
  },
  togglePhoneticSymbolModal(state) {
    state.showPhoneticSymbolModal = !state.showPhoneticSymbolModal
  },
  startNewFile(state) {
    state.newFile += 1
  },
  /**
   * 불러오기 시 tiptap 콘텐츠 입력
   * @name Mutations
   * @function setLoadedContent
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/editor', ['setLoadedContent'])
   * }
   * mounted() {
   *   this.setLoadedContent(this.selectedProject.v3.tiptap)
   * }
   */
  setLoadedContent(state, json) {
    state.loadedContent = json
  },
  /**
   * 프로젝트 타이틀 입력
   * @name Mutations
   * @function setProjectTitle
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/editor', ['setProjectTitle'])
   * }
   * mounted() {
   *   this.setProjectTitle('제목 없음')
   * }
   */
  setProjectTitle(state, title) {
    state.projectTitle = title
  },
  setTiptapContent(state, json) {
    state.tiptapContent = deepFreeze(json)
  },
  /**
   * 프로젝트 ID 입력
   * @name Mutations
   * @function setProjectId
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/editor', ['setProjectId'])
   * }
   * mounted() {
   *   this.setProjectId('제목 없음')
   * }
   */
  setProjectId(state, id) {
    state.projectId = id
  },
  setProjectRevision(state, revision) {
    state.projectRevision = revision
  },
  setProjectShare(state, share) {
    state.projectShare = share
  },
  setFileName(state, name) {
    state.fileName = name
  },
  setShowDownloadProgress(state, value) {
    state.showDownloadProgress = value
  },
  setShowPlanUpgradeModal(state, value) {
    state.showPlanUpgradeModal = value
  },
  setVolume(state, value) {
    state.volume = value
  },
  setCreditId(state, value) {
    state.creditId = value
  },
  resetCreditId(state) {
    state.creditId = null
  },
  toggleIsOpenEditorInspector(state) {
    state.isOpenEditorInspector = !state.isOpenEditorInspector
  },
  toggleIsOpenCommentInspector(state) {
    state.isOpenCommentInspector = !state.isOpenCommentInspector
  },
  setIsOpenEditorInspector(state, value) {
    state.isOpenEditorInspector = value
  },
  setIsEnableWatermark(state, value) {
    state.isEnableWatermark = value
  },
  setIsSaving(state, value) {
    state.isSaving = value
  },
  setIsProjectLoading(state, value) {
    state.isProjectLoading = value
  },
  resetEditorStore(state) {
    state.tiptapContent = {type: 'doc', content: []}
    state.loadedContent = null
    state.projectTitle = ''
    state.projectId = null
    state.projectRevision = null
    state.projectShare = {}
    state.fileName = ''
    state.projectFolderId = ''
    state.savedProjectPayloadString = ''
    state.isProjectLoading = false
    this.commit('typecast/editor/resetModalState')
  },
  setProjectLastModified(state, value) {
    state.projectLastModified = value
  },
  setShowAnonymousLoginModal(state, value) {
    state.anonymous.showLoginModal = value
  },
  setShowAnonymousTutorialModal(state, value) {
    state.anonymous.showTutorialModal = value
  },
  setAnonymousProject(state, value) {
    state.anonymous.anonymousProject = value
  },
  removeAnonymousProject(state) {
    state.anonymous.anonymousProject = {}
    state.anonymous.showTutorialModal = false
    state.anonymous.showLoginModal = false
  },
  setAnonymousloginModalStatus(state, value) {
    state.anonymous.loginModalStatus = value
  },
  resetModalState(state) {
    state.showMenuForMobile = false
    state.showDownloadProgress = false
    state.showPhoneticSymbolModal = false
    state.showPlanUpgradeModal = false
    state.isOpenEditorInspector = false
    state.creditId = null
    state.isEnableWatermark = false
    state.isSaving = false
    state.anonymous.showTutorialModal = false
    state.anonymous.showLoginModal = false
  },
  setInspectorMenu(state, menu) {
    state.inspectorMenu = menu
    this.commit('typecast/editor/setIsOpenEditorInspector', menu !== '')
  },
  setParagraphIdList(state, paragraphIdList) {
    state.paragraphIdList = deepFreeze(paragraphIdList)
  },
  setProjectFolderId(state, id) {
    state.projectFolderId = id
  },
  setProjectVersionName(state, value) {
    state.projectVersionName = value
  },
  setShowProjectVersionList(state, value) {
    state.showProjectVersionList = value
  },
  setEnableForceRoute(state, value) {
    state.enableForceRoute = value
  },
  setDisableAutoSave(state, value) {
    state.disableAutoSave = value
  },
  setCurrentParagraphId(state, value) {
    if (!value || value === state.currentParagraphId) {
      return
    }
    state.currentParagraphId = value
  },
  setCurrentActorId(state, value) {
    if (!value || value === state.currentActorId) {
      return
    }
    state.currentActorId = value
  },
  setCurrentQueryText(state, value) {
    if (value === state.currentQueryText) {
      return
    }
    state.currentQueryText = value
  },
  setCurrentTextNodeMarkAttrs(state, attrs) {
    state.currentTextNodeMarkAttrs = attrs
  },
  setSavedProjectPayloadString(state, value) {
    state.savedProjectPayloadString = value
  },
  setPlayingStatusDots(state, value) {
    state.playingStatusDots = value
  },
  setPlayingStatusPlayerBar(state, value) {
    state.playingStatusPlayerBar = value
  },
}

function cleanseAssetPayload(asset) {
  const _asset = {...asset}
  delete _asset.preview
  delete _asset.volumeCached
  delete _asset.thumbUrl
  return _asset
}

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
}
