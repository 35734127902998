var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { scene } from '@/context';
import { Frame } from 'scenejs';
import { Media } from '@scenejs/media';
import { renderPlayScene } from '@/render/renderPlayScene';
import { version } from '@/versioning';
import { Slide, getConstructorName } from '@/model';
import { cloneDeep } from 'utils';
import { WATERMARK_MEDIA_SCENE_ID } from '@/constants';
import Big from 'big.js';
const PACKAGE_NAME = 'videoDom';
function serialize(scene, parent, commands) {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof parent === 'string') {
            parent = parent.replace(/-/g, '_');
        }
        const itemIdPairList = [];
        scene.forEach((item, id) => itemIdPairList.push([item, id]));
        const promised = itemIdPairList.map(([item, id]) => __awaiter(this, void 0, void 0, function* () {
            if (item instanceof Frame) {
                const cssString = JSON.stringify(item.toCSSObject());
                if (cssString === '{}') {
                    return;
                }
                commands.push(`${parent}.setFrame(${id}, new ${PACKAGE_NAME}.Frame(${cssString}));`);
                return;
            }
            if (typeof id === 'number') {
                return;
            }
            if (item instanceof Media) {
                const info = item.getInfo();
                const mediaElement = item.getMediaElement();
                if (!info.url.length && item.getPromisedUrl) {
                    info.url = yield item.getPromisedUrl();
                }
                if (mediaElement.tagName === 'AUDIO') {
                    // 실제로 주석이 포함된 command를 export한다.
                    // AUDIO가 렌더링 시에는 필요없기 때문에 파싱하는 용도로만 남겨둔다.
                    commands.push(`${parent}.addMedia('${id}', '${info.url}').seek(${info.seek[0]}, ${info.seek[1]}).setVolume(${info.volume}).setPlaySpeed(${info.playSpeed}).setDelay(${info.delay});`);
                }
                else if (mediaElement.tagName === 'VIDEO') {
                    commands.push(`${parent}.addMedia('${id}', document.getElementById('${id}')).seek(${info.seek[0]}, ${info.seek[1]}).setVolume(${info.volume}).setPlaySpeed(${info.playSpeed}).setDelay(${info.delay});`);
                }
                return;
            }
            const currentScene = id.replace(/-/g, '_');
            const options = {
                duration: item.state.duration,
                startTransitionDuration: 0,
                endTransitionDuration: 0,
                fillMode: item.getFillMode(),
                direction: item.getDirection(),
                iterationCount: item.getIterationCount(),
                delay: item.getDelay(),
                easing: item.getEasing(),
                playSpeed: item.getPlaySpeed(),
                selector: item.state.selector,
            };
            if (item instanceof Slide) {
                options.startTransitionDuration = item.startTransitionDuration;
                options.endTransitionDuration = new Big(item.state.duration)
                    .minus(item.startTransitionDuration)
                    .minus(item.durationWithoutTransition)
                    .toNumber();
            }
            const className = `${PACKAGE_NAME}.${getConstructorName(item)}`;
            commands.push(`const ${currentScene} = new ${className}({options: ${JSON.stringify(options)}});`);
            commands.push(`${parent}.setItem('${id}', ${currentScene});`);
            return serialize(item, id, commands);
        }));
        yield Promise.all(promised);
    });
}
/** @public */
export const exportScene = () => __awaiter(void 0, void 0, void 0, function* () {
    renderPlayScene();
    const commands = [
        `const scene = new ${PACKAGE_NAME}.Scene();`,
        `scene.setTime(0);`,
        'window.scene = scene;',
        `'video-dom v${version}';`,
    ];
    const clonedScene = cloneDeep(scene);
    if (clonedScene.getItem(WATERMARK_MEDIA_SCENE_ID)) {
        clonedScene.removeItem(WATERMARK_MEDIA_SCENE_ID);
    }
    yield serialize(clonedScene, 'scene', commands);
    return commands.join('');
});
