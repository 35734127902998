const urlEn = {
  tips: 'https://help.typecast.ai/en/collections/3703017-tips',
  help: 'https://help.typecast.ai/en',
  terms_of_use: 'https://help.typecast.ai/en/articles/5361255-terms-of-use',
  privacy_policy: 'https://help.typecast.ai/en/articles/5361327-personal-information-handling-policy-or-privacy-policy',
  open_kakao: 'https://open.kakao.com/o/gmn4ndzb',
  neosapeince: 'https://neosapience.com/about/',
  recruitment: 'https://neosapience.com/jobs/',
  news: 'https://neosapience.com/news/',
  facebook: 'https://www.facebook.com/typecastkr',
  youtube: 'https://www.youtube.com/channel/UCMdKT9YjU_c8_FN88c4ZPwg',
  linkedin: 'https://www.linkedin.com/company/neosapience',
  medium: 'https://medium.com/typecast-ai',
  actor_request_free: 'https://forms.gle/fHpnUJwYZVYtLJQt7',
  actor_request_paid: 'https://forms.gle/zgGYG973J6t4o15y6',
  about_us: 'https://neosapience.com/about/',
  pro_change_announcement:
    'https://www.notion.so/neosapience/Announcement-Pro-Membership-Plan-Change-of-Available-Channel-Types-b0fa01bf402d406faac1620a65d53352',
  beta_tester_recruit:
    'https://docs.google.com/forms/d/e/1FAIpQLSd4LvibhlirUikR3n6NYSZEjuguv4qqZgIFV5WjSFqFX8-4Mw/viewform',
  personal_information_agreement:
    'https://help.typecast.ai/en/articles/5372688-personal-information-collection-usage-agreement',
  virtual_human: 'https://neosapience.notion.site/Typecast-Video-Feature-8c428c70987b48e8b2b773b466c53b89',
  faq: 'https://help.typecast.ai/en/collections/3703014-faq',
  video_feedback: 'https://forms.gle/VL4rxcp5q3H5oqCd8',
  video_group_chat: 'https://open.kakao.com/o/gdhzY4md',
  award: 'https://typecastform.typeform.com/to/R4XH6dzu',
  fhd_video: 'https://neosapience.notion.site/FHD-2b58b3cfbe4b454dbe6cc1261bd266c9',
  discord: 'https://discord.com/invite/7xMpRr2BCD',
  start_typecast: 'https://www.youtube.com/embed/eWUJf3J415U',
  new_editor: 'https://youtu.be/eWUJf3J415U?t=22',
  new_membership: 'https://www.notion.so/neosapience/Typecast-Membership-Updates-05c30d8fc02d4db4a22d9e1aedc20b5e',
  home_contact: '/contact?lang=en',
  home_character: '/character?lang=en',
  home_guideline: '/guideline?lang=en',
  home_policy: '/policy?lang=en',
  pipedrive: 'https://webforms.pipedrive.com/f/6zfFOVYuaWXOgYYE9LKtp93VPJJhrLk3b4ZeRoABlTgaxezpiITdRcTY0J07rytP8v',
  pipedrive_banner:
    'https://webforms.pipedrive.com/f/72vCJ9UXXVjfMZqWz1l3EPE6ZF3axy4MKsP2lPLcqQ4Bv2W2PThKjBTxkbzp8sDJLl',
  how_to_use_shared_project:
    'https://neosapience.notion.site/How-to-use-the-Typecast-Business-Plan-4a8ed30978aa4f2ebf88c24db0fbbe20?pvs=4',
  blog_wordpress: 'https://typecast.ai/learn/wp-json/wp/v2/posts?per_page=4&context=embed',
  blog: 'https://typecast.ai/learn/',
}

export default urlEn
