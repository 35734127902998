import * as Sentry from '@sentry/vue'

export default {
  install(Vue) {
    Sentry.init({
      Vue,
      dsn: 'https://c67cf358b9a943ac9ef062aacaeee49a@sentry.io/2626965',
      integrations: [new Sentry.Replay()],
      replaysOnErrorSampleRate: 0.01, //recommended 1.0
      environment: process.env.NODE_ENV,
      release: process.env.CIRCLE_TAG,
      enableTracing: false,
    })
  },
}
