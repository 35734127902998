import { merge } from 'utils';
import { objectTransformToString } from './objectTransformToString';
import { config } from '@/config';
const DEFAULT_CHARACTER_SCALE = 1;
const CHARACTER_SCALE_RANGE = 0.5;
const MIN_SAFETY_CHARACTER_SCALE = DEFAULT_CHARACTER_SCALE - CHARACTER_SCALE_RANGE;
const MAX_SAFETY_CHARACTER_SCALE = DEFAULT_CHARACTER_SCALE + CHARACTER_SCALE_RANGE;
const CHARACTER_CONTAINER_WIDTH = 900;
const CHARACTER_CONTAINER_HEIGHT = 1080;
const defaultCharacterContainerStyle = {
    position: 'absolute',
    zIndex: 2999,
    bottom: '0px',
    userSelect: 'none',
    pointerEvents: 'none',
    visibility: 'visible',
    width: `${CHARACTER_CONTAINER_WIDTH}px`,
    height: `${CHARACTER_CONTAINER_HEIGHT}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
};
const defaultCharacterElementStyle = {
    position: 'absolute',
    height: '100%',
};
export const getCharacterObjectStyle = (vProps, { isRealCharacter, bodyType, }) => {
    const characterContainerStyle = getCharacterContainerStyle(vProps, bodyType);
    const characterElementStyle = getCharacterElementStyle(vProps, isRealCharacter);
    const characterBaseElementStyle = merge({}, characterElementStyle, {
        position: 'initial',
    });
    const characterLipElementStyle = merge({ display: 'none', zIndex: 1 }, characterElementStyle);
    const characterEyeElementStyle = merge({ display: 'none', zIndex: 1 }, characterElementStyle);
    return {
        characterContainerStyle,
        characterBaseElementStyle,
        characterLipElementStyle,
        characterEyeElementStyle,
    };
};
const getCharacterContainerStyle = ({ characterPosition, characterVisibility, characterScale }, bodyType) => {
    const style = merge({}, defaultCharacterContainerStyle);
    const transformObject = {};
    const styleAndTransformObject = {
        style,
        transformObject,
    };
    const { isShortsProject } = config;
    updateCharacterContainerPosition({ characterPosition, isShortsProject, characterScale }, styleAndTransformObject);
    updateCharacterContainerVisibility({ characterVisibility }, styleAndTransformObject);
    updateCharacterContainerScale({ characterScale, isShortsProject }, styleAndTransformObject);
    updateCharacterContainerByBodyType({ bodyType }, styleAndTransformObject);
    style.transform = objectTransformToString(transformObject);
    return style;
};
const getCharacterElementStyle = ({ characterFlip }, isRealCharacter) => {
    const style = merge({}, defaultCharacterElementStyle);
    const transformObject = {};
    const styleAndTransformObject = {
        style,
        transformObject,
    };
    updateCharacterElementPosition({ isRealCharacter }, styleAndTransformObject);
    updateCharacterElementFlip({ characterFlip }, styleAndTransformObject);
    style.transform = objectTransformToString(transformObject);
    return style;
};
const updateCharacterContainerByBodyType = ({ bodyType }, { style, transformObject }) => {
    if (bodyType === 'WHOLE') {
        return;
    }
    transformObject.translateY = '50%';
    style.height = `${CHARACTER_CONTAINER_HEIGHT * 2}px`;
};
const updateCharacterContainerPosition = ({ characterPosition, isShortsProject, characterScale }, { style, transformObject }) => {
    if (characterPosition === 'center') {
        transformObject.translateX = '-50%';
        style.left = '50%';
        style.right = 'unset';
        style.transformOrigin = 'bottom center';
        return;
    }
    let offset = '5%';
    if (isShortsProject) {
        // neg offset의 경우 scale이 너무 작으면 character가 안보이는 경우가 있음
        // scale 0.7 -> -5% ... scale 1.7 -> -40%
        const scale = getNormScale(isShortsProject, characterScale);
        offset = `${35 * (0.7 - scale) - 5}%`;
    }
    if (characterPosition === 'right') {
        style.left = 'unset';
        style.right = offset;
        style.transformOrigin = 'bottom right';
        return;
    }
    style.left = offset;
    style.right = 'unset';
    style.transformOrigin = 'bottom left';
};
const updateCharacterContainerVisibility = ({ characterVisibility }, { style }) => {
    if (typeof characterVisibility !== 'boolean') {
        return;
    }
    style.visibility = characterVisibility ? 'visible' : 'hidden';
};
const updateCharacterContainerScale = ({ characterScale, isShortsProject }, { transformObject }) => {
    const scale = getNormScale(isShortsProject, characterScale);
    transformObject.scale = scale;
};
// shorts: scale 1.5 -> norm scale 1.8 ... scale 0.5 -> norm scale 0.7
// wide: scale 1.5 -> norm scale 1 ... scale 0.5 -> norm scale 0.5
const getNormScale = (isShortsProject, characterScale = 1) => {
    const safeScale = Math.min(Math.max(MIN_SAFETY_CHARACTER_SCALE, characterScale), MAX_SAFETY_CHARACTER_SCALE);
    if (isShortsProject) {
        const min = 0.7;
        const upTo = 1;
        return min + (safeScale - MIN_SAFETY_CHARACTER_SCALE) * upTo;
    }
    const min = 0.5;
    const upTo = 0.5;
    return min + (safeScale - MIN_SAFETY_CHARACTER_SCALE) * upTo;
};
const updateCharacterElementPosition = ({ isRealCharacter = false }, { transformObject }) => {
    transformObject.translateY = '10%';
    if (isRealCharacter) {
        transformObject.translateY = '1%';
    }
};
const updateCharacterElementFlip = ({ characterFlip }, { transformObject }) => {
    if (typeof characterFlip !== 'boolean') {
        return;
    }
    transformObject.scaleX = characterFlip ? -1 : 1;
};
