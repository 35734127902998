import auth from './auth'
import typecastHttp from './modules/http'
import TypecastEditor from './modules/editor/Editor'
import Account from './modules/Account'

export default function ({Vue, router, store}) {
  const $http = typecastHttp(Vue, router)
  const $auth = auth($http)

  return {
    $http: $http,
    $auth: $auth,
    $editor: TypecastEditor.getInstance(Vue, store),
    $account: Account.getInstance($auth),
  }
}
