import CONSTANTS from '@/config/constants'
import {FacebookAuthProvider} from 'firebase/auth'
class FacebookLogin {
  static _instance = null
  constructor() {}

  async signIn() {
    const facebookLoginPromise = () =>
      new Promise((resolve, reject) => {
        window.FB.login(
          response => {
            if (response.status === 'connected') {
              window.FB.api('/me', {fields: 'email'}, me => {
                resolve({...me, ...response})
              })
            } else {
              reject(response)
            }
          },
          {scope: 'email'},
        )
      })

    try {
      const facebookLoginResult = await facebookLoginPromise()
      return this._facebookSignInDataDecorator(facebookLoginResult)
    } catch (error) {
      throw error
    }
  }

  signOut() {
    window.FB.logout(() => {})
  }

  getLoginStatus() {
    return new Promise(resolve => {
      window.FB.getLoginStatus(response => {
        window.FB.api('/me', {fields: 'email'}, me => {
          resolve(this._facebookSignInDataDecorator({...me, ...response}))
        })
      })
    })
  }

  async getCredential(token) {
    return FacebookAuthProvider.credential(token)
  }

  getProviderInstance() {
    return new FacebookAuthProvider()
  }

  _facebookSignInDataDecorator(response) {
    return {
      provider: CONSTANTS.PROVIDER.FACEBOOK,
      token: response.authResponse.accessToken,
      providerUid: response.authResponse.userID,
      email: response.email,
    }
  }

  static getInstance() {
    if (!this._instance) {
      this._instance = new FacebookLogin()
    }
    return this._instance
  }
}

export default FacebookLogin
