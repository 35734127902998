import { i18n } from '@/i18n';
export const LanguageCodeMap = {
    get auto() {
        return i18n.t('language_code.auto');
    },
    get 'en-us'() {
        return i18n.t('language_code.en-us');
    },
    get 'ko-kr'() {
        return i18n.t('language_code.ko-kr');
    },
    get 'ja-jp'() {
        return i18n.t('language_code.ja-jp');
    },
    get 'es-es'() {
        return i18n.t('language_code.es-es');
    },
    get 'de-de'() {
        return i18n.t('language_code.de-de');
    },
    get 'zh-cn'() {
        return i18n.t('language_code.zh-cn');
    },
    get 'fr-fr'() {
        return i18n.t('language_code.fr-fr');
    },
    get 'pt-pt'() {
        return i18n.t('language_code.pt-pt');
    },
};
