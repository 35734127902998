import { config } from '@/config';
import { FOR_SUBTITLE_STYLE_DATASET_NAME, UNSELECTABLE_ELEMENT_CLASS_NAME, } from '@/constants';
import { getCachedQuery, getCurrentCachedQuery, getCurrentPlayBufferItemQueryId, } from '@/share/render/getCachedQuery';
import { createVElementId, getSpeakTextObjectStyle, objectStyleToString, } from '@/share/virtualDOM';
import { Flags } from 'million';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import { nextRenderTick } from '../nextRenderTick';
import Vue from 'vue';
import { emitEvent } from '@/emitEvent';
import { pasteWithMaxLength } from 'utils/src/pasteWithMaxLength';
const SUBTITLE_CLASS_NAME = 'video-dom__subtitle';
const SUBTITLE_TEXT_CLASS_NAME = `${SUBTITLE_CLASS_NAME}_text`;
const SUBTITLE_OUTLINE_CLASS_NAME = `${SUBTITLE_CLASS_NAME}--outline`;
export const createSubtitleVElement = (slideVProps, options) => {
    const { containerStyle, wrapperStyle, childrenStyle } = getSpeakTextObjectStyle();
    const props = {
        id: createVElementId(),
        class: '',
        style: objectStyleToString(containerStyle),
    };
    const videoDomStore = useVideoDomStore();
    if (videoDomStore.subtitle.style === 'outline') {
        props.class += ` ${SUBTITLE_OUTLINE_CLASS_NAME}`;
    }
    const subtitleTextVElement = createSubtitleTextVElement(slideVProps, childrenStyle, options);
    const wrapperForContentEditableVElement = createWrapperForContentEditableVElement(wrapperStyle);
    wrapperForContentEditableVElement.children = [subtitleTextVElement];
    return {
        tag: 'div',
        children: [wrapperForContentEditableVElement],
        flag: Flags.ELEMENT,
        props,
    };
};
const createWrapperForContentEditableVElement = (wrapperStyle) => ({
    tag: 'div',
    flag: Flags.ELEMENT,
    props: {
        id: createVElementId(),
        style: objectStyleToString(wrapperStyle),
    },
});
// TODO: MAX_LENGTH 대신 app의 const 사용해야 함
const MAX_LENGTH = 300;
// TODO: 이거 필요한지?
const ALLOW_COMMAND_KEY_LIST = [
    'a',
    'c',
    'v',
    'x',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
];
const createSubtitleTextVElement = (slideVProps, subtitleTextVElementStyle, options) => {
    var _a, _b, _c;
    const playBufferItemQueryId = getCurrentPlayBufferItemQueryId();
    const queryId = (_b = (_a = options === null || options === void 0 ? void 0 : options.queryId) !== null && _a !== void 0 ? _a : playBufferItemQueryId) !== null && _b !== void 0 ? _b : (_c = getCurrentCachedQuery()) === null || _c === void 0 ? void 0 : _c.id;
    const elementId = createVElementId();
    const baseSubtitleTextVElement = {
        tag: 'span',
        props: {
            id: elementId,
            style: objectStyleToString(subtitleTextVElementStyle),
            class: `${SUBTITLE_TEXT_CLASS_NAME} ${UNSELECTABLE_ELEMENT_CLASS_NAME}`,
            contentEditable: 'false',
        },
        flag: Flags.ELEMENT_TEXT_CHILDREN,
    };
    const { invisible: isSubtitleInvisible, text: subtitleText } = getSubtitle(slideVProps, queryId);
    if (isSubtitleInvisible || !queryId) {
        baseSubtitleTextVElement.props.class += ` invisible`;
    }
    if (options === null || options === void 0 ? void 0 : options.setEmptySubtitle) {
        return baseSubtitleTextVElement;
    }
    const videoDomStore = useVideoDomStore();
    const editorOptionsStore = useEditorOptionsStore();
    const isPlayed = playBufferItemQueryId !== undefined;
    const isEditable = (options === null || options === void 0 ? void 0 : options.editable) && !isPlayed;
    if (isEditable) {
        baseSubtitleTextVElement.props.class += ` editable`;
        nextRenderTick({ id: elementId }, element => {
            let prevText = '';
            let pastedText = '';
            let selectionStart = 0;
            let selectionEnd = 0;
            element.addEventListener('click', () => {
                element.contentEditable = 'true';
                element.focus();
            });
            element.addEventListener('paste', event => {
                var _a, _b;
                event.preventDefault();
                const text = (_b = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text/plain')) !== null && _b !== void 0 ? _b : '';
                pastedText = text;
                const selection = document.getSelection();
                if (selection) {
                    const range = selection.getRangeAt(0);
                    range.deleteContents();
                    const textNode = document.createTextNode(text);
                    range.insertNode(textNode);
                    range.setStartAfter(textNode);
                    range.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            });
            element.addEventListener('keydown', event => {
                var _a, _b;
                const isCtrlPushed = event.ctrlKey || event.metaKey;
                const isNotAllowedEvent = isCtrlPushed && !ALLOW_COMMAND_KEY_LIST.includes(event.key);
                if (isNotAllowedEvent) {
                    event.preventDefault();
                    return;
                }
                if (event.key === 'Escape') {
                    element.blur();
                    return;
                }
                prevText = getText(element);
                const selection = document.getSelection();
                const anchorOffset = (_a = selection === null || selection === void 0 ? void 0 : selection.anchorOffset) !== null && _a !== void 0 ? _a : 0;
                const focusOffset = (_b = selection === null || selection === void 0 ? void 0 : selection.focusOffset) !== null && _b !== void 0 ? _b : 0;
                selectionStart = Math.min(anchorOffset, focusOffset);
                selectionEnd = Math.max(anchorOffset, focusOffset);
            });
            element.addEventListener('input', event => {
                var _a, _b;
                const currentQuery = getCurrentCachedQuery();
                if (!currentQuery) {
                    return;
                }
                const text = getText(element);
                if (text.length > MAX_LENGTH) {
                    // @ts-ignore
                    Vue.notify({
                        group: 'main',
                        title: config.i18nConverter('exceed_300'),
                        ignoreDuplicates: true,
                    });
                    const range = document.createRange();
                    const isPasted = event.inputType === 'insertFromPaste';
                    if (isPasted) {
                        element.textContent = pasteWithMaxLength(prevText, selectionStart, selectionEnd, pastedText, MAX_LENGTH);
                        // TODO: duplicate code
                        const selection = document.getSelection();
                        const anchorOffset = (_a = selection === null || selection === void 0 ? void 0 : selection.anchorOffset) !== null && _a !== void 0 ? _a : 0;
                        const focusOffset = (_b = selection === null || selection === void 0 ? void 0 : selection.focusOffset) !== null && _b !== void 0 ? _b : 0;
                        const selectionStart2 = Math.min(anchorOffset, focusOffset);
                        const selectionEnd2 = Math.max(anchorOffset, focusOffset);
                        const selectionRange = selectionEnd2 - selectionStart2;
                        const remainLength = MAX_LENGTH - prevText.length + selectionRange;
                        range.setStart(element.childNodes[0], selectionEnd + remainLength);
                    }
                    else {
                        element.textContent = prevText;
                        range.setStart(element.childNodes[0], selectionEnd);
                    }
                    const selection = document.getSelection();
                    selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
                    selection === null || selection === void 0 ? void 0 : selection.addRange(range);
                    element.dataset.subtitleText = getText(element);
                    return;
                }
                prevText = text;
                element.dataset.subtitleText = text;
                const isSynced = currentQuery.text === text;
                emitEvent('update-subtitle', { data: { isSynced } });
            });
            element.addEventListener('blur', () => {
                element.contentEditable = 'false';
                const selection = document.getSelection();
                selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
                const currentQuery = getCurrentCachedQuery();
                if (!currentQuery) {
                    return;
                }
                const text = getText(element);
                const { text: originalText, id: queryId } = currentQuery;
                const cleanText = text.replace(/\s/g, '');
                const isSameWithOriginalText = text === originalText || !cleanText.length;
                const currentSlide = videoDomStore.slideList[editorOptionsStore.slideIndex];
                if (!currentSlide) {
                    return;
                }
                const oldSubtitle = currentSlide.subtitleMap[queryId];
                const newSubtitle = Object.assign(Object.assign({}, oldSubtitle), { text: isSameWithOriginalText ? undefined : text });
                emitEvent('update-subtitle', { data: { isSynced: undefined } });
                Vue.set(currentSlide.subtitleMap, queryId, newSubtitle);
                const needSyncDOM = isSameWithOriginalText && !cleanText.length;
                if (needSyncDOM) {
                    element.textContent = originalText;
                }
            });
        }, {
            label: 'editableSubtitle',
        });
    }
    const subtitleTextVElement = baseSubtitleTextVElement;
    subtitleTextVElement.children = [subtitleText !== null && subtitleText !== void 0 ? subtitleText : ''];
    subtitleTextVElement.props[FOR_SUBTITLE_STYLE_DATASET_NAME] = subtitleText;
    return subtitleTextVElement;
};
const getText = (element) => { var _a; return (_a = element.textContent) !== null && _a !== void 0 ? _a : ''; };
const getSubtitle = (slideVProps, queryId) => {
    var _a;
    const { i18nConverter } = config;
    const placeholderText = i18nConverter('place_holder');
    const { subtitleMap } = slideVProps;
    if (!subtitleMap) {
        return { text: placeholderText };
    }
    const query = getCachedQuery(queryId);
    if (!query) {
        return { text: placeholderText };
    }
    const { invisible, text: subtitleText } = (_a = subtitleMap[query.id]) !== null && _a !== void 0 ? _a : {};
    return {
        invisible,
        text: subtitleText !== null && subtitleText !== void 0 ? subtitleText : query.text,
    };
};
