import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
export default (url, type) => {
    var _a, _b, _c;
    if (!url || typeof url !== 'string') {
        return true;
    }
    if (type === 'image') {
        const expiryRegex = /Expires=([^\s^&]+)/;
        const expiry = (_a = expiryRegex.exec(url)) === null || _a === void 0 ? void 0 : _a[1];
        return expiry && !dayjs().isBefore(dayjs(Number(expiry) * 1000));
    }
    const dateRegex = /X-Amz-Date=([^\s^&]+)/;
    const date = (_b = dateRegex.exec(url)) === null || _b === void 0 ? void 0 : _b[1];
    const expiryRegex = /X-Amz-Expires=([^\s^&]+)/;
    const expiry = (_c = expiryRegex.exec(url)) === null || _c === void 0 ? void 0 : _c[1];
    if (!date || !expiry) {
        return true;
    }
    const from = dayjs(date, 'YYYYMMDDTHHmmssZ');
    return !dayjs().isBefore(dayjs(from).add(expiry, 'seconds'));
};
