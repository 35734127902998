// en, number, ko
const MIX_WHITELIST_REGEX = /^[^0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]+$/g
// number, ja
const JPN_WHITELIST_REGEX = /^[^0-9a-zA-Z\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u4e00-\u9faf\u3005\u30F6]+$/gu
// en, es, number 라틴어계열 검증 정규식 (에스파냐어, 포르투갈어, 프랑스어)
const LATIN_WHITELIST_REGEX = /^[^0-9a-zA-ZÀ-ÿ]+$/gu
// en, number, de
const DE_WHITELIST_REGEX = /^[^0-9a-zA-ZäöüÄÖÜß]+$/gu
// en, number, ch
const CH_WHITELIST_REGEX =
  /^[^0-9a-zA-Z\u3002\u3006\u3007\u4e00-\u9fff\u3400-\u4dbf\ud840-\udb7f\udb80-\udbef\udbc0-\udc4f\uff1f\uff01\u003f\u0021\u002e\u003b\uff1b\u002c\uff0c\u3001\u003a]+$/gu
// eslint-disable-next-line no-useless-escape
const SPECIAL_CHARACTER_REGEX = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g

import config from '@/config/config'
/**
 * @param {string} str
 * @returns {boolean}
 */
export const isValidEditorText = text => {
  if (config.feature.otherLanguage) {
    return (text || '').trim().length !== 0
  }
  return (text || '').replace(MIX_WHITELIST_REGEX, '').trim().length !== 0
}

export const isOnlySpecialCharacter = text => {
  return text.replace(SPECIAL_CHARACTER_REGEX, '').length === 0
}

export const isValidEditorTextByLang = (text, lang) => {
  const trimmedText = (text || '').trim().replace(SPECIAL_CHARACTER_REGEX, '')
  switch (lang) {
    case 'auto':
    case 'en-us':
    case 'ko-kr':
      return trimmedText.replace(MIX_WHITELIST_REGEX, '').length !== 0
    case 'ja-jp':
      return trimmedText.replace(JPN_WHITELIST_REGEX, '').length !== 0
    case 'es-es':
    case 'pt-pt':
    case 'fr-fr':
      return trimmedText.replace(LATIN_WHITELIST_REGEX, '').length !== 0
    case 'de-de':
      return trimmedText.replace(DE_WHITELIST_REGEX, '').length !== 0
    case 'zh-cn':
      return trimmedText.replace(CH_WHITELIST_REGEX, '').length !== 0
  }
}
