import { selecto } from '@/context';
import { getVPropsById } from '@/share/virtualDOM/getVPropsById';
/** @public */
export const getSelectedVPropsList = () => {
    const selectedTargets = selecto.getSelectedTargets();
    const vPropsList = selectedTargets.map(elementToVProps);
    return vPropsList;
};
const elementToVProps = (element) => {
    const id = element.getAttribute('id');
    return getVPropsById(id);
};
