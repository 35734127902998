/*
 * Generated type guards for "ProjectV3.ts".
 * WARNING: Do not manually change this file.
 */
import { isProject } from "../Project.guard";
import { isTiptapDetail } from "../TiptapDetail.guard";
export function isProjectV3(obj) {
    const typedObj = obj;
    return (isProject(typedObj) &&
        typedObj["version"] === 3 &&
        isTiptapDetail(typedObj["v3"]));
}
