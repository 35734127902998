import { rerenderDOM } from '@/share/render';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { resetTargets } from '@/context';
import { mergeWithoutArray } from 'utils/src/mergeWithoutArray';
// @ts-ignore
import store from '@/store';
import { onPiniaMounted } from 'store/plugins/OnPiniaMountedPlugin';
// NOTE: videoDomStore가 초기화 된 시점에서는 editorOptionsStore도 사용 가능 (반대도 허용)
onPiniaMounted('videoDom', () => {
    const editorOptionsStore = storeToRefs(useEditorOptionsStore());
    const videoDomStore = useVideoDomStore();
    // NOTE(Refactor): 썸네일 리렌더 로직은 useUpdateThumbnail.ts,
    // edit container 리렌더 로직은 updateSlide.ts와 VideoDomEditor.vue에 있음
    // 이를 한 곳에서 관리하도록 구성 필요
    watch(() => {
        var _a;
        return [
            videoDomStore.slideList,
            videoDomStore.slideTimelineDurationList,
            videoDomStore.globalBackgroundColor,
            videoDomStore.subtitle,
            store.state.typecast.editor.currentTextNodeMarkAttrs.id,
            (_a = store.state.typecast.queryCache.currentPlayBufferItem) === null || _a === void 0 ? void 0 : _a.queryId,
        ];
    }, () => rerenderDOM(), { deep: true });
    watch(editorOptionsStore.slideIndex, () => resetTargets());
});
/** @public */
export const updateSlide = (vProps, slideIndex) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const editorOptionsStore = storeToRefs(useEditorOptionsStore());
    const { slideIndex: currentSlideIndex } = editorOptionsStore;
    const targetIndex = slideIndex !== null && slideIndex !== void 0 ? slideIndex : currentSlideIndex.value;
    const slide = slideList[targetIndex];
    if (!slide) {
        return;
    }
    const mergedSlide = getMergedSlide(slide, vProps);
    slideList.splice(targetIndex, 1, mergedSlide);
};
const getMergedSlide = (baseSlide, patchSlide) => {
    const mergedSlide = mergeWithoutArray({}, baseSlide, patchSlide);
    if (patchSlide.subtitleMap) {
        mergedSlide.subtitleMap = patchSlide.subtitleMap;
    }
    return mergedSlide;
};
