var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SELECTABLE_ELEMENT_CLASS_NAME } from '@/constants';
import { createVElementId, getSrcByAssetId, objectStyleToString, } from '@/share/virtualDOM';
import { Flags } from 'million';
import { nextRenderTick } from '../nextRenderTick';
import { getVPropsById } from '@/share/virtualDOM/getVPropsById';
import Vue from 'vue';
import { transformToFrame } from '@/share/context';
const ASSET_CLASS_NAME = `video-dom__asset`;
export const createAssetVElement = ({ children }, options) => {
    const childrenVElement = (children !== null && children !== void 0 ? children : []).map(vProps => createVElement(vProps, options));
    return {
        tag: 'div',
        props: {
            id: createVElementId(),
        },
        flag: Flags.ELEMENT,
        children: childrenVElement,
    };
};
const createVElement = (vProps, options) => {
    if (vProps.type === 'image') {
        setSrc(vProps);
        retryLoadImage(vProps);
    }
    const { type, class: className, style, src } = vProps;
    const props = {
        id: vProps.id,
        class: `${className !== null && className !== void 0 ? className : ''} ${ASSET_CLASS_NAME}`,
        style: objectStyleToString(style !== null && style !== void 0 ? style : {}),
        src,
    };
    if (!(options === null || options === void 0 ? void 0 : options.isNotSelectable)) {
        props.class += ` ${SELECTABLE_ELEMENT_CLASS_NAME}`;
        addFrameProperty(vProps);
    }
    return {
        tag: type === 'image' ? 'img' : 'div',
        flag: Flags.ELEMENT,
        props,
    };
};
const setSrc = (vProps) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if ((_a = vProps.src) === null || _a === void 0 ? void 0 : _a.length) {
        return;
    }
    const promisedSrc = getSrcByAssetId(vProps.assetId, 'image', null);
    if (typeof promisedSrc === 'string') {
        Vue.set(vProps, 'src', promisedSrc);
        return;
    }
    Vue.set(vProps, 'src', yield promisedSrc);
});
const retryLoadImage = (vProps) => {
    nextRenderTick(vProps, element => {
        element.addEventListener('error', () => __awaiter(void 0, void 0, void 0, function* () {
            const vProps = getVPropsById(element.id);
            const src = yield getSrcByAssetId(vProps.assetId, 'image', null, true);
            // NOTE: vProps 수정 시, slideList에 붙은 후에는 undefined 될 수 있는 값은 Vue.set 사용 (vue 2 한정)
            Vue.set(vProps, 'src', src);
        }));
    }, {
        label: 'retryLoadImage',
    });
};
const addFrameProperty = (vProps) => nextRenderTick(vProps, element => {
    if (element.frame) {
        return;
    }
    element.frame = getFrameFromVElementProps(vProps);
}, {
    label: 'addFrameProperty',
});
const getFrameFromVElementProps = ({ style }) => {
    if (!style) {
        return transformToFrame();
    }
    return transformToFrame(style.transform);
};
