// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ValidateObjectParams(_target, _propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor.value = function (...args) {
        const params = args[0];
        if (!params || typeof params !== 'object' || Array.isArray(params)) {
            console.error('ValidateObjectParams: Parameters should be an object');
            throw new Error('Parameters should be an object');
        }
        for (const [key, value] of Object.entries(params)) {
            if (value === undefined || value === null || value === '' || Number.isNaN(value)) {
                const ERROR_MESSAGE = `ValidateObjectParams: ${key} is required`;
                console.error(ERROR_MESSAGE);
                throw new Error();
            }
        }
        return originalMethod.apply(this, args);
    };
    return descriptor;
}
