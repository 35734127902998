var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { editMode, playMode } from '@/mode';
import { state } from '@/state';
import { playContainer } from '@/context';
import { RootScene } from '@/model';
import { m, render } from 'million';
import { clearMediaBufferState } from '@/share/render';
export const scene = new RootScene({
    selector: true,
});
/** @public */
export const setVolume = (volume) => scene.setVolume(volume);
/** @public */
export const destroyScene = () => {
    editMode();
    scene.clear();
    render(playContainer, m('div'));
};
/** @public */
export const pause = () => {
    scene.pause();
    clearMediaBufferState();
};
/** @public */
export const play = () => __awaiter(void 0, void 0, void 0, function* () {
    const { mode } = state;
    if (scene.isEnded()) {
        setSeekTime(0);
    }
    if (mode !== 'play') {
        yield playMode();
    }
    scene.play();
});
/** @public */
export const setSeekTime = (seekTime) => {
    // NOTE: 0.04초인 이유는 문서 참고 https://www.notion.so/neosapience/0-aee32aec5fdc40d9b1656150e0b76a56?pvs=4
    if (seekTime === 0) {
        scene.setTime(0.04);
        return;
    }
    scene.setTime(seekTime);
};
/** @public */
export const getDuration = () => {
    return scene.getDuration();
};
