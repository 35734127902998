import AnimationSceneItem from '@/model/animations/AnimationSceneItem';
export default class SmoothAnimationSceneItem extends AnimationSceneItem {
    constructor(animationPosition, slideDurationWithoutTransition, half, direction) {
        super(animationPosition, `smooth${direction}`, slideDurationWithoutTransition, half);
        this.direction = direction;
        const keyFrame = this.getKeyFrame();
        this.set(keyFrame);
    }
    getKeyFrame() {
        if (this.animationPosition === 'start') {
            return {};
        }
        if (this.direction === 'left') {
            return {
                '0%': {
                    'mask-size': '300% 300%',
                    '-webkit-mask-size': '300% 300%',
                    'mask-position': 0,
                    '-webkit-mask-position': 0,
                    'mask-image': 'linear-gradient(to left, transparent 40%, black 60%)',
                    '-webkit-mask-image': 'linear-gradient(to left, transparent 40%, black 60%)',
                },
                '100%': {
                    'mask-position': '100%',
                    '-webkit-mask-position': '100%',
                },
            };
        }
        if (this.direction === 'right') {
            return {
                '0%': {
                    'mask-size': '300% 300%',
                    '-webkit-mask-size': '300% 300%',
                    'mask-position': '100%',
                    '-webkit-mask-position': '100%',
                    'mask-image': 'linear-gradient(to right, transparent 40%, black 60%)',
                    '-webkit-mask-image': 'linear-gradient(to right, transparent 40%, black 60%)',
                },
                '100%': {
                    'mask-position': 0,
                    '-webkit-mask-position': 0,
                },
            };
        }
        if (this.direction === 'up') {
            return {
                '0%': {
                    'mask-size': '300% 300%',
                    '-webkit-mask-size': '300% 300%',
                    'mask-position': '0 0',
                    '-webkit-mask-position': '0 0',
                    'mask-image': 'linear-gradient(to top, transparent 40%, black 60%)',
                    '-webkit-mask-image': 'linear-gradient(to top, transparent 40%, black 60%)',
                },
                '100%': {
                    'mask-position': '0 100%',
                    '-webkit-mask-position': '0 100%',
                },
            };
        }
        return {
            '0%': {
                'mask-size': '300% 300%',
                '-webkit-mask-size': '300% 300%',
                'mask-position': '0 100%',
                '-webkit-mask-position': '0 100%',
                'mask-image': 'linear-gradient(to bottom, transparent 40%, black 60%)',
                '-webkit-mask-image': 'linear-gradient(to bottom, transparent 40%, black 60%)',
            },
            '100%': {
                'mask-position': '0 0',
                '-webkit-mask-position': '0 0',
            },
        };
    }
    calculateDuration() {
        const duration = super.calculateDuration();
        return Math.min(duration, this.slideDurationWithoutTransition / 2);
    }
}
