export function isNode(obj) {
    const typedObj = obj;
    return ((typedObj !== null &&
        typeof typedObj === "object" ||
        typeof typedObj === "function") &&
        (typedObj["type"] === "text" ||
            typedObj["type"] === "separator") &&
        (typeof typedObj["text"] === "undefined" ||
            typedObj["text"] === null ||
            typeof typedObj["text"] === "string") &&
        (typeof typedObj["marks"] === "undefined" ||
            typedObj["marks"] === null ||
            Array.isArray(typedObj["marks"]) &&
                typedObj["marks"].every((e) => (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                    e["type"] === "query" &&
                    (e["attrs"] !== null &&
                        typeof e["attrs"] === "object" ||
                        typeof e["attrs"] === "function") &&
                    (typeof e["attrs"]["id"] === "undefined" ||
                        e["attrs"]["id"] === null ||
                        typeof e["attrs"]["id"] === "string") &&
                    (typeof e["attrs"]["style"] === "undefined" ||
                        e["attrs"]["style"] === null ||
                        typeof e["attrs"]["style"] === "string" ||
                        typeof e["attrs"]["style"] === "number") &&
                    (typeof e["attrs"]["styleTag"] === "undefined" ||
                        e["attrs"]["styleTag"] === null ||
                        typeof e["attrs"]["styleTag"] === "string") &&
                    (typeof e["attrs"]["speed"] === "undefined" ||
                        e["attrs"]["speed"] === null ||
                        typeof e["attrs"]["speed"] === "number") &&
                    (typeof e["attrs"]["silence"] === "undefined" ||
                        e["attrs"]["silence"] === null ||
                        typeof e["attrs"]["silence"] === "number") &&
                    (typeof e["attrs"]["preSilence"] === "undefined" ||
                        e["attrs"]["preSilence"] === null ||
                        typeof e["attrs"]["preSilence"] === "number") &&
                    (typeof e["attrs"]["customSpeed"] === "undefined" ||
                        e["attrs"]["customSpeed"] === null ||
                        typeof e["attrs"]["customSpeed"] === "string" ||
                        typeof e["attrs"]["customSpeed"] === "number") &&
                    (typeof e["attrs"]["tempo"] === "undefined" ||
                        e["attrs"]["tempo"] === null ||
                        typeof e["attrs"]["tempo"] === "number") &&
                    (typeof e["attrs"]["pitch"] === "undefined" ||
                        e["attrs"]["pitch"] === null ||
                        typeof e["attrs"]["pitch"] === "number") &&
                    (typeof e["attrs"]["lastPitch"] === "undefined" ||
                        e["attrs"]["lastPitch"] === null ||
                        typeof e["attrs"]["lastPitch"] === "number"))));
}
export function isText(obj) {
    const typedObj = obj;
    return (isNode(typedObj) &&
        typedObj["type"] === "text" &&
        typeof typedObj["text"] === "string" &&
        (typeof typedObj["marks"] === "undefined" ||
            typedObj["marks"] === null ||
            Array.isArray(typedObj["marks"]) &&
                typedObj["marks"].every((e) => (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                    e["type"] === "query" &&
                    (e["attrs"] !== null &&
                        typeof e["attrs"] === "object" ||
                        typeof e["attrs"] === "function") &&
                    (typeof e["attrs"]["id"] === "undefined" ||
                        e["attrs"]["id"] === null ||
                        typeof e["attrs"]["id"] === "string") &&
                    (typeof e["attrs"]["style"] === "undefined" ||
                        e["attrs"]["style"] === null ||
                        typeof e["attrs"]["style"] === "string" ||
                        typeof e["attrs"]["style"] === "number") &&
                    (typeof e["attrs"]["styleTag"] === "undefined" ||
                        e["attrs"]["styleTag"] === null ||
                        typeof e["attrs"]["styleTag"] === "string") &&
                    (typeof e["attrs"]["speed"] === "undefined" ||
                        e["attrs"]["speed"] === null ||
                        typeof e["attrs"]["speed"] === "number") &&
                    (typeof e["attrs"]["silence"] === "undefined" ||
                        e["attrs"]["silence"] === null ||
                        typeof e["attrs"]["silence"] === "number") &&
                    (typeof e["attrs"]["preSilence"] === "undefined" ||
                        e["attrs"]["preSilence"] === null ||
                        typeof e["attrs"]["preSilence"] === "number") &&
                    (typeof e["attrs"]["customSpeed"] === "undefined" ||
                        e["attrs"]["customSpeed"] === null ||
                        typeof e["attrs"]["customSpeed"] === "string" ||
                        typeof e["attrs"]["customSpeed"] === "number") &&
                    (typeof e["attrs"]["tempo"] === "undefined" ||
                        e["attrs"]["tempo"] === null ||
                        typeof e["attrs"]["tempo"] === "number") &&
                    (typeof e["attrs"]["pitch"] === "undefined" ||
                        e["attrs"]["pitch"] === null ||
                        typeof e["attrs"]["pitch"] === "number") &&
                    (typeof e["attrs"]["lastPitch"] === "undefined" ||
                        e["attrs"]["lastPitch"] === null ||
                        typeof e["attrs"]["lastPitch"] === "number"))));
}
export function isSeparator(obj) {
    const typedObj = obj;
    return (isNode(typedObj) &&
        typedObj["type"] === "separator");
}
