import CONSTANTS from '@/config/constants'
import {setCookie, getCookie, deleteCookie} from '@/utils/cookieManager'
import {getParsedPlan} from '@/model/membership-plan'

function paymentFailureErrorParser({error_code: errorCode, reason}, defaultReason) {
  const {ERROR_NEED_TO_BE_PARSED} = CONSTANTS
  if (ERROR_NEED_TO_BE_PARSED.includes(errorCode)) {
    return defaultReason
  }
  return reason
}

function getSelectedPlanInfo(planList, packageId) {
  for (const {name, monthly, yearly} of Object.values(planList)) {
    if (monthly.package_id === packageId) {
      return {
        name,
        period: 'monthly',
      }
    }
    if (yearly.package_id === packageId) {
      return {
        name,
        period: 'yearly',
      }
    }
  }
  return {}
}

function _getPaymentFailureCookieKey(uid) {
  return `payment-failure-${uid}`
}

function getPaymentFailure(
  {uid},
  bannerStatus, // closed
) {
  const paymentFailureCookieKey = _getPaymentFailureCookieKey(uid)
  const showPaymentFailure = getCookie(paymentFailureCookieKey)
  switch (showPaymentFailure) {
    case '':
      setCookie(paymentFailureCookieKey, true)
      return true
    case bannerStatus:
    case 'false':
      return false
    default:
      return true
  }
}

function closePaymentFailureBanner({uid}) {
  const paymentFailureCookieKey = _getPaymentFailureCookieKey(uid)
  setCookie(paymentFailureCookieKey, 'closed')
}

function setFalsePaymentFailureCookie({uid}) {
  const paymentFailureCookieKey = _getPaymentFailureCookieKey(uid)
  setCookie(paymentFailureCookieKey, false)
}

function deletePaymentFailureCookie({uid}) {
  const paymentFailureCookieKey = _getPaymentFailureCookieKey(uid)
  deleteCookie(paymentFailureCookieKey)
}

function getSelectedCouponPlan({couponItem, planList, paymentCurrency}) {
  const {
    package: {plan_name: planName},
  } = couponItem.coupon_event
  const plan = planList[planName]
  return getParsedPlan({
    plan,
    period: 'monthly',
    currency: paymentCurrency,
  })
}

export {
  paymentFailureErrorParser,
  getSelectedPlanInfo,
  getPaymentFailure,
  closePaymentFailureBanner,
  setFalsePaymentFailureCookie,
  deletePaymentFailureCookie,
  getSelectedCouponPlan,
}
