export function isDetail(obj) {
    const typedObj = obj;
    return ((typedObj !== null &&
        typeof typedObj === "object" ||
        typeof typedObj === "function") &&
        (typeof typedObj["query_cache_items"] === "undefined" ||
            typedObj["query_cache_items"] === null ||
            (typedObj["query_cache_items"] !== null &&
                typeof typedObj["query_cache_items"] === "object" ||
                typeof typedObj["query_cache_items"] === "function") &&
                Object.entries(typedObj["query_cache_items"])
                    .every(([key, value]) => ((value !== null &&
                    typeof value === "object" ||
                    typeof value === "function") &&
                    (typeof value["queryId"] === "undefined" ||
                        value["queryId"] === null ||
                        typeof value["queryId"] === "string") &&
                    (typeof value["hash"] === "undefined" ||
                        value["hash"] === null ||
                        typeof value["hash"] === "number") &&
                    (typeof value["speak"] === "undefined" ||
                        value["speak"] === null ||
                        (value["speak"] !== null &&
                            typeof value["speak"] === "object" ||
                            typeof value["speak"] === "function") &&
                            (typeof value["speak"]["query"] === "undefined" ||
                                value["speak"]["query"] === null ||
                                (value["speak"]["query"] !== null &&
                                    typeof value["speak"]["query"] === "object" ||
                                    typeof value["speak"]["query"] === "function") &&
                                    typeof value["speak"]["query"]["text"] === "string" &&
                                    typeof value["speak"]["query"]["actor_id"] === "string" &&
                                    typeof value["speak"]["query"]["lang"] === "string" &&
                                    typeof value["speak"]["query"]["max_seconds"] === "number" &&
                                    (typeof value["speak"]["query"]["namespace"] === "undefined" ||
                                        value["speak"]["query"]["namespace"] === null ||
                                        typeof value["speak"]["query"]["namespace"] === "string") &&
                                    typeof value["speak"]["query"]["speaker_db"] === "string" &&
                                    typeof value["speak"]["query"]["speaker_id"] === "string" &&
                                    typeof value["speak"]["query"]["version"] === "string" &&
                                    typeof value["speak"]["query"]["audio_quality"] === "string" &&
                                    (typeof value["speak"]["query"]["platform"] === "undefined" ||
                                        value["speak"]["query"]["platform"] === null ||
                                        typeof value["speak"]["query"]["platform"] === "string")) &&
                            (typeof value["speak"]["speak_url"] === "undefined" ||
                                value["speak"]["speak_url"] === null ||
                                typeof value["speak"]["speak_url"] === "string") &&
                            (typeof value["speak"]["audio"] === "undefined" ||
                                value["speak"]["audio"] === null ||
                                (value["speak"]["audio"] !== null &&
                                    typeof value["speak"]["audio"] === "object" ||
                                    typeof value["speak"]["audio"] === "function") &&
                                    (typeof value["speak"]["audio"]["url"] === "undefined" ||
                                        value["speak"]["audio"]["url"] === null ||
                                        typeof value["speak"]["audio"]["url"] === "string") &&
                                    (typeof value["speak"]["audio"]["extension"] === "undefined" ||
                                        value["speak"]["audio"]["extension"] === null ||
                                        typeof value["speak"]["audio"]["extension"] === "string") &&
                                    (typeof value["speak"]["audio"]["high"] === "undefined" ||
                                        value["speak"]["audio"]["high"] === null ||
                                        (value["speak"]["audio"]["high"] !== null &&
                                            typeof value["speak"]["audio"]["high"] === "object" ||
                                            typeof value["speak"]["audio"]["high"] === "function") &&
                                            (typeof value["speak"]["audio"]["high"]["url"] === "undefined" ||
                                                value["speak"]["audio"]["high"]["url"] === null ||
                                                typeof value["speak"]["audio"]["high"]["url"] === "string") &&
                                            (typeof value["speak"]["audio"]["high"]["extension"] === "undefined" ||
                                                value["speak"]["audio"]["high"]["extension"] === null ||
                                                typeof value["speak"]["audio"]["high"]["extension"] === "string")) &&
                                    (typeof value["speak"]["audio"]["hd1"] === "undefined" ||
                                        value["speak"]["audio"]["hd1"] === null ||
                                        (value["speak"]["audio"]["hd1"] !== null &&
                                            typeof value["speak"]["audio"]["hd1"] === "object" ||
                                            typeof value["speak"]["audio"]["hd1"] === "function") &&
                                            (typeof value["speak"]["audio"]["hd1"]["url"] === "undefined" ||
                                                value["speak"]["audio"]["hd1"]["url"] === null ||
                                                typeof value["speak"]["audio"]["hd1"]["url"] === "string") &&
                                            (typeof value["speak"]["audio"]["hd1"]["extension"] === "undefined" ||
                                                value["speak"]["audio"]["hd1"]["extension"] === null ||
                                                typeof value["speak"]["audio"]["hd1"]["extension"] === "string")) &&
                                    (typeof value["speak"]["audio"]["low"] === "undefined" ||
                                        value["speak"]["audio"]["low"] === null ||
                                        (value["speak"]["audio"]["low"] !== null &&
                                            typeof value["speak"]["audio"]["low"] === "object" ||
                                            typeof value["speak"]["audio"]["low"] === "function") &&
                                            (typeof value["speak"]["audio"]["low"]["url"] === "undefined" ||
                                                value["speak"]["audio"]["low"]["url"] === null ||
                                                typeof value["speak"]["audio"]["low"]["url"] === "string") &&
                                            (typeof value["speak"]["audio"]["low"]["extension"] === "undefined" ||
                                                value["speak"]["audio"]["low"]["extension"] === null ||
                                                typeof value["speak"]["audio"]["low"]["extension"] === "string")))) &&
                    typeof key === "string"))) &&
        (typeof typedObj["style_label_version_list"] === "undefined" ||
            typedObj["style_label_version_list"] === null ||
            (typedObj["style_label_version_list"] !== null &&
                typeof typedObj["style_label_version_list"] === "object" ||
                typeof typedObj["style_label_version_list"] === "function") &&
                Object.entries(typedObj["style_label_version_list"])
                    .every(([key, value]) => ((typeof value === "undefined" ||
                    value === null ||
                    typeof value === "string") &&
                    typeof key === "string"))));
}
