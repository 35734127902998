import { Flags, createElement } from 'million';
import { EDIT_CONTAINER_ID, PLAY_CONTAINER_ID } from '@/constants';
import { initMoveable } from '@/context';
import { autoResizeWithParent } from './autoResizeWithParent';
import { resizeWithParentElement } from '@/share/context';
import { objectStyleToString } from '@/share/virtualDOM';
export let playContainer;
export let editContainer;
export const attachContainer = (outerContainer, width, height) => {
    clearElementChildren(outerContainer);
    const playRootContainer = createRootContainer();
    const editRootContainer = createRootContainer();
    playContainer = createChildContainer(PLAY_CONTAINER_ID);
    editContainer = createChildContainer(EDIT_CONTAINER_ID);
    playRootContainer.style.setProperty('width', `${width}px`);
    playRootContainer.style.setProperty('height', `${height}px`);
    playRootContainer.appendChild(playContainer);
    editRootContainer.style.setProperty('width', `${width}px`);
    editRootContainer.style.setProperty('height', `${height}px`);
    editRootContainer.appendChild(editContainer);
    outerContainer.appendChild(editRootContainer);
    outerContainer.appendChild(playRootContainer);
    autoResizeWithParent(editRootContainer);
    resizeWithParentElement(editRootContainer);
    initMoveable(editRootContainer);
};
const createRootContainer = () => createHTMLElement({
    type: 'container',
    style: {
        position: 'relative',
        transformOrigin: 'top left',
        overflow: 'hidden',
        userSelect: 'none',
        textAlign: 'initial',
    },
});
const createChildContainer = (id) => createHTMLElement({
    id,
    type: 'container',
    style: {
        width: '100%',
        height: '100%',
    },
});
const createHTMLElement = (vProps) => {
    var _a;
    return createElement({
        tag: 'div',
        props: {
            id: vProps.id,
            style: objectStyleToString((_a = vProps.style) !== null && _a !== void 0 ? _a : {}),
        },
        flag: Flags.ELEMENT,
    });
};
const clearElementChildren = (element) => (element.textContent = '');
