import { SceneItem } from 'scenejs';
import { state } from '@/state';
import { PLAY_ID, WATERMARK_MEDIA_SCENE_ID } from '@/constants';
import { BufferedAudio, addSceneItem, getCharacterId, getCorrectedTimelineMediaAsset, getSpeakId, getTimelineVideoId, getTransitionOverlayId, } from '@/share/render';
import { createVElementId, getSrcByAssetId, isAnimationCharacter, } from '@/share/virtualDOM';
import { scene } from '@/context';
import { CharacterScene, Slide as SlideModel, MediaScene, } from '@/model';
import { createAnimationSceneItem } from '@/model/animations';
import TransitionSceneItem from '@/model/TransitionSceneItem';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import watermarkAudioSrc from '@/assets/audio/typecast_allages_leveled_low.mp3';
// @ts-ignore
import vuexStore from '@/store';
// @ts-ignore
import { getRestOfTimelineVideoId } from '@/share/render/getRestOfTimelineVideoId';
import Big from 'big.js';
import { createSlideDurationDictList } from './transforms/createSlideDurationDictList';
import { getCharacterInfo } from './transforms/createCharacterVElement';
/** @public */
export const renderPlayScene = () => {
    const alreadySceneRendered = Object.values(scene.items).length;
    if (alreadySceneRendered) {
        return;
    }
    const videoDomStore = useVideoDomStore();
    const slideList = videoDomStore.slideList;
    const filteredSlideList = slideList.filter(({ speakList }) => speakList === null || speakList === void 0 ? void 0 : speakList.length);
    const slideDurationDictList = createSlideDurationDictList(filteredSlideList);
    const { timelineAssetLists } = state;
    const correctedTimelineVideoList = timelineAssetLists.video.map(getCorrectedTimelineMediaAsset);
    const remainingVideoSlideScene = getRemainingVideoSlideScene(slideDurationDictList, correctedTimelineVideoList);
    const hasRemainingVideoSlideScene = !!remainingVideoSlideScene;
    const slideSceneList = filteredSlideList.map(useCreateSlideScene(slideDurationDictList, hasRemainingVideoSlideScene));
    slideSceneList.forEach(slideScene => {
        scene.setItem(slideScene.getId(), slideScene);
    });
    if (hasRemainingVideoSlideScene) {
        scene.setItem(remainingVideoSlideScene.getId(), remainingVideoSlideScene);
    }
    correctedTimelineVideoList.forEach((timelineVideo, ind, { length }) => {
        const isLastTimelineVideo = length - 1 === ind;
        const isRemainAndLastTimelineVideo = isLastTimelineVideo && hasRemainingVideoSlideScene;
        const mediaScene = createTimelineVideoMediaScene(timelineVideo, isRemainAndLastTimelineVideo);
        if (!mediaScene) {
            return;
        }
        scene.setItem(mediaScene.getId(), mediaScene);
    });
    const correctedTimelineBgmList = timelineAssetLists.bgm.map(getCorrectedTimelineMediaAsset);
    correctedTimelineBgmList.map(bgm => {
        const mediaScene = createBgmMediaScene(bgm);
        if (!mediaScene) {
            return;
        }
        scene.setItem(mediaScene.getId(), mediaScene);
    });
    const needWatermarkAudio = vuexStore.state.typecast.editor.isEnableWatermark;
    if (needWatermarkAudio) {
        const watermarkMedia = createWatermarkMediaScene(scene);
        scene.setItem(watermarkMedia.getId(), watermarkMedia);
    }
};
const useCreateSlideScene = (slideDurationDictList, hasRemainingVideoSlideScene) => (slideVProps, slideIndex, slideVPropsList) => {
    const slideScene = new SlideModel();
    const slideId = slideVProps.id;
    slideScene.setId(slideId);
    const slideDurationDict = slideDurationDictList[slideIndex];
    const { delay, duration, startTransitionDuration, endTransitionDuration, durationWithoutTransition, } = slideDurationDict;
    slideScene.setDelay(delay.toNumber());
    slideScene.setDuration(duration.toNumber());
    slideScene.startTransitionDuration = startTransitionDuration.toNumber();
    slideScene.endTransitionDuration = endTransitionDuration.toNumber();
    slideScene.durationWithoutTransition = durationWithoutTransition.toNumber();
    const speakSceneItemListWithoutElement = createSceneItemBySpeakList(slideDurationDict);
    const speakTextSceneItemList = speakSceneItemListWithoutElement.map(useCreateSpeakTextSceneItem(slideId));
    const characterSceneItemListWithoutElement = createSceneItemBySpeakList(slideDurationDict);
    const characterBuildInOutSceneItemList = characterSceneItemListWithoutElement.map(useCreateCharacterBuildInOutSceneItem(slideVProps, slideDurationDict));
    addSceneItem(slideScene, [
        ...speakTextSceneItemList,
        ...characterBuildInOutSceneItemList,
    ]);
    const transitionSceneItem = new TransitionSceneItem();
    transitionSceneItem.setId(getTransitionId(slideId));
    transitionSceneItem.setElement(`#${PLAY_ID} #${slideId}`);
    const transitionOverlaySceneItem = new TransitionSceneItem();
    const overlayId = getTransitionOverlayId(slideId);
    transitionOverlaySceneItem.setId(overlayId);
    transitionOverlaySceneItem.setElement(`#${PLAY_ID} #${overlayId}`);
    const isLastSlide = slideIndex === slideVPropsList.length - 1;
    if (hasRemainingVideoSlideScene || !isLastSlide) {
        addEndTransitionSceneItem(slideDurationDict, transitionSceneItem, slideVProps);
        addEndTransitionOverlaySceneItem(slideDurationDict, transitionOverlaySceneItem, slideVProps);
    }
    const mediaScene = createMediaScene(slideDurationDict, slideVProps);
    slideScene.setItem(mediaScene.getId(), mediaScene);
    if (isAnimationCharacter(slideVProps)) {
        const animationCharacterScene = createAnimationCharacterScene(slideDurationDict, slideVProps);
        slideScene.setItem(`${slideId}_animationCharacter`, animationCharacterScene);
    }
    const prevSlideVProps = slideVPropsList[slideIndex - 1];
    if (!prevSlideVProps) {
        addSceneItem(slideScene, [transitionSceneItem, transitionOverlaySceneItem].map(returnSceneItemWhenIsNotEmpty));
        return slideScene;
    }
    addStartTransitionOverlaySceneItem(slideDurationDict, transitionOverlaySceneItem, prevSlideVProps);
    // NOTE: startTransition은 이전 슬라이드의 transition에 따라 결정된다.
    // 따라서 현재 transition을 검증하지 않고 이전 슬라이드의 transition을 검증해야 하며,
    // 이는 createStartTransitionSceneItem() 에서 수행된다.
    addStartTransitionSceneItem(slideDurationDict, transitionSceneItem, prevSlideVProps);
    addSceneItem(slideScene, [transitionSceneItem, transitionOverlaySceneItem].map(returnSceneItemWhenIsNotEmpty));
    return slideScene;
};
const useCreateSpeakTextSceneItem = (slideId) => (speakTextSceneItem, index, { length }) => {
    const isOnlyOneSpeak = length === 1;
    if (isOnlyOneSpeak) {
        return;
    }
    const speakId = getSpeakId(slideId, index);
    speakTextSceneItem.setElement(`#${speakId}`);
    speakTextSceneItem.setId(speakId);
    return speakTextSceneItem;
};
// 캐릭터 빌드인/빌드아웃
const useCreateCharacterBuildInOutSceneItem = (slideVprops, slideDurationDict) => {
    const { id: slideId, characterBuildInAnimation, characterBuildOutAnimation, } = slideVprops;
    return (sceneItem, index, { length }) => {
        const characterId = getCharacterId(slideId, index);
        sceneItem.setElement(`#${characterId}`);
        sceneItem.setId(characterId);
        const isFirstChild = index === 0;
        if (isFirstChild) {
            addCharacterBuildInSceneItem(sceneItem, slideDurationDict, characterBuildInAnimation);
        }
        const isLastChild = index === length - 1;
        const isOnlyOneSpeak = length === 1;
        if (isLastChild) {
            addCharacterBuildOutSceneItem(sceneItem, slideDurationDict, characterBuildOutAnimation, isOnlyOneSpeak);
        }
        return sceneItem;
    };
};
// 캐릭터 빌드인
const addCharacterBuildInSceneItem = (sceneItem, { durationWithoutTransition }, characterBuildInAnimation) => {
    if (isNoneAnimation(characterBuildInAnimation)) {
        return sceneItem;
    }
    sceneItem.setId(`${sceneItem.getId()}-buildIn`);
    const startSceneItem = createAnimationSceneItem('start', characterBuildInAnimation, true, durationWithoutTransition.toNumber());
    sceneItem.set(startSceneItem);
    return sceneItem;
};
// 캐릭터 빌드아웃
const addCharacterBuildOutSceneItem = (sceneItem, { durationWithoutTransition, speakDurationDictList, preSilence, }, characterBuildOutAnimation, isOnlyOneSpeak) => {
    if (isNoneAnimation(characterBuildOutAnimation)) {
        return sceneItem;
    }
    sceneItem.setId(`${sceneItem.getId()}-buildOut`);
    const endSceneItem = createAnimationSceneItem('end', characterBuildOutAnimation, true, durationWithoutTransition.toNumber());
    const [lastSpeakDurationDict] = speakDurationDictList.slice(-1);
    const { duration: speakDuration } = lastSpeakDurationDict;
    if (isOnlyOneSpeak) {
        endSceneItem.setDelay(speakDuration
            .plus(preSilence)
            .minus(endSceneItem.getDuration())
            .toNumber());
    }
    else {
        endSceneItem.setDelay(speakDuration.minus(endSceneItem.getDuration()).toNumber());
    }
    sceneItem.set(endSceneItem);
    return sceneItem;
};
const isNoneAnimation = (animation) => !animation || animation === 'none';
const isTransitionOverlay = (transition) => (transition === null || transition === void 0 ? void 0 : transition.search(/fade\w+/)) === 0;
const createSceneItemBySpeakList = (slideDurationDict) => {
    const { startTransitionDuration, speakDurationDictList, preSilence } = slideDurationDict;
    return speakDurationDictList.map(({ delay, duration }, speakIndex, { length }) => {
        const sceneItem = new SceneItem();
        const isFirstSpeak = speakIndex === 0;
        if (isFirstSpeak) {
            sceneItem.setDelay(delay.plus(startTransitionDuration).toNumber());
        }
        else {
            sceneItem.setDelay(delay.plus(startTransitionDuration).plus(preSilence).toNumber());
        }
        const isOnlyOneSpeak = length === 1;
        if (isOnlyOneSpeak) {
            return sceneItem;
        }
        const endSceneItem = createAnimationSceneItem('end', 'none');
        endSceneItem.setDelay(duration.toNumber());
        if (isFirstSpeak) {
            endSceneItem.setDelay(duration.plus(preSilence).toNumber());
            sceneItem.set(endSceneItem);
            return sceneItem;
        }
        const startSceneItem = createAnimationSceneItem('start', 'none');
        const isLastSpeak = speakIndex === length - 1;
        if (isLastSpeak) {
            sceneItem.set(startSceneItem);
            return sceneItem;
        }
        sceneItem.set(startSceneItem);
        sceneItem.set(endSceneItem);
        return sceneItem;
    });
};
const addStartTransitionOverlaySceneItem = ({ startTransitionDuration }, transitionSceneItem, prevSlideVProps) => {
    const { transition } = prevSlideVProps;
    if (!isTransitionOverlay(transition)) {
        return;
    }
    const startSceneItem = createAnimationSceneItem('start', transition);
    startSceneItem.setDuration(startTransitionDuration.toNumber());
    transitionSceneItem.set(startSceneItem);
    return transitionSceneItem;
};
const addEndTransitionOverlaySceneItem = ({ duration, endTransitionDuration }, transitionSceneItem, slideVProps) => {
    const { transition } = slideVProps;
    if (!isTransitionOverlay(transition)) {
        return;
    }
    const endSceneItem = createAnimationSceneItem('end', transition);
    endSceneItem.setDelay(duration.minus(endTransitionDuration).toNumber());
    endSceneItem.setDuration(endTransitionDuration.toNumber());
    transitionSceneItem.set(endSceneItem);
    return transitionSceneItem;
};
const addStartTransitionSceneItem = ({ startTransitionDuration }, transitionSceneItem, prevSlideVProps) => {
    const { transition: prevTransition } = prevSlideVProps;
    if (isTransitionOverlay(prevTransition) || isNoneAnimation(prevTransition)) {
        return;
    }
    const startSceneItem = createAnimationSceneItem('start', prevTransition);
    startSceneItem.setDuration(startTransitionDuration.toNumber());
    transitionSceneItem.set(startSceneItem);
    return transitionSceneItem;
};
const addEndTransitionSceneItem = ({ duration, endTransitionDuration }, transitionSceneItem, slideVProps) => {
    const { transition } = slideVProps;
    if (isTransitionOverlay(transition) || isNoneAnimation(transition)) {
        return;
    }
    // 슬라이드가 끝났을 때는 반드시 화면에서 사라져야하고 transition에 따라 사라질 애니메이션이 결정된다.
    // 하지만 TransitionOverlay의 경우에는 다음 슬라이드가 Overlay되며 등장을 하기 때문에 애니메이션이 필요가 없고 슬라이드의 길이만 늘리면 된다.
    const endSceneItem = createAnimationSceneItem('end', transition);
    endSceneItem.setDelay(duration.minus(endTransitionDuration).toNumber());
    endSceneItem.setDuration(endTransitionDuration.toNumber());
    transitionSceneItem.set(endSceneItem);
    return transitionSceneItem;
};
const getTransitionId = (slideId) => `${slideId}-transition`;
const returnSceneItemWhenIsNotEmpty = (sceneItem) => sceneItem.times.length ? sceneItem : undefined;
const createMediaScene = ({ startTransitionDuration, speakDurationDictList, preSilence, }, slideVProps) => {
    const { isRealCharacter } = getCharacterInfo(slideVProps);
    const { speakList, id: slideId } = slideVProps;
    const mediaScene = new MediaScene();
    mediaScene.setDelay(startTransitionDuration.toNumber());
    mediaScene.setId(`${slideId}-speak`);
    speakDurationDictList.forEach(({ delay, duration }, speakIndex) => {
        const { audioUrl: assetId, silence } = speakList[speakIndex];
        const speakId = getSpeakId(slideId, speakIndex);
        const audioElement = createAudioElement();
        const mediaSceneItem = mediaScene.addMedia(speakId, audioElement);
        mediaSceneItem.getPromisedUrl = () => getSrcByAssetId(assetId, 'audio', null);
        mediaSceneItem.seek(0, duration.minus(silence).toNumber());
        mediaSceneItem.setDelay(delay.plus(preSilence).toNumber());
    });
    if (!isRealCharacter) {
        return mediaScene;
    }
    speakDurationDictList.forEach(({ delay, duration }, speakIndex) => {
        const characterId = getCharacterId(slideId, speakIndex);
        const selector = `#${PLAY_ID} #${characterId} video`;
        const videoElement = document.querySelector(selector) ||
            document.createElement('video'); // avoid DOMException error
        const videoMediaSceneItem = mediaScene.addMedia(characterId, videoElement);
        if (speakIndex === 0) {
            videoMediaSceneItem.seek(0, duration.plus(preSilence).toNumber());
        }
        else {
            videoMediaSceneItem.seek(0, duration.toNumber());
            videoMediaSceneItem.setDelay(delay.plus(preSilence).toNumber());
        }
    });
    return mediaScene;
};
const createAudioElement = () => {
    const audioElement = new BufferedAudio(scene);
    audioElement.autoplay = false;
    audioElement.preload = 'none';
    return audioElement;
};
const createAnimationCharacterScene = ({ startTransitionDuration, speakDurationDictList, preSilence, }, slideVProps) => {
    const characterScene = new CharacterScene();
    characterScene.setDelay(startTransitionDuration.toNumber());
    const { id: slideId } = slideVProps;
    speakDurationDictList.forEach(({ delay, duration }, speakIndex) => {
        const characterId = getCharacterId(slideId, speakIndex);
        const characterSceneItem = characterScene.addCharacter(characterId);
        characterSceneItem.setDuration(duration.toNumber());
        characterSceneItem.setDelay(delay.plus(preSilence).toNumber());
    });
    return characterScene;
};
const createTimelineVideoMediaScene = (timelineVideo, isRemainAndLastTimelineVideo) => {
    if (timelineVideo.startTrim === timelineVideo.endTrim) {
        return;
    }
    const mediaScene = new MediaScene();
    const timelineVideoId = getTimelineVideoId(timelineVideo);
    mediaScene.setId(timelineVideoId);
    const mediaSceneItemId = getTimelineVideoId(timelineVideo, true);
    let videoElement = document.querySelector(`#${PLAY_ID} #${mediaSceneItemId}`);
    if (!videoElement) {
        videoElement = document.createElement('video');
        videoElement.setAttribute('id', mediaSceneItemId);
    }
    const mediaSceneItem = mediaScene.addMedia(mediaSceneItemId, videoElement);
    let volume = parseInt(timelineVideo.volume, 10) / 100;
    if (Number.isNaN(volume)) {
        volume = 1;
    }
    const editorOptionsStore = useEditorOptionsStore();
    mediaSceneItem.originVolume = volume;
    mediaScene.setVolume(editorOptionsStore.previewVolume / 100);
    const { startTrim, endTrim } = timelineVideo;
    mediaSceneItem.seek(startTrim, endTrim);
    const videoDelay = timelineVideo.offset;
    mediaScene.setDelay(videoDelay);
    const elementId = `#${PLAY_ID} #${mediaSceneItemId}`;
    if (!isRemainAndLastTimelineVideo) {
        const endTransitionSceneItem = new TransitionSceneItem();
        endTransitionSceneItem.setElement(elementId);
        endTransitionSceneItem.setId(`${mediaSceneItemId}-end`);
        mediaScene.setItem(endTransitionSceneItem.getId(), endTransitionSceneItem);
    }
    mediaScene.setDuration(mediaScene.getDuration());
    return mediaScene;
};
const getRemainingVideoSlideScene = (slideDurationDictList, timelineVideoList) => {
    const [lastSlideDurationDict] = slideDurationDictList.slice(-1);
    const sceneEndTime = lastSlideDurationDict.delay
        .plus(lastSlideDurationDict.duration)
        .toNumber();
    const lastTimelineVideo = getLastTimelineVideo(timelineVideoList, sceneEndTime);
    if (!lastTimelineVideo) {
        return;
    }
    const slideScene = createTemporarySlideScene();
    const restOfTimelineVideoId = getRestOfTimelineVideoId(lastTimelineVideo);
    slideScene.setId(restOfTimelineVideoId);
    slideScene.setDelay(sceneEndTime);
    const { offset, startTrim, endTrim } = lastTimelineVideo;
    const videoDuration = new Big(endTrim).minus(startTrim);
    const videoEndTime = videoDuration.plus(offset);
    const slideDuration = videoEndTime.minus(sceneEndTime);
    slideScene.setDuration(slideDuration.toNumber());
    slideScene.durationWithoutTransition = slideDuration.toNumber();
    const transitionSceneItem = new TransitionSceneItem();
    const transitionId = getTransitionId(slideScene.getId());
    transitionSceneItem.setId(`${transitionId}-end`);
    slideScene.setItem(transitionSceneItem.getId(), transitionSceneItem);
    return slideScene;
};
const createTemporarySlideScene = () => {
    const slideScene = new SlideModel();
    slideScene.setId(createVElementId());
    return slideScene;
};
const getLastTimelineVideo = (timelineVideoList, sceneEndTime) => {
    if (!timelineVideoList.length) {
        return;
    }
    const lastTimelineVideo = timelineVideoList.reduce((prev, curr) => {
        const prevEndTime = prev.endTrim - prev.startTrim + prev.offset;
        const currEndTime = curr.endTrim - curr.startTrim + curr.offset;
        return prevEndTime > currEndTime ? prev : curr;
    }, timelineVideoList[0]);
    const lastTimelineVideoEndTime = lastTimelineVideo.endTrim -
        lastTimelineVideo.startTrim +
        lastTimelineVideo.offset;
    if (lastTimelineVideoEndTime < sceneEndTime) {
        return;
    }
    return lastTimelineVideo;
};
const createWatermarkMediaScene = (scene) => {
    const watermarkMedia = new MediaScene();
    watermarkMedia.setId(WATERMARK_MEDIA_SCENE_ID);
    const audio = new Audio();
    audio.src = watermarkAudioSrc;
    audio.loop = true;
    const watermarkMediaItem = watermarkMedia.addMedia('watermark-audio-item', audio);
    watermarkMediaItem.seek(0, scene.getDuration());
    watermarkMediaItem.originVolume = 0.3;
    return watermarkMedia;
};
const createBgmMediaScene = (bgm) => {
    if (bgm.startTrim === bgm.endTrim) {
        return null;
    }
    const mediaScene = new MediaScene();
    mediaScene.setId(`bgm-${bgm.id}`);
    const audioElement = createAudioElement();
    const mediaSceneItem = mediaScene.addMedia(`bgm-${bgm.id}`, audioElement);
    mediaSceneItem.getPromisedUrl = () => getSrcByAssetId(bgm.id, 'bgm', bgm.assetFrom);
    mediaSceneItem.preloadResource();
    let volume = parseInt(bgm.volume, 10) / 100;
    if (Number.isNaN(volume)) {
        volume = 1;
    }
    mediaSceneItem.originVolume = volume;
    const editorOptionsStore = useEditorOptionsStore();
    mediaScene.setVolume(editorOptionsStore.previewVolume / 100);
    mediaSceneItem.seek(bgm.startTrim, bgm.endTrim);
    mediaScene.setDelay(bgm.offset);
    mediaScene.setDuration(mediaSceneItem.getDuration());
    return mediaScene;
};
