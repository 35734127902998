import {getParsedPlan} from '@/model/membership-plan'
import CONSTANTS from '@/config/constants'
export default {
  _store: null,
  _i18n: null,
  setStore(store) {
    this._store = store
  },
  setI18n(i18n) {
    this._i18n = i18n
  },
  _planList() {
    if (!this._store) {
      return null
    }
    return this._store.getters['payment/planList']
  },
  _getDiscountAmount(amountYearly, amount) {
    if (!amountYearly) {
      return 0
    }
    return amountYearly * 12 - amount
  },
  getPlanInfo(planId, period) {
    const planList = this._planList()
    const currency = this._store.getters['typecast/user/paymentCurrency']
    const plan = getParsedPlan({plan: planList[planId], period, currency})
    return {
      payment_plan_code: plan.package_id,
      payment_plan_name: CONSTANTS.PLAN_NAME[plan.name],
      payment_plan_price: currency === 'usd' ? plan.amount / 100 : plan.amount,
      payment_plan_cycle: period,
      payment_plan_multi: ['team_id', 'business_id'].includes(plan.name),
      payment_plan_discount_rate: plan.amount_discount_rate * 0.01,
      payment_plan_discount_amount: this._getDiscountAmount(plan.amount_monthly, plan.amount),
      payment_plan_duration: plan.seconds,
      payment_plan_currency: currency,
    }
  },
}
