import { config } from '@/config';
import { getPassedOrSelectedIdList } from '@/share/virtualDOM';
import { updateElement } from '@/virtualDOM/update';
import { orderBack } from '@/virtualDOM/order';
import { resetTargets } from '@/context';
export const setAsBackground = (maybeIdList) => {
    const idList = getPassedOrSelectedIdList(maybeIdList);
    const { originWidth, originHeight } = config;
    const width = `${originWidth}px`;
    const height = `${originHeight}px`;
    idList.forEach(id => updateElement(id, {
        style: {
            width,
            height,
            transform: `translate(0, 0)`,
        },
    }));
    orderBack(...idList);
    resetTargets();
    return idList;
};
