import {Plugin, PluginKey} from 'prosemirror-state'
import throttle from 'lodash-es/throttle'

class Menu {
  constructor({options, editorView}) {
    this.options = {
      ...{
        resizeObserver: true,
        element: null,
        onUpdate: () => false,
      },
      ...options,
    }
    this.editorView = editorView
    this.menuList = []
    this.throttledSendUpdate = throttle(this.sendUpdate, 300)

    this.options.editor.on('focus', ({view}) => {
      this.sendUpdate(view)
    })
    if (this.options.element.offsetParent) {
      this.options.element.offsetParent.addEventListener('scroll', () => {
        this.sendUpdate(this.editorView)
      })
    }

    // sometimes we have to update the position
    // because of a loaded images for example
    if (this.options.resizeObserver && window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.sendUpdate(this.editorView)
      })
      this.resizeObserver.observe(this.editorView.dom)
    }
  }

  update(view) {
    // this method is called every time the editor state changes
    this.throttledSendUpdate(view)
  }

  sendUpdate(view) {
    const {state} = view
    const parent = this.options.element.offsetParent
    this.menuList = []
    if (!parent) {
      return
    }
    const editorBoundings = view.dom.getBoundingClientRect()

    state.doc.nodesBetween(0, state.doc.nodeSize - 2, (node, pos) => {
      const cursorBoundings = view.coordsAtPos(pos)
      const top = cursorBoundings.top - editorBoundings.top + 15
      this.menuList.push({top: top, pos: pos})

      return false
    })

    this.options.onUpdate(this.menuList)
  }

  destroy() {
    if (this.throttledSendUpdate) this.throttledSendUpdate.cancel()
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.editorView.dom)
    }
  }
}

export default function (options) {
  return new Plugin({
    key: new PluginKey('floating_menu'),
    view(editorView) {
      return new Menu({editorView, options})
    },
  })
}
