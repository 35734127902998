const state = {
  isMobile: null,
  isPhone: null,
  isTablet: null,
  isDesktop: null,
  isDesktopSmall: null,
  isDesktopLarge: null,
  isDesktopXLarge: null,
}

// getters
const getters = {
  isMobile: state => state.isMobile,
  isPhone: state => state.isPhone,
  isTablet: state => state.isTablet,
  isDesktop: state => state.isDesktop,
  isDesktopSmall: state => state.isDesktopSmall,
  isDesktopLarge: state => state.isDesktopLarge,
  isDesktopXLarge: state => state.isDesktopXLarge,
}

// actions
const actions = {}

const mutations = {
  setIsMobile(state, val) {
    state.isMobile = val
  },
  setIsPhone(state, val) {
    state.isPhone = val
  },
  setIsTablet(state, val) {
    state.isTablet = val
  },
  setIsDesktop(state, val) {
    state.isDesktop = val
  },
  setIsDesktopSmall(state, val) {
    state.isDesktopSmall = val
  },
  setIsDesktopLarge(state, val) {
    state.isDesktopLarge = val
  },
  setIsDesktopXLarge(state, val) {
    state.isDesktopXLarge = val
  },
}

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
}
