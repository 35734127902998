export function findDiffStart(a, b, pos) {
  for (let i = 0; ; i++) {
    if (i == a.childCount || i == b.childCount) return a.childCount == b.childCount ? null : pos

    let childA = a.child(i),
      childB = b.child(i)
    if (childA == childB) {
      pos += childA.nodeSize
      continue
    }

    if (!childA.sameMarkup(childB)) return pos

    if (childA.isText && childA.text != childB.text) {
      for (let j = 0; childA.text[j] == childB.text[j]; j++) pos++
      return pos
    }
    if (childA.content.size || childB.content.size) {
      let inner = findDiffStart(childA.content, childB.content, pos + 1)
      if (inner != null) return inner
    }
    pos += childA.nodeSize
  }
}

export function findDiffEnd(a, b, posA, posB) {
  for (let iA = a.childCount, iB = b.childCount; ; ) {
    if (iA == 0 || iB == 0) return iA == iB ? null : {a: posA, b: posB}

    let childA = a.child(--iA),
      childB = b.child(--iB),
      size = childA.nodeSize
    if (childA == childB) {
      posA -= size
      posB -= size
      continue
    }

    if (!childA.sameMarkup(childB)) return {a: posA, b: posB}

    if (childA.isText && childA.text != childB.text) {
      let same = 0,
        minSize = Math.min(childA.text.length, childB.text.length)
      while (
        same < minSize &&
        childA.text[childA.text.length - same - 1] == childB.text[childB.text.length - same - 1]
      ) {
        same++
        posA--
        posB--
      }
      return {a: posA, b: posB}
    }
    if (childA.content.size || childB.content.size) {
      let inner = findDiffEnd(childA.content, childB.content, posA - 1, posB - 1)
      if (inner) return inner
    }
    posA -= size
    posB -= size
  }
}
