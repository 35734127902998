var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { APIRequestUtils } from '../http-utils';
import backend from '@/backend/backend-api';
import { getVideoDownload } from '@/backend/video-editor-api';
import { downloadByUrl } from '@/libs/utils/download';
import * as Sentry from '@sentry/vue';
const BACK_DOWNLOAD_INTERVAL_TIME = 3000;
export const useBackDownloadUiStore = defineStore('backDownloadUi', {
    state: () => ({ isBackDownloadFolded: true }),
});
export const useBackDownloadStore = defineStore('backDownload', {
    state: () => ({
        downloadIdList: [],
        downloadDetailTable: {},
        backDownloadPollingTable: {},
        backDownloadPollingInterval: null,
        youtubePollingTable: {},
        youtubePollingInterval: null,
    }),
    getters: {
        hasDownloadList: (state) => state.downloadIdList.length > 0,
    },
    actions: {
        existPollingItem() {
            return Object.entries(this.downloadDetailTable).some(([_key, { status }]) => status === 'progress');
        },
        fetchBackDownload($http) {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.requestBackDownloadList($http);
                if (this.downloadIdList.length) {
                    yield this.requestUpdateDetailList($http);
                    const needAutoExpand = this.existPollingItem();
                    if (needAutoExpand) {
                        const backDownloadUiStore = useBackDownloadUiStore();
                        backDownloadUiStore.$patch(state => {
                            state.isBackDownloadFolded = false;
                        });
                    }
                    this.requestBackDownloadPolling($http);
                    this.requestYoutubePolling($http);
                }
            });
        },
        requestBackDownloadList($http) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield APIRequestUtils.request({
                    $http,
                    request: backend.getBackDownloadList,
                });
                const LIST_MAX_SIZE = 10;
                this.$patch(state => {
                    state.downloadIdList =
                        response.result.length > LIST_MAX_SIZE
                            ? response.result.slice(0, LIST_MAX_SIZE)
                            : response.result;
                });
            });
        },
        requestDeleteAllBackDownloadList($http) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield APIRequestUtils.request({
                    $http,
                    params: this.downloadIdList,
                    request: backend.deleteBackDownloadList,
                });
                this.$patch(state => {
                    state.downloadIdList = response.result;
                });
            });
        },
        updateDetailMappingTable(patchedDetailItem) {
            this.$patch(state => {
                state.downloadDetailTable = Object.assign(Object.assign({}, state.downloadDetailTable), patchedDetailItem);
            });
        },
        requestUpdateDetailList($http) {
            return __awaiter(this, void 0, void 0, function* () {
                const params = {
                    downloadIds: this.downloadIdList,
                    page: 1,
                    limit: this.downloadIdList.length,
                };
                const response = yield APIRequestUtils.request({
                    $http,
                    params,
                    request: backend.getUserDownloadVideo,
                });
                const patchedDownloadTable = {};
                const patchedBackDownloadPollingTable = {};
                const patchedYoutubePollingTable = {};
                response.result.forEach(detail => {
                    const downloadId = detail._id;
                    patchedDownloadTable[downloadId] = detail;
                    if (detail.status === 'progress') {
                        patchedBackDownloadPollingTable[downloadId] = {
                            project_id: detail.project_id,
                            timestamp: detail.timestamp,
                            status: detail.status,
                            filename: detail.filename,
                            progress: 0,
                            showUrl: '',
                        };
                    }
                    const youtubeUploadData = detail.youtube_upload;
                    if (youtubeUploadData) {
                        if (youtubeUploadData.status === 'hold' ||
                            youtubeUploadData.status === 'progress') {
                            patchedYoutubePollingTable[downloadId] = youtubeUploadData;
                        }
                    }
                });
                this.$patch(state => {
                    state.downloadDetailTable = patchedDownloadTable;
                    state.backDownloadPollingTable = Object.assign(Object.assign({}, state.backDownloadPollingTable), patchedBackDownloadPollingTable);
                    state.youtubePollingTable = Object.assign(Object.assign({}, state.youtubePollingTable), patchedYoutubePollingTable);
                });
            });
        },
        requestBackDownloadPolling($http) {
            if (!this.backDownloadPollingTable || this.backDownloadPollingInterval) {
                return;
            }
            let requestLock = false;
            const interval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                if (requestLock) {
                    return;
                }
                requestLock = true;
                const promiseList = [];
                const requestIdList = [];
                const newBackDownloadPollingTable = Object.keys(this.backDownloadPollingTable)
                    .filter(downloadId => this.backDownloadPollingTable[downloadId].status === 'progress')
                    .reduce((filtered, downloadId) => {
                    const detail = this.backDownloadPollingTable[downloadId];
                    const projectUid = detail.project_id;
                    const timestamp = detail.timestamp;
                    const promise = getVideoDownload($http, projectUid, timestamp);
                    promiseList.push(promise);
                    requestIdList.push(downloadId);
                    filtered[downloadId] = detail;
                    return filtered;
                }, {});
                if (!promiseList.length) {
                    this.stopBackDownloadPolling();
                    return;
                }
                try {
                    const results = yield Promise.allSettled(promiseList);
                    let isDone = true;
                    results.forEach((result, i) => {
                        const downloadId = requestIdList[i];
                        switch (result.status) {
                            case 'fulfilled': {
                                const data = result.value;
                                const progress = data.progress;
                                const statusType = data.statusType;
                                const status = statusType === 'COMPLETE'
                                    ? 'done'
                                    : statusType === 'FAILED'
                                        ? 'failed'
                                        : 'progress';
                                this.updateBackDownloadPollingResult(downloadId, progress, status, data.showUrl, newBackDownloadPollingTable, $http);
                                if (status === 'progress') {
                                    isDone = false;
                                }
                                break;
                            }
                            case 'rejected': {
                                this.updateBackDownloadPollingResult(downloadId, 0, 'failed', '', newBackDownloadPollingTable, $http);
                                break;
                            }
                        }
                    });
                    if (isDone) {
                        this.stopBackDownloadPolling();
                    }
                }
                catch (error) {
                    Sentry.withScope((scope) => {
                        scope.setTag('API', 'BACK_DOWNLOAD_POLLING');
                        Sentry.captureException(error);
                    });
                }
                finally {
                    requestLock = false;
                }
            }), BACK_DOWNLOAD_INTERVAL_TIME);
            this.$patch(state => {
                state.backDownloadPollingInterval = interval;
            });
        },
        addBackDownloadPollingItem(item) {
            if (this.backDownloadPollingTable.hasOwnProperty(item.downloadId)) {
                return;
            }
            this.backDownloadPollingTable[item.downloadId] = Object.assign(Object.assign({ status: 'progress', progress: 0 }, item.params), { showUrl: '' });
        },
        updateBackDownloadPollingResult(downloadId, progress, status, showUrl, newBackDownloadPollingTable, $http) {
            return __awaiter(this, void 0, void 0, function* () {
                const processData = newBackDownloadPollingTable[downloadId];
                const detailData = this.downloadDetailTable[downloadId];
                let downloadCount = (detailData === null || detailData === void 0 ? void 0 : detailData.download_count) || 0;
                if (this.downloadIdList.includes(downloadId) &&
                    progress === 100 &&
                    status === 'done') {
                    try {
                        const detail = this.downloadDetailTable[downloadId];
                        const videoPresignedUrl = yield $http
                            .post(detail.download_url, {
                            filename: `${processData.filename}.mp4`,
                        })
                            .then((res) => res.data.result);
                        downloadByUrl(videoPresignedUrl, processData.filename, 'mp4');
                        downloadCount += 1;
                    }
                    catch (error) {
                        downloadByUrl(showUrl, processData.filename, 'mp4');
                    }
                    const backDownloadUiStore = useBackDownloadUiStore();
                    backDownloadUiStore.$patch(state => {
                        state.isBackDownloadFolded = false;
                    });
                }
                const patchFnList = [];
                if (processData) {
                    const patchFn = (state) => {
                        newBackDownloadPollingTable[downloadId] = Object.assign(Object.assign({}, processData), { progress,
                            status,
                            showUrl });
                        state.backDownloadPollingTable = newBackDownloadPollingTable;
                    };
                    patchFnList.push(patchFn);
                }
                if (detailData && detailData.status !== status) {
                    const patchFn = (state) => {
                        state.downloadDetailTable[downloadId] = Object.assign(Object.assign({}, detailData), { status, download_count: downloadCount });
                        state.downloadDetailTable = Object.assign({}, state.downloadDetailTable);
                    };
                    patchFnList.push(patchFn);
                }
                this.$patch(state => {
                    patchFnList.forEach(patchFn => patchFn(state));
                });
            });
        },
        requestYoutubePolling($http) {
            if (!this.youtubePollingTable || this.youtubePollingInterval) {
                return;
            }
            let requestLock = false;
            const interval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                if (requestLock) {
                    return;
                }
                requestLock = true;
                const promiseList = [];
                const requestIdList = [];
                const newYoutubePollingTable = Object.keys(this.youtubePollingTable)
                    .filter(downloadId => {
                    const status = this.youtubePollingTable[downloadId].status;
                    return status === 'hold' || status === 'progress';
                })
                    .reduce((filtered, downloadId) => {
                    const youtubeData = this.youtubePollingTable[downloadId];
                    const promise = backend.getYoutubeStatus($http, youtubeData.youtube_upload_url);
                    promiseList.push(promise);
                    requestIdList.push(downloadId);
                    filtered[downloadId] = youtubeData;
                    return filtered;
                }, {});
                if (!promiseList.length) {
                    this.stopYoutubePolling();
                    return;
                }
                try {
                    const results = yield Promise.allSettled(promiseList);
                    let isDone = true;
                    results.forEach((result, i) => {
                        const downloadId = requestIdList[i];
                        switch (result.status) {
                            case 'fulfilled': {
                                const data = result.value;
                                const status = data.status;
                                if (status === 'hold' || status === 'progress') {
                                    isDone = false;
                                }
                                this.updateYoutubePollingResult(newYoutubePollingTable, downloadId, status, data.youtube_config);
                                break;
                            }
                            case 'rejected': {
                                this.updateYoutubePollingResult(newYoutubePollingTable, downloadId, 'failed', null);
                                break;
                            }
                        }
                    });
                    if (isDone) {
                        this.stopYoutubePolling();
                    }
                }
                catch (error) {
                    Sentry.withScope((scope) => {
                        scope.setTag('API', 'YOUTUBE_DIRECT_UPLOAD_POLLING');
                        Sentry.captureException(error);
                    });
                }
                finally {
                    requestLock = false;
                }
            }), BACK_DOWNLOAD_INTERVAL_TIME);
            this.$patch(state => {
                state.youtubePollingInterval = interval;
            });
        },
        addYoutubePollingItem(item) {
            if (this.youtubePollingTable.hasOwnProperty(item.downloadId)) {
                return;
            }
            this.youtubePollingTable[item.downloadId] = item.youtubeUploadData;
        },
        updateYoutubePollingResult(newYoutubePollingTable, downloadId, status, responseConfigData) {
            this.$patch(state => {
                const configData = newYoutubePollingTable[downloadId];
                const newConfigData = responseConfigData
                    ? Object.assign(Object.assign({}, configData), { status, youtube_config: responseConfigData }) : Object.assign(Object.assign({}, configData), { status });
                newYoutubePollingTable[downloadId] = newConfigData;
                state.youtubePollingTable = newYoutubePollingTable;
                state.downloadDetailTable[downloadId].youtube_upload = newConfigData;
            });
        },
        stopBackDownloadPolling(withoutStorePatch = false) {
            if (this.backDownloadPollingInterval) {
                clearInterval(this.backDownloadPollingInterval);
                if (!withoutStorePatch) {
                    this.$patch(state => {
                        state.backDownloadPollingInterval = null;
                    });
                }
            }
        },
        stopYoutubePolling(withoutStorePatch = false) {
            if (this.youtubePollingInterval) {
                clearInterval(this.youtubePollingInterval);
                if (!withoutStorePatch) {
                    this.$patch(state => {
                        state.youtubePollingInterval = null;
                    });
                }
            }
        },
        $reset() {
            this.stopBackDownloadPolling(true);
            this.stopYoutubePolling(true);
            this.$patch(state => {
                state.downloadIdList = [];
                state.downloadDetailTable = {};
                state.backDownloadPollingTable = {};
                state.backDownloadPollingInterval = null;
                state.youtubePollingTable = {};
                state.youtubePollingInterval = null;
            });
        },
    },
});
