import {setBlockTypePos} from 'prosemirror-commands'
import Node from '../Utils/Node'
import {Plugin} from 'prosemirror-state'
import {nanoid} from 'nanoid'
import CONSTANTS from '@/config/constants'

export function isDisabledSpeedSetting(actor) {
  return !(actor.tuning && actor.tuning.includes('speed'))
}

export function isDisabledStyleSetting(actor) {
  return !(actor.tuning && actor.tuning.includes('style'))
}

export default class Paragraph extends Node {
  constructor(options = {}) {
    super(options)
    this.hasCandidateActor = options.hasCandidateActor
    if (!this.hasCandidateActor) {
      this.hasCandidateActor = () => ({})
    }
    this.getDefaultActor = options.getDefaultActor
  }

  get name() {
    return 'paragraph'
  }

  get schema() {
    return {
      attrs: {
        id: {
          default: null,
        },
        actor: {
          default: null,
        },
        rest: {
          default: 0,
        },
      },
      content: 'inline*',
      group: 'block',
      draggable: false,
      parseDOM: [
        {
          tag: 'p',
          getAttrs: dom => {
            const actor = dom.getAttribute('data-actor-id')
            const id = dom.getAttribute('data-paragraph-id')
            const attrs = {}
            if (actor) {
              const {hasActor, willChange} = this.hasCandidateActor(actor)
              if (hasActor) {
                attrs.actor = actor
              } else {
                attrs.actor = willChange.actor_id
              }
            } else {
              attrs.actor = this.options.actor
            }
            if (id) {
              attrs.id = id
            }
            return attrs
          },
        },
      ],
      toDOM: node => [
        'p',
        {
          'data-actor-id': node.attrs.actor,
          'data-paragraph-id': node.attrs.id,
        },
        0,
      ],
    }
  }

  get plugins() {
    const getDefaultActor = this.getDefaultActor

    const paragraphPlugin = new Plugin({
      appendTransaction(trs, prevState, state) {
        // if all selectected text is deleted, then append a new paragraph
        const lastTransaction = trs[trs.length - 1]
        if (lastTransaction.doc.content.content[0].attrs.actor || !lastTransaction.docChanged) {
          return
        }

        const actor = getDefaultActor()
        const tr = state.tr
        tr.setBlockType(0, state.doc.content.size, state.doc.content.content[0].type, {
          id: nanoid(),
          actor: actor.actor_id,
          rest: 0,
        })
        return tr
      },
    })

    return [paragraphPlugin]
  }

  commands({type}) {
    return {
      paragraph: (pos, attrs, isChangeActor) => setBlockTypePos(pos, type, attrs, isChangeActor),
      updateParagraphStyle: ({pos, attrs}) => this.updateQueryStyleInParagraph(pos, attrs),
      updateParagraphLastPitch: ({pos, lastPitch}) => this.updateQueryLastPitchInParagraph(pos, lastPitch),
    }
  }

  updateQueryStyleInParagraph(pos, attrs) {
    return ({tr}, dispatch) => {
      const {includesStyle, isCustomSpeedEnable, isCustomStyleEnable, isLastPitchEnable, isDotsModel} = attrs
      const targetNodeSelection = tr.doc.resolve(pos)
      const parent = targetNodeSelection.parent
      if (parent.type.name === 'paragraph') {
        tr.doc.nodesBetween(pos, pos + parent.content.size, (node, nodePos) => {
          if (node.marks.length > 0) {
            const marks = node.marks[0]
            let params = {}
            if (!includesStyle.includes(marks.attrs.style)) {
              params = {...params, style: CONSTANTS.DEFAULT_STYLE_NAME}
            }
            if (!isCustomSpeedEnable && marks.attrs.customSpeed) {
              params = {...params, speed: 1, customSpeed: 0}
            }
            if (!isCustomStyleEnable && marks.attrs.styleTag) {
              params = {...params, style: CONSTANTS.DEFAULT_STYLE_NAME, styleTag: null}
            }
            if (!isLastPitchEnable && marks.attrs.lastPitch) {
              params = {...params, lastPitch: null}
            }
            if (isDotsModel) {
              params = {...params, speed: 1, lastPitch: null, tempo: 1, pitch: 0}
            }
            tr.updateQueryAttrs(nodePos, nodePos + node.nodeSize, marks.type.create(params), params)
          }
        })
      }
      dispatch(tr)
    }
  }

  updateQueryLastPitchInParagraph(pos, lastPitch) {
    return ({tr}, dispatch) => {
      const targetNodeSelection = tr.doc.resolve(pos)
      const parent = targetNodeSelection.parent
      tr.doc.nodesBetween(pos, pos + parent.content.size, (node, nodePos) => {
        if (node.marks.length > 0) {
          const marks = node.marks[0]
          tr.updateQueryAttrs(nodePos, nodePos + node.nodeSize, marks.type.create({lastPitch: lastPitch}), {
            lastPitch: lastPitch,
          })
        }
      })
      dispatch(tr)
    }
  }
}
