import backend from '@/backend/backend-api'
import isEqual from 'lodash-es/isEqual'
import cloneDeep from 'lodash-es/cloneDeep'
import Vue, {getCurrentInstance} from 'vue'
import CONSTANTS from '@/config/constants'
import router, {isAnonymousPage} from '@/router'
import store from '@/store'
import {i18n} from '@/i18n'

/**
 * @module typecast/store/actor
 */
/**
 * Actor 정보 store state
 * @name State
 * @type {object}
 * @property {actors} 사용 가능한 액터 목록
 * @property {candidateActor} 현재 캐스팅 되어있는 액터 목록
 * @property {selectedFilter} ActorSelectionModal 선택된 필터의 키값들
 * @property {selectedFilterCount} ActorSelectionModal 선택된 필터의 수
 * @property {currentTab} ActorSelectionModal 현재 탭
 * @property {initialSelectedFilter} PortalActorList free 성우추천 섹션에서 캐릭터선택 모달 오픈 시 사용하는 초기state
 * @property {temporaryFilter} PortalActorList selectedFilter 잠시 저장해두는 state
 * @property {userActorMeta} ActorMemo memo, bookmark 등 액터의 메타정보를 저장하는 state
 */

const state = {
  hasAllActor: false,
  actors: [],
  candidateActor: [],
  initCandidateActorCount: 1,
  actorOrder: 'score',
  selectedFilter: {
    category: [],
    visual_type: [],
    fine_tuned: [],
    language: [],
    gender: [],
    age: [],
    content: [],
    mood: [],
    special: [],
  },
  selectedFilterCount: 0,
  currentTab: CONSTANTS.ACTOR_SORTING_TAB_TYPE.DEFAULT,
  initialSelectedFilter: {
    category: [],
    visual_type: [],
    fine_tuned: [],
    language: [],
    gender: [],
    age: [],
    content: [],
    mood: [],
    special: [],
  },
  temporaryFilter: null,
  loading: false,
  userActorMeta: {},
  makerActorList: [],
  userActorMetaFetchUid: null,
}

/**
 * 액터 정보 Getter
 * @name Getters
 * @type {object}
 * @getter {Array} actors=actors 액터 목록 정보
 * @getter {Array} candidateActors=candidateActors 캐스팅 된 액터 목록
 * @getter {Array} actorFilters=actorFilters 액터 검색용 필터
 * @example
 * import {mapGetters} from 'vuex'
 * // in Vue computed
 * {
 *  ...
 *  ...mapGetters('typecast/user', ['me'])
 * }
 * // return user information
 * console.log(this.me)
 */
const getters = {
  actors: state => state.actors,
  candidateActor: state => state.candidateActor,
  initCandidateActorCount: state => state.initCandidateActorCount,
  candidateActorIdList: state => state.candidateActor.map(actor => actor.actor_id),
}

// actions
const actions = {
  /**
   * 모든 액터 조회 기능
   * @name Actions
   * @function reqActorAll
   * @returns {Promise}
   * @example
   * import {mapActions} from 'vuex'
   * // in Vue methods property
   * {
   *  ...
   *  ...mapActions('typecast/actor', ['reqActorAll'])
   * }
   * mounted() {
   *  this.reqActorAll()
   * }
   */
  async reqActorAll({commit, state, dispatch}, noAuth) {
    if (state.hasAllActor) {
      return
    }

    commit('setLoading', true)
    const $http = this._vm.$typecast.$http

    try {
      const actors = await backend.prosodyActorAll($http)
      actors.forEach((actor, index) => {
        actor._index = index
        actor.memo = null
      })

      commit('setActors', actors)
      if (!noAuth) {
        commit('setHasAllActor', true)
      }
      await dispatch('getUserActorMeta')
      commit('mapUserActorMetaWithActors')
    } finally {
      commit('setLoading', false)
    }
  },
  async reqActorList({state, commit}, actorIdList) {
    if (state.hasAllActor) {
      return
    }
    const $http = this._vm.$typecast.$http
    const actors = await Promise.all(actorIdList.map(id => backend.getActor($http, id)))
    commit('setActors', actors)
  },
  /**
   * 기능없는 액터의 커스텀UI https://www.notion.so/neosapience/ui-9c045ce538004909ae30ce043aee06e2
   * notify 후 에디터로 이동
   * @name Actions
   * @function onClickCustomFeature
   * @param {String} title notify에 띄울 제목
   * @param {String} filterCategory 미리 선택해두는 필터 카테고리
   * @param {String} filterOption 미리 선택해두는 필터옵션
   * @returns {void}
   */
  onClickCustomFeature({state, commit}, {title, filterCategory, filterOption}) {
    const {$notify} = this._vm
    const openActorSelectionModal = () => {
      const currentActorId = store.state.typecast.editor.currentActorId
      const actorIdx = state.candidateActor.findIndex(actor => actor.actor_id === currentActorId)
      const projectId = store.getters['typecast/editor/projectId']
      commit('setTemporalFilter')
      commit('toggleFilter', {filterCategory, filterOption})
      const NO_ACTOR_SELECTED = -1
      const DEFAULT_ACTOR_INDEX = 0
      store.commit('typecast/create/setChoosedActor', actorIdx === NO_ACTOR_SELECTED ? DEFAULT_ACTOR_INDEX : actorIdx)
      router.push({
        name: isAnonymousPage() ? 'audioActorSelectionModal_anonymous' : 'audioActorSelectionModal',
        params: {projectId},
        query: {type: 'replace', actorByFeature: filterOption},
      })
    }

    $notify.callback = openActorSelectionModal.bind(null)
    $notify({
      group: 'pending-template',
      title,
      text: i18n.t('pending_template.actor_change'),
      data: {
        btnIconName: 'IconChevronLargeRight',
        className: 'feature',
      },
      ignoreDuplicates: true,
    })
  },
  async getUserActorMeta({state, commit}) {
    const uid = store.getters['typecast/user/uid']
    if (isAnonymousPage() || !uid) {
      return
    }
    if (!!state.userActorMetaFetchUid && state.userActorMetaFetchUid === uid) {
      return
    }
    const $http = this._vm.$typecast.$http
    const {result: userActorMeta} = await backend.getUserActorMeta($http)
    const parsedData = parseUserActorMetaToObject(userActorMeta)
    commit('setActorMetaAll', parsedData)
    commit('setUserActorMetaFetchUid', uid)
  },
  async updateUserActorMeta({state, commit}, {actor, params}) {
    const $http = this._vm.$typecast.$http
    const method = state.userActorMeta[actor.actor_id] ? 'put' : 'post'
    try {
      await backend.updateUserActorMeta($http, {actorId: actor.actor_id, method, params})
      commit('setActorMeta', {actor, meta: params})
    } catch (error) {
      console.error(error)
    }
  },
  async toggleBookmark({commit, dispatch}, {actor}) {
    const bookmark = actor.bookmark ? false : true
    await dispatch('updateUserActorMeta', {actor, params: {is_bookmark: bookmark}})
    commit('updateActorBookmark', {actor, bookmark})
  },
  async updateActorMemo({commit, dispatch}, {actor, bookmark, memo}) {
    await dispatch('updateUserActorMeta', {actor, params: {is_bookmark: bookmark, memo}})
    commit('updateActorBookmark', {actor, bookmark})
    commit('updateActorMemo', {actor, memo})
  },
  async deleteActorMemo({commit, dispatch}, {actor}) {
    await dispatch('updateUserActorMeta', {actor, params: {delete_memo: true}})
    commit('updateActorMemo', {actor, memo: ''})
  },
  async getMakerActorList() {
    if (
      isEqual(
        store.state.typecast.actor.makerActorList.map(actor => actor.actor_id),
        store.getters['typecast/user/me'].maker_actor_list,
      )
    ) {
      return
    }
    const me = store.getters['typecast/user/me']
    const vm = getCurrentInstance()
    const $http = vm.proxy.$http
    try {
      const actorList = await Promise.all(me.maker_actor_list.map(actorId => backend.getActor($http, actorId)))
      store.commit('typecast/actor/setMakerActorList', actorList)
    } catch (error) {
      console.error(error)
    }
  },
}

function updateList(list, {filterFunc, key, updateValue}) {
  const targetList = filterFunc ? list.filter(filterFunc) : list
  targetList.forEach(target => (target[key] = updateValue))
}

const mutations = {
  /**
   * 저장소에 actor set
   * @name mutations
   * @function setActors
   * @param {Array} actorIds 액터 ID 목록
   * @returns {void}
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/actor', ['setActors'])
   * }
   * mounted() {
   *  this.setActors([SELECTED_ACTOR_ID])
   * }
   */
  setActors(state, data) {
    state.actors = data
  },

  updateActorBookmark(state, {actor, bookmark}) {
    const targetActorId = actor.actor_id
    updateList(state.actors, {
      filterFunc: actor => actor.actor_id === targetActorId,
      key: 'bookmark',
      updateValue: bookmark,
    })

    const candidateIndex = state.candidateActor.findIndex(candidate => candidate.actor_id === actor.actor_id)
    const isThisActorAlsoCandidate = candidateIndex !== -1
    if (isThisActorAlsoCandidate) {
      state.candidateActor.splice(candidateIndex, 1, {...state.candidateActor[candidateIndex], bookmark})
    }
  },

  updateActorRecent(state, {actor, recent}) {
    const targetActorId = actor.actor_id
    updateList(state.actors, {
      filterFunc: actor => actor.actor_id === targetActorId,
      key: 'recent',
      updateValue: recent,
    })
  },

  updateActorMemo(state, {actor, memo}) {
    const targetActorId = actor.actor_id
    updateList(state.actors, {
      filterFunc: actor => actor.actor_id === targetActorId,
      key: 'memo',
      updateValue: memo,
    })

    const candidateIndex = state.candidateActor.findIndex(candidate => candidate.actor_id === actor.actor_id)
    const isThisActorAlsoCandidate = candidateIndex !== -1
    if (isThisActorAlsoCandidate) {
      state.candidateActor.splice(candidateIndex, 1, {...state.candidateActor[candidateIndex], memo})
    }
  },

  /**
   * 저장소에 캐스팅 할 액터 추가(1명만)
   * @name mutations
   * @function pushCandidateActor
   * @param {Object} actor 선택한 액터 정보
   * @returns {void}
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/actor', ['pushCandidateActor'])
   * }
   * mounted() {
   *  this.pushCandidateActor(SELECTED_ACTOR)
   * }
   */
  pushCandidateActor(state, actor) {
    state.candidateActor.push(actor)
  },
  /**
   * 저장소에 캐스팅 할 액터 추가(여러명)
   * @name mutations
   * @function pushCandidateActors
   * @param {Array<Object>} actors 선택한 액터들의 정보 정보
   * @returns {void}
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/actor', ['pushCandidateActors'])
   * }
   * mounted() {
   *  this.pushCandidateActors([SELECTED_ACTOR, SELECTED_ACTOR, SELECTED_ACTOR])
   * }
   */
  pushCandidateActors(state, actors) {
    if (actors instanceof Array) {
      state.candidateActor = state.candidateActor.concat(actors)
    }
  },

  /**
   * 특정 index에 캐스팅된 액터 변경
   * @name mutations
   * @function editCandidateActor
   * @param {Integer} index 수정할 candidateActor index
   * @param {Object} actor 보낸 index에서 변경 될 actor
   * @returns {void}
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/actor', ['editCandidateActor'])
   * }
   * mounted() {
   *  this.editCandidateActor({0, SELECTED_ACTOR})
   * }
   */
  editCandidateActor(state, {index, actor}) {
    Vue.set(state.candidateActor, index, actor)
  },

  setCandidateActors(state, actors) {
    state.candidateActor = actors
  },
  /**
   * 초기 캐스팅 된 액터 설정. 인기순위가 가장 높은 캐릭터 순서로 정렬된다
   * @name mutations
   * @function initCandidateActors
   * @returns {void}
   * @example
   * import {mapMutations} from 'vuex'
   * {
   *  ...
   *  ...mapMutations('typecast/actor', ['initCandidateActors'])
   * }
   * mounted() {
   *  this.initCandidateActors([SELECTED_ACTOR, SELECTED_ACTOR, SELECTED_ACTOR])
   * }
   */
  initCandidateActors(state, {country} = {}) {
    let filteringActors = state.actors.filter(actor => {
      return !actor.hidden
    })
    if (country) {
      const filteringLanguage = country === 'KR' ? 'ko-kr' : 'en-us'
      filteringActors = filteringActors.filter(actor => actor.language.includes(filteringLanguage))
    }

    if (state.actorOrder === 'score') {
      filteringActors = filteringActors.sort((a, b) => {
        return b.score - a.score
      })
    }

    state.candidateActor = filteringActors.slice(0, state.initCandidateActorCount)
  },

  deleteCandidateActors(state, index) {
    if (state.candidateActor.length === 1) {
      return
    }
    state.candidateActor.splice(index, 1)
  },

  setActorOrder(state, actorScoreOrder) {
    state.actorOrder = actorScoreOrder
  },
  resetCandidateActors(state) {
    state.candidateActor.splice(0, state.candidateActor.length)
  },
  toggleFilter(state, {filterCategory: key, filterOption: value}) {
    if (!state.selectedFilter[key].includes(value)) {
      state.selectedFilter[key].push(value)
      state.selectedFilterCount += 1
    } else {
      state.selectedFilter[key] = state.selectedFilter[key].filter(filter => filter !== value)
      state.selectedFilterCount -= 1
    }
  },
  resetFilter(state) {
    for (const filter in state.selectedFilter) {
      state.selectedFilter[filter] = []
    }
    state.selectedFilterCount = 0
  },
  setCurrentTab(state, tabName) {
    if (state.currentTab === tabName) {
      state.currentTab = CONSTANTS.ACTOR_SORTING_TAB_TYPE.DEFAULT
    } else {
      state.currentTab = tabName
    }
  },
  setTemporalFilter(state) {
    state.temporaryFilter = {...state.selectedFilter}
    state.selectedFilter = cloneDeep(state.initialSelectedFilter)
  },
  resetTemporalFilter(state) {
    state.selectedFilter = {...state.temporaryFilter}
    state.temporaryFilter = null
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  mapUserActorMetaWithActors(state) {
    const actorsMappedWithMetaData = state.actors.map(actor => {
      const metaData = state.userActorMeta[actor.actor_id]
      if (!metaData) {
        return actor
      }
      actor.memo = metaData.memo
      actor.bookmark = metaData.is_bookmark
      return actor
    })
    mutations.setActors(state, actorsMappedWithMetaData)
  },
  setActorMetaAll(state, metaData) {
    state.userActorMeta = metaData
  },
  setActorMeta(state, {actor, meta}) {
    state.userActorMeta = {
      ...state.userActorMeta,
      [actor.actor_id]: {
        ...state.userActorMeta[actor.actor_id],
        ...(meta.delete_memo ? {memo: ''} : meta),
      },
    }
  },
  setMakerActorList(state, actorList) {
    state.makerActorList = actorList
  },
  setHasAllActor(state, value) {
    state.hasAllActor = value
  },
  setUserActorMetaFetchUid(state, value) {
    state.userActorMetaFetchUid = value
  },
}

function parseUserActorMetaToObject(metaList) {
  const data = {}
  metaList.forEach(({actor_id, memo, is_bookmark}) => {
    data[actor_id] = {memo, is_bookmark}
  })
  return data
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
