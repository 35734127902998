import {datadogRum} from '@datadog/browser-rum'
import config from '@/config/config'
export default {
  install() {
    datadogRum.init({
      applicationId: config.datadog.applicationId,
      clientToken: config.datadog.clientToken,
      site: 'datadoghq.com',
      service: 'typecast-studio',
      env: process.env.NODE_ENV,
      version: process.env.CIRCLE_TAG,
      sampleRate: 10,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
  },
}
