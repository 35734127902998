import store from '@/store'
import flatten from 'lodash-es/flatten'
import {isValidEditorText} from '@/utils/isValidEditorText'
import {createQueryHash} from '@/utils/nsHash'
import {useVideoDomStore} from 'store/editor'
import AnimationSceneItem from '@/model/animations/AnimationSceneItem'
import {getSrcByAssetId} from '@/share/virtualDOM/getSrcByAssetId'
import {isTiptapTextNode} from 'utils/typecast/isTiptapTextNode'

function getTransitionDuration(paraIndex, queryIndex, slideList, queryList) {
  let start = 0,
    end = 0
  if (paraIndex && !queryIndex) {
    start = AnimationSceneItem.getTransitionDuration(slideList[paraIndex - 1].transition)
  }
  if (paraIndex !== slideList.length - 1 && queryIndex === queryList.length - 1) {
    end = AnimationSceneItem.getTransitionDuration(slideList[paraIndex].transition)
  }
  return {
    startTransitionDuration: start,
    endTransitionDuration: end,
  }
}

export const getDownloadSentences = quality => {
  const {slideList} = useVideoDomStore()
  const queries = store.getters['typecast/queryCache/queries']
  const tiptapContent = store.getters['typecast/editor/tiptapContent']
  const queryCacheItems = store.state.typecast.queryCache.queryCacheItems
  const styleLabelVersionList = store.state.typecast.queryCache.styleLabelVersionList
  return flatten(
    tiptapContent.content.map((paragraph, paraIndex) => {
      if (!paragraph.content) {
        return []
      }
      const queryIdList = paragraph.content
        .filter(isTiptapTextNode)
        .map(({marks, text}) => {
          if (!marks) {
            return
          }

          if (!isValidEditorText(text)) {
            return
          }

          return marks[0].attrs.id
        })
        .filter(queryId => queryId)

      if (queryIdList.length === 0) {
        return []
      }

      return queryIdList.map((queryId, queryIndex) => {
        const query = queries[queryId]
        const textHash = createQueryHash({...query, styleVersion: styleLabelVersionList[query.actor]})
        const {speak} = queryCacheItems[textHash]
        const {startTransitionDuration, endTransitionDuration} = getTransitionDuration(
          paraIndex,
          queryIndex,
          slideList,
          queryIdList,
        )
        return {
          character_id: query.actor,
          text_hash: textHash.toString(),
          text: query.text,
          speak_url: speak.speak_url,
          audio_url: speak.audio[quality]?.url || speak.audio.url,
          duration: speak.duration,
          silence: query.silence / 1000,
          pre_silence: query.rest_sec,
          start_transition_duration: startTransitionDuration,
          end_transition_duration: endTransitionDuration,
        }
      })
    }),
  )
}

export const getAssetLists = type => {
  const timelineAssetLists = store.state.typecast.videoEditor.timelineAssetLists
  const assetList = timelineAssetLists[type]
  return assetList.map(async ({id, offset, startTrim, endTrim, totalDuration, volume, assetFrom}) => ({
    url: await getSrcByAssetId(id, type, assetFrom),
    delay: offset,
    start_time: startTrim || 0,
    end_time: endTrim || totalDuration,
    volume: volume * 0.01,
  }))
}
