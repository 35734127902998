import CONSTANTS from '@/config/constants'
import {GoogleAuthProvider} from 'firebase/auth'
import config from '@/config/config'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/vue'
import {loadGoogleScript} from '@/libs/modules/Google'

class GoogleLogin {
  static _instance = null
  static lock = false
  google
  afterSignInCallback
  lastLoginStatus

  successLoadingScript = false
  errorLoadingScript = false

  successLoadingCallback = {
    renderButton: null,
    openPrompt: null,
  }

  constructor(google) {
    try {
      this.successLoadingScript = true
      this.google = google
      this.google.accounts.id.initialize({
        client_id: config.google.client_id,
        callback: this._handleCredentialResponse.bind(this),
      })
      this.callWaitLoadingCallback()
    } catch (error) {
      this.errorLoadingScript = true
      this.clearWaitLoadingCallback()
      Sentry.captureException(error)
      throw new Error(error)
    }
  }

  async signIn() {
    // abstract function. don't remove
  }

  signOut() {
    // abstract function. don't remove
  }

  waitLoadingScript(targetKey, callback) {
    if (this.successLoadingCallback[targetKey]) {
      return
    }
    this.successLoadingCallback[targetKey] = callback
  }

  callWaitLoadingCallback() {
    for (const callbackKey in this.successLoadingCallback) {
      if (this.successLoadingCallback[callbackKey]) {
        this.successLoadingCallback[callbackKey]()
      }
    }
  }
  clearWaitLoadingCallback() {
    for (const callbackKey in this.successLoadingCallback) {
      this.successLoadingCallback[callbackKey] = null
    }
  }

  getLoginStatus() {
    return this.lastLoginStatus
  }

  async getCredential(token) {
    return GoogleAuthProvider.credential(token)
  }

  getProviderInstance() {
    return new GoogleAuthProvider()
  }

  renderButton(elem, {width}) {
    if (this.errorLoadingScript) {
      return
    }

    if (!this.successLoadingScript) {
      this.waitLoadingScript('renderButton', () => this.renderButton(elem, {width}))
      return
    }
    this.google.accounts.id.renderButton(elem, {
      size: 'large',
      type: 'standard',
      theme: 'outline',
      text: 'sign_in_with',
      shape: 'circle',
      logo_alignment: 'left',
      width: width || 360,
    })
  }

  openPrompt() {
    if (this.errorLoadingScript) {
      return
    }

    if (!this.successLoadingScript) {
      this.waitLoadingScript('openPrompt', () => this.openPrompt())
      return
    }
    this.google.accounts.id.prompt(notification => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // continue with another identity provider.
      }
    })
  }
  cancelPrompt() {
    this.google.accounts.id.cancel()
  }

  setAfterSignInCallback(callback) {
    this.afterSignInCallback = callback
  }

  _googleSignInDataDecorator(credential) {
    const decodeCrendential = jwt_decode(credential)
    return {
      provider: CONSTANTS.PROVIDER.GOOGLE,
      token: credential,
      email: decodeCrendential.email,
    }
  }

  async _handleCredentialResponse({credential}) {
    const data = this._googleSignInDataDecorator(credential)
    this.lastLoginStatus = data
    if (this.afterSignInCallback) {
      this.afterSignInCallback(CONSTANTS.PROVIDER.GOOGLE, data)
    }
  }

  static async getInstance() {
    if (!this._instance) {
      try {
        const google = await loadGoogleScript()
        this._instance = new GoogleLogin(google)
      } catch (e) {
        console.error(e)
        this._instance = null
      }
    }
    return this._instance
  }
}

export default GoogleLogin
