var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UNSELECTABLE_ELEMENT_CLASS_NAME } from '@/constants';
import { state } from '@/state';
import { Flags } from 'million';
import { createVElementId, getSrcByAssetId, objectStyleToString, } from '@/share/virtualDOM';
import { rerenderDOM } from '@/share/render';
import { nextRenderTick } from '../nextRenderTick';
export const createVideoVElement = (timelineDurationPairOrTimelineVideo) => __awaiter(void 0, void 0, void 0, function* () {
    if (!timelineDurationPairOrTimelineVideo) {
        return;
    }
    if (Array.isArray(timelineDurationPairOrTimelineVideo)) {
        return getVideoVElementTimelineDurationPair(timelineDurationPairOrTimelineVideo);
    }
    return _getVideoVElement(timelineDurationPairOrTimelineVideo);
});
const getVideoVElementTimelineDurationPair = (timelineDurationPair) => {
    const timelineVideo = getTimelineVideo(timelineDurationPair);
    if (!timelineVideo) {
        return;
    }
    return _getVideoVElement(timelineVideo);
};
const _getVideoVElement = (timelineVideo) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const fullWidth = timelineVideo.fillType === 'width' || timelineVideo.fillType === 'stretch';
    const fullHeight = timelineVideo.fillType === 'height' || timelineVideo.fillType === 'stretch';
    const src = `${yield getSrcByAssetId(timelineVideo.id, 'video', timelineVideo.assetFrom)}#t=${(_a = timelineVideo.startTrim) !== null && _a !== void 0 ? _a : 0}`;
    const key = `${(_b = src.match(/.*\.mp4/)) === null || _b === void 0 ? void 0 : _b[0]}#t=${(_c = timelineVideo.startTrim) !== null && _c !== void 0 ? _c : 0}&${timelineVideo.fillType}`;
    const videoElementId = createVElementId();
    const videoVElement = {
        key,
        tag: 'video',
        props: {
            id: videoElementId,
            src,
            class: UNSELECTABLE_ELEMENT_CLASS_NAME,
            style: objectStyleToString({
                position: 'absolute',
                width: fullWidth ? '100%' : ' initial',
                height: fullHeight ? '100%' : 'initial',
                objectFit: timelineVideo.fillType === 'stretch' ? 'fill' : 'initial',
                transform: timelineVideo.fillType === 'height'
                    ? 'translateX(-50%)'
                    : timelineVideo.fillType === 'width'
                        ? 'translateY(-50%)'
                        : 'initial',
                left: timelineVideo.fillType === 'height' ? '50%' : 'initial',
                top: timelineVideo.fillType === 'width' ? '50%' : 'initial',
            }),
        },
        flag: Flags.ELEMENT_NO_CHILDREN,
    };
    nextRenderTick({ id: videoElementId }, element => {
        element.addEventListener('error', () => __awaiter(void 0, void 0, void 0, function* () {
            // NOTE: video의 src가 만료된 경우 이를 갱신하고 리렌더링
            yield getSrcByAssetId(timelineVideo.id, 'video', timelineVideo.assetFrom, true);
            rerenderDOM();
        }));
    }, {
        label: 'createVideoElement',
    });
    return videoVElement;
});
const getTimelineVideo = (timelineDurationPair) => {
    const { timelineAssetLists } = state;
    const timelineVideo = timelineAssetLists.video.find(({ offset: startTime, startTrim, endTrim, totalDuration }) => {
        const videoDuration = (endTrim !== null && endTrim !== void 0 ? endTrim : totalDuration) - (startTrim !== null && startTrim !== void 0 ? startTrim : 0);
        const endTime = startTime + videoDuration;
        return ((startTime <= timelineDurationPair[0] &&
            timelineDurationPair[0] <= endTime) ||
            (startTime <= timelineDurationPair[1] &&
                timelineDurationPair[1] <= endTime) ||
            (timelineDurationPair[0] <= startTime &&
                startTime <= timelineDurationPair[1]) ||
            (timelineDurationPair[0] <= endTime &&
                endTime <= timelineDurationPair[1]));
    });
    return timelineVideo;
};
