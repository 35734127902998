import { dispatchEvent } from './dispatchEvent';
import { editContainer } from '@/context';
import { debounce } from 'utils';
/** @public */
export const addEventListener = (eventType, handler) => {
    // @ts-ignore
    editContainer === null || editContainer === void 0 ? void 0 : editContainer.addEventListener(eventType, handler);
};
/** @public */
export const removeEventListener = (eventType, handler) => {
    // @ts-ignore
    editContainer === null || editContainer === void 0 ? void 0 : editContainer.removeEventListener(eventType, handler);
};
// TODO: emitEvent 지우고, 바로 dispatchEvent 호출하도록
export const emitEvent = (eventType, options) => {
    switch (eventType) {
        case 'update-subtitle':
            dispatchEvent('updateSubtitle', options);
            break;
        case 'update-element':
            dispatchUpdateElement();
            break;
        case 'select':
            dispatchEvent('select', options);
            break;
        case 'contextmenu':
            dispatchEvent('contextmenu', options);
            break;
        case 'select-character':
            dispatchEvent('select', options);
            break;
        case 'contextmenu-character':
            dispatchEvent('contextmenu', options);
            break;
    }
};
const UPDATE_ELEMENT_DEBOUNCE_WAIT = 20;
const dispatchUpdateElement = debounce(() => dispatchEvent('updateElement'), UPDATE_ELEMENT_DEBOUNCE_WAIT);
