import FloatingMenu from '../Plugins/FloatingMenu'

export default {
  props: {
    editor: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      menuList: [{pos: 0, top: 0}],
    }
  },
  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (editor) {
          this.$nextTick(() => {
            editor.registerPlugin(
              // eslint-disable-next-line new-cap
              FloatingMenu({
                editor,
                element: this.$el,
                onUpdate: menuList => {
                  this.menuList = menuList
                  this.$emit('menuUpdate', this.menuList)
                },
              }),
            )
          })
        }
      },
    },
  },

  render() {
    if (!this.editor) {
      return null
    }

    return this.$scopedSlots.default({
      commands: this.editor.commands,
      menuList: this.menuList,
    })
  },

  beforeDestroy() {
    if (!this.editor) {
      return
    }
    this.editor.unregisterPlugin('floating_menu')
  },
}
