import { Text, Separator } from '@/projects/node/Node';
export default class NodeFactory {
    static factory(node) {
        if (node.type === 'text') {
            return new Text(node);
        }
        else {
            return new Separator();
        }
    }
}
