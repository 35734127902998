const devConfig = {
  firebase: {
    apiKey: 'AIzaSyCTwjf3l_ix__RCCNrcu25ggaIQ4wbfwU8',
    authDomain: 'typecast-a4c8f.firebaseapp.com',
    databaseURL: 'https://typecast-a4c8f.firebaseio.com',
    projectId: 'typecast-a4c8f',
    storageBucket: 'typecast-a4c8f.appspot.com',
    messagingSenderId: '807769798561',
  },
  google: {
    client_id: '807769798561-1m3m219pdvfukfchtulk02llhvlj1tmr.apps.googleusercontent.com',
    youtube_client_id: '782528292904-kfi01mdae89rs71i39jp70r22s7nqhon.apps.googleusercontent.com',
  },
  fb: {
    code: '2354201908166990',
    appId: '295475138132447',
  },
  stripe: {
    apiKey: 'pk_test_YDonN0ja7f6mjzDysPhOlH8Q00YHVzMPwU',
  },
  iamport: {
    apiKey: 'imp43063300',
  },
  feature: {
    actorStyle: true,
    payment: true,
    quality: true,
    social: true,
    survey: false,
    import: true,
    pricingStrategy: true,
    saveTemporary: true,
    oneVocoder: true,
    actorRecommend: true,
    otherLanguage: true,
    nps: false,
  },
  mixpanel: {
    token: '38113fe3429b7e7f1b83ab2596e6803a',
  },
  datadog: {
    applicationId: '0232af93-1c3f-45d1-8acd-d657dfb67733',
    clientToken: 'pub478636da7559a0727a0c5045ce1a3f11',
  },
  growthbook: {
    clientKey: 'sdk-rWSjVq3ZSGY3HaON',
  },
}

const stagingConfig = {
  firebase: {
    apiKey: 'AIzaSyBJN3ZYdzTmjyQJ-9TdpikbsZDT9JUAYFk',
    authDomain: 'typecast-prod-50cb1.firebaseapp.com',
    databaseURL: 'https://typecast-prod-50cb1.firebaseio.com',
    projectId: 'typecast-prod-50cb1',
    storageBucket: 'typecast-prod-50cb1.appspot.com',
    messagingSenderId: '1088982360731',
  },
  google: {
    client_id: '1088982360731-463t3qn4e16mqkn960f663p8bse98u5v.apps.googleusercontent.com',
    youtube_client_id: '1088982360731-463t3qn4e16mqkn960f663p8bse98u5v.apps.googleusercontent.com',
  },
  stripe: {
    apiKey: 'pk_live_ZYfpsGDbUFrv6ubwwDOQgTPf00ON6WGpxp',
  },
  iamport: {
    apiKey: 'imp07659181',
  },
  fb: {
    code: '614055545668017',
    appId: '940504746379418',
  },
  feature: {
    actorStyle: true,
    payment: true,
    quality: false,
    social: true,
    survey: false,
    import: true,
    pricingStrategy: true,
    saveTemporary: true,
    oneVocoder: true,
    actorRecommend: true,
    otherLanguage: true,
    nps: false,
  },
  mixpanel: {
    token: '38113fe3429b7e7f1b83ab2596e6803a',
  },
  datadog: {
    applicationId: '0232af93-1c3f-45d1-8acd-d657dfb67733',
    clientToken: 'pub478636da7559a0727a0c5045ce1a3f11',
  },
  growthbook: {
    clientKey: 'sdk-l1vTdtmgqgLYT1',
  },
}

const prodConfig = {
  firebase: {
    apiKey: 'AIzaSyBJN3ZYdzTmjyQJ-9TdpikbsZDT9JUAYFk',
    authDomain: 'typecast-prod-50cb1.firebaseapp.com',
    databaseURL: 'https://typecast-prod-50cb1.firebaseio.com',
    projectId: 'typecast-prod-50cb1',
    storageBucket: 'typecast-prod-50cb1.appspot.com',
    messagingSenderId: '1088982360731',
  },
  google: {
    client_id: '1088982360731-463t3qn4e16mqkn960f663p8bse98u5v.apps.googleusercontent.com',
    youtube_client_id: '1088982360731-463t3qn4e16mqkn960f663p8bse98u5v.apps.googleusercontent.com',
  },
  stripe: {
    apiKey: 'pk_live_ZYfpsGDbUFrv6ubwwDOQgTPf00ON6WGpxp',
  },
  iamport: {
    apiKey: 'imp07659181',
  },
  fb: {
    code: '614055545668017',
    appId: '940504746379418',
  },
  feature: {
    actorStyle: true,
    payment: true,
    quality: false,
    social: true,
    survey: false,
    import: true,
    pricingStrategy: true,
    saveTemporary: true,
    oneVocoder: true,
    actorRecommend: true,
    otherLanguage: true,
    nps: true,
  },
  mixpanel: {
    token: '97ff1685f987dd26527096de35e5b10e',
  },
  datadog: {
    applicationId: '0232af93-1c3f-45d1-8acd-d657dfb67733',
    clientToken: 'pub478636da7559a0727a0c5045ce1a3f11',
  },
  growthbook: {
    clientKey: 'sdk-l1vTdtmgqgLYT1',
  },
}

let config
switch (process.env.NODE_ENV) {
  case 'production':
    config = prodConfig
    break
  case 'staging':
    config = stagingConfig
    break
  case 'development':
    config = devConfig
    break
  default:
    config = devConfig
    break
}
export default config
