import { getVPropsById } from '@/share/virtualDOM/getVPropsById';
import { mergeWithoutArray } from 'utils/src/mergeWithoutArray';
import { resetTargets } from '@/context';
import { emitEvent } from '@/emitEvent';
/** @public */
export const updateElement = (id, updateVProps) => {
    const originVProps = getVPropsById(id);
    const mergedVProps = mergeWithoutArray({}, originVProps, updateVProps);
    for (const key in updateVProps) {
        if (key === 'id') {
            continue;
        }
        // @ts-ignore
        originVProps[key] = mergedVProps[key];
    }
    resetTargets();
    emitEvent('update-element');
};
