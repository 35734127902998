import {useModal} from '@/composables/useModal'

export default {
  install(Vue) {
    Vue.prototype.$modal = function showModal(comp, props, emits) {
      const {openModal} = useModal()
      return openModal(comp, props, emits)
    }

    Vue.prototype.$closeModal = function closeModal(comp) {
      const {closeModal} = useModal()
      return closeModal(comp.name)
    }
  },
}
