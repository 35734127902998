import {GrowthBook} from '@growthbook/growthbook'
import mixpanel from 'mixpanel-browser'
import config from '@/config/config'
import store from '@/store'

export class GrowthbookManager {
  _growthbook
  static instance

  constructor() {
    // Create a GrowthBook context
    this._growthbook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: config.growthbook.clientKey,
      enableDevMode: process.env.NODE_ENV === 'development' ? true : false,
      trackingCallback: function (experiment, result) {
        mixpanel.track('$experiment_started', {
          'Experiment name': experiment.key,
          'Variant name': result.variationId,
        })
      },
    })
    this.loadFeatures()
  }

  async loadFeatures() {
    await this.getGrowthbookInstance().loadFeatures({autoRefresh: true})
    store.commit('typecast/user/setLoadGrowthbookFeatures', true)
  }

  feature(featureName) {
    return this.getGrowthbookInstance().feature(featureName)
  }

  setAttributes(items) {
    this.getGrowthbookInstance().setAttributes(items)
  }

  getAttributes() {
    return this.getGrowthbookInstance().getAttributes()
  }

  isOn(featureName) {
    return this.getGrowthbookInstance().isOn(featureName)
  }

  getGrowthbookInstance() {
    return this._growthbook
  }

  getFeatureValue(featureName) {
    return this.getGrowthbookInstance().getFeatureValue(featureName)
  }

  /**
   * @returns {GrowthbookManager}
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new GrowthbookManager()
    }
    return this.instance
  }
}

export default {
  install(Vue) {
    Vue.prototype.$growthbook = GrowthbookManager.getInstance()
  },
}
