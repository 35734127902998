import { watch } from 'vue';
export const WatchPiniaPlugin = ({ store, options: { watch: watchMap }, }) => {
    if (!watchMap) {
        return;
    }
    watchState(store.$state, watchMap);
};
const watchState = (state, watchMap) => {
    const keys = Object.keys(watchMap);
    keys.forEach(key => {
        const maybeWatcher = watchMap[key];
        if (typeof maybeWatcher === 'function') {
            watch(() => state[key], maybeWatcher);
        }
        else {
            watchState(state[key], maybeWatcher);
        }
    });
};
