import backend from '@/backend/backend-api'
import isEmpty from 'lodash-es/isEmpty'
import sortBy from 'lodash-es/sortBy'
import CONSTANTS, {AUDIO_PROJECT_MEDIA_TYPE} from '@/config/constants'
import {deleteShow} from '@/backend/video-editor-api.js'
import router from '@/router.js'
import {i18n} from '@/i18n'

export const initState = () => ({
  projectList: [],
  projectLoading: false,
  projectError: false,
  nextItem: 1,
  endPage: 1,
  templateList: [],
  isSearching: false,
  selectedProject: {},
  selectedFolder: {},
  projectSortBy: 'last_modified', // name, _id, last_opened
  myFolderList: [],
  menubarFolderList: [],
  sharedProjectList: [],
  breadcrumbList: [],
  showDropdown: false,
  showPopup: false,
  showMenubarPopup: false,
  showCreateFolderModal: false,
  showCreateProjectModal: false,
  showDeleteModal: false,
  showMoveToFolderModal: false,
  showRenameModal: false,
  showShareModal: false,
  showUpgradeToShareModal: false,
  showUpgradeToImportModal: false,
  showXlsxImportModal: false,
  importedFile: null,
  showList: true,
  recentProjectList: [],
  needInitializeSelectedList: false,
  prevRoute: '',
  keyword: '',
  importedFileFormat: '',
  originalProjectList: [],
  originalFolderList: [],
  isPending: false,
  selectedProjectList: [],
  projectListPendedDelete: [],
})

const getters = {
  getProjectById: state => id => state.projectList.find(project => project._id.$oid === id),
  getSharedProjectById: state => id => state.sharedProjectList.find(project => project._id.$oid === id),
  isSharedFolder: state => state.breadcrumbList[0] === CONSTANTS.SHARE_PROJECT_ROUTE,
  isInMyProjectFolder: state =>
    state.breadcrumbList.length === 1 && state.breadcrumbList[0] === CONSTANTS.MY_PROJECT_ROUTE,
  isProjectOption: state => isEmpty(state.selectedFolder),
  isProjectPage: state => CONSTANTS.PROJECT_ROUTE_LIST.includes(state.breadcrumbList[0]),
  getCurrentFolderState: state => (state.breadcrumbList.length === 1 ? 'root' : 'sub'),
}

const mutations = {
  setProjectList(state, newProjectList) {
    state.projectList = newProjectList
  },
  updateProjectList(state, project) {
    const targetIndex = state.projectList.findIndex(item => item._id.$oid === project._id.$oid)
    state.projectList.splice(targetIndex, 1, project)
  },
  updateSharedProjectList(state, project) {
    const targetIndex = state.sharedProjectList.findIndex(item => item._id.$oid === project._id.$oid)
    state.sharedProjectList.splice(targetIndex, 1, project)
  },
  setLoadPrevState(state, {newNextItem, newEndPage}) {
    state.nextItem = newNextItem
    state.endPage = newEndPage
  },
  resetLoadPrevState(state) {
    state.importedFile = null
    state.projectList = []
    state.sharedProjectList = []
    state.nextItem = 1
    state.endPage = 1
    state.keyword = ''
    state.isSearching = false
  },
  resetFolderPrevState(state) {
    state.myFolderList = []
  },
  setStatus(state, {loading, error}) {
    state.projectLoading = loading
    state.projectError = error
  },
  setTemplateList(state, data) {
    state.templateList = data
  },
  setSelectedProject(state, project = {}) {
    state.selectedProject = project
  },
  setSelectedFolder(state, folder = {}) {
    state.selectedFolder = folder
  },
  setProjectSortBy(state, sortBy) {
    state.projectSortBy = sortBy
  },
  setMyFolderList(state, myFolderList) {
    state.myFolderList = myFolderList
  },
  setFolderList(state, folderList) {
    state.myFolderList = folderList
  },
  setSharedProjectList(state, sharedProjectList) {
    state.sharedProjectList = sharedProjectList
  },
  setBreadcrumb(state, path) {
    const breadcrumb = path.split('/').slice(3)
    state.breadcrumbList = breadcrumb
  },
  toggleDropdown(state) {
    state.showDropdown = !state.showDropdown
  },
  setPopup(state, newState) {
    state.showPopup = newState
  },
  toggleModal(state, modalType) {
    state[modalType] = !state[modalType]
  },
  setShowMenubarPopup(state, newState) {
    state.showMenubarPopup = newState
  },
  closeAllModal(state) {
    state.showDropdown = false
    state.showPopup = false
    state.showMenubarPopup = false
    state.showCreateFolderModal = false
    state.showCreateProjectModal = false
    state.showDeleteModal = false
    state.showMoveToFolderModal = false
    state.showRenameModal = false
    state.showShareModal = false
    state.showUpgradeToImportModal = false
    state.showUpgradeToShareModal = false
    state.showXlsxImportModal = false
  },
  setImportedFile(state, file) {
    state.importedFile = file
  },
  setImportedFileFormat(state, format) {
    state.importedFileFormat = format
  },
  toggleFolderList(state) {
    state.showList = !state.showList
  },
  setRecentProjectList(state, newRecentProjectList) {
    state.recentProjectList = newRecentProjectList
  },
  setNeedInitializeSelectedList(state, needInitialize = false) {
    state.needInitializeSelectedList = needInitialize
  },
  setPrevRoute(state, newRoute = '') {
    state.prevRoute = newRoute
  },
  setKeyword(state, searchKeyword = '') {
    state.keyword = searchKeyword
    state.isSearching = !!searchKeyword.length
  },
  deleteProjectElements(state, projectIds) {
    state.originalProjectList = state.projectList
    const newProjectList = state.projectList.filter(project => !projectIds.includes(project._id.$oid))
    state.projectList = newProjectList
  },
  setProjectListPendedDelete(state, projectIds) {
    state.projectListPendedDelete = projectIds
  },
  recoverProjectElements(state, force) {
    if (force) {
      state.projectList = state.originalProjectList
    }
    state.selectedProjectList = []
  },
  deleteFolderElement(state, folderId) {
    state.originalFolderList = state.myFolderList
    const newFolderList = state.myFolderList.filter(folder => folder._id !== folderId)
    state.myFolderList = newFolderList
    this.commit('typecast/project/setMenubarFolderList', newFolderList)
  },
  recoverFolderElement(state) {
    state.myFolderList = state.originalFolderList
    state.selectedFolder = {}
    this.commit('typecast/project/setMenubarFolderList', state.originalFolderList)
  },
  setMenubarFolderList(state, folderList) {
    state.menubarFolderList = sortBy(folderList, ['name', '_id'])
  },
  setSelectedProjectIdList(state, projectIdList = []) {
    state.selectedProjectList = projectIdList
  },
  setIsPending(state, isPending) {
    state.isPending = isPending
  },
}

const getCurrentURI = (folderId, isInMyProjectFolder) => {
  const lastChar = isInMyProjectFolder ? '/' : ''
  return router.history.current.path + lastChar === `/${i18n.locale}/dashboard/my-project/${folderId}`
}

const actions = {
  async sortProjectList({state, commit, dispatch, getters}, sortBy) {
    commit('setProjectSortBy', sortBy)
    dispatch('getProjectList', state.keyword)
    if (getters.isInMyProjectFolder) {
      dispatch('getFolderList')
    }
  },
  async sortSharedProjectList({state, commit, dispatch}, sortBy) {
    commit('setProjectSortBy', sortBy)
    dispatch('getSharedProjectList', state.keyword)
  },
  async handleCancelDelete({state, commit, getters}, {cb, folderId}) {
    commit('setIsPending', false)
    if (state.selectedProjectList.length) {
      const isInCurrentFolder = getCurrentURI(folderId, getters.isInMyProjectFolder)
      if (isInCurrentFolder) {
        state.selectedProjectList.forEach(id => cb(id))
      }
      commit('recoverProjectElements', isInCurrentFolder)
      commit('setProjectListPendedDelete', [])
    } else {
      commit('recoverFolderElement')
    }
    const category = state.selectedProjectList.length ? 'project' : 'folder'
    this._vm.$nsEvent.event({category: `after_${category}`, action: 'cancel_delete'})
    this._vm.$mixpanel.sendEvent('click_pending_delete', {category})
    commit('closeAllModal')
  },
  async wait({state, commit}) {
    commit('setIsPending', true)
    let count = 0
    let intervalId

    return new Promise(
      resolve =>
        (intervalId = setInterval(() => {
          if (count >= 3) {
            return resolve()
          }
          if (!state.isPending) {
            return resolve()
          }
          count += 1
        }, 1000)),
    ).finally(() => clearInterval(intervalId))
  },
  async deleteProjectHandler(
    {state, commit, dispatch},
    {projectIdList = [], $notify, title, text, btnIconName, errorMsg},
  ) {
    $notify({group: 'pending-template', title, text, data: {btnIconName, className: 'delete'}})

    commit('setSelectedProjectIdList', projectIdList)
    commit('setProjectListPendedDelete', projectIdList)
    commit('deleteProjectElements', projectIdList)
    commit('closeAllModal')
    try {
      await dispatch('wait')

      if (!state.isPending) {
        return
      }

      const $http = this._vm.$typecast.$http
      await backend.deleteProject($http, {project_ids: projectIdList})
      const videoList = projectIdList.filter(target =>
        state.originalProjectList.find(project => project._id.$oid === target && project.media_type === 'video'),
      )
      if (videoList.length) {
        await deleteShow($http, videoList)
      }

      commit('setSelectedProjectIdList')
      commit('setIsPending', false)

      setTimeout(() => {
        commit('setProjectListPendedDelete', [])
      }, 1000)

      this._vm.$nsEvent.event({category: 'after_project', action: 'project_delete'})
      this._vm.$mixpanel.sendEvent('complete_delete', {category: 'project'})

      if (state.endPage >= state.nextItem && state.projectList.length < 50) {
        dispatch('getProjectList')
      }
    } catch (error) {
      $notify({
        group: 'main',
        type: 'error',
        title: errorMsg,
      })
    }
  },
  async deleteFolderHandler({state, commit, getters, dispatch}, {$notify, title, text, btnIconName, errorMsg}) {
    $notify({group: 'pending-template', title, text, data: {btnIconName, className: 'delete'}})

    const {_id: folderId} = state.selectedFolder
    commit('deleteFolderElement', folderId)
    commit('closeAllModal')

    try {
      const isInCurrentFolder = getCurrentURI(folderId, getters.isInMyProjectFolder)
      if (isInCurrentFolder) {
        router.push('/dashboard/my-project')
      }
      await dispatch('wait')

      if (!state.isPending) {
        if (isInCurrentFolder) {
          router.push(`/dashboard/my-project/${folderId}`)
        }
        return
      }

      const $http = this._vm.$typecast.$http
      const {
        result: {video_project_ids: videoList},
      } = await backend.deleteFolder($http, folderId)
      if (videoList.length) {
        await deleteShow($http, videoList)
      }

      commit('setSelectedFolder')
      commit('setIsPending', false)

      this._vm.$nsEvent.event({category: 'after_folder', action: 'folder_delete'})
      this._vm.$mixpanel.sendEvent('complete_delete', {category: 'folder'})
    } catch (error) {
      $notify({
        group: 'main',
        type: 'error',
        title: errorMsg,
      })
    }
  },
  async addProjectToList({state, commit}, projectUrl) {
    const $http = this._vm.$typecast.$http
    const newProject = await backend.getProjectDetail($http, projectUrl)
    const newProjectList = [newProject, ...state.projectList]
    commit('setProjectList', newProjectList)
  },
  async renameProject({commit}, project) {
    const $http = this._vm.$typecast.$http
    const name = project.name
    const oid = project._id.$oid

    try {
      await backend.updateProjectName($http, oid, name)
      commit('updateProjectList', project)
    } catch (error) {
      console.error(error)
    }
  },
  async renameSharedProject({commit}, project) {
    const $http = this._vm.$typecast.$http
    const name = project.name
    const oid = project._id.$oid

    try {
      await backend.updateProjectName($http, oid, name)
      commit('updateSharedProjectList', project)
    } catch (error) {
      console.error(error)
    }
  },
  async saveProject({dispatch, commit}, project) {
    await dispatch('createProject', project)
    commit('updateProjectList', project)
  },
  async createTemplateProject({dispatch, commit}, project) {
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})

    try {
      const {
        result: {
          project_url: projectUrl,
          project_v2_url: projectV2Url,
          project: {
            _id: {$oid: projectId},
          },
        },
      } = await backend.createProject($http, project)
      await dispatch('addProjectToList', projectV2Url)

      return {
        projectName: project.name,
        projectId,
        projectUrl,
      }
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    } finally {
      commit('setStatus', {loading: false, error: false})
    }
  },
  async createProject({state, dispatch, commit, getters}, project) {
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})
    const v10 = project.v10 || {
      tiptap: null,
      query_cache_items: {},
      style_label_version_list: [],
      slide_list: [], // not used anymore, remain for backward compatibility
      additional_data: {
        slide_list_v2: [],
      },
    }

    Object.values(v10.query_cache_items).forEach(item => {
      if (item.audio) {
        delete item.audio
      }
      if (item.speak) {
        const includeKeys = new Set(['query', 'audio', 'speak_url', 'duration'])
        const allKeys = Object.keys(item.speak)
        for (const key of allKeys) {
          if (!includeKeys.has(key)) {
            delete item.speak[key]
          }
        }
      }
    })

    try {
      const projectObj = {
        name: project.name,
        oid: project._id?.$oid,
        folder_id: state.breadcrumbList[0] === 'my-project' ? state.breadcrumbList[1] || '' : '',
        media_type: project.media_type || 'audio',
        v10,
        version: 10,
      }
      const {
        result: {
          project_url: projectUrl,
          project_v2_url: projectV2Url,
          project: {
            _id: {$oid: projectId},
          },
        },
      } = await backend.createProject($http, projectObj)

      if (!project._id?.$oid) {
        await dispatch('addProjectToList', projectV2Url)
      }

      return {
        projectName: project.name,
        projectId,
        projectUrl,
        folderPath: getters.getCurrentFolderState,
      }
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    } finally {
      commit('setStatus', {loading: false, error: false})
    }
  },
  async shareProject({commit}, projectId, checkedMemberList) {
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})

    try {
      // if (!this.alreadySharedList) {
      await backend.shareProjectToGroupMember($http, projectId, checkedMemberList)
      // } else {
      //   await backend.updateShareProjectToGroupMember($http, projectId, this.checkedMemberList)
      // }

      commit('setStatus', {loading: false, error: false})
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  async getProjectTemplate({commit}) {
    try {
      const $http = this._vm.$typecast.$http
      const result = await backend.getProjectTemplate($http)
      commit('setTemplateList', result.result)
    } catch (error) {
      console.error(error)
    }
  },
  async duplicateProject({commit, dispatch}, project) {
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})

    try {
      const targetProjectId = project._id.$oid
      const {
        result: {project_v2_url: projectV2Url, project: projectDetail},
      } = await backend.cloneProject($http, targetProjectId)

      dispatch('addProjectToList', projectV2Url)

      return projectDetail
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
      throw error
    } finally {
      commit('setStatus', {loading: false, error: false})
    }
  },
  async getSharedProjectList({commit, dispatch}, searchKeyword = '') {
    commit('resetLoadPrevState')
    commit('setKeyword', searchKeyword)
    return await dispatch('getMoreSharedProjectList')
  },
  async getMoreSharedProjectList({state, commit}) {
    if (state.endPage < state.nextItem || state.projectLoading) return
    commit('setStatus', {loading: true, error: false})

    const $http = this._vm.$typecast.$http

    try {
      const options = {
        limit: process.env.NODE_ENV === 'development' ? 12 : 50,
        name: state.keyword,
        page: state.nextItem,
        sort: state.projectSortBy,
      }
      const response = await backend.getSharedProjectList($http, options)
      const newNextItem = state.nextItem + 1
      const newEndPage = response.page.end_page
      const newProjectList = [...state.sharedProjectList, ...response.result]

      commit('setSharedProjectList', newProjectList)
      commit('setLoadPrevState', {newNextItem, newEndPage})
      commit('setStatus', {loading: false, error: false})
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  async getFolderList({commit, state}) {
    const $http = this._vm.$typecast.$http

    try {
      const result = await backend.getFolderList($http, state.projectSortBy)

      commit('setMyFolderList', result.project_folder_list)
      commit('setMenubarFolderList', result.project_folder_list)
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  async createFolder({state, commit, dispatch}, folderName) {
    if (state.projectLoading) return
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})

    try {
      const result = await backend.createFolder($http, folderName)

      await dispatch('getFolderList')
      commit('setStatus', {loading: false, error: false})
      return result.project_folder
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  async renameFolder({commit, dispatch}, folder) {
    const $http = this._vm.$typecast.$http
    const {_id: oid, name} = folder

    try {
      await backend.updateFolderName($http, oid, name)

      dispatch('getFolderList')
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  getProjectList({commit, dispatch}, searchKeyword = '') {
    return new Promise((resolve, reject) => {
      commit('resetLoadPrevState')
      commit('setKeyword', searchKeyword)
      dispatch('getMoreProjectList')
        .then(r => resolve(r))
        .catch(error => reject(error))
    })
  },
  async getMoreProjectList({state, commit}) {
    if (state.endPage < state.nextItem || state.projectLoading) return
    commit('setStatus', {loading: true, error: false})

    const $http = this._vm.$typecast.$http

    try {
      const options = {
        limit: process.env.NODE_ENV === 'development' ? 12 : 50,
        name: state.keyword,
        page: state.nextItem,
        sort: state.projectSortBy,
      }
      const folderId = state.breadcrumbList[1] || ''
      const response = await backend.getProjectList($http, options, folderId)

      const newNextItem = state.nextItem + 1
      const newEndPage = response.page.end_page
      const newProjectList = [...state.projectList, ...response.result]

      commit('setProjectList', newProjectList)
      commit('setLoadPrevState', {newNextItem, newEndPage})
      commit('setStatus', {loading: false, error: false})
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
      throw new Error(error)
    }
  },
  async moveToFolder({commit, dispatch}, {projectIds, folderId}) {
    const $http = this._vm.$typecast.$http
    commit('setStatus', {loading: true, error: false})

    try {
      await backend.moveProjectToFolder($http, projectIds, folderId)
      commit('setStatus', {loading: false, error: false})
      dispatch('getProjectList')
    } catch (error) {
      console.error(error)
      commit('setStatus', {loading: false, error: true})
    }
  },
  async getRecentProjectList({rootState, commit}) {
    const $http = this._vm.$typecast.$http
    const options = {
      limit: rootState.screen.isPhone ? 10 : 4,
      sort: 'last_modified',
      project_type: 'any',
    }

    try {
      const response = await backend.getProjectList($http, options)
      commit('setRecentProjectList', response.result)
    } catch (error) {
      console.error(error)
    }
  },
  async invokeCreateProject({dispatch}, {mediaType = AUDIO_PROJECT_MEDIA_TYPE, isFirstVisit}) {
    const {NEW_VISITOR_TEMPLATE_ID} = CONSTANTS
    try {
      if (isFirstVisit) {
        const project = {
          template_id: NEW_VISITOR_TEMPLATE_ID[mediaType][process.env.NODE_ENV][i18n.locale],
          version: 10,
        }
        const {projectId} = await dispatch('createTemplateProject', project)
        return {projectId}
      }
      const project = {
        name: i18n.t('project_create.default'),
        media_type: mediaType,
      }
      const {projectId, folderPath} = await dispatch('createProject', project)
      return {projectId, folderPath}
    } catch (error) {
      throw new Error(error)
    }
  },
}

export default {
  namespaced: true,
  state: initState(),
  getters,
  mutations,
  actions,
}
