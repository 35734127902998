var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { hashing } from 'utils/src/hashing';
import { ref, toRef, watch } from 'vue';
export const StateHashPiniaPlugin = ({ store, options, }) => {
    if (!options.stateHash) {
        return;
    }
    const { $state } = store;
    // NOTE: Plugin에서 새로운 State를 추가하기 위해서는 아래와 같이 $state 및 store 두 곳에 정의해야 함
    // (https://pinia.vuejs.org/core-concepts/plugins.html#adding-new-state)
    $state.isRequireSave = ref(false);
    store.isRequireSave = toRef($state, 'isRequireSave');
    const stateHash = ref(0);
    store.updateStateHash = () => {
        const stringified = JSON.stringify(stateWithoutIsRequireSave($state));
        const hash = hashing(stringified);
        stateHash.value = hash;
    };
    const updateIsRequireSave = () => {
        const stringified = JSON.stringify(stateWithoutIsRequireSave($state));
        const hash = hashing(stringified);
        const isRequireSave = stateHash.value !== hash;
        $state.isRequireSave.value = isRequireSave;
    };
    watch($state, updateIsRequireSave, { deep: true });
    watch(stateHash, updateIsRequireSave);
    // @ts-ignore override $init action
    if (store.$init) {
        const originStoreInit = store.$init;
        store.$init = state => {
            originStoreInit(state);
            store.$initState = state;
            store.updateStateHash();
        };
        return;
    }
    store.$init = state => {
        store.$patch(state);
        store.$initState = state;
        store.updateStateHash();
    };
};
const stateWithoutIsRequireSave = (state) => {
    // eslint-disable-next-line
    const { isRequireSave } = state, rest = __rest(state, ["isRequireSave"]);
    return rest;
};
