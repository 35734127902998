/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 *
 * @typedef {{
 *  assetFrom: 'asset' | 'asset_sample'
 *  assetUrl: string
 *  duration: number
 *  genre: string[]
 *  genreEn: string[]
 *  height: number | null
 *  instruments: string[]
 *  isBookmark: boolean
 *  mainMood: string[]
 *  subMood: string[]
 *  tempo: string
 *  theme: string[]
 *  thumbUrl: string | null
 *  title: string
 *  _id: string
 *  name: string
 *  lastUsedAt: number | null
 *  assetId: string | null
 *  userAssetMetaUrl: string | null
 * }} Asset
 *
 * @typedef {{
 *  total: number
 *  limit: number
 *  page: number
 *  endPage: number
 *  skip: number
 * }} Page
 *
 * @typedef {{
 *  type: string;
 *  name: string;
 *  duration: number;
 *  assetBlob: Blob;
 *  width?: number;
 *  height?: number;
 *  thumbBlob?: Blob;
 *  tempId?: string; // 업로드 중인 에셋을 구분하기 위한 임시 아이디
 * }} AssetQuery
 *
 * @typedef {{
 *  assetH: number;
 *  assetW: number;
 *  assetTs: number;
 *  createTs: number;
 *  presignedTs: number;
 *  updateTs: number;
 *  assetName: string;
 *  assetPath: string;
 *  assetType: string;
 *  assetUrl: string;
 *  thumbUrl: string;
 *  createAt: string;
 *  updateAt: string;
 * }} ImageAsset
 *
 * @typedef {{
 *  assetType: string;
 *  assetName: string;
 *  assetBlob: Blob;
 *  thumbBlob: Blob;
 *  assetW: number;
 *  assetH: number;
 * }} ImageAssetQuery
 */

import {
  URL_CHARACTER_LIST,
  URL_VIDEO_SHOW,
  URL_VIDEO_SHOW_CLONE,
  URL_VIDEO_SHOW_DOWNLOADING,
  URL_IMAGE_ASSET_LIST,
  URL_IMAGE_ASSET,
  URL_IMAGE_ASSET_URL,
  URL_ASSET,
  URL_SAMPLE_BGM_LIST,
  URL_USER_ASSET_META,
  URL_SAMPLE_RECOMMENDED_BGM,
} from './video-api-url'
import {snakeToCamel, camelToSnake} from 'utils/src/convertNamingConvention'
import {encodeUrlParams, getCamelResult} from '@/utils/backend-utils'
import {captureException} from '@sentry/vue'

export const DOWNLOAD_STATUS_FAILED = 'FAILED'

export const getRealCharacterList = async $http => {
  const {characterList: realCharacterList} = getCamelResult(await $http.get(URL_CHARACTER_LIST))
  return realCharacterList
}

export const getShow = async ($http, projectUid) => {
  const url = encodeUrlParams(URL_VIDEO_SHOW, ['project_uid', projectUid])
  const {show} = getCamelResult(await $http.get(url))
  return show
}

export const deleteShow = ($http, projectUidList) => {
  const url = encodeUrlParams(URL_VIDEO_SHOW, ['project_uid_list', projectUidList])
  return $http.delete(url)
}

export const cloneShow = ($http, {projectUid, cloneUid, projectName}) => {
  const url = encodeUrlParams(URL_VIDEO_SHOW_CLONE, ['project_uid', projectUid])
  const payload = camelToSnake({cloneUid, projectName})
  return $http.post(url, payload)
}

export const postVideoDownload = async (
  $http,
  {projectUid, slideList, projectName, quality, dom, scene, duration, mediaType, bgmList, youtubeConfig},
) => {
  const sentenceList = slideList.flatMap(slide => slide)
  const resolutionType = quality.toLocaleUpperCase()

  const payload = camelToSnake({
    resolutionType,
    projectName,
    sentenceList,
    dom,
    scene,
    duration,
    mediaType,
    youtubeConfig,
    version: 'V3',
    bgmList,
  })
  const url = encodeUrlParams(URL_VIDEO_SHOW_DOWNLOADING, ['project_uid', projectUid])
  try {
    const {downloading: downloadState} = getCamelResult(await $http.post(url, payload))
    return downloadState
  } catch (error) {
    let errorCode = ''
    if (error.response?.data?.detail?.message?.error_code === 'app/invalid/credit-not-enough') {
      errorCode = 'credit_exceed_modal.exceed_time'
    }

    return {statusType: DOWNLOAD_STATUS_FAILED, errorCode}
  }
}

export const getVideoDownload = async ($http, projectUid, timestamp) => {
  const url = encodeUrlParams(URL_VIDEO_SHOW_DOWNLOADING, [
    ['project_uid', projectUid],
    ['timestamp', timestamp],
  ])
  const {downloading: downloadState} = getCamelResult(await $http.get(url))
  return downloadState
}

export const getImageAssetList = async ($http, {assetType, assetName, ordering, indexingValue, limit}) => {
  const url = encodeUrlParams(URL_IMAGE_ASSET_LIST, [
    ['asset_type', assetType || 'IMAGE'],
    ['asset_name', assetName],
    ['ordering', ordering || -1],
    ['indexing_value', indexingValue],
    ['limit', limit || 50],
  ])
  const assetList = getCamelResult(await $http.get(url))
  return assetList
}

export const putImageAsset = ($http, {assetTs, assetName, assetW, assetH}) => {
  const payload = camelToSnake({assetTs, assetName, assetW, assetH})
  return $http.put(URL_IMAGE_ASSET, payload)
}

/**
 * @param {AxiosInstance} $http
 * @param {ImageAssetQuery} imageAssetQuery
 * @returns {ImageAsset}
 */
export const postImageAsset = async ($http, {assetType, assetName, assetBlob, thumbBlob, assetW, assetH}) => {
  try {
    const payload = camelToSnake({assetType, assetName})
    const initData = getCamelResult(await $http.post(URL_IMAGE_ASSET, payload))
    const intAssetW = parseInt(assetW)
    const intAssetH = parseInt(assetH)

    const {assetTs, assetUploadUrl, thumbUploadUrl} = snakeToCamel(initData.asset)
    await $http.put(assetUploadUrl, assetBlob)
    await $http.put(thumbUploadUrl, thumbBlob)
    const updatedData = getCamelResult(
      await putImageAsset($http, {assetTs, assetName, assetW: intAssetW, assetH: intAssetH}),
    )

    return updatedData.asset
  } catch (error) {
    captureException(error)
    throw new Error(assetName)
  }
}

export const deleteImageAsset = ($http, assetTsList) => {
  const url = encodeUrlParams(URL_IMAGE_ASSET, ['asset_ts_list', assetTsList.join(',')])
  return $http.delete(url)
}

export const getImageAssetUrl = async ($http, assetPathList) => {
  const url = encodeUrlParams(URL_IMAGE_ASSET_URL, ['asset_path_list', assetPathList.join(',')])
  const {assetUrlList} = getCamelResult(await $http.get(url))
  return assetUrlList
}

/**
 * @param {AxiosInstance} $http
 * @param {string} projectId
 * @param {Partial<{skip: number; type: string; limit: number}>?} options
 * @returns {Promise<{assetList: Asset[]; page: Page}>}
 */
export const getAssetList = async ($http, projectId, options) => {
  const url = encodeUrlParams(URL_ASSET, [
    ['project_id', projectId],
    ['skip', options?.skip],
    ['type', options?.type],
    ['limit', options?.limit],
  ])
  const {data} = await $http.get(url)
  const assetList = data.result.map(snakeToCamel)
  const page = snakeToCamel(data.page)
  return {assetList, page}
}

export const getAsset = ($http, assetId, assetFrom) => {
  const url = assetFrom === 'asset_sample' ? URL_SAMPLE_BGM_LIST : URL_ASSET // /asset for video & bgm uploaded & image, /asset_sample for bgm samples
  return $http.get(`${url}/${assetId}`).then(res => snakeToCamel(res.data.result))
}

/**
 * @param {AxiosInstance} $http
 * @param {AssetQuery} assetQuery
 * @returns {Promise<Asset>}
 */
export const postAsset = async ($http, {type, name, width, height, duration, assetBlob, thumbBlob}) => {
  try {
    const payload = camelToSnake({type, name, width, height, duration})
    const {_id, assetUploadUrl, thumbUploadUrl} = getCamelResult(await $http.post(URL_ASSET, payload))

    if (assetUploadUrl) {
      await $http.put(assetUploadUrl, assetBlob, {headers: {'x-amz-acl': 'bucket-owner-full-control'}})
    }

    if (thumbUploadUrl) {
      await $http.put(thumbUploadUrl, thumbBlob, {headers: {'x-amz-acl': 'bucket-owner-full-control'}})
    }

    return getCamelResult(await $http.put(`${URL_ASSET}/${_id}`, {name, uploaded: true}))
  } catch (error) {
    throw new Error(name)
  }
}

/**
 * @param {AxiosInstance} $http
 * @param {string} id
 */
export const deleteAsset = async ($http, id) => {
  await $http.delete(`${URL_ASSET}/${id}`)
}

export const getBlobImageUrl = async ($http, imageUrl) => {
  const blob = await $http.get(imageUrl, {responseType: 'blob'})
  return URL.createObjectURL(blob.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Partial<{page: number; limit: number}>?} options
 * @returns {Promise<{assetList: Asset[]; page: Page}>}
 */
export const getSampleBgmList = async ($http, options) => {
  const url = encodeUrlParams(URL_SAMPLE_BGM_LIST, [
    ['page', options?.page],
    ['limit', options?.limit],
  ])
  const {data} = await $http.get(url)

  const assetList = data.result.map(snakeToCamel)
  const respondPage = snakeToCamel(data.page)
  return {assetList, page: respondPage}
}

/**
 * @param {AxiosInstance} $http
 * @param {Partial<{text: string; word: string}>?} options
 * @returns {Promise<Asset[]>}
 */
export const postSampleRecommendedBgm = async ($http, {text, word}) => {
  const payload = camelToSnake({text, word})
  const {data} = await $http.post(URL_SAMPLE_RECOMMENDED_BGM, payload)
  return data.result.map(snakeToCamel)
}

/**
 * @param {AxiosInstance} $http
 * @param {Partial<{skip: number; isBookmark: boolean; isUsed: boolean, limit: number}>?} options
 * @returns {Promise<{assetList: Asset[]; page: Page}>}
 */
export const getUserAssetMetaList = async ($http, options) => {
  const url = encodeUrlParams(URL_USER_ASSET_META, [
    ['skip', options?.skip],
    ['is_bookmark', Number(options?.isBookmark === true)],
    ['is_used', Number(options?.isUsed === true)],
    ['limit', options?.limit],
  ])
  const {data} = await $http.get(url)

  const assetList = data.result.map(snakeToCamel)
  const respondPage = snakeToCamel(data.page)

  return {assetList, page: respondPage}
}

/**
 * @param {AxiosInstance} $http
 * @param {string} assetId
 * @param {Asset['assetFrom']} assetFrom
 * @param {Partial<{isBookmark: boolean; isUsed: boolean}} options
 * @returns {Promise<{userAssetMetaUrl: string} | undefined>}
 */
export const postUserAssetMeta = async ($http, assetId, assetFrom, options) => {
  const payload = camelToSnake({assetId, assetFrom, ...options})
  const {data} = await $http.post(URL_USER_ASSET_META, payload)
  const {result} = data
  return snakeToCamel(result)
}

/**
 * @param {AxiosInstance} $http
 * @param {string} assetId
 * @param {Partial<{isBookmark: boolean; isUsed: boolean}} options
 */
export const putUserAssetMeta = async ($http, assetId, options) => {
  const payload = camelToSnake({...options})
  await $http.put(`${URL_USER_ASSET_META}/${assetId}`, payload)
}
