import { toTimeline } from 'utils';
import Big from 'big.js';
import { useVideoDomStore } from 'store/editor';
/** @public */
export const setAllSpeakList = (allSpeakList) => {
    const validAllSpeakList = allSpeakList.map(toTimelineSpeakList);
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    validAllSpeakList.forEach((speakList, speakIndex) => (slideList[speakIndex].speakList = speakList));
};
const toTimelineSpeakList = (speakList) => speakList.map(toTimelineSpeak);
const toTimelineSpeak = (speak) => {
    const { duration, silence, preSilence } = speak;
    // 비디오 프로젝트에서 쓰이는 모든 부동소수점 방식의 실수는 2의 유효자릿수를 갖는다.
    // 따라서 유효자릿수를 둘째자리로 설정한다. 다만 이 결과에도 부동소수점 오차는 존재한다.
    const fixedDuration = toTimeline(duration);
    const fixedSilence = toTimeline(silence);
    const fixedPreSilence = toTimeline(preSilence !== null && preSilence !== void 0 ? preSilence : 0);
    // 최종적으로 부동소수점 오차가 있는 값이 전달이 된다.
    // 하지만 이 값을 전달받아 사용하는 곳에서 유효자릿수를 알고 있기 때문에 문제가 되지 않는다.
    return Object.assign(Object.assign({}, speak), { duration: new Big(fixedDuration), silence: new Big(fixedSilence), preSilence: new Big(fixedPreSilence) });
};
