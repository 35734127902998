<template>
  <label class="t-checkbox-container t-body2" v-on="checkboxListeners" :for="id" :class="[{disabled}, buttonSizeClass]">
    <slot name="default"></slot>
    <input
      type="checkbox"
      :id="id"
      :name="name"
      :disabled="disabled"
      :checked="isChecked"
      :value="value"
      @change="updateCheckboxState"
    />
    <span class="checkbox-mark"></span>
  </label>
</template>

<script>
import {defineComponent, isVue2} from 'vue-demi'

export default defineComponent({
  name: 'TCheckbox',
  model: {
    prop: 'checked',
    event: 'custom-change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    size: {
      type: String,
      default: 'small',
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonSizeClass() {
      if (this.staticClasses.includes('small') || this.small) {
        return ''
      }
      if (this.staticClasses.includes('large') || this.large) {
        return 'large'
      }
      return this.size
    },
    checkboxListeners() {
      if (isVue2) {
        return this.$listeners
      }

      return this.$attrs
    },
    isChecked() {
      const checkedValue = this.checked.indexOf(this.value)
      if (checkedValue >= 0) {
        return true
      }
      return false
    },
    staticClasses() {
      const staticClasses = this.$options._parentVnode.data.staticClass || ''
      const staticClassesList = staticClasses.split(' ')
      const dynamicKey = this.$options._parentVnode.data.class || {}
      if (this.$el) {
        return [...this.$el.classList]
      }
      const dynamicClass = Object.keys(dynamicKey)
      return [...new Set([...staticClassesList, ...dynamicClass])]
    },
  },
  methods: {
    updateCheckboxState() {
      const dynamicCheckedValues = this.checked
      const removeValue = dynamicCheckedValues.indexOf(this.value)
      if (removeValue >= 0) {
        dynamicCheckedValues.splice(removeValue, 1)
      } else {
        dynamicCheckedValues.push(this.value)
      }
      this.$emit('custom-change', dynamicCheckedValues)
    },
  },
})
</script>
