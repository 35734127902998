import { isDetail } from "../Detail.guard";
export function isSentence(obj) {
    const typedObj = obj;
    return ((typedObj !== null &&
        typeof typedObj === "object" ||
        typeof typedObj === "function") &&
        (typedObj["type"] === "sentence" ||
            typedObj["type"] === "separator") &&
        typeof typedObj["text"] === "string");
}
export function isDetailV4(obj) {
    const typedObj = obj;
    return (isDetail(typedObj) &&
        Array.isArray(typedObj["paragraphs"]) &&
        typedObj["paragraphs"].every((e) => (e !== null &&
            typeof e === "object" ||
            typeof e === "function") &&
            e["type"] === "paragraph" &&
            typeof e["actor_id"] === "string" &&
            typeof e["pre_silence"] === "number" &&
            Array.isArray(e["data"]) &&
            e["data"].every((e) => (e !== null &&
                typeof e === "object" ||
                typeof e === "function") &&
                (e["type"] === "sentence" ||
                    e["type"] === "separator"))));
}
