import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
export const getSlide = (slideIndex) => {
    const videoDomStore = useVideoDomStore();
    const editorOptionsStore = useEditorOptionsStore();
    const { slideList } = videoDomStore;
    const { slideIndex: currentSlideIndex } = editorOptionsStore;
    if (!slideIndex) {
        return slideList[currentSlideIndex];
    }
    return slideList[slideIndex];
};
