import * as TypeGuards from './Detail.guard';
export default class Detail {
    constructor(detail) {
        this.validate(detail);
        this.query_cache_items = createQueryCacheItems(detail.query_cache_items);
        this.style_label_version_list = createStyleLabelVersionList(detail.style_label_version_list);
    }
    validate(detail) {
        if (process.env.NODE_ENV === 'production') {
            return;
        }
        if (!TypeGuards.isDetail(detail) || !this.isDetail(detail)) {
            throw new Error();
        }
    }
}
const createQueryCacheItems = (queryCacheItems) => {
    // 잘못된 초기값으로 인해 배열일 수 있지만 배열에 엘리먼트가 존재하는 경우는 없으므로 무시한다.
    if (Array.isArray(queryCacheItems)) {
        return {};
    }
    const items = {};
    for (const [key, value] of Object.entries(queryCacheItems || {})) {
        const { queryId, hash, speak } = value;
        // 셋 중 하나라도 없으면 사용할 수 없으므로 제거한다.
        if (!queryId || !hash || !speak) {
            continue;
        }
        const { query } = speak;
        if (!query) {
            continue;
        }
        items[key] = Object.assign(Object.assign({}, value), { queryId, speak: Object.assign(Object.assign({}, speak), { query }) });
    }
    return items;
};
const createStyleLabelVersionList = (style_label_version_list) => {
    const list = {};
    for (const [key, value] of Object.entries(style_label_version_list || {})) {
        // key가 캐릭터이고 value가 버전 정보인데 빈 값인 경우가 존재한다.
        if (!value) {
            continue;
        }
        list[key] = value;
    }
    return list;
};
export { TypeGuards };
