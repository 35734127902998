import { attachContainer, resetTargets, scene } from '@/context';
import { setState } from '@/state';
const defaultOnError = (error) => console.error(error);
export const config = {
    assetIdToUrl: assetId => assetId,
    originWidth: 0,
    originHeight: 0,
    onLock: () => undefined,
    onUnlock: () => undefined,
    outerContainer: document.createElement('div'),
    i18nConverter: source => source,
    onError: () => undefined,
    isShortsProject: false,
};
const CONTAINER_WIDTH = 1920;
const CONTAINER_WIDTH_SHORTS = 1080;
const CONTAINER_HEIGHT = 1080;
const CONTAINER_HEIGHT_SHORTS = 1920;
/** @public */
export const initVideoDom = ({ outerContainer, assetIdToUrl, onLock, onUnlock, i18nConverter, onError, isShortsProject, }) => {
    updateConfig('assetIdToUrl', assetIdToUrl);
    updateConfig('onLock', onLock);
    updateConfig('onUnlock', onUnlock);
    updateConfig('outerContainer', outerContainer);
    updateConfig('i18nConverter', i18nConverter);
    updateConfig('onError', onError !== null && onError !== void 0 ? onError : defaultOnError);
    updateConfig('isShortsProject', isShortsProject);
    const width = isShortsProject ? CONTAINER_WIDTH_SHORTS : CONTAINER_WIDTH;
    const height = isShortsProject ? CONTAINER_HEIGHT_SHORTS : CONTAINER_HEIGHT;
    updateConfig('originWidth', width);
    updateConfig('originHeight', height);
    attachContainer(outerContainer, width, height);
    scene.clear();
    resetTargets();
};
const updateConfig = (propName, value) => {
    config[propName] = value;
};
/** @public */
export const initCharacterList = (characterList) => setState('characterList', characterList);
/** @public */
export const initRealCharacterList = (realCharacterList) => setState('realCharacterList', realCharacterList);
/** @public */
export const initTimelineAssetLists = (timelineAssetLists) => {
    setState('timelineAssetLists', timelineAssetLists);
};
