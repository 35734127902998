import { ref } from 'vue';
const INTERVAL_MS = 500;
const bufferedElements = [];
let bufferCheckIntervalId = undefined;
const isMediaBuffering = ref(false);
export const useMediaBuffering = () => {
    return { isMediaBuffering };
};
export const clearMediaBufferState = () => {
    clearInterval(bufferCheckIntervalId);
    bufferCheckIntervalId = undefined;
    isMediaBuffering.value = false;
    bufferedElements.forEach(mediaElement => mediaElement.removeEventListener('playing', preventPlayAfterBuffering));
    bufferedElements.splice(0, bufferedElements.length);
};
export const handleMediaBuffering = (target, scene) => {
    if (target.bufferHandled) {
        return;
    }
    target.bufferHandled = true;
    target.addEventListener('waiting', ({ target: mediaElement }) => {
        if (!(mediaElement instanceof HTMLMediaElement)) {
            return;
        }
        if (isMediaPlayable(mediaElement)) {
            return;
        }
        const alreadyHandled = bufferedElements.includes(mediaElement);
        if (alreadyHandled) {
            return;
        }
        mediaElement.addEventListener('playing', preventPlayAfterBuffering);
        bufferedElements.push(mediaElement);
        scene.pause();
        startCheckMediaIsPlayable(scene);
    }, true);
};
const preventPlayAfterBuffering = ({ target: mediaElement }) => {
    if (!mediaElement ||
        !bufferedElements.includes(mediaElement)) {
        return;
    }
    mediaElement.pause();
};
const startCheckMediaIsPlayable = (scene) => {
    if (bufferCheckIntervalId) {
        return;
    }
    isMediaBuffering.value = true;
    bufferCheckIntervalId = setInterval(() => {
        const isPlayable = bufferedElements.every(isMediaPlayable);
        if (!isPlayable) {
            return;
        }
        clearMediaBufferState();
        scene.play();
    }, INTERVAL_MS);
};
const isMediaPlayable = (mediaElement) => {
    if (mediaElement.networkState !== mediaElement.NETWORK_LOADING) {
        return true;
    }
    if (mediaElement.readyState >= mediaElement.HAVE_ENOUGH_DATA) {
        return true;
    }
    return false;
};
export class BufferedAudio extends Audio {
    constructor(scene) {
        super();
        handleMediaBuffering(this, scene);
    }
}
