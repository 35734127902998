import {setCookie} from './cookieManager'

/* eslint-disable new-cap */
export default {
  _lastLanguageOverride: null,
  _userId: null,
  _plan: null,
  _email: null,
  _period: null,

  hide() {
    window.Intercom('hide')
  },
  boot(position) {
    window.Intercom('boot', {
      app_id: 'oc7ql7r8',
      language_override: this._lastLanguageOverride || null,
      user_id: this._userId || null,
      email: this._email || null,
      plan: this._plan || null,
      plan_period: this._period || null,
      alignment: position || 'right',
    })
  },
  setLanguage(language) {
    this._lastLanguageOverride = language
    this.boot()
  },
  setPaymentUser({userId, plan, email, period}) {
    // Not assign in development server
    if (process.env.NODE_ENV === 'development') {
      return
    }
    this._userId = userId
    this._plan = plan
    this._email = email
    this._period = period
    this.setIntercomCookie()
    this.boot()
  },
  show(position) {
    if (position) {
      this.boot(position)
    }
    window.Intercom('show')
  },
  /**
   * @see https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
   *  */
  update(updateData) {
    window.Intercom('update', updateData)
  },
  resetPaymentData() {
    this._userId = null
    this._plan = null
    this._email = null
    this._period = null
  },
  showNewMessage(preMessage) {
    window.Intercom('showNewMessage', preMessage)
  },
  setIntercomCookie() {
    const userInfo = {
      userId: this._userId,
      plan: this._plan,
      email: this._email,
      period: this._period,
    }
    const intercomInfoCookie = Buffer.from(JSON.stringify(userInfo))
    setCookie('_intercom_typecast_user_', intercomInfoCookie.toString('base64'), {
      isSecure: true,
      sameSite: true,
    })
  },
  shutdown() {
    try {
      window.Intercom('shutdown')
    } catch (error) {
      console.error(error)
    }
  },
}
