import * as TypeGuards from './Project.guard';
import Project from '@/projects/Project';
import ProjectV0 from '@/projects/v0/ProjectV0';
import ProjectV3 from '@/projects/v3/ProjectV3';
import ProjectV4 from '@/projects/v4/ProjectV4';
import ProjectV10 from '@/projects/v10/ProjectV10';
export default class ProjectFactory {
    static factory(project, oldVideoSlideList = [], characters = [], candidateCharacters = []) {
        Project.characters = characters;
        Project.candidateCharacters = candidateCharacters;
        if (!project.version) {
            return new ProjectV0(project).migrate();
        }
        if (project.version === 3) {
            return new ProjectV3(project).migrate();
        }
        if (project.version === 4) {
            return new ProjectV4(project).migrate();
        }
        if (project.version === 10) {
            return new ProjectV10(project, oldVideoSlideList).migrate();
        }
        throw new Error();
    }
}
export { TypeGuards };
