import AnimationSceneItem from '@/model/animations/AnimationSceneItem';
export default class NoneAnimationSceneItem extends AnimationSceneItem {
    constructor(animationPosition) {
        super(animationPosition, 'none', Number.MAX_SAFE_INTEGER, false);
        this.set(this.getKeyFrame());
    }
    getKeyFrame() {
        if (this.animationPosition === 'start') {
            return {
                '0%': {
                    display: 'none',
                },
                '100%': {
                    display: 'block',
                },
            };
        }
        return {
            '0%': {
                display: 'block',
            },
            '100%': {
                display: 'none',
            },
        };
    }
}
