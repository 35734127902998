import Moveable from 'moveable';
import Selecto from 'selecto';
import { SELECTABLE_ELEMENT_CLASS_NAME, UNSELECTABLE_ELEMENT_CLASS_NAME, } from '@/constants';
import { frameToTransform } from '@/share/context';
import Vue from 'vue';
import { emitEvent } from '@/emitEvent';
import { moveableEventHandler, moveableGroupEventHandler, } from './moveableHandlers';
import { selectoDragStartHandler, selectoSelectEndHandler, selectoSelectHandler, } from './selectoHandlers';
import { getVPropsById } from '@/share/virtualDOM/getVPropsById';
import { getSelectedVPropsList } from '@/virtualDOM/get';
const UNSELECTABLE_SELECTOR = `:not(.${UNSELECTABLE_ELEMENT_CLASS_NAME})`;
const SELECTABLE_TARGETS_SELECTOR = `.${SELECTABLE_ELEMENT_CLASS_NAME}${UNSELECTABLE_SELECTOR}`;
const MOVEABLE_AREA_CLASS_NAME = 'moveable-area';
export let moveable;
export let selecto;
export const initMoveable = (container) => {
    moveable = new Moveable(container, {
        draggable: true,
        scalable: true,
        keepRatio: true,
        rotatable: true,
        rootContainer: container,
    });
    selecto = new Selecto({
        container,
        rootContainer: container,
        selectableTargets: [SELECTABLE_TARGETS_SELECTOR],
        hitRate: 0,
        selectByClick: true,
        selectFromInside: false,
        toggleContinueSelect: ['shift'],
        ratio: 0,
    });
    moveable
        .on('clickGroup', event => selecto.clickTarget(event.inputEvent, event.inputTarget))
        .on('dragGroup', moveableGroupEventHandler)
        .on('scaleGroup', moveableGroupEventHandler)
        .on('rotateGroup', moveableGroupEventHandler)
        .on('scale', moveableEventHandler)
        .on('drag', moveableEventHandler)
        .on('rotate', moveableEventHandler)
        .on('dragEnd', updateTransformStyleWhenDragEnd)
        .on('scaleEnd', updateTransformedStyle)
        .on('rotateEnd', updateTransformedStyle)
        .on('dragGroupEnd', updateTransformedStyle)
        .on('scaleGroupEnd', updateTransformedStyle)
        .on('rotateGroupEnd', updateTransformedStyle);
    selecto
        .on('dragStart', selectoDragStartHandler)
        .on('select', selectoSelectHandler)
        .on('selectEnd', selectoSelectEndHandler);
    disableContextmenuEvent(container);
    container.addEventListener('contextmenu', handleSelectoContextmenuEvent);
};
const updateTransformStyleWhenDragEnd = ({ isDrag }) => {
    const isClick = !isDrag;
    if (isClick) {
        return;
    }
    updateTransformedStyle();
};
const updateTransformedStyle = () => {
    const selectedDOMElementList = selecto.getSelectedTargets();
    selectedDOMElementList.forEach(element => {
        const frame = element.frame;
        const transform = frameToTransform(frame);
        const id = element.getAttribute('id');
        const vProps = getVPropsById(id);
        if (!vProps.style) {
            return;
        }
        Vue.set(vProps.style, 'transform', transform);
    });
    emitEvent('update-element');
};
const disableContextmenuEvent = (container) => container.addEventListener('contextmenu', event => {
    event.preventDefault();
    event.stopPropagation();
});
const handleSelectoContextmenuEvent = (originEvent) => {
    const target = originEvent.target;
    const { classList } = target;
    const data = getSelectedVPropsList();
    const isMoveableArea = classList.contains(MOVEABLE_AREA_CLASS_NAME);
    if (isMoveableArea) {
        emitEvent('contextmenu', { data, originEvent });
        return;
    }
    const isCharacter = target.getAttribute('type') === 'character';
    if (isCharacter) {
        setTargets([]);
        emitEvent('contextmenu-character', { originEvent });
        return;
    }
    const isSelectable = classList.contains(SELECTABLE_ELEMENT_CLASS_NAME);
    if (!isSelectable) {
        setTargets([]);
        emitEvent('contextmenu', { data, originEvent });
        return;
    }
    setTargets([target]);
    emitEvent('contextmenu', { data, originEvent });
};
export const setZoom = (zoom) => {
    // @ts-ignore
    moveable.zoom = zoom;
};
export const resetTargets = () => setTargets([]);
export const setTargets = (targets) => {
    // @ts-ignore
    moveable.target = targets;
    selecto.setSelectedTargets(targets);
};
export const updateTargetRect = () => moveable.updateRect();
