import { state } from '@/state';
export const getRealCharacter = (characterId) => {
    if (!characterId) {
        return;
    }
    return state.realCharacterList.find(({ characterId: storedCharacterId }) => storedCharacterId === characterId);
};
export const getLive2dCharacter = (characterId) => {
    const realCharacter = getRealCharacter(characterId);
    if (!realCharacter) {
        return {
            isLive2D: false,
            isLive2DWithUpperOutfits: false,
            live2DCharacter: null,
        };
    }
    const { previewList, characterType } = realCharacter;
    const isLive2D = characterType === 'LIVE2D';
    const isLive2DWithUpperOutfits = isLive2D &&
        (previewList === null || previewList === void 0 ? void 0 : previewList.every(preview => preview.upperImage && preview.upperVideo));
    return {
        isLive2D,
        isLive2DWithUpperOutfits,
        live2DCharacter: realCharacter,
    };
};
