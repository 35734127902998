import {Node, Plugin} from 'tiptap'
import {nanoid} from 'nanoid'
import {TextSelection} from 'prosemirror-state'
import {ReplaceStep} from 'prosemirror-transform'
import {getSavedDefaultQueryAttrsFromTextNode} from '@/utils/defaultQueryAttrs'
import Vue from 'vue'
import {i18n} from '../i18n'
import CONSTANTS from '@/config/constants'

export default class Separator extends Node {
  constructor(options = {}) {
    super(options)
    this.handleClickEvent = options.handleClickEvent
    if (!this.handleClickEvent) {
      this.handleClickEvent = () => {
        // assign empty function since there isn't any given handler for click event.
      }
    }
    this.handleClickOn = options.handleClickOn
    if (!this.handleClickOn) {
      this.handleClickOn = () => {
        // assign empty function since there isn't any given handler for click event.
      }
    }
    this.prevClickNode = null
  }

  get name() {
    return 'separator'
  }

  get defaultOptions() {
    return {
      separatorClass: 'separator',
    }
  }

  get schema() {
    return {
      inline: true,
      selectable: false,
      group: 'inline',
      marks: '',
      parseDOM: [{tag: 'img.separator'}],
      toDOM: () => ['img', {class: this.options.separatorClass}],
    }
  }

  keys({type}) {
    return {
      'Ctrl-Enter': this.separatorQuery(type),
    }
  }

  commands({type}) {
    return () => this.separatorQuery(type)
  }

  separatorQuery(type) {
    return (state, dispatch) => {
      const {$from, $to, $cursor} = state.selection
      const position = $cursor ? $cursor.pos : $to.pos
      const tr = state.tr

      if ($from.nodeBefore && $to.nodeAfter) {
        const {savedQuerySilence, savedQuerySpeed} = getSavedDefaultQueryAttrsFromTextNode($from)

        const leftQuery = $from.nodeBefore.marks.find(mark => mark.type.name === 'query')
        const rightQuery = $to.nodeAfter.marks.find(mark => mark.type.name === 'query')
        const leftText = $from.nodeBefore.text || ''
        const rightText = $to.nodeAfter.text || ''

        if (leftQuery && rightQuery && rightText.length && leftQuery.attrs.id === rightQuery.attrs.id) {
          if (leftText.length) {
            const {style, speed} = leftQuery.attrs
            const userSettingSpeed = savedQuerySpeed ?? speed
            const lastCharacter = leftText.trim().slice(-1)
            if (lastCharacter === '.' || lastCharacter === '!' || lastCharacter === '?') {
              const userSettingSilence = savedQuerySilence ?? CONSTANTS.DEFAULT_SILENCE_MILLI_SEC
              tr.removeMark($from.pos - leftText.length, $from.pos, leftQuery).addMark(
                $from.pos - leftText.length,
                $from.pos,
                state.schema.marks.query.create({
                  id: nanoid(),
                  silence: userSettingSilence,
                  style: style,
                  speed: userSettingSpeed,
                }),
              )
            } else {
              const userSettingSilence = savedQuerySilence ?? CONSTANTS.DEFAULT_SILENCE_MILLI_SEC
              tr.removeMark($from.pos - leftText.length, $from.pos, leftQuery).addMark(
                $from.pos - leftText.length,
                $from.pos,
                state.schema.marks.query.create({
                  id: nanoid(),
                  silence: userSettingSilence,
                  style: style,
                  speed: userSettingSpeed,
                }),
              )
            }
          }
          const {silence, style, speed} = rightQuery.attrs
          const userSettingSilence = savedQuerySilence ?? silence
          const userSettingSpeed = savedQuerySpeed ?? speed
          tr.removeMark(position, position + rightText.length, rightQuery).addMark(
            position,
            position + rightText.length,
            state.schema.marks.query.create({
              id: nanoid(),
              silence: userSettingSilence,
              style: style,
              speed: userSettingSpeed,
            }),
          )
          if (state.selection instanceof TextSelection) {
            tr.replaceSelectionWith(type.create(), false)
          } else {
            const node = type.create()
            tr.insert(position, node)
          }
        }
        dispatch(tr)
      }
    }
  }

  get plugins() {
    const separatorPlugin = new Plugin({
      props: {
        handleClickOn(view, pos, node, nodePos, event, direct) {
          if (node?.type?.name === 'separator') {
            this.handleClickOn?.(view, pos, node, nodePos, event, direct)
          }
        },
        handleClick(view, pos, e) {
          const tr = view.state.tr
          let isClickSeparatorByEvent = false
          if (e.target) {
            isClickSeparatorByEvent = e.target.classList.contains('separator')
          }
          if (!isClickSeparatorByEvent) {
            return
          }
          tr.doc.descendants((node, nodePos) => {
            const isClickSeparatorByPosition =
              (pos === nodePos && node.type.name === 'separator') ||
              (pos === nodePos + 1 && node.type.name === 'separator')
            if (isClickSeparatorByPosition) {
              const selection = TextSelection.create(tr.doc, pos === nodePos ? pos : pos - 1)
              view.dispatch(tr.setSelection(selection))
              view.focus()
              this.handleClickEvent(Object.is(node, this.prevClickNode))
              this.prevClickNode = node
            }
          })
        },
      },
      // if query text exceed 300(CONSTANTS.MAX_QUERY_LENGTH), filter transaction
      filterTransaction(tr, state) {
        if (!tr.docChanged) return true

        let noFiltered = true
        tr.steps.forEach(step => {
          if (step.slice) {
            if (step instanceof ReplaceStep) {
              const {$from, $to} = tr.selection
              let leftText = ''
              let rightText = ''
              let leftTextLength = 0
              let rightTextLength = 0

              const isStepDelete = step.from < step.to && step.slice.content.size === 0
              if (isStepDelete) {
                const {$from: $stateFrom, $to: $stateTo} = state.selection
                const isCollapsed = $stateFrom.pos === $stateTo.pos
                const queries = []

                if (isCollapsed) {
                  const isBackSpace = $from.pos !== $stateFrom.pos // backspace: true, delete: false
                  queries.push(isBackSpace ? $from.nodeBefore?.marks?.[0] : $to.nodeAfter?.marks?.[0])
                } else {
                  state.doc.nodesBetween($stateFrom.pos, $stateTo.pos, node => {
                    queries.push(node?.marks?.[0])
                  })
                }

                for (const query of queries) {
                  if (!query) continue
                  const targetQueryElement = document.querySelector(`span[data-query-id="${query.attrs.id}"]`)
                  if (targetQueryElement && targetQueryElement.getAttribute('contenteditable') === 'false') {
                    noFiltered = false
                    return false
                  }
                }
              }

              if ($from.nodeBefore) leftText = $from.nodeBefore.text || ''
              if ($to.nodeAfter) {
                rightText = $to.nodeAfter.text || ''
              }
              if (leftText) leftTextLength = leftText.length
              if (rightText) rightTextLength = rightText.length

              if (step.slice.content.size > 0) {
                step.slice.content.content.forEach(stepNode => {
                  if (stepNode.type.name === 'text') {
                    // typing시 300(CONSTANTS.MAX_QUERY_LENGTH) 이상인 경우
                    if (leftTextLength + rightTextLength > CONSTANTS.MAX_QUERY_LENGTH) {
                      noFiltered = false
                      this.$notify({
                        group: 'main',
                        type: 'success',
                        title: i18n.t('exceed_300'),
                        ignoreDuplicates: true,
                      })
                    }
                  }
                })
              } else {
                const {nodeBefore: nodeBeforeSelection} = tr.before.resolve(step.from)
                const {nodeAfter: nodeAfterSelection} = tr.before.resolve(step.to)

                const isLeftNodeText = nodeBeforeSelection?.type.name === 'text'
                const isRightNodeText = nodeAfterSelection?.type.name === 'text'

                if (!isLeftNodeText || !isRightNodeText) {
                  noFiltered = true
                  return noFiltered
                }

                const leftTextId = nodeBeforeSelection.marks[0].attrs.id
                const rightTextId = nodeAfterSelection.marks[0].attrs.id

                const resultTextWillExceedMax = leftTextLength + rightTextLength > CONSTANTS.MAX_QUERY_LENGTH

                if (resultTextWillExceedMax) {
                  const isCombiningSentences = leftTextId !== rightTextId
                  const notificationType = isCombiningSentences ? 'success' : 'error'
                  const notificationTitle = isCombiningSentences ? 'na_merge_300' : 'editor.exceed_max_length'

                  this.$notify({
                    group: 'main',
                    type: notificationType,
                    title: i18n.t(notificationTitle),
                    ignoreDuplicates: true,
                  })

                  noFiltered = !isCombiningSentences
                  return noFiltered
                }
              }
            }
          }
        })
        return noFiltered
      },
      // if separator is deleted, merge query.
      appendTransaction(_, prevState, state) {
        const prevSelection = prevState.selection
        const {$from, $to} = state.selection
        const tr = state.tr

        const currentNodeCount = state.doc.nodeSize
        const prevNodeCount = prevState.doc.nodeSize
        const {savedQuerySilence} = getSavedDefaultQueryAttrsFromTextNode($from)
        const userSettingSilence = savedQuerySilence ?? CONSTANTS.DEFAULT_SILENCE_MILLI_SEC
        let leftQuery = null
        let rightQuery = null
        let rightSilence = userSettingSilence ?? CONSTANTS.DEFAULT_SILENCE_MILLI_SEC
        let prevRightSilence = userSettingSilence ?? CONSTANTS.DEFAULT_SILENCE_MILLI_SEC
        // left text is text node before $from of current selection.
        let leftText = ''
        // right text is text node after $to of current selection.
        let rightText = ''
        let rightSeparator = false

        if ($from.nodeBefore) {
          leftQuery = $from.nodeBefore.marks.find(mark => mark.type.name === 'query')
          leftText = $from.nodeBefore.text || ''
        }

        if ($to.nodeAfter) {
          rightQuery = $to.nodeAfter.marks.find(mark => mark.type.name === 'query')
          if (rightQuery) {
            rightSilence = $to.nodeAfter.marks[0].attrs.silence
          }
          rightText = $to.nodeAfter.text || ''
          if ($to.nodeAfter.type.name === 'separator') {
            rightSeparator = true
          }
        }

        let separatorDeleted = false
        // if current node count is less than prev node count, separator is deleted.
        if (currentNodeCount < prevNodeCount) {
          // if prev selection's mark is query, memory prev right silence.
          if (prevSelection.$to.nodeBefore) {
            const prevRightQuery = prevSelection.$to.nodeBefore.marks.find(mark => mark.type.name === 'query')
            if (prevRightQuery) {
              prevRightSilence = prevRightQuery.attrs.silence
            }
          }
          // if prev selection's range is 0 and cursor's position is prev selection's $from, check separator is deleted.
          if (prevSelection.from == prevSelection.to) {
            if (prevSelection.$from.nodeBefore && prevSelection.$from.nodeBefore.type.name === 'separator') {
              separatorDeleted = true
            }
          } else {
            // if prev selection's range is not 0 and there is separator in prev selection's range, check separator is deleted.
            prevState.doc.nodesBetween(prevSelection.from, prevSelection.to, node => {
              if (node.type.name === 'separator') {
                separatorDeleted = true
              }
            })
          }

          const leftTextLength = leftText.length
          const rightTextLength = rightText.length
          if (leftQuery && rightQuery && leftQuery.attrs.id !== rightQuery.attrs.id) {
            const isUndo = prevState.history$.done.eventCount > state.history$.done.eventCount
            if (isUndo) return tr
            tr.removeMark($to.pos, $to.pos + rightTextLength, rightQuery).addMark(
              $to.pos,
              $to.pos + rightTextLength,
              leftQuery,
            )
            if (rightSilence !== userSettingSilence) {
              tr.updateQueryAttrs(
                $from.pos - leftTextLength,
                $to.pos + rightTextLength,
                state.schema.marks.query.create({silence: rightSilence}),
                {silence: rightSilence},
              )
            } else {
              tr.updateQueryAttrs(
                $from.pos - leftTextLength,
                $to.pos + rightTextLength,
                state.schema.marks.query.create({silence: userSettingSilence}),
                {silence: userSettingSilence},
              )
            }
          } else if (separatorDeleted && leftQuery && !rightQuery && rightSeparator === false) {
            tr.updateQueryAttrs(
              $from.pos - leftTextLength,
              $to.pos,
              state.schema.marks.query.create({silence: userSettingSilence}),
              {
                silence: userSettingSilence,
              },
            )
          } else if (separatorDeleted && leftQuery && rightSeparator) {
            tr.updateQueryAttrs(
              $from.pos - leftTextLength,
              $to.pos,
              state.schema.marks.query.create({silence: prevRightSilence}),
              {silence: prevRightSilence},
            )
          } else if (!leftQuery && rightSeparator) {
            tr.replace($to.pos, $to.pos + 1)
          }
        }
        return tr
      },
    })
    separatorPlugin.$notify = Vue.notify
    separatorPlugin.handleClickEvent = this.handleClickEvent
    separatorPlugin.handleClickOn = this.handleClickOn
    separatorPlugin.prevClickNode = this.prevClickNode
    return [separatorPlugin]
  }
}
