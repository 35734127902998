import {SHORTS_PROJECT_MEDIA_TYPE} from '@/config/constants'
import * as API from '@/backend/video-editor-api'
import {useEditorOptionsStore, useVideoDomStore} from 'store/editor'
import {snakeToCamel} from 'utils/src/convertNamingConvention'
import {flatten} from 'lodash-es'

export default {
  async requestAsset(_, assetId, assetFrom) {
    const {$http} = this._vm
    const result = await API.getAsset($http, assetId, assetFrom)
    const blobImageUrl = await API.getBlobImageUrl($http, result.thumbUrl)
    result.thumbUrl = blobImageUrl
    return result
  },
  async requestAssetList({rootState, state, commit, dispatch}, skip) {
    const {projectId} = rootState.typecast.editor
    const {assetList: storedAssetList} = state
    const {$http} = this._vm

    const {assetList, page} = await API.getAssetList($http, projectId, {skip})
    const blobImageUrlPromise = assetList.map(asset => API.getBlobImageUrl($http, asset.thumbUrl))
    const blobImageUrlList = await Promise.all(blobImageUrlPromise)
    const resultWithBlobImageUrl = assetList.map((asset, index) => ({
      ...asset,
      thumbUrl: blobImageUrlList[index],
    }))
    dispatch('setAssetListAndCacheUrls', [...storedAssetList, ...resultWithBlobImageUrl])
    commit('setAssetTotalCount', page.total)
    return {requestedAssetList: resultWithBlobImageUrl}
  },
  setAssetListAndCacheUrls: ({commit}, assetList) => {
    commit('setAssetList', assetList)
    commit('setCachedAssetUrls', assetList)
  },
  /**
   * @param {import('@/backend/video-editor-api').AssetQuery[]} assetQueryList
   * @returns {Promise<[
   *  import('@/backend/video-editor-api').Asset[],
   *  string[]
   * ]>}
   */
  async requestUploadAsset({commit}, assetQueryList) {
    const {$http} = this._vm

    const settledAssetList = await Promise.allSettled(
      assetQueryList.map(assetQuery => API.postAsset($http, assetQuery)),
    )

    const assetList = settledAssetList
      .filter(settledAsset => settledAsset.status === 'fulfilled')
      .map(fulfilledAsset => fulfilledAsset.value)
    const rejectedAssetNameList = settledAssetList
      .filter(settledAsset => settledAsset.status === 'rejected')
      .map(rejectedAsset => rejectedAsset.reason.message)

    const blobImageUrlPromise = assetList.map(({thumbUrl}) =>
      thumbUrl ? API.getBlobImageUrl($http, thumbUrl) : undefined,
    )
    const blobImageUrlList = await Promise.all(blobImageUrlPromise)
    const resultWithBlobImageUrl = assetList.map((asset, index) => ({...asset, thumbUrl: blobImageUrlList[index]}))
    commit('setCachedAssetUrls', assetList)
    return [resultWithBlobImageUrl, rejectedAssetNameList]
  },
  async requestDeleteAsset({state, commit}, assetIdList) {
    const {assetList} = state
    const {$http} = this._vm

    await Promise.all(assetIdList.map(id => API.deleteAsset($http, id)))
    assetList.filter(({_id}) => assetIdList.includes(_id)).forEach(({thumbUrl}) => URL.revokeObjectURL(thumbUrl))
    const remainAssetList = assetList.filter(({_id}) => !assetIdList.includes(_id))
    commit('setAssetList', remainAssetList)
  },
  async requestImageAssetList({state, commit}, assetQuery) {
    const {imageAssetList: storedAssetList} = state
    const {$http} = this._vm

    const {assetList, assetTotalCount} = await API.getImageAssetList($http, assetQuery)
    const blobImageUrlPromise = assetList.map(asset => API.getBlobImageUrl($http, asset.thumbUrl))
    const blobImageUrlList = await Promise.all(blobImageUrlPromise)
    const resultWithBlobImageUrl = assetList.map((asset, index) => ({...asset, thumbUrl: blobImageUrlList[index]}))
    commit('setImageAssetList', [...storedAssetList, ...resultWithBlobImageUrl])
    commit('setCachedAssetUrls', assetList)
    commit('setImageAssetTotalCount', assetTotalCount)
  },
  async requestUploadImageAsset({commit}, assetQueryList) {
    const {$http} = this._vm

    const settledAssetList = await Promise.allSettled(
      assetQueryList.map(assetQuery => API.postImageAsset($http, assetQuery)),
    )
    const assetList = settledAssetList
      .filter(settledAsset => settledAsset.status === 'fulfilled')
      .map(fulfilledAsset => fulfilledAsset.value)
    const rejectedImageNameList = settledAssetList
      .filter(settledAsset => settledAsset.status === 'rejected')
      .map(rejectedAsset => rejectedAsset.reason.message)

    const blobImageUrlPromise = assetList.map(asset => API.getBlobImageUrl($http, asset.thumbUrl))
    const blobImageUrlList = await Promise.all(blobImageUrlPromise)
    const resultWithBlobImageUrl = assetList.map((asset, index) => ({...asset, thumbUrl: blobImageUrlList[index]}))
    commit('setCachedAssetUrls', assetList)
    return [resultWithBlobImageUrl, rejectedImageNameList]
  },
  async requestDeleteImageAsset({state, commit}, assetTsList) {
    const {imageAssetList} = state
    const {$http} = this._vm
    const remainAssetList = []

    await API.deleteImageAsset($http, assetTsList)
    imageAssetList.forEach(asset => {
      if (assetTsList.includes(asset.assetTs)) {
        URL.revokeObjectURL(asset.thumbUrl)
        return
      }
      remainAssetList.push(asset)
    })

    commit('setImageAssetList', remainAssetList)
  },
  async initializeVideoProjectExistAudio(
    {rootGetters, commit},
    {$http, projectUid, projectData, mediaType, subtitle, bgColor},
  ) {
    commit('setProjectUid', projectUid)

    const videoDomStore = useVideoDomStore()
    const slideList = projectData.additional_data.slide_list_v2
    videoDomStore.$init({slideList, subtitle: snakeToCamel(subtitle || {}), globalBackgroundColor: bgColor})

    const editorOptionsStore = useEditorOptionsStore()
    const currentSlideId = projectData.additional_data.slide_list_v2[0]?.slideId
    editorOptionsStore.setCurrentSlideIndexAndId(0, currentSlideId)

    const isShortsProject = mediaType === SHORTS_PROJECT_MEDIA_TYPE
    const showCanvas = isShortsProject && !rootGetters['typecast/editor/isHistoryMode']
    commit('setIsShortsProject', isShortsProject)
    commit('setShowCanvas', showCanvas)

    const timelineAssetLists = projectData.additional_data.timeline
    commit('setTimelineAssetLists', {list: timelineAssetLists})

    const assetUrlPromises = Object.values(timelineAssetLists).map(assetList =>
      assetList.map(asset => {
        return {
          id: asset.id,
          promise: API.getAsset($http, asset.id, asset.assetFrom),
        }
      }),
    )

    const flattenAssetUrlPromises = flatten(assetUrlPromises)
    commit(
      'setCachedAssetUrls',
      flatten(assetUrlPromises).map(({id, promise}) => ({_id: id, assetUrl: promise.then(res => res.assetUrl)})),
    )

    flattenAssetUrlPromises.forEach(async ({id, promise}) => {
      const {thumbUrl} = await promise
      const timelineAsset = timelineAssetLists.video.find(({id: videoId}) => id === videoId)
      if (timelineAsset) {
        timelineAsset.thumbUrl = thumbUrl
      }
    })
  },
  async setProjectLoading({commit}, isProjectLoading) {
    commit('setIsProjectLoading', isProjectLoading)
    this._vm.$typecast.$editor.setIsProjectLoading(isProjectLoading)
    commit('setIsProjectInitialized', !isProjectLoading)
  },
}
