import ko from './backlinkKo'
import en from './backlinkEn'

export default {
  install(Vue) {
    Vue.prototype.$url = function getUrl(key) {
      const isKorean = this.$i18n.locale === 'ko'
      return isKorean ? ko[key] : en[key]
    }
  },
}
