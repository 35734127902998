import {getLastActorStyleLabel} from '../actor/actor-version-helpers'

export default {
  _store: null,
  setStore(store) {
    this._store = store
  },
  _getActors() {
    if (!this._store) {
      return null
    }
    return this._store.getters['typecast/actor/actors']
  },
  _getActor(actorId) {
    if (!this._store) {
      return null
    }
    return this._getActors().find(actor => actor.actor_id === actorId)
  },
  _getActorMood(actor) {
    if (actor.tag_v2.mood) {
      return Array.from(new Set(actor.tag_v2.mood.map(mood => mood.title)))
    }
    return []
  },
  _isVideoReal(actor) {
    return actor.character_flags.includes('video-real')
  },
  _isNewActor(actor) {
    return actor.character_flags.includes('new')
  },
  _getActorStyleType(actor) {
    const lastStyleLabel = getLastActorStyleLabel(actor)

    if (lastStyleLabel.flags.includes('style-emo')) {
      return 'style-emo'
    }
    if (lastStyleLabel.flags.includes('style-tone')) {
      return 'style-tone'
    }
    if (lastStyleLabel.flags.includes('style-all')) {
      return 'style-all'
    }
  },
  _isLastPitch(actor) {
    const lastStyleLabel = getLastActorStyleLabel(actor)
    return lastStyleLabel.flags.includes('last-pitch')
  },
  getActorInfoById(actorId) {
    const actor = this._getActor(actorId)
    return this.getActorInfo(actor)
  },
  getActorInfo(actor) {
    return {
      actor_id: actor.unique_id,
      actor_filter_age: actor.age,
      actor_name: actor.name['en'],
      actor_lang: actor.language,
      actor_sex: actor.sex[0],
      actor_filter_tone: actor.tag_v2.tone,
      actor_filter_mood: this._getActorMood(actor),
      actor_filter_content: actor.tag_v2.content,
      actor_is_new: this._isNewActor(actor),
      actor_is_virtual_human: this._isVideoReal(actor),
      actor_style_type: this._getActorStyleType(actor),
      actor_is_last_pitch: this._isLastPitch(actor),
    }
  },
}
