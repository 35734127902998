import { defineStore } from 'pinia';
import { addFontFamily } from './addFontFamily';
import { SUBTITLE_STYLE_LIST, SUBTITLE_SIZE_LIST, SUBTITLE_POSITION_LIST, } from './constants';
import { presignedUrlCacheDatabase } from './presignedUrlCache';
// @ts-ignore
import store from '@/store';
export { SUBTITLE_STYLE_LIST, SUBTITLE_SIZE_LIST, SUBTITLE_POSITION_LIST, presignedUrlCacheDatabase, };
export const useEditorOptionsStore = defineStore('editorOptions', {
    state: () => ({
        realCharacterList: [],
        slideIndex: 0,
        currentSlideId: '',
        previewVolume: 100,
        featureCommands: [],
    }),
    actions: {
        setCurrentSlideIndexAndId(slideIndex, slideId) {
            const { slideIndex: prevSlideIndex } = this;
            if (prevSlideIndex !== slideIndex) {
                const { cleanSubtitleMap } = useVideoDomStore();
                cleanSubtitleMap(prevSlideIndex);
            }
            this.slideIndex = slideIndex;
            this.currentSlideId = slideId;
        },
    },
});
export const useVideoDomStore = defineStore('videoDom', {
    stateHash: true,
    state: () => ({
        slideList: [],
        slideTimelineDurationList: [],
        // NOTE: 기본값은 light/src/projects/Project.ts에서 설정됨
        subtitle: {
            fontFamily: '',
            style: 'bg_short',
            size: 'medium',
            position: 'bottom',
        },
        globalBackgroundColor: '#ffffff',
    }),
    getters: {
        subtitleStyleIndex: state => SUBTITLE_STYLE_LIST.indexOf(state.subtitle.style).toString(),
        currentSlide: state => {
            const { slideIndex } = useEditorOptionsStore();
            return state.slideList[slideIndex];
        },
    },
    actions: {
        resetVideoProject() {
            this.slideList = [];
            const editorOptionsStore = useEditorOptionsStore();
            editorOptionsStore.$patch({
                slideIndex: 0,
                currentSlideId: '',
                featureCommands: [],
            });
        },
        // TODO: 전달받은 slideIndex 단위로 debounce 처리
        // 예를 들어, slideIndex 0을 두 번 연속으로 전달받으면
        // 최종적으로 slideIndex 0에 대한 cleanSubtitleMap은 한 번만 실행
        cleanSubtitleMap(slideIndex = useEditorOptionsStore().slideIndex) {
            const slide = this.slideList[slideIndex];
            if (!slide) {
                return;
            }
            const subtitleMap = slide.subtitleMap;
            if (!subtitleMap) {
                return;
            }
            const subtitleQueryIdList = Object.keys(subtitleMap);
            const isEmptySubtitle = !subtitleQueryIdList.length;
            if (isEmptySubtitle) {
                return;
            }
            const queries = store.getters['typecast/queryCache/queries'];
            const slideQueryList = Object.entries(queries).filter(([, query]) => query.paragraphIndex === slideIndex);
            const newSubtitleMap = slideQueryList.reduce((newSubtitleMap, [queryId, { text: originText }]) => {
                const subtitle = subtitleMap[queryId];
                const isQueryRemoved = !subtitle;
                if (isQueryRemoved) {
                    return newSubtitleMap;
                }
                const { text, invisible } = subtitle;
                const isTextNotChanged = text === originText;
                if (isTextNotChanged) {
                    delete subtitle.text;
                }
                const isNotInvisible = !invisible;
                if (isNotInvisible) {
                    delete subtitle.invisible;
                }
                const isSubtitleEmpty = !Object.keys(subtitle).length;
                if (isSubtitleEmpty) {
                    return newSubtitleMap;
                }
                newSubtitleMap[queryId] = subtitle;
                return newSubtitleMap;
            }, {});
            this.slideList[slideIndex].subtitleMap = newSubtitleMap;
        },
    },
    watch: {
        subtitle: {
            fontFamily: addFontFamily,
        },
    },
});
