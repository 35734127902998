import { cloneDeep } from 'utils';
import { getSlide } from '@/share/virtualDOM/getSlide';
import { useVideoDomStore } from 'store/editor';
import { createVElementId } from '@/share/virtualDOM';
import { emitEvent } from '@/emitEvent';
/** @public */
export const setApplyAllElements = (slideIndex, targetSlideIndex) => {
    var _a;
    if (slideIndex === targetSlideIndex) {
        return;
    }
    const slide = getSlide(slideIndex);
    const children = (_a = slide.children) !== null && _a !== void 0 ? _a : [];
    const clonedChildren = cloneDeep(children);
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const targetSlide = slideList[targetSlideIndex];
    if (!targetSlide) {
        return;
    }
    targetSlide.children = clonedChildren.map(resetId);
    emitEvent('update-element');
};
const resetId = (vProps) => {
    vProps.id = createVElementId();
    return vProps;
};
