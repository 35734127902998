var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { playContainer, resetTargets } from '@/context';
import { renderPlayContainer } from '@/render/renderPlayContainer';
import { renderPlayScene } from '@/render/renderPlayScene';
import { setState } from '@/state';
export const playMode = () => __awaiter(void 0, void 0, void 0, function* () {
    playContainer.parentElement.focus();
    playContainer.style.setProperty('display', 'none');
    setState('mode', 'play');
    yield renderPlayContainer();
    renderPlayScene();
    playContainer.style.setProperty('display', 'block');
    resetTargets();
});
