/**
 * @typedef {Record<string, string>} StyleLabelVersionList
 */

import backend from '@/backend/backend-api.js'
import Vue from 'vue'
import store from '@/store'
import CONSTANTS from '../../config/constants'
import {i18n} from '../../i18n'
import {createQueryHash} from '../../utils/nsHash'
import * as Sentry from '@sentry/vue'
import {isValidEditorText, isValidEditorTextByLang, isOnlySpecialCharacter} from '@/utils/isValidEditorText'
import {startsWithSpecialCharacterFilter} from '@/utils/stringFilter'
import getEstimateDurationByText from '@/utils/getEstimateDurationByText'
import {presignedUrlCacheDatabase, useVideoDomStore} from 'store/editor'
import AnimationSceneItem from '@/model/animations/AnimationSceneItem'
import {DotsTakeCache} from './dotsTakeCache'
import {getWrongLanguageErrorMessage} from '@/utils/i18nMessages'

/**
 * @module typecast/store/queryCache
 */

let gBatchUrl
let batchInterval = []
let qBatchInterval = []

let shouldStopCreateAllAudio = false
let isCreatingAllAudio = false
/**
 * 재생 문장 store state
 * @name State
 * @type {object}
 * @property {Object} queries tiptap 에디터 document 파싱한 값
 * @property {Object} queryCacheItems 캐싱한 쿼리
 * @property {Array} playBufferItems 재생 중인 Buffer
 * @property {Array} downloadBufferItems 다운로드 버퍼
 * @property {Object} downloadWay 다운로드 방식
 * @property {Boolean} downloadError 다운로드 중 에러 발생 플래그
 */
const state = () => {
  return {
    queries: {},
    queryCacheItems: {},
    playBufferItems: [],
    downloadBufferItems: [],
    downloadWay: {
      mergeDown: true,
      fileType: 'mp3',
      quality: 'normal',
      selectDownload: false,
    },
    downloadError: false,
    hd2Progress: 0,
    currentPlayBufferItem: null,
    tooltipQueryId: null,
    pasteStart: false,
    /**
     * @type {StyleLabelVersionList}
     */
    styleLabelVersionList: {},
    previewQuality: CONSTANTS.PREVIEW_QUALITY.HIGH,
  }
}

function clearBatchInterval(interval) {
  const batchIndex = batchInterval.indexOf(interval)
  if (interval) {
    if (batchIndex > -1) {
      batchInterval.splice(batchIndex, 1)
    }
    clearInterval(interval)
    interval = null
  } else {
    batchInterval.forEach(interval => {
      clearInterval(interval)
      interval = null
    })
    batchInterval = []
  }
}

function clearQBatchInterval(interval) {
  const batchIndex = qBatchInterval.indexOf(interval)
  if (interval) {
    if (batchIndex > -1) {
      qBatchInterval.splice(batchIndex, 1)
    }
    clearInterval(interval)
  } else {
    qBatchInterval.forEach(interval => {
      clearInterval(interval)
    })
  }
  qBatchInterval = []
}

async function batch($http, queryParams) {
  const params = queryParams.map(query => ({...query, text: startsWithSpecialCharacterFilter(query.text)}))
  return await backend.speakBatch($http, params)
}

async function detailBatch($http, urlList) {
  return await backend.speakDetailBatch($http, urlList)
}

async function qBatch($http, urlList, quality) {
  return await backend.speakQBatch($http, urlList, quality)
}

async function intervalHandler(
  $http,
  $notify,
  state,
  urlList,
  playBufferChunk,
  commit,
  intervalInstance,
  download,
  dontPlayFlag,
) {
  try {
    const speakList = await detailBatch($http, urlList)
    const isThisIntervalCleared = !batchInterval.find(intervalId => intervalId === intervalInstance)
    if (isThisIntervalCleared) {
      return
    }
    let isThereAnyFailedSpeak = false
    for (const [index, speak] of speakList.entries()) {
      const playBufferItem = playBufferChunk[index]
      const queryId = playBufferItem.queryId
      const updatedSpeak = DotsTakeCache.getInstance().updateSpeakTakeNumber({queryId, speak})
      commit('updateQueryCacheItem', {hash: playBufferItem.hash, obj: {speak: updatedSpeak}})
      if (!playBufferItem.audio && speak.status === 'done') {
        loadAudio($http, $notify, state, speak.audio, playBufferItem, commit, download, dontPlayFlag)
        commit('updateQuery', {id: playBufferItem.queryId, obj: {status: CONSTANTS.QUERY_CLASS_DONE}})
        DotsTakeCache.getInstance().addToCacheWithSpeak({queryId, speak, hash: playBufferItem.hash})
      } else if (speak.status === 'failed') {
        isThereAnyFailedSpeak = true
        clearBatchInterval(intervalInstance)
        commit('setDownloadError', true)
        commit('removeQueryCacheItem', playBufferItem.hash)
        commit('updateQuery', {id: playBufferItem.queryId, obj: {status: CONSTANTS.QUERY_CLASS_FAILED}})
        $notify({
          group: 'main',
          type: 'error',
          text: `create text error: ${speak.query.text}`,
        })
      }
    }

    const isSpeakComplete = speak => speak.status === 'done' || speak.status === 'failed'

    if (isThereAnyFailedSpeak) {
      speakList.forEach((speak, index) => {
        const playBufferItem = playBufferChunk[index]
        if (!isSpeakComplete(speak)) {
          commit('removeQueryCacheItem', playBufferItem.hash)
          commit('updateQuery', {id: playBufferItem.queryId, obj: {status: null}})
          commit('removePlayBufferItemByQueryId', playBufferItem.queryId)
          const queryDom = document.querySelector(`span[data-query-id="${playBufferItem.queryId}"]`)
          queryDom?.removeAttribute('contenteditable')
        }
      })
    }

    const completed = speakList.filter(isSpeakComplete)
    if (completed.length === urlList.length) {
      clearBatchInterval(intervalInstance)
    }
  } catch (error) {
    clearBatchInterval(intervalInstance)
    commit('setDownloadError', true)
    playBufferChunk.map(item => {
      commit('removeQueryCacheItem', item.hash)
      commit('updateQuery', {id: item.queryId, obj: {status: CONSTANTS.QUERY_CLASS_FAILED}})
    })
    $notify({
      group: 'main',
      type: 'error',
      title: 'Failed!',
      text: backend.httpErrorMsg(error),
    })
    Sentry.captureException(error)
  }
}

function createDetailBatchInterval($http, $notify, state, urlList, playBufferChunk, commit, download, dontPlayFlag) {
  if (isCreatingAllAudio && shouldStopCreateAllAudio) {
    return
  }
  const intervalInstance = setInterval(() => {
    intervalHandler($http, $notify, state, urlList, playBufferChunk, commit, intervalInstance, download, dontPlayFlag)
  }, 1000)
  intervalHandler($http, $notify, state, urlList, playBufferChunk, commit, intervalInstance, download, dontPlayFlag)
  batchInterval.push(intervalInstance)
  return intervalInstance
}

export function getAudioUrl(previewQuality, audio) {
  if (previewQuality === CONSTANTS.PREVIEW_QUALITY.LOW && audio.low) {
    return audio.low.url
  } else if (previewQuality === CONSTANTS.PREVIEW_QUALITY.HIGH && audio.hd1) {
    return audio.hd1.url
  }
  return audio.url
}

export async function getPresignedAudioUrl($http, url) {
  const key = url.split('speak/')[1]

  if (window.indexedDB) {
    await presignedUrlCacheDatabase.caches
      .where('expires')
      .below(parseInt(new Date().valueOf() / 1000))
      .delete()

    const presignedUrl = await presignedUrlCacheDatabase.caches.where('key').equals(key).first()
    if (presignedUrl) {
      return presignedUrl.url
    }
  }

  const res = await $http.get(`${url}/cloudfront`)
  const {result} = res.data

  if (window.indexedDB) {
    const params = new URLSearchParams(new URL(result).search)
    const expires = params.get('Expires')

    const handlePutCacheException = (e, extraInfo) => {
      Sentry.withScope(scope => {
        scope.setTag('CACHE', 'PRESIGNED_URL')
        scope.setExtra('source_url', url)
        scope.setExtra('presigned_url', result)
        scope.setLevel('warning')
        Sentry.captureMessage(`Failed to put presigned url to cache. ${extraInfo}`)
        Sentry.captureException(e)
      })
    }

    await presignedUrlCacheDatabase.caches
      .put({
        key,
        url: result,
        expires: Number(expires),
      })
      .catch(async e => {
        if (e.name === 'QuotaExceededError' || (e.inner && e.inner.name === 'QuotaExceededError')) {
          await presignedUrlCacheDatabase.caches.clear()
          await presignedUrlCacheDatabase.caches
            .put({
              key,
              url: result,
              expires,
            })
            .catch(e => {
              handlePutCacheException(e, 'QuotaExceededError, second try')
            })
        } else {
          handlePutCacheException(e, 'first try')
        }
      })
  }

  return result
}

export function createAudioParamsByQuery(query) {
  const styleLabelVersionList = store.state.typecast.queryCache.styleLabelVersionList || {}

  const actor = store.state.typecast.actor.candidateActor.find(actor => actor.actor_id === query.actor)
  const lang = CONSTANTS.AUTO_LANGS.includes(actor.language) ? CONSTANTS.LANG : actor.language

  const customSpeed = Number(query.customSpeed)
  const isCustomSpeedEnable = customSpeed > 0 && Number(query.speed) === CONSTANTS.CUSTOM_SPEED
  const param = {
    actor_id: query.actor,
    text: query.text,
    lang,
    force_length: isCustomSpeedEnable ? '1' : '0',
    max_seconds: isCustomSpeedEnable ? customSpeed : CONSTANTS.MAX_SECONDS,
    naturalness: CONSTANTS.NATURALNESS,
    speed_x: query.speed,
    gid: query.id,
    tempo: query.tempo,
    pitch: query.pitch,
    last_pitch: query.lastPitch,
    mode: CONSTANTS.SPEAK_MODE,
    retake: query.retake,
  }

  if (query.actor_version === '1.0.0') {
    param.style_idx = query.style
  } else {
    param.style_label = CONSTANTS.DEFAULT_STYLE_TAGS.includes(query.style) ? CONSTANTS.DEFAULT_STYLE_NAME : query.style
    if (param.style_label.startsWith && param.style_label.startsWith('styletag-')) {
      param.styletag = query.styleTag
    }
  }

  if (styleLabelVersionList[query.actor]) {
    param.style_label_version = styleLabelVersionList[query.actor]
  }

  return param
}

async function loadAudio($http, $notify, state, audio, bufferItem, commit, download, dontPlayFlag, subtitlePlayFlag) {
  try {
    const updatedCacheItem = state.queryCacheItems[bufferItem.hash]
    if (updatedCacheItem) {
      const url = getAudioUrl(state.previewQuality, audio)
      const ext = url ? audio.extension : ''
      // TEMP: if download, don't get presigned url
      const bufferAudioUrl = download && !subtitlePlayFlag ? '' : await getPresignedAudioUrl($http, url)
      const audioData = {
        audioSource: [bufferAudioUrl],
        audioFormat: [ext],
        quality: state.previewQuality,
        createAt: Date.now(),
      }
      const updateData = {
        audio: audioData,
      }
      commit('updateQueryCacheItem', {hash: bufferItem.hash, obj: updateData})
      commit(download ? 'updateDownloadBufferItem' : 'updatePlayBufferItem', {
        bufferItem,
        obj: {audio: audioData, speak: updatedCacheItem.speak},
      })
      if (!dontPlayFlag) {
        // current play buffer is null = first play audio
        setFirstPlayBuffer(bufferItem, state, commit)
      }
    }
  } catch (error) {
    $notify({
      group: 'main',
      type: 'error',
      text: i18n.t('speak.audio_load_error'),
    })
    commit('setDownloadError', true)
    Sentry.captureException(error)
  }
}

function checkQStatus($http, $notify, batchResult, downloadBufferChunk, commit, quality) {
  if (batchResult.result.status === 'done') {
    for (const item of downloadBufferChunk) {
      commit('updateDownloadBufferItem', {bufferItem: item, obj: {q: true}})
    }
    return
  }
  const qBatchInstance = setInterval(async () => {
    try {
      const res = await $http.get(batchResult.result.url)
      if (quality === 'hd2' && res.data.result.progress !== null) {
        commit('setHd2Progress', res.data.result.progress)
      }
      if (res.data.result.status === 'done') {
        clearQBatchInterval(qBatchInstance)

        for (const item of downloadBufferChunk) {
          commit('updateDownloadBufferItem', {bufferItem: item, obj: {q: true}})
        }
      } else if (res.data.result.status === 'failed') {
        clearQBatchInterval(qBatchInstance)
        $notify({
          group: 'main',
          type: 'error',
          title: 'Failed!',
        })
        commit('setDownloadError', true)
      }
    } catch (error) {
      clearQBatchInterval(qBatchInstance)
      // eslint-disable-next-line camelcase
      const errorCode = error.response?.data?.message?.error_code
      let errorMessage = ''
      switch (errorCode) {
        case 'auth/invalid/permission':
          errorMessage = i18n.t('speak_batch_q.auth/invalid/permission')
          break
        case 'app/status/not-done':
          errorMessage = i18n.t('speak_batch_q.app/status/not-done')
          break
        case 'app/invalid/quality':
          errorMessage = i18n.t('speak_batch_q.app/invalid/quality')
          break
        default:
          errorMessage = i18n.t('download.default_error_message')
          break
      }
      $notify({
        group: 'main',
        type: 'error',
        text: errorMessage,
      })
      Sentry.captureException(error)
      commit('setDownloadError', true)
    }
  }, 3000)
  qBatchInterval.push(qBatchInstance)
}

function getStyleAndVersion(query, candidateActor) {
  const queryActor = candidateActor.find(actor => actor.actor_id === query.actor)
  if (!queryActor) {
    throw new Error('StyleAndVersion: Cannot find actor')
  }
  let max = 0
  if (queryActor.tuning && queryActor.tuning.includes('style')) {
    max = queryActor.num_styles - 1 || 3
  }
  let style = query.style
  if (queryActor.version === '1.0.0') {
    style = Math.min(query.style, max)
  }
  return {
    style,
    version: queryActor.version,
  }
}

function checkExist(state, queryObject) {
  const hash = createQueryHash({...queryObject, styleVersion: state.styleLabelVersionList[queryObject.actor]})
  const cachedData = state.queryCacheItems[hash]
  const hasCache = !!cachedData
  if (hasCache && cachedData.speak && cachedData.speak.audio) {
    queryObject.status = CONSTANTS.QUERY_CLASS_DONE
  } else {
    queryObject.status = queryObject.text.length > CONSTANTS.MAX_QUERY_LENGTH ? CONSTANTS.QUERY_CLASS_FAILED : null
  }
}

export function tiptapContentValue(json, state) {
  const queries = {}
  if (!json) {
    return queries
  }
  for (let i = 0; i < json.content.length; i++) {
    const paragraph = json.content[i]
    if (paragraph.content) {
      for (let j = 0; j < paragraph.content.length; j++) {
        const text = paragraph.content[j]
        if (text.marks && text.type === 'text' && text.text.trim().length !== 0 && isValidEditorText(text.text)) {
          const queryObject = {}
          queryObject.actor = paragraph.attrs.actor
          queryObject.text = text.text.trim()
          for (let k = 0; k < text.marks.length; k++) {
            const query = text.marks[k]
            if (query.type === 'query') {
              queryObject.id = query.attrs.id
              queryObject.style = query.attrs.style
              queryObject.styleTag = query.attrs.styleTag
              queryObject.speed = query.attrs.speed
              queryObject.customSpeed = query.attrs.customSpeed
              queryObject.rest_sec = j === 0 ? paragraph.attrs.rest || 0 : 0
              queryObject.silence = query.attrs.silence
              queryObject.tempo = query.attrs.tempo
              queryObject.pitch = query.attrs.pitch
              queryObject.lastPitch = query.attrs.lastPitch
              queryObject.takeNumber = query.attrs.takeNumber ?? 0
              queryObject.status = null
              queryObject.paragraphIndex = i
            }
          }
          queries[queryObject.id] = queryObject
          checkExist(state, queryObject)
        }
      }
    }
  }
  return queries
}

async function reloadSpeakIfNoAudio($http, item) {
  if (!item.speak.audio) {
    const speak = await $http.get(item.speak.speak_url).then(res => res.data.result)
    if (speak.status === 'done') {
      item.speak = speak
      return item
    } else {
      return null
    }
  } else {
    return item
  }
}

function setFirstPlayBuffer(bufferItem, state, commit) {
  const bufferIndex = state.playBufferItems.findIndex(buffer => buffer.hash === bufferItem.hash)
  if (bufferIndex === 0 && !state.currentPlayBufferItem) {
    commit('setNextPlayBuffer')
  }
}

const BEFORE_CREATE_AUDIO_ERROR = {
  WRONG_LANGUAGE_ERROR: 1,
  ONLY_SPECIAL_CHARACTER_ERROR: 2,
  EXCEED_MAX_LENGTH_ERROR: 3,
}

function setBeforeCreateAudioError({errorBatchItem, batchList, errorType, errorActor, $notify, commit}) {
  const queryId = errorBatchItem.gid
  const queryElement = document.querySelector(`[data-query-id="${queryId}"]`)
  queryElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})

  if (errorBatchItem) {
    batchList.forEach(batchItem => {
      if (errorBatchItem.gid === batchItem.gid) {
        commit('updateQuery', {id: batchItem.gid, obj: {status: CONSTANTS.QUERY_CLASS_FAILED}})
      } else {
        commit('updateQuery', {id: batchItem.gid, obj: {status: null}})
      }
    })
    let errorMessage
    switch (errorType) {
      case BEFORE_CREATE_AUDIO_ERROR.WRONG_LANGUAGE_ERROR:
        errorMessage = getWrongLanguageErrorMessage(errorActor.name[i18n.locale], errorActor.language)
        break
      case BEFORE_CREATE_AUDIO_ERROR.ONLY_SPECIAL_CHARACTER_ERROR:
        errorMessage = i18n.t('editor.only_special_character_error')
        break
      case BEFORE_CREATE_AUDIO_ERROR.EXCEED_MAX_LENGTH_ERROR:
        errorMessage = i18n.t('editor.exceed_max_length')
    }

    $notify({
      group: 'main',
      type: 'error',
      text: errorMessage,
    })
    return
  }
}

const getDurationPerParagraph = ({queries, queryCacheItems, candidateActor, styleLabelVersionList, isEstimation}) => {
  const durationList = []
  const queryList = Object.values(queries)
  const predictActor = {}

  queryList.forEach(query => {
    const actor = predictActor[query.actor] || candidateActor.find(actor => actor.actor_id === query.actor)
    const hash = createQueryHash({...query, styleVersion: styleLabelVersionList[query.actor]})
    const cachedQuery = queryCacheItems[hash]
    const prevSum = durationList[query.paragraphIndex]
    const hasRealDuration = !!cachedQuery?.speak?.duration
    const rest = query.rest_sec
    let duration = 0

    if (!actor) {
      durationList[query.paragraphIndex] = prevSum ? prevSum : 0
    } else {
      predictActor[actor.actor_id] = actor
      if (hasRealDuration) {
        const silence = query.silence / 1000
        duration = cachedQuery.speak.duration + silence
      }
      if (!hasRealDuration && isEstimation) {
        duration = getEstimateDurationByText({
          actor,
          text: query.text,
          silence: query.silence,
          tempo: query.tempo,
          customSpeed: query.customSpeed,
        })
      }
      durationList[query.paragraphIndex] = prevSum ? prevSum + duration + rest : duration + rest
    }
  })

  return durationList
}

// has cache, but some require data is null in cache
const isInvalidAudioCache = cachedData => {
  const speak = cachedData?.speak
  const audio = cachedData?.speak?.audio
  return (
    !speak ||
    !audio ||
    !audio.high ||
    !audio.hd1 ||
    !audio.low ||
    !audio.url ||
    !audio.high.url ||
    !audio.hd1.url ||
    !audio.low.url
  )
}

export function notifySpeakBatchError(error) {
  const errorCode = error.response?.data?.message?.error_code
  if (errorCode === 'app/invalid/actor_id') {
    Vue.notify({
      group: 'main',
      type: 'error',
      text: i18n.t('character_not_available'),
      duration: 6000,
    })
    return true
  }

  if (errorCode === 'auth/invalid/permission') {
    Vue.notify({
      group: 'main',
      type: 'error',
      text: i18n.t('speak_batch_post.auth/invalid/permission'),
    })
    return true
  }

  if (errorCode === 'app/invalid/style_label') {
    Vue.notify({
      group: 'main',
      type: 'error',
      text: i18n.t('speak_batch_post.app/invalid/style_label'),
    })
    return true
  }

  return false
}

/**
 * QueryCache Getter
 * @name Getters
 * @getter {Array} actorFilters=actorFilters 액터 검색용 필터
 * @getter {Object} queries=queries tiptap 에디터 document 파싱한 값
 * @getter {Object} queryCacheItems=queryCacheItems 캐싱한 쿼리
 * @getter {Array} playBufferItems=playBufferItems 재생 중인 Buffer
 * @getter {Array} downloadBufferItems=downloadBufferItems 다운로드 버퍼
 * @getter {Object} downloadWay=downloadWay 다운로드 방식
 * @getter {Boolean} downloadError=downloadError 다운로드 중 에러 발생 플래그
 * @example
 * import {mapGetters} from 'vuex'
 * // in Vue computed
 * {
 *  ...
 *  ...mapGetters('typecast/queryCache', ['queries'])
 * }
 * // return user information
 * console.log(this.me)
 */
const getters = {
  queries: state => state.queries,
  queryCacheItems: state => state.queryCacheItems,
  playBufferItems: state => state.playBufferItems,
  downloadBufferItems: state => state.downloadBufferItems,
  downloadWay: state => state.downloadWay,
  downloadError: state => state.downloadError,
  hd2Progress: state => state.hd2Progress,
  currentPlayBufferItem: state => state.currentPlayBufferItem,
  tooltipQueryId: state => state.tooltipQueryId,
  pasteStart: state => state.pasteStart,
  styleLabelVersionList: state => state.styleLabelVersionList,
  isMixDownload: (state, _, rootState) =>
    !!rootState.typecast.videoEditor.timelineAssetLists.bgm?.length &&
    state.downloadWay.mergeDown &&
    !state.downloadWay.selectDownload,
  durationPerParagraph(state, getters, rootState) {
    return getDurationPerParagraph({
      queries: state.queries,
      queryCacheItems: state.queryCacheItems,
      candidateActor: rootState.typecast.actor.candidateActor,
      styleLabelVersionList: state.styleLabelVersionList,
      isEstimation: true,
    })
  },
  totalDuration(state, getters) {
    return getters.durationPerParagraph.reduce((a, b) => a + b, 0)
  },
  realTotalDuration(state, getters, rootState) {
    const durationList = getDurationPerParagraph({
      queries: state.queries,
      queryCacheItems: state.queryCacheItems,
      candidateActor: rootState.typecast.actor.candidateActor,
      styleLabelVersionList: state.styleLabelVersionList,
      isEstimation: false,
    })
    return durationList.reduce((a, b) => a + b, 0)
  },
  totalTransitionDuration(_, getters) {
    const videoDomStore = useVideoDomStore()
    const {slideList} = videoDomStore
    return getters.durationPerParagraph
      .map((_, index) => {
        const isFirstOrLast = !index || index === getters.durationPerParagraph.length - 1
        return AnimationSceneItem.getTransitionDuration(slideList[index].transition, isFirstOrLast) * 2
      })
      .reduce((a, b) => a + b, 0)
  },

  isCompleteAudioLoad(state) {
    const queryHashList = Object.values(state.queries).map(query =>
      createQueryHash({...query, styleVersion: state.styleLabelVersionList[query.actor]}),
    )
    const targetBufferItems = state.downloadBufferItems.filter(item => queryHashList.includes(item.hash))
    const unloadedQuery = targetBufferItems.find(item => !item?.speak)
    const matchLength = queryHashList.length === targetBufferItems.length
    return matchLength && !unloadedQuery
  },
}

// actions
const actions = {
  /**
   * tiptap document 파싱
   * @name Actions
   * @function parseTiptap
   * @example
   * import {mapActions} from 'vuex'
   * // in Vue methods property
   * {
   *  ...
   *  ...mapActions('typecast/actor', ['parseTiptap'])
   * }
   * mounted() {
   *  this.parseTiptap(TIPTAP.DOCUMENT)
   * }
   */
  parseTiptap({commit, state}, json) {
    const queries = tiptapContentValue(json, state)
    commit('setQueries', queries)
  },

  createCache({commit, state}, {index, candidateActor, download, dontPlayFlag, targetQueries, subtitlePlayFlag}) {
    // Object.entries Guarantee Object Order
    const $http = this._vm.$typecast.$http
    const $notify = this._vm.$notify
    const queries = targetQueries || state.queries
    const cachingTarget = Object.entries(queries).slice(index, index + CONSTANTS.CACHE_STRIDE)
    for (const [queryId, query] of cachingTarget) {
      commit('setQueryStyleAndVersion', {queryId, styleAndVersion: getStyleAndVersion(query, candidateActor)})
      const hash = createQueryHash({
        ...query,
        styleVersion: state.styleLabelVersionList[query.actor],
        id: queryId,
      })
      const cachedData = state.queryCacheItems[hash]
      let hasCache = !!cachedData

      // if has cache, but cache is invalid
      if (hasCache && isInvalidAudioCache(cachedData)) {
        commit('removeQueryCacheItem', hash)
        hasCache = false
      }

      if (!hasCache) {
        const cacheItem = {hash, queryId, speak: null, audio: null}
        commit('addQueryCacheItem', cacheItem)
        commit(download ? 'pushDownloadBufferItem' : 'pushPlayBufferItem', cacheItem)
      } else {
        if (!cachedData.queryId) {
          commit('updateQueryCacheItem', {hash, obj: {queryId}})
        }
        if (cachedData.audio && !cachedData.audio.quality) {
          commit('updateQueryCacheItem', {
            hash,
            obj: {audio: {...cachedData.audio, quality: CONSTANTS.PREVIEW_QUALITY.NORMAL}},
          })
        }
        const bufferItem = {
          hash,
          queryId,
          speak: cachedData.speak,
          audio: cachedData.audio,
        }

        commit(download ? 'pushDownloadBufferItem' : 'pushPlayBufferItem', bufferItem)
        loadAudio(
          $http,
          $notify,
          state,
          bufferItem.speak.audio,
          bufferItem,
          commit,
          download,
          dontPlayFlag,
          subtitlePlayFlag,
        )
      }
    }
  },

  async batchAndUpdate({rootState, commit, state}, batchOptions = {}) {
    const index = batchOptions.index || 0
    const download = batchOptions.download || false
    const $http = this._vm.$typecast.$http
    const $notify = this._vm.$notify
    const dontPlayFlag = batchOptions.dontPlayFlag || false
    // filter only non cached item.
    const playBufferChunk = download
      ? state.downloadBufferItems.slice(index, index + CONSTANTS.CACHE_STRIDE).filter(item => !item.speak)
      : state.playBufferItems.slice(index, index + CONSTANTS.CACHE_STRIDE).filter(item => !item.speak)
    const batchParam = playBufferChunk.map(item => {
      commit('updateQuery', {id: item.queryId, obj: {status: CONSTANTS.QUERY_CLASS_PROGRESS}})
      const query = state.queries[state.queryCacheItems[item.hash].queryId]
      return createAudioParamsByQuery(query)
    })

    const isOnlySpecialCharacterErrorParam = batchParam.find(batchItem => isOnlySpecialCharacter(batchItem.text))
    if (isOnlySpecialCharacterErrorParam) {
      setBeforeCreateAudioError({
        errorBatchItem: isOnlySpecialCharacterErrorParam,
        batchList: batchParam,
        errorType: BEFORE_CREATE_AUDIO_ERROR.ONLY_SPECIAL_CHARACTER_ERROR,
        $notify,
        commit,
      })
      commit('resetPlayBuffer')
      throw new Error('editor.only_special_character_error')
    }

    const languageErrorParam = batchParam.find(batchItem => !isValidEditorTextByLang(batchItem.text, batchItem.lang))
    if (languageErrorParam) {
      setBeforeCreateAudioError({
        errorBatchItem: languageErrorParam,
        batchList: batchParam,
        errorType: BEFORE_CREATE_AUDIO_ERROR.WRONG_LANGUAGE_ERROR,
        errorActor: rootState.typecast.actor.candidateActor.find(
          actor => actor.actor_id === languageErrorParam.actor_id,
        ),
        $notify,
        commit,
      })
      commit('resetPlayBuffer')
      throw new Error('editor.wrong_language_error')
    }

    const isExceedMaxLength = batchParam.find(batchItem => batchItem.text.length > CONSTANTS.MAX_QUERY_LENGTH)
    if (isExceedMaxLength) {
      setBeforeCreateAudioError({
        errorBatchItem: isExceedMaxLength,
        batchList: batchParam,
        errorType: BEFORE_CREATE_AUDIO_ERROR.EXCEED_MAX_LENGTH_ERROR,
        $notify,
        commit,
      })
      throw new Error('editor.exceed_max_length')
    }

    if (batchParam.length) {
      try {
        const batchResult = await batch($http, batchParam)
        const intervalId = createDetailBatchInterval(
          $http,
          $notify,
          state,
          batchResult.speak_urls,
          playBufferChunk,
          commit,
          download,
          dontPlayFlag,
        )
        return intervalId
      } catch (error) {
        commit('setDownloadError', true)
        batchParam.map(item => {
          commit('updateQuery', {id: item.gid, obj: {status: CONSTANTS.QUERY_CLASS_FAILED}})
        })
        commit('resetPlayBuffer')

        const isNotified = notifySpeakBatchError(error)
        if (!isNotified) {
          Vue.notify({
            group: 'main',
            type: 'error',
            text: i18n.t('speak.default_error_message'),
          })
        }
        throw new Error(error)
      }
    }
  },

  async qBatchAndUpdateAll({dispatch, state}) {
    const to = state.downloadBufferItems.length
    dispatch('qBatchAndUpdate', {from: 0, to})
  },

  async qBatchAndUpdate({commit, state}, {from, to}) {
    const $http = this._vm.$typecast.$http
    const $notify = this._vm.$notify

    const downloadBufferChunk = state.downloadBufferItems.slice(from, to).filter(item => !item.q)
    const batchParam = downloadBufferChunk.map(item => item.speak.speak_url)

    if (batchParam.length) {
      try {
        const batchResult = await qBatch($http, batchParam, state.downloadWay.quality)
        gBatchUrl = batchResult.result.url
        checkQStatus($http, $notify, batchResult, downloadBufferChunk, commit, state.downloadWay.quality)
      } catch (error) {
        // eslint-disable-next-line camelcase
        const errorCode = error.response?.data?.message?.error_code
        let errorMessage = ''
        switch (errorCode) {
          case 'auth/invalid/permission':
            errorMessage = i18n.t('speak_batch_q.auth/invalid/permission')
            break
          case 'app/status/not-done':
            errorMessage = i18n.t('speak_batch_q.app/status/not-done')
            break
          case 'app/invalid/quality':
            errorMessage = i18n.t('speak_batch_q.app/invalid/quality')
            break
          default:
            errorMessage = i18n.t('speak.default_error_message')
            break
        }
        $notify({
          group: 'main',
          type: 'error',
          text: errorMessage,
        })
        Sentry.captureException(error)
        commit('setDownloadError', true)
      }
    }
  },

  async loadAudioFromCache({commit}, queryCacheItems) {
    const $http = this._vm.$typecast.$http
    for (const [, item] of Object.entries(queryCacheItems)) {
      if (item.speak !== null) {
        const newItem = await reloadSpeakIfNoAudio($http, item)
        if (newItem) {
          commit('addQueryCacheItem', newItem)
        }
      }
    }
  },

  async cancelQBatch() {
    const $http = this._vm.$typecast.$http
    const $notify = this._vm.$notify

    if (qBatchInterval.length) {
      clearQBatchInterval()
    }
    if (gBatchUrl) {
      try {
        return await $http.delete(gBatchUrl)
      } catch (error) {
        Sentry.captureException(error)
        $notify({
          group: 'main',
          type: 'error',
          text: i18n.t('speak.q_batch_cancel'),
        })
      }
    }
  },

  async cancelAll({dispatch, commit}) {
    dispatch('cancelQBatch')
    commit('cancelBatch')
  },

  async createAllAudio({state, commit, dispatch}, {candidateActor, styleLabelVersionList}) {
    commit('setDownloadError', false)
    commit('resetPlayBuffer')
    commit('resetDownloadBuffer')
    isCreatingAllAudio = true
    shouldStopCreateAllAudio = false
    const queryLength = Object.values(state.queries).length
    const loopCount = Math.ceil(queryLength / CONSTANTS.CACHE_STRIDE)
    const indexedArray = []
    for (let i = 0; i < loopCount; i++) {
      indexedArray.push(i)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        for (const index of indexedArray) {
          if (state.downloadError) {
            dispatch('cancelCreateAllAudio')
            throw new Error('failed to generate all speak audio')
          }
          if (isCreatingAllAudio && shouldStopCreateAllAudio) {
            break
          }
          dispatch('createCache', {
            index: index * CONSTANTS.CACHE_STRIDE,
            candidateActor: candidateActor,
            dontPlayFlag: true,
            download: true,
          })
          await (async () => {
            const intervalId = await dispatch('batchAndUpdate', {
              index: index * CONSTANTS.CACHE_STRIDE,
              dontPlayFlag: true,
              download: true,
              styleLabelVersionList,
            })

            const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

            // 현재 polling중인 query chunk의 polling이 끝날때까지 기다리고, 다음 chunk으로 넘어가도록 한다.
            let isPollingRunning = true
            while (isPollingRunning) {
              if (isCreatingAllAudio && shouldStopCreateAllAudio) {
                break
              }
              isPollingRunning = batchInterval.findIndex(interval => interval === intervalId) !== -1
              await wait(300)
            }
          })()
        }
        resolve()
      } catch (error) {
        Sentry.captureException(error)
        reject(error)
      } finally {
        isCreatingAllAudio = false
      }
    })
  },

  cancelCreateAllAudio({state, commit, dispatch}) {
    shouldStopCreateAllAudio = true
    dispatch('cancelAll')
    const validQueryCacheItemMap = {}
    const queryIdsWithInvalidCache = []
    Object.entries(state.queryCacheItems).forEach(([hash, cache]) => {
      if (isInvalidAudioCache(cache)) {
        queryIdsWithInvalidCache.push(cache.queryId)
        return
      }
      validQueryCacheItemMap[hash] = cache
    })

    queryIdsWithInvalidCache.forEach(id => {
      const queryDom = document.querySelector(`span[data-query-id="${id}"]`)
      queryDom?.removeAttribute('contenteditable')
      commit('updateQuery', {id, obj: {status: null}})
    })

    /**
     * `cancelCreateAllAudio` action이 호출될 때,
     * 음성 생성을 위한 polling interval callback 함수(=== `intervalHandler`함수)가 도중 취소되면서 음성정보가 없는 캐시가 남는 현상이 발생한다.
     * 이를 valid하지 않은 캐시로 간주하고 제거한다.
     */
    commit('setQueryCacheItems', validQueryCacheItemMap)
  },

  // FIXME: refactor
  async createCurrentParaAudio({commit, dispatch}, {candidateActor, styleLabelVersionList, queries}) {
    commit('resetDownloadBuffer')
    const queryLength = Object.values(queries).length
    const loopCount = Math.ceil(queryLength / CONSTANTS.CACHE_STRIDE)
    const indexedArray = []
    for (let i = 0; i < loopCount; i++) {
      indexedArray.push(i)
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        for (const index of indexedArray) {
          await dispatch('createCache', {
            index: index * CONSTANTS.CACHE_STRIDE,
            candidateActor: candidateActor,
            dontPlayFlag: true,
            download: true,
            targetQueries: queries,
            subtitlePlayFlag: true,
          })
          await dispatch('batchAndUpdate', {
            index: index * CONSTANTS.CACHE_STRIDE,
            dontPlayFlag: true,
            download: true,
            styleLabelVersionList,
          })
        }
        resolve()
      } catch (error) {
        Sentry.captureException(error)
        reject(error)
      }
    })
  },
}

const mutations = {
  setQueries(state, queries) {
    state.queries = queries
  },
  updateQuery(state, {id, obj}) {
    const item = state.queries[id]
    const newQuery = {...item, ...obj}
    Vue.set(state.queries, id, newQuery)
  },
  addQueryCacheItem(state, item) {
    Vue.set(state.queryCacheItems, item.hash, item)
  },
  updateQueryCacheItem(state, {hash, obj}) {
    const item = state.queryCacheItems[hash]
    const newCacheItem = {...item, ...obj}
    Vue.set(state.queryCacheItems, hash, newCacheItem)
  },
  removeQueryCacheItem(state, hash) {
    Vue.delete(state.queryCacheItems, hash)
  },
  resetStateAll(state) {
    state.queryCacheItems = {}
    state.playBufferItems = []
    state.downloadBufferItems = []
  },
  pushPlayBufferItem(state, item) {
    state.playBufferItems.push(item)
  },
  updatePlayBufferItem(state, {bufferItem, obj}) {
    const index = state.playBufferItems.findIndex(item => item.hash === bufferItem.hash)
    if (index !== -1) {
      if (obj.speak) {
        bufferItem.speak = obj.speak
      }
      if (obj.audio) {
        bufferItem.audio = obj.audio
      }
      Vue.set(state.playBufferItems, index, bufferItem)
    }
  },
  removePlayBufferItemByQueryId(state, queryId) {
    const targetIndex = state.playBufferItems.findIndex(buffer => {
      return buffer.queryId === queryId
    })
    Vue.delete(state.playBufferItems, targetIndex)
  },
  removePlayBufferItem(state) {
    if (state.playBufferItems.length) {
      Vue.delete(state.playBufferItems, 0)
    }
  },
  removePlayBufferItemAll(state) {
    state.playBufferItems = []
  },
  setNextPlayBuffer(state) {
    if (state.playBufferItems.length) {
      state.currentPlayBufferItem = state.playBufferItems[0]
    } else {
      state.currentPlayBufferItem = null
    }
  },
  resetPlayBuffer(state) {
    state.playBufferItems = []
    state.currentPlayBufferItem = null
  },
  setQueryStyleAndVersion(state, {queryId, styleAndVersion}) {
    state.queries[queryId].style = styleAndVersion.style
    state.queries[queryId].actor_version = styleAndVersion.version
  },
  pushDownloadBufferItem(state, item) {
    state.downloadBufferItems.push(item)
  },
  updateDownloadBufferItem(state, {bufferItem, obj}) {
    const index = state.downloadBufferItems.findIndex(item => item.hash === bufferItem.hash)
    if (obj.speak) {
      bufferItem.speak = obj.speak
    }
    if (obj.audio) {
      bufferItem.audio = obj.audio
    }
    if (obj.q) {
      Vue.set(bufferItem, 'q', obj.q)
    }
    Vue.set(state.downloadBufferItems, index, bufferItem)
  },
  resetDownloadBuffer(state) {
    state.downloadBufferItems = []
  },
  setDownloadWay(state, downloadWay) {
    state.downloadWay = downloadWay
  },
  setDownloadError(state, error) {
    state.downloadError = error
  },
  setHd2Progress(state, value) {
    state.hd2Progress = value
  },
  setTooltipQueryId(state, id) {
    state.tooltipQueryId = id
  },
  setPasteStart(state, isPaste) {
    state.pasteStart = isPaste
  },
  setQueryCacheItems(state, items) {
    state.queryCacheItems = items
  },
  setStyleLabelVersionListRaw(state, value) {
    if (!value) {
      return
    }
    Object.entries(value).forEach(([actorId, version]) => {
      Vue.set(state.styleLabelVersionList, actorId, version)
    })
  },
  setStyleLabelVersionList(state, {actor, version}) {
    Vue.set(state.styleLabelVersionList, actor, version)
  },
  removeStyleLabelVersion(state, actor) {
    Vue.delete(state.styleLabelVersionList, actor)
  },
  setPreviewQuality(state, value) {
    state.previewQuality = value
  },
  resetQueryCacheStore(state) {
    state.currentPlayBufferItem = null
    state.downloadBufferItems = []
    state.playBufferItems = []
    state.queries = {}
    state.styleLabelVersionList = {}
    state.queryCacheItems = {}
    state.previewQuality = CONSTANTS.PREVIEW_QUALITY.HIGH
  },
  cancelBatch() {
    clearBatchInterval()
  },
}

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
}
