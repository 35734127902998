var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Scene from 'scenejs';
import { isString } from '@daybrush/utils';
export default class Media extends Scene {
    constructor(url) {
        super();
        this.url = '';
        this.isPlayMedia = false;
        this.init(url);
    }
    getMediaItem() {
        return this.mediaItem;
    }
    getVolume() {
        return this.mediaItem.get(0, 'volume');
    }
    setVolume(volume) {
        this.mediaItem.set(0, 'volume', volume);
        return this;
    }
    setPlaySpeed(playSpeed) {
        this.mediaItem.setPlaySpeed(playSpeed);
        return this;
    }
    seek(fromTime, toTime) {
        const mediaItem = this.mediaItem;
        mediaItem.set(0, 'seek', fromTime);
        mediaItem.set('100%', 'seek', toTime);
        mediaItem.setDuration(toTime - fromTime);
        return this;
    }
    setElement(element) {
        this.mediaItem.setElement(element);
        return this;
    }
    getMediaElement() {
        return this.mediaItem.getElements()[0];
    }
    getInfo() {
        const mediaItem = this.mediaItem;
        const seek = [mediaItem.get(0, 'seek'), mediaItem.get('100%', 'seek')];
        const playSpeed = mediaItem.getPlaySpeed();
        const volume = this.getVolume();
        const delay = this.getDelay();
        const info = {
            url: this.url,
            seek,
            playSpeed,
            delay,
            volume,
        };
        return info;
    }
    init(url) {
        const mediaItem = this.newItem('media');
        mediaItem.newFrame(0);
        mediaItem.newFrame(1);
        mediaItem.set(0, 'volume', 1);
        mediaItem.set(0, 'seek', 0);
        mediaItem.set('100%', 'seek', 0);
        this.mediaItem = mediaItem;
        if (isString(url)) {
            const audio = new Audio();
            audio.preload = 'none';
            audio.src = url;
            this.setElement(audio);
            this.url = url;
        }
        else {
            if (url.src) {
                this.url = url.src;
            }
            else {
                const sourceElement = url.querySelector('[src]');
                this.url = sourceElement ? sourceElement.src : '';
            }
            this.setElement(url);
        }
        let prevTime = 0;
        this.on('paused', () => {
            var _a;
            this.isPlayMedia = false;
            (_a = this.getMediaElement()) === null || _a === void 0 ? void 0 : _a.pause();
        });
        this.on('animate', (e) => __awaiter(this, void 0, void 0, function* () {
            const mediaElement = this.getMediaElement();
            let time = e.time;
            const isReversTime = prevTime > time;
            const isRunning = this.getPlayState() === 'running';
            const isReverse = this.getDirection().indexOf('reverse') > -1;
            const isPlayMedia = this.isPlayMedia;
            const frame = e.frames.media;
            const playSpeed = mediaItem.getPlaySpeed();
            const duration = this.getDuration();
            const volume = this.getVolume();
            const seek = frame.get('seek');
            prevTime = time;
            if (!isRunning || !isPlayMedia) {
                mediaElement.playbackRate = playSpeed * (isReverse ? -1 : 1);
                mediaElement.currentTime = seek;
            }
            // ===== CUSTOMIZED CODE START =====
            const THRESHOLD = 0.000001;
            const diff = duration - time;
            // if diff is less than 0.000001, time is same with duration.
            if (diff > 0 && diff < THRESHOLD) {
                time = duration;
            }
            // ===== CUSTOMIZED CODE END =====
            if (isReversTime || time <= 0 || duration <= time) {
                // end play
                if (isRunning) {
                    this.isPlayMedia = false;
                    mediaElement.pause();
                }
            }
            else {
                mediaElement.volume = volume;
                if (!isPlayMedia && isRunning) {
                    this.isPlayMedia = true;
                    mediaElement.play();
                }
            }
        }));
    }
    preloadResource() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.url.length || !this.getPromisedUrl) {
                return;
            }
            const url = yield this.getPromisedUrl();
            if (!(url === null || url === void 0 ? void 0 : url.length)) {
                return;
            }
            this.url = url;
            const mediaElement = this.getMediaElement();
            mediaElement.src = url;
            mediaElement.preload = 'auto';
        });
    }
}
