export const initCookieScript = (lang) => {
    if (lang !== 'ko' && process.env.NODE_ENV === 'production') {
        const element = document.querySelector('head');
        const script = document.createElement('script');
        if (element) {
            script.setAttribute('src', '//cdn.cookie-script.com/s/fbf9cf96145d9f207f03f905bb56127f.js');
            element.prepend(script);
        }
    }
};
