import { TypeGuards, } from '@/types/tiptap';
import { styles } from '@/types/style';
import { nanoid } from 'nanoid';
import { getValue } from '@/utils';
export class Node {
    constructor(node) {
        this.validate(node);
        this.type = node.type;
    }
    validate(node) {
        if (process.env.NODE_ENV === 'production') {
            return;
        }
        if (!TypeGuards.isNode(node) || !this.isNode(node)) {
            throw new Error();
        }
    }
}
export class Text extends Node {
    constructor(text) {
        super(text);
        this.type = 'text';
        this.text = text.text;
        this.marks = getValue(text.marks, [{ type: 'query', attrs: {} }]).map(query => Text.createQuery(query));
    }
    isNode(text) {
        return TypeGuards.isText(text);
    }
    static createQuery(query) {
        return Object.assign(Object.assign({}, query), { attrs: createAttributes(query.attrs) });
    }
}
const createAttributes = (attrs) => {
    return Object.assign(Object.assign({}, attrs), { id: getValue(attrs.id, nanoid()), style: createStyle(attrs.style), styleTag: getValue(attrs.styleTag, ''), speed: getValue(attrs.speed, 1), silence: getValue(attrs.silence, 100), preSilence: getValue(attrs.preSilence, 0), customSpeed: getCustomSpeed(attrs.customSpeed), tempo: getValue(attrs.tempo, 1), pitch: getValue(attrs.pitch, 0), lastPitch: getValue(attrs.lastPitch, null) });
};
const createStyle = (style) => {
    style = String(style);
    // parseInt는 숫자로 시작하는 문자열을 변환 시킬 수 있기 때문에 Number로 형변환을 한다.
    // 숫자를 찾더라도 지원하는 스타일의 개수를 넘어서는 숫자일 수 있기 때문에 반환하지 않고 아래에서 검사한다.
    const styleNumber = Number(style);
    if (!isNaN(styleNumber)) {
        style = `normal-${styleNumber + 1}`;
    }
    if (styles.includes(style)) {
        return style;
    }
    return 'normal-1';
};
const getCustomSpeed = (customSpeed, defaultValue = 0) => {
    if (customSpeed === null || customSpeed === undefined) {
        return defaultValue;
    }
    customSpeed = Number(customSpeed);
    if (!isNaN(customSpeed)) {
        return customSpeed;
    }
    return defaultValue;
};
export class Separator extends Node {
    constructor() {
        super({ type: 'separator' });
        this.type = 'separator';
    }
    isNode(separator) {
        return TypeGuards.isSeparator(separator);
    }
}
