import { frameToTransform } from '@/share/context';
export const moveableGroupEventHandler = ({ events, eventType, }) => events
    .map(event => {
    event.eventType = eventType;
    return event;
})
    .forEach(moveableEventHandler);
export const moveableEventHandler = (event) => {
    const { target, eventType } = event;
    const frame = target.frame;
    switch (eventType) {
        case 'scale':
        case 'scaleGroup':
            scaleHandler(event, frame);
            break;
        case 'drag':
        case 'dragGroup':
            dragHandler(event, frame);
            break;
        case 'rotate':
            rotateHandler(event, frame);
            break;
        case 'rotateGroup':
            rotateGroupHandler(event, frame);
            break;
    }
    const transform = frameToTransform(frame);
    target.style.setProperty('transform', transform);
};
const scaleHandler = ({ drag, scale }, frame) => {
    const { beforeTranslate } = drag;
    frame.scale = scale;
    frame.translate = beforeTranslate;
};
const dragHandler = ({ beforeTranslate }, frame) => {
    frame.translate = beforeTranslate;
};
const rotateHandler = ({ target, transform, rotation }, frame) => {
    target.style.setProperty('transform', transform);
    frame.rotate = rotation;
};
const rotateGroupHandler = ({ drag, rotation }, frame) => {
    const { beforeTranslate } = drag;
    frame.rotate = rotation;
    frame.translate = beforeTranslate;
};
