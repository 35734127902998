import { Slide } from '@/model';
export const addSceneItem = (sceneOrSceneItem, sceneItemList) => {
    if (sceneOrSceneItem instanceof Slide) {
        addSceneItemToScene(sceneOrSceneItem, sceneItemList);
        return;
    }
    addSceneItemToSceneItem(sceneOrSceneItem, sceneItemList);
};
const addSceneItemToScene = (targetScene, sceneItemList) => sceneItemList.forEach(sceneItem => sceneItem && targetScene.setItem(sceneItem.getId(), sceneItem));
const addSceneItemToSceneItem = (targetSceneItem, sceneItemList) => sceneItemList.forEach(sceneItem => sceneItem && targetSceneItem.set(sceneItem));
