var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from '@/config';
import { createPlayContainerVElement } from '@/render/renderPlayContainer';
import { createElement } from 'million';
import { useVideoDomStore } from 'store/editor';
import style from '!raw-loader!sass-loader!./../../styles/backend.scss';
// @ts-ignore
import store from '@/store';
import { addWatermark } from '@/share/render';
/** @public */
export const exportDOM = () => __awaiter(void 0, void 0, void 0, function* () {
    const { subtitle } = useVideoDomStore();
    const playContainerVElement = yield createPlayContainerVElement({
        isDownload: true,
    });
    const isPaidUser = store.getters['typecast/user/isPaidUser'];
    if (!isPaidUser) {
        addWatermark(playContainerVElement);
    }
    const { originWidth, originHeight } = config;
    const playContainerEl = createElement(playContainerVElement);
    playContainerEl.appendChild(createStyleElement());
    playContainerEl.appendChild(createFontFamilyLinkElement(subtitle.fontFamily));
    playContainerEl.style.setProperty('width', `${originWidth}px`);
    playContainerEl.style.setProperty('height', `${originHeight}px`);
    return playContainerEl;
});
const createStyleElement = () => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = style;
    return styleElement;
};
const createFontFamilyLinkElement = (fontFamily) => {
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = `https://fonts.googleapis.com/css2?family=${fontFamily}`;
    return linkElement;
};
