import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import { createVElementId } from '@/share/virtualDOM';
/** @public */
// TODO(Refactor): id랑 slideId가 있는데, 둘 중 하나만을 사용해야 함 (아마 slideId가 제거될 듯)
export const addSlideAt = (slideIndex, vProps) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    setDefaultVProps(vProps);
    slideList.splice(slideIndex, 0, vProps);
};
/** @public */
export const addSlide = (props) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex } = editorOptionsStore;
    if (!slideList.length) {
        return addSlideAt(0, props);
    }
    return addSlideAt(slideIndex + 1, props);
};
const setDefaultVProps = (vProps) => {
    vProps.id = createVElementId();
    vProps.type = 'slide';
    const { style } = vProps;
    if (!style) {
        vProps.style = {};
    }
};
