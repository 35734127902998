import {AUDIO_PROJECT_MEDIA_TYPE, SHORTS_PROJECT_MEDIA_TYPE} from '@/config/constants'
import {getTimelineVideoDuration} from '@/utils/timeline'
import flatten from 'lodash-es/flatten'

export default {
  isLoading: state => state.loadingCnt > 0,
  isShowCanvas: state => state.showCanvas,
  isCanvasWideMode: state => state.canvasWideMode,
  mediaType: state => (state.isShortsProject ? SHORTS_PROJECT_MEDIA_TYPE : AUDIO_PROJECT_MEDIA_TYPE),
  timelineVideoDuration: state => {
    if (!state.timelineAssetLists.video[0]) {
      return
    }

    const {totalDuration, startTrim, endTrim} = state.timelineAssetLists.video[0]
    const isTrimmed = !!startTrim || !!endTrim
    return isTrimmed ? endTrim - startTrim : totalDuration
  },
  timelineAssetTotalDuration: (state, getters, rs, rootGetters) => {
    const audioDuration = rootGetters['typecast/queryCache/totalDuration']
    // TODO: 비디오 에셋을 여러개 삽입하게 될 경우 정책 논의 후 수정 필요

    return Math.max(audioDuration, getters.lastAssetEndTime)
  },
  lastAssetEndTime: state => {
    const assetList = flatten(Object.values(state.timelineAssetLists))
    let maxEndTime = 0

    assetList.forEach(asset => {
      const endTime = asset.offset + getTimelineVideoDuration(asset)
      if (maxEndTime < endTime) {
        maxEndTime = endTime
      }
    })

    return maxEndTime
  },
}
