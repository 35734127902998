import { FPS } from 'utils/src/constants';
export const MODULE_PREFIX = 'vd__';
export const SELECTABLE_ELEMENT_CLASS_NAME = `${MODULE_PREFIX}selectable`;
export const UNSELECTABLE_ELEMENT_CLASS_NAME = `${MODULE_PREFIX}unselectable`;
export const PLAY_ID = `${MODULE_PREFIX}__play`;
export const PLAY_CONTAINER_ID = `${PLAY_ID}__container`;
export const EDIT_CONTAINER_ID = `${MODULE_PREFIX}__edit`;
// for backend
export const SLIDE_INDEX_PROPERTY_NAME = 'data-slide-index';
export const SENTENCE_INDEX_PROPERTY_NAME = 'data-sentence-index';
export const START_TRANSITION_DURATION_PROPERTY_NAME = 'data-start-transition-duration';
export const END_TRANSITION_DURATION_PROPERTY_NAME = 'data-end-transition-duration';
export const PRE_SILENCE_PROPERTY_NAME = 'data-pre-silence-duration';
export const VIDEO_UNIT_LENGTH = 1 / FPS;
export const TRANSITION_TIME = 0.48;
export const MAX_SCENE_DURATION = 60 * 30;
export const WATERMARK_MEDIA_SCENE_ID = 'watermark-audio';
export const FOR_SUBTITLE_STYLE_DATASET_NAME = 'data-subtitle-text';
