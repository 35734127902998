import axios from 'axios'
import {ConcurrencyManager} from 'axios-concurrency'
import {getAuth} from 'firebase/auth'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/vue'
import {
  URL_ASSET,
  URL_CHARACTER_LIST,
  URL_SAMPLE_BGM_LIST,
  ACTOR_URL,
  ANONYMOUS_TEMPLATE_URL,
} from '@/backend/video-api-url'

export default function (Vue, router) {
  const $http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  })
  const MAX_CONCURRENT_REQUESTS = 5
  const MAX_RETRY_REQUESTS = 5
  let isAlreadyFetchingAccessToken = false
  let subscribers = []

  // eslint-disable-next-line new-cap
  ConcurrencyManager($http, MAX_CONCURRENT_REQUESTS)

  function onAccessTokenFetched(accessToken) {
    subscribers = subscribers.filter(callback => callback(accessToken))
  }

  function addSubscriber(callback) {
    subscribers.push(callback)
  }

  function authErrorHandler(error, status, originalRequest, tag) {
    Sentry.withScope(scope => {
      scope.setTag('API', tag)
      Sentry.setExtra('status', status)
      Sentry.setExtra('url', originalRequest.url)
      Sentry.setExtra('token', originalRequest.headers.Authorization)
      Sentry.captureException(error)
    })

    // INFO: https://www.notion.so/neosapience/nextPath-24c2d3f8d561484fb17b3ef8e4f8807f
    // accessToken만료 되어서 갱신 하는중 refreshToken까지도 만료 되거나 에러가 나왔다면
    // login으로 이동 > login 이후 nextPath로 해당 위치로 다시 이동
    if (router.currentRoute.path === '/') {
      window.location.reload()
    } else {
      let nextPath = ''
      if (!location.pathname.includes('login')) {
        nextPath = location.href.replace(`${location.protocol}//${location.host}`, '')
      }
      Vue.prototype.$typecast.$auth.cleanAccessToken()
      router.replace({
        path: '/',
        query: {nextPath: nextPath},
      })
    }
    subscribers = []
  }

  /**
   * s3 url Checks
   * Test case: https://regexr.com/654pq
   */
  function s3UrlCheck(url) {
    const s3UrlRegexp = /s3(.|-)?(.*)\.amazonaws\.com/
    const XAmzAlgorithm = /X-Amz-Algorithm=/
    if (s3UrlRegexp.test(url) || XAmzAlgorithm.test(url)) {
      return true
    }
    return false
  }

  function isNoauth() {
    return !Vue.prototype.$auth.isAccessTokenExist()
  }

  function isNoauthAllowed(config) {
    const url = config.url
    const noauthAllowedUrl = [URL_CHARACTER_LIST, ACTOR_URL, URL_ASSET, URL_SAMPLE_BGM_LIST]
    return noauthAllowedUrl.some(allowedUrl => {
      if (url.includes(allowedUrl)) {
        // URL_ASSET은 query param이 없는 경우만
        if (url.includes(URL_ASSET)) {
          const urlObj = new URL(url, window.location.origin)
          return Array.from(urlObj.searchParams).length === 0
        }
        return true
      }
      return false
    })
  }

  function addNoauthPostfix(url) {
    return url + '/noauth'
  }

  $http.interceptors.request.use(
    config => {
      config.retryCount = config.retryCount || 1

      if (s3UrlCheck(config.url)) {
        delete config.headers.common.Authorization
      }

      if (isNoauth() && isNoauthAllowed(config)) {
        config.url = addNoauthPostfix(config.url)
      }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
  $http.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const {config, response = {}} = error
      const {status, data} = response
      const originalRequest = config
      originalRequest.retryCount = originalRequest.retryCount + 1

      // TODO: remove second condition after image asset api is migrated
      const isAuthExpired = data?.message?.error_code === 'auth/expired' || data?.error_code === 'typecast:401'
      if (status === 401 && isAuthExpired) {
        if (originalRequest.url.includes('/video/show/downloading')) {
          Sentry.withScope(scope => {
            scope.setTag('API', 'DOWNLOAD_401')
            Sentry.setExtra('status', status)
            Sentry.setExtra('url', originalRequest.url)
            Sentry.setExtra('token', originalRequest.headers.Authorization)
            Sentry.captureException(error)
          })
        }

        if (originalRequest.retryCount > MAX_RETRY_REQUESTS) {
          authErrorHandler(error, status, originalRequest, 'MAX_RETRY_REQUESTS')
          return Promise.reject(error)
        }

        if (!isAlreadyFetchingAccessToken) {
          if (getAuth().currentUser) {
            isAlreadyFetchingAccessToken = true
            getAuth()
              .currentUser.getIdToken(true)
              .then(idToken => {
                isAlreadyFetchingAccessToken = false
                const parse = jwt_decode(idToken)
                Vue.$typecast.$auth.proactiveAuth.setTokenExpiredAt(parse.exp * 1000)
                if (parse._id) {
                  onAccessTokenFetched(idToken)
                }
              })
              .catch(error => {
                authErrorHandler(error, status, originalRequest, 'FIREBASE_AUTH_ERROR')
                return Promise.reject(error)
              })
          } else {
            authErrorHandler(error, status, originalRequest, 'FIREBASE_CURRENT_USER_ERROR')
            return Promise.reject(error)
          }
        }
        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(idToken => {
            Vue.$typecast.$auth.setAccessToken(idToken)
            originalRequest.headers.Authorization = 'Bearer ' + idToken
            resolve($http(originalRequest))
          })
        })
        return retryOriginalRequest
      } else if (data?.message?.error_code === 'auth/not-authorized') {
        authErrorHandler(error, status, originalRequest, 'AUTH_NOT_AUTHORIZED')
        return Promise.reject(error)
      } else if (data?.message?.error_code === 'app/invalid/ip-address') {
        authErrorHandler(error, status, originalRequest, 'REQUIRES_AUTH_ROLE')
        return Promise.reject(error)
      }
      return Promise.reject(error)
    },
  )

  return $http
}
