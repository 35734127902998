<template>
  <div id="app">
    <div v-if="isDimmed" class="back-download-dimmed" />
    <notifications group="main" position="top center" class="notification main-notification" />
    <notifications
      group="custom-template"
      :duration="-1"
      animation-name="v-fade-left"
      position="top"
      class="notification custom-template-notification"
    >
      <template slot="body" slot-scope="props">
        <div class="custom-template t-body1 medium">
          <div class="custom-template-content">
            <div class="custom-template-title" v-html="props.item.title"></div>
          </div>
          <button class="custom-template-close" @click="refreshPage(props)">
            <CustomIcon icon-name="IconReset" />
            {{ $t('release.refresh') }}
          </button>
        </div>
      </template>
    </notifications>
    <notifications
      group="pending-template"
      position="top center"
      class="notification main-notification pending-notification"
      :duration="3000"
    >
      <template #body="{item: {title, text, data}, close}">
        <div class="vue-notification" :class="data.className">
          <div v-html="title"></div>
          <div class="button-wrapper">
            <div
              class="cancel-button t-body1 medium"
              @click="
                () => {
                  close()
                  $notify.callback()
                }
              "
            >
              {{ text }}
            </div>
            <button class="close-button" @click="close">
              <CustomIcon :icon-name="data.btnIconName" />
            </button>
          </div>
        </div>
      </template>
    </notifications>
    <transition name="fade" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
    <ModalContainer />
    <component v-if="hasDownloadList" :is="BackDownloadModal" />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'
import throttle from 'lodash-es/throttle'
import {setCookie} from './utils/cookieManager'
import CustomIcon from '@/components/v3/CustomIcon'
import {useBackDownloadStore, useBackDownloadUiStore} from 'store/back-download'
import {storeToRefs} from 'pinia'
import ModalContainer from './containers/modal/ModalContainer.vue'

export default {
  provide() {
    const $mixpanel = this.$mixpanel
    const $auth = this.$auth
    return {
      $mixpanel,
      $auth,
    }
  },
  components: {
    CustomIcon,
    ModalContainer,
  },
  beforeCreate() {
    // for google optimizer
    setCookie('optimize_language', this.$i18n.locale)
  },
  setup() {
    const {isBackDownloadFolded} = storeToRefs(useBackDownloadUiStore())
    const {hasDownloadList} = storeToRefs(useBackDownloadStore())
    return {
      hasDownloadList,
      isBackDownloadFolded,
    }
  },
  created() {
    if (this.$auth.isAccessTokenExist() && !this.isAnonymousPage()) {
      this.reqMe()
    }
    window.addEventListener('resize', throttle(this.checkMobile, 300))
    this.checkMobile()
  },
  mounted() {
    this.$router.onError(error => {
      if (/chunk .* failed/i.test(error.message)) {
        this.show('custom-template')
      }
    })
  },
  computed: {
    ...mapGetters('typecast/user', ['me']),
    ...mapGetters('screen', ['isPhone', 'isDesktop']),
    isDimmed() {
      return !this.isBackDownloadFolded && !this.isDesktop
    },
    BackDownloadModal() {
      //NOTE: https://www.notion.so/neosapience/Q1E3-Direct-Pinia-load-timing-error-aa5f5ba138fa47d5842821193cff860e?pvs=4
      //import 타이밍에 따라서 pinia 로딩 문제가 있음
      return () => import('./components/v3/video/modal/BackDownloadModal')
    },
  },
  watch: {
    me(newVal, oldVal) {
      if (!this.$hj) {
        return
      }
      const newValLength = Object.keys(newVal).length
      const oldValLength = Object.keys(oldVal).length
      if (newValLength === 0 || newValLength === oldValLength) {
        return
      }
      const plan = newVal.plan
      const settings = newVal.settings
      if (plan === 'undefined' || settings === 'undefined') {
        console.error(`Something went wrong with 'me'`)
        return
      }
      this.$hj('identify', newVal.uid, {
        user_plan: plan.current.name,
        user_country: settings.country,
        user_language: settings.language,
      })
    },
  },
  methods: {
    ...mapActions('typecast/user', ['reqMe']),
    ...mapMutations('screen', [
      'setIsMobile',
      'setIsPhone',
      'setIsTablet',
      'setIsDesktop',
      'setIsDesktopSmall',
      'setIsDesktopLarge',
      'setIsDesktopXLarge',
    ]),
    checkMobile() {
      this.setIsMobile(window.matchMedia('(max-width: 768px)').matches)
      this.setIsPhone(window.matchMedia('(max-width: 767px)').matches)
      this.setIsTablet(window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches)
      this.setIsDesktop(window.matchMedia('(min-width: 1024px)').matches)
      this.setIsDesktopSmall(window.matchMedia('(min-width: 1024px) and (max-width: 1439px)').matches)
      this.setIsDesktopLarge(window.matchMedia('(min-width: 1280px)').matches)
      this.setIsDesktopXLarge(window.matchMedia('(min-width: 1440px)').matches)
    },
    show(group) {
      this.clean(group)
      const msg = this.isPhone
        ? this.$t('release.update-notification')
        : this.$t('release.update-notification').replace('<br/>', ' ')
      this.$notify({
        group,
        title: msg,
      })
    },
    clean(group) {
      this.$notify({group, clean: true})
    },
    refreshPage(props) {
      props.close()
      window.location.reload()
    },
    isAnonymousPage() {
      const href = window.location.href
      return href.indexOf('anonymous-audio-editor') > -1
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile)
  },
}
</script>

<style lang="scss">
html {
  -ms-overflow-style: -ms-autohiding-scrollbar;

  body {
    background: $t-white;
  }
}
body {
  margin: 0;
  padding: 0;

  a {
    &,
    &:hover {
      color: #ff8400;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

#app {
  font-family: 'Noto Sans KR', 'Noto Sans JP', 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: initial;
  text-align: center;
  color: #404040;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#mouse-floating-tooltip {
  background-color: #262626;
  border-radius: 0.5rem;
  color: $t-white;
  font-size: 14px;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0;
  line-height: 1.2512rem;
  opacity: 0.9;
  visibility: hidden;
  z-index: 100;
}

nav {
  div {
    a,
    a:hover,
    a:focus {
      color: $t-primary-500;
      text-decoration: none;
    }
  }

  .tab,
  .tab:hover,
  .tab:focus {
    text-decoration: none;
    &:first-child,
    &:hover,
    &:focus {
      &.router-link-active {
        font-weight: normal !important;
      }
      &.router-link-exact-active {
        font-weight: bold !important;
      }
    }
    &.router-link-active {
      font-weight: bold !important;
    }
  }
}

nav {
  .section,
  .section:hover,
  .section:focus {
    text-decoration: none;
    font-size: 14px;
    font-weight: normal;
    color: #434451;
    margin-right: 0;

    &:nth-last-child(1) {
      margin-right: 0;
    }
    &.router-link-active {
      font-weight: bold;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.user-name > button {
  font-size: 16px;
  color: $t-black !important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  font-weight: 500;
  pointer-events: none;

  &.no-break {
    .typecast-tooltip-inner {
      max-width: initial;
    }
  }

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 0.5rem 1rem;
    line-height: 1.2512rem;

    sub {
      line-height: 1.0725rem;
      bottom: 0;
    }
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #c2c3c8;
    border: unset;
    font-family: 'Noto Sans KR', 'Avenir', Helvetica, Arial, sans-serif;

    .popover-inner {
      width: 200px;
      background: $color;
      padding: 7px 10px;
      border-radius: 5px;
      box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .popover-arrow {
      display: none;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

html,
body,
#app {
  height: 100%;
}

.custom-template {
  background-color: $t-grey-900;
  border-radius: 0.75rem;
  box-shadow: 0px 2px 4px 0px #00000029;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  opacity: 0.98;
  padding: 1rem 1.5rem;
  margin-top: 5.375rem;
  min-width: 30rem;

  &-title {
    color: $t-white;
    text-align: left;
  }

  &-close {
    color: $t-primary-500;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    text-align: right;
    justify-content: flex-end;
  }
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}

.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateY(-500px) scale(0.2);
}

.notification {
  &.vue-notification-group {
    min-width: 20rem;
    width: fit-content !important;
    max-width: 100%;
    &.custom-template-notification {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.main-notification {
    top: 5rem !important;
    left: 50% !important;
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
      width: calc(100vw - 16px) !important;
    }
  }

  .vue-notification {
    padding: 1rem;
    font-size: 1rem;
    color: white;
    border-radius: 12px;
    background: $t-grey-900;
    border-left-color: transparent;

    &.success {
      background: $t-grey-900;
      border-left-color: transparent;

      .notification-title {
        word-wrap: break-word;
      }
    }

    &.warn,
    &.error {
      background: $t-danger;
      border-left-color: transparent;
    }
  }
}

.pending-notification {
  .vue-notification {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 0.5rem;
    .button-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
    .cancel-button {
      color: $t-primary-500;
      cursor: pointer;
    }
    &.delete {
      .cancel-button {
        padding-right: 0.25rem;
      }
      .close-button {
        padding-left: 0.25rem;

        .icon {
          width: 0.875rem;
          height: 0.875rem;
          margin: 0 0.3125rem;
        }
      }
    }
    &.feature {
      .close-button {
        padding: 0;
        .icon {
          path {
            fill: $t-primary-500;
          }
        }
      }
    }
  }
}

@include media('<tablet') {
  .custom-template {
    min-width: unset;
    margin-top: 4rem;
  }
}

.back-download-dimmed {
  position: fixed;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0.5;
}
</style>
