import { resetTargets } from '@/context';
import { emitEvent } from '@/emitEvent';
import { useVideoDomStore } from 'store/editor';
/** @public */
export const removeElement = (targetId) => {
    const { childIndex, slideVProps } = getChildIndexAndSlideVProps(targetId);
    const { children: targetSlideChildren } = slideVProps;
    targetSlideChildren === null || targetSlideChildren === void 0 ? void 0 : targetSlideChildren.splice(childIndex, 1);
    resetTargets();
    emitEvent('update-element');
};
const getChildIndexAndSlideVProps = (targetId) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    for (const slide of slideList) {
        const children = slide.children;
        const childIndex = children.findIndex(({ id }) => id === targetId);
        if (childIndex !== -1) {
            return { childIndex, slideVProps: slide };
        }
    }
    throw new Error(`Cannot find target element with id: ${targetId}`);
};
