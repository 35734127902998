export default {
  isProjectInitialized: false,
  loadingCnt: 0,
  requestUpdateCnt: 0,
  autoPlay: false,
  isEditorFocus: false,
  isBlockEditorUpdate: false,
  isForceRoute: false,
  imageAssetList: [],
  imageAssetTotalCount: 0,
  assetList: [],
  assetTotalCount: 0,
  timelineAssetLists: {video: [], bgm: []},
  cachedAssetUrls: {},
  cachedTimelineAssetLists: {video: [], bgm: []},
  isTimelineOpen: false,
  projectUid: '',
  copiedSlide: null,
  showCanvas: false,
  canvasWideMode: false,
  lastDisplaySubtitle: '',
  isShortsProject: false,
  isProjectLoading: true,
}
