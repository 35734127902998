import backend from '@/backend/backend-api'
import * as Sentry from '@sentry/vue'
import debounce from 'lodash-es/debounce'
import isEqual from 'lodash-es/isEqual'
import UAParser from 'ua-parser-js'
import {datadogRum} from '@datadog/browser-rum'
import {getCookie} from '@/utils/cookieManager'
import store from '@/store'
import CONSTANTS from '@/config/constants'

/**
 * @module typecast/store/user
 */
/**
 * User 정보 store state
 * @name State
 * @type {object}
 * @property {Object} me 사용자 정보 객체
 */
const state = {
  me: {},
  myMetrics: {},
  socialType: null,
  groupInfo: {},
  creditRemaining: null,
  creditUsed: null,
  creditTotal: null,
  inactivation: false,
  isDisableGapi: false,
  featureNews: [],
  numOfFeatureNews: 0,
  showCouponNudge: false,
  loadGrowthbookFeatures: false,
}
/**
 * User 정보 Getter
 * @name Getters
 * @type {object}
 * @getter {Object} me=me 사용자 정보
 * @example
 * import {mapGetters} from 'vuex'
 * // in Vue computed
 * {
 *  ...
 *  ...mapGetters('typecast/user', ['me'])
 * }
 * // return user information
 * console.log(this.me)
 */
const getters = {
  me: state => state.me,
  myMetrics: state => state.myMetrics,
  uid: state => state.me.uid,
  socialType: state => state.socialType,
  groupInfo: state => state.groupInfo,
  creditRemaining: state => state.creditRemaining,
  inactivation: state => state.inactivation,
  hasMe: state => Object.keys(state.me).length !== 0,
  isGroupUser: state => state.me.group?.active,
  isGroupMember(state) {
    const isActive = state.me.group?.active
    const roles = state.me.group?.roles || []

    return isActive && roles.includes('member')
  },
  isGroupAdmin(state) {
    const isActive = state.me.group?.active
    const roles = state.me.group?.roles
    return isActive && roles.includes('admin')
  },
  planName: (state, getters) => (getters.hasMe ? state.me.plan.current.name : null),
  isBasicUser: state => !!(Object.keys(state.me).length && state.me.plan.current.name === 'basic_id'),
  isPaidUser: state => !!(Object.keys(state.me).length && state.me.plan.current.name !== 'free_id'),
  isFreeUser: state => !!(Object.keys(state.me).length && state.me.plan.current.name === 'free_id'),
  isBusinessUser: state => !!(Object.keys(state.me).length && state.me.plan.current.name === 'business_id'),
  isProUser: state => !!(Object.keys(state.me).length && state.me.plan.current.name === 'pro_id'),
  isEnterprise: (state, getters) => getters.hasMe && state.me.plan.enterprise,
  isAppPaidUser: state => state.me.payment_info?.pg === CONSTANTS.PAYMENT_METHOD.GOOGLE,
  isVideoUser: state => state.me.settings?.paid.activation.video,
  isStripeUser: state => state.me.payment_info?.pg === CONSTANTS.PAYMENT_METHOD.STRIPE,
  isUserSetByAdmin: state => state.me.plan?.prepaid || state.me.plan?.enterprise,
  // eslint-disable-next-line camelcase
  isRefundedUser: state => !state.me.plan?.re_subscribable,
  willBeFreeUser: state => state.me.subscription_canceled,
  isFHDUser: state => state.me.settings?.paid.activation.video_fhd,
  isHD1User: state =>
    !!(Object.keys(state.me).length && state.me.roles && state.me.roles.includes('paid::download::hd1')),
  isHD2User: (state, getters) => getters.hasMe && state.me.roles && state.me.roles.includes('paid::download::hd2'),
  isForceHighQualityUser: (state, getters) =>
    getters.hasMe && state.me.roles && state.me.roles.includes('paid::qa-high-quality'),
  isImportUser: (state, getters) => getters.hasMe && state.me.settings.paid.activation.import,
  isCommentUser: (state, getters) => getters.hasMe && state.me.settings.paid.activation.comment,
  isSpeakControl: (state, getters) => getters.hasMe && state.me.settings.paid.activation.speak_control,
  audioQuality: (state, getters) =>
    getters.hasMe
      ? state.me.settings.paid.activation.audio_quality
      : CONSTANTS.AUDIO_DOWNLOAD_CONFIG.AUDIO_QUALITY.NORMAL,
  videoQuality: (state, getters) => getters.hasMe && state.me.settings.paid.activation.video_quality,
  userCountryCode: state => state.me.settings?.country,
  paymentCountryCode: state => {
    if (state.me.payment_info?.pg) {
      return state.me.payment_info.pg === CONSTANTS.PAYMENT_METHOD.STRIPE ? 'us' : 'kr'
    }
    return state.me.settings?.country === 'kr' ? 'kr' : 'us'
  },
  paymentCurrency: state => {
    if (state.me.payment_info?.pg) {
      return state.me.payment_info.pg === CONSTANTS.PAYMENT_METHOD.STRIPE ? 'usd' : 'krw'
    }
    return state.me.settings?.country === 'kr' ? 'krw' : 'usd'
  },
  isPaymentFailure: state => !!(Object.keys(state.me).length && state.me.plan?.failure.error_code),
  myPaymentPeriod: state => state.me.plan.current.period,
  isReservedVolumeDown: state =>
    !!(
      state.me.plan.downgrade.volume.seconds.total_seconds_be_reduced ||
      state.me.plan.downgrade.volume.member.quantity_be_reduced ||
      state.me.plan.downgrade.volume.custom_actor_limit.quantity_be_reduced
    ),
  isReservedVolumeUp: state =>
    !!(
      state.me.plan.upgrade.volume.seconds.total_seconds ||
      state.me.plan.upgrade.volume.member.quantity ||
      state.me.plan.upgrade.volume.custom_actor_limit.quantity
    ),
  memberRemaining: state => state.me.group.member_limit - state.me.group.used_members,
  memberUsed: state => state.me.group.used_members,
  memberTotal: state => state.me.group.member_limit,
  isNewVisitor: state => state.me.settings?.is_new_visitor,
  myCouponId: state => state.me.plan.current.coupon_id,
  isMakerUser: state => state.me.settings?.paid.activation.custom_actor_limit > 0,
  slotTotal: state => state.me.settings?.paid.activation.custom_actor_limit,
  slotUsed: state => state.me.maker_actor_list.length,
}

function reqMe({dispatch, commit, state, getters}, isForce) {
  if (!isForce && Object.keys(state.me).length > 0) {
    return
  }
  const $http = this._vm.$typecast.$http
  const $auth = this._vm.$typecast.$auth
  return backend
    .me($http)
    .then(data => {
      if ($auth.isAccessTokenExist()) {
        commit('setMe', data)
        if (getters.uid !== null) {
          dispatch('setUidToSentry')
          dispatch('setUserIdToGtag')
          dispatch('setMixpanelIdentify')
          dispatch('setMixpanelBaseData')
          dispatch('setUserDataToGrowthbook')
          dispatch('setUidToDatadog', data)
          store.dispatch('typecast/actor/getUserActorMeta')
        }
        return data
      } else {
        commit('setMe', {})
        dispatch('setUidToSentry', null)
        dispatch('resetUserIdToGtag')
        dispatch('resetUserDataToGrowthbook')
        dispatch('resetUidToDatadog')
      }
    })
    .catch(error => {
      console.error(error)
      commit('setMe', {})
      dispatch('setUidToSentry', null)
      dispatch('resetUserIdToGtag')
      dispatch('resetUserDataToGrowthbook')
      dispatch('resetUidToDatadog')
    })
}
// actions
const actions = {
  /**
   * User 정보 요청 Actions
   * @name Actions
   * @function reqMe
   * @returns {void}
   * @example
   * import {mapActions} from 'vuex'
   * // in Vue methods property
   * {
   *  ...
   *  ...mapActions('typecast/user', ['reqMe'])
   * }
   * // request user information
   * this.reqMe()
   */
  reqMe: debounce(reqMe, 300, {
    leading: true,
    trailing: false,
  }),

  reqMyMetrics({commit}) {
    const $http = this._vm.$typecast.$http
    backend
      .myMetrics($http)
      .then(data => {
        commit('myMetrics', data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  setUidToSentry({getters}) {
    Sentry.configureScope(function (scope) {
      if (scope.getUser()?.id === getters.uid) {
        return
      }
      if (getters.uid === null) {
        scope.setUser(null)
      } else {
        scope.setUser({id: getters.uid})
      }
    })
  },
  setUidToDatadog({state, getters}) {
    if (!getters.planName) {
      return
    }
    if (datadogRum.getUser()?.id === getters.uid) {
      return
    }
    datadogRum.setUser({
      id: getters.uid,
      name: state.me.fullname,
      email: state.me.email,
      plan: getters.planName,
    })
  },
  setUserDataToGrowthbook({state, getters}) {
    if (this._vm.$auth.isAnonymous()) {
      return
    }
    const $growthbook = this._vm.$growthbook
    if ($growthbook.getAttributes().uid === getters.uid) {
      return
    }
    const parser = new UAParser()
    const browser = parser.getBrowser()
    const device = parser.getDevice()
    $growthbook.setAttributes({
      uid: getters.uid,
      device: device.type,
      country: state.me.settings.country,
      browser: `${browser.name} ${browser.version}`,
    })
  },
  setUserIdToGtag({getters}) {
    if (!getters.planName) {
      return
    }
    const $gtag = this._vm.$gtag
    let userProperties

    if (getters.me.anonymous) {
      userProperties = {
        user_properties: {
          anonymous_user_id: getters.uid,
        },
      }
    } else {
      userProperties = {
        user_id: getters.uid,
        user_properties: {
          plan: getters.planName,
        },
      }
    }

    $gtag.set(userProperties)
    $gtag.config(userProperties)
  },
  setMixpanelIdentify({getters}) {
    const {$mixpanel, $mixpanelDatahouse} = this._vm
    const prevUid = $mixpanel.getIdentify()
    if (getters.uid === prevUid) {
      return
    }
    $mixpanel.setIdentify($mixpanelDatahouse.getIdentifier())
  },
  setMixpanelBaseData({getters}) {
    if (!getters.planName) {
      return
    }
    const {$mixpanel, $mixpanelDatahouse} = this._vm
    const oldUserData = $mixpanel.getUserData()
    const newUserData = $mixpanelDatahouse.getUserData()
    if (!isEqual(oldUserData, newUserData)) {
      $mixpanel.setUserData(newUserData)
    }
    const oldSuperProperty = $mixpanel.getSuperProperties()
    const newSuperProperty = $mixpanelDatahouse.getSuperProperties()
    if (!isEqual(oldSuperProperty, newSuperProperty)) {
      $mixpanel.setSuperProperties(newSuperProperty)
    }
  },
  resetUserIdToGtag() {
    const $gtag = this._vm.$gtag
    const resetUserProperties = {
      user_id: null,
      user_properties: {
        plan: null,
      },
    }
    $gtag.set(resetUserProperties)
    $gtag.config(resetUserProperties)
  },
  resetUserDataToGrowthbook() {
    const $growthbook = this._vm.$growthbook
    $growthbook.setAttributes({uid: null, device: null, country: null, browser: null})
  },
  resetUidToDatadog() {
    datadogRum.removeUser()
  },
  async reqGroupInfo({commit, state}) {
    const $http = this._vm.$typecast.$http
    const groupId = state.me.group.admin_id
    try {
      if (groupId) {
        const response = await backend.getGroupInfo($http, groupId)
        commit('setGroupInfo', response)
      }
    } catch (error) {
      commit('setGroupInfo', {error})
      console.error(error)
    }
  },
  async getUsageInfo({commit, state}) {
    const $http = this._vm.$typecast.$http
    if (!state.me.uid) return

    try {
      const result = await backend.getUserDownload($http, 1)
      const {
        total: {used_seconds: used, total_seconds: total, remain_seconds: remaining},
      } = result.extra

      commit('setUsageInfo', {remaining, used, total})
    } catch (error) {
      console.error(error)
    }
  },
  async putIsNewVisitorToFalse({commit, state, getters}) {
    if (!getters.isNewVisitor) {
      return
    }
    try {
      const newMe = {...state.me, settings: {...state.me.settings, is_new_visitor: false}}
      commit('setMe', newMe)
      const $http = this._vm.$typecast.$http
      await backend.putMe($http, newMe)
    } catch (error) {
      console.error(error)
    }
  },
}
const mutations = {
  setMe(state, data) {
    state.me = data
  },
  setLoadGrowthbookFeatures(state, value) {
    state.loadGrowthbookFeatures = value
  },
  myMetrics(state, data) {
    state.myMetrics = data
  },
  setSocialType(state, social) {
    state.socialType = social
  },
  setGroupInfo(state, data) {
    state.groupInfo = data
  },
  setUsageInfo(state, {remaining, used, total}) {
    state.creditRemaining = remaining
    state.creditUsed = used
    state.creditTotal = total
  },
  setPaidFeature(state, values) {
    const keys = Object.keys(values)
    keys.forEach(key => {
      state.me.settings.paid.activation[key] = values[key]
    })
  },
  setInactivation(state, value) {
    state.inactivation = value
  },
  setIsDisableGapi(state, value) {
    state.isDisableGapi = value
  },
  setNumOfFeatureNews(state, value) {
    state.numOfFeatureNews = value
  },
  setFeatureNews(state, value) {
    state.featureNews = value
  },
  setShowCouponNudge(state, value) {
    const couponItem = store.state.payment.couponItem
    const oldCoupon = getCookie(CONSTANTS.COOKIE_KEY_COUPON.CLOSE_COUPON)
    if (oldCoupon && isEqual(JSON.parse(oldCoupon), couponItem)) {
      state.showCouponNudge = false
      return
    }

    if (!value) {
      state.showCouponNudge = false
      return
    }

    state.showCouponNudge = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
