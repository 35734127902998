import { MAX_SCENE_DURATION } from '@/constants';
export const getCorrectedTimelineMediaAsset = (timelineAsset) => {
    var _a, _b;
    const clonedTimelineAsset = Object.assign(Object.assign({}, timelineAsset), { endTrim: (_a = timelineAsset.endTrim) !== null && _a !== void 0 ? _a : timelineAsset.totalDuration, startTrim: (_b = timelineAsset.startTrim) !== null && _b !== void 0 ? _b : 0 });
    const isExceededMaxSceneDuration = clonedTimelineAsset.offset >= MAX_SCENE_DURATION;
    if (isExceededMaxSceneDuration) {
        clonedTimelineAsset.startTrim = 0;
        clonedTimelineAsset.endTrim = 0;
        clonedTimelineAsset.offset = MAX_SCENE_DURATION;
        return clonedTimelineAsset;
    }
    const duration = clonedTimelineAsset.endTrim - clonedTimelineAsset.startTrim;
    const isSpansMaxSceneDuration = duration + clonedTimelineAsset.offset > MAX_SCENE_DURATION;
    if (isSpansMaxSceneDuration) {
        const newEndTrim = MAX_SCENE_DURATION - clonedTimelineAsset.offset;
        clonedTimelineAsset.endTrim = newEndTrim;
    }
    return clonedTimelineAsset;
};
