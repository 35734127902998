import { defineComponent } from 'vue';
import Icons from '@/assets/icons';
const __default__ = defineComponent({
    props: {
        iconName: {
            type: String,
            required: true,
        },
        styleObj: {
            type: Object,
            default: () => ({}),
        },
        fillColor: {
            type: String,
            default: '',
        },
    },
    computed: {
        componentName() {
            return Icons[this.iconName];
        },
    },
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars((_vm, _setup) => ({
        "680565be": (_vm.fillColor)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
