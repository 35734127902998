import CONSTANTS from '@/config/constants'
import * as Sentry from '@sentry/vue'

export default function ({actor, text, silence = CONSTANTS.DEFAULT_SILENCE_SEC, tempo = 1, customSpeed}) {
  try {
    const korCount = text.match(CONSTANTS.KOR_REGEXR)?.length
    const jpnCount = text.match(CONSTANTS.JPN_REGEXR)?.length
    const engEspDeCount = text.match(CONSTANTS.ENG_ESP_DE_REGEXR)?.length
    const numberCount = text.match(CONSTANTS.NUMBER_REGEXR)?.length
    const speedParams = actor.speed_params

    let sum = speedParams.intercept
    if (customSpeed > 0) {
      sum = customSpeed
    } else {
      if (korCount) {
        sum += korCount * speedParams.han
      }
      if (jpnCount) {
        sum += jpnCount * speedParams.han
      }
      if (engEspDeCount) {
        sum += engEspDeCount * speedParams.eng
      }
      if (numberCount) {
        sum += numberCount * speedParams.digit
      }
      if (tempo !== 1) {
        sum /= tempo
      }
      sum *= 1.2 // 데이터 보정: 인위적으로 1.2 늘려줌
    }

    sum += silence / 1000
    return sum
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('ERROR', 'ESTIMATE_DURATION_TIME')
      Sentry.setExtra('actor', actor)
      Sentry.setExtra('text', text)
      Sentry.setExtra('silence', silence)
      Sentry.setExtra('tempo', tempo)
      Sentry.captureException(error)
    })
    return 0
  }
}
