import {Plugin, Extension} from 'tiptap'
import {DecorationSet, Decoration} from 'prosemirror-view'
import store from '@/store'

export default class Focus extends Extension {
  get name() {
    return 'focus'
  }

  get defaultOptions() {
    return {
      className: 'has-focus',
      nested: false,
    }
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          decorations: ({doc, selection}) => {
            const {anchor, $anchor} = selection
            const isTextNodeActive = $anchor.nodeBefore?.isText || $anchor.nodeAfter?.isText
            const decorations = []
            let currentQueryText = ''
            let currentTextNodeMarkAttrs = {}
            if (isTextNodeActive) {
              let from = anchor
              let to = from

              if ($anchor.nodeBefore?.isText) {
                currentQueryText = $anchor.nodeBefore.text
                from = anchor - $anchor.nodeBefore.nodeSize
                if ($anchor.nodeBefore.marks[0]?.attrs) {
                  currentTextNodeMarkAttrs = $anchor.nodeBefore.marks[0].attrs
                }
              }

              if ($anchor.nodeAfter?.isText) {
                currentQueryText += $anchor.nodeAfter.text
                to = anchor + $anchor.nodeAfter.nodeSize
                if ($anchor.nodeAfter.marks[0]?.attrs) {
                  currentTextNodeMarkAttrs = $anchor.nodeAfter.marks[0].attrs
                }
              }

              const decoration = Decoration.node(from, to, {
                class: this.options.className,
              })
              decorations.push(decoration)
            }

            const currentParagraphId = $anchor.parent.attrs?.id || ''
            const currentActorId = $anchor.parent.attrs?.actor || ''
            currentTextNodeMarkAttrs = {...currentTextNodeMarkAttrs, actorId: currentActorId}

            /* FIXME: currentQuery 정보에 다 때려 넣어도 되지 않을까 함
             * currentQuery {
               paragraphId, 
               actorId,
               queryId,
               attrs,
             }
             */
            store.commit('typecast/editor/setCurrentParagraphId', currentParagraphId)
            store.commit('typecast/editor/setCurrentActorId', currentActorId)
            store.commit('typecast/editor/setCurrentQueryText', currentQueryText)
            store.commit('typecast/editor/setCurrentTextNodeMarkAttrs', currentTextNodeMarkAttrs)

            if (decorations.length) {
              return DecorationSet.create(doc, decorations)
            }
          },
        },
      }),
    ]
  }
}
