import * as TypeGuards from './ProjectV0.guard';
import Project from '@/projects/Project';
import ProjectV3 from '@/projects/v3/ProjectV3';
import { getValue } from '@/utils';
export default class ProjectV0 extends Project {
    constructor(project) {
        super(project);
        this.version = 0;
        this.query_list = project.query_list.map(query => (Object.assign(Object.assign({}, query), { style_idx: getValue(Number(query.style_idx), 0), speak_list: getValue(query.speak_list, [])
                .filter(speak => speak)
                .map(speak => createSpeak(speak, query.speed_x, query.style_cluster_idx)), silence_sec: getValue(query.silence_sec, 0.1) * 1000 })));
        this.style_list = project.style_list;
    }
    isProject(project) {
        return TypeGuards.isProjectV0(project);
    }
    migrate() {
        const paragraph = this.query_list.map(query => {
            var _a;
            return createParagraph((_a = this.style_list[query.style_idx]) === null || _a === void 0 ? void 0 : _a.actor_id, query);
        });
        return new ProjectV3(Object.assign(Object.assign({}, this), { version: 3, v3: {
                tiptap: {
                    type: 'doc',
                    content: paragraph,
                },
            } })).migrate();
    }
}
const createSpeak = (speak, defaultSpeedX, defaultStyleClusterIndex) => {
    defaultSpeedX = getValue(defaultSpeedX, 1);
    defaultStyleClusterIndex = getValue(defaultStyleClusterIndex, 0);
    if (typeof speak === 'string') {
        return {
            speak_url: speak,
            speed_x: defaultSpeedX,
            style_cluster_idx: defaultStyleClusterIndex,
        };
    }
    return {
        speak_url: getValue(speak === null || speak === void 0 ? void 0 : speak.speak_url, ''),
        speed_x: getValue(speak === null || speak === void 0 ? void 0 : speak.speed_x, defaultSpeedX),
        style_cluster_idx: getValue(speak === null || speak === void 0 ? void 0 : speak.style_cluster_idx, defaultStyleClusterIndex),
    };
};
const createParagraph = (actor, query) => {
    if (!actor) {
        query.style_idx = 0;
    }
    return {
        type: 'paragraph',
        attrs: {
            actor,
        },
        content: [
            {
                type: 'text',
                text: query.text,
                marks: [createQuery(query)],
            },
        ],
    };
};
const createQuery = (query) => {
    const speak = getValue(query.speak_list[0], {
        speak_url: '',
        style_cluster_idx: 0,
        speed_x: 1,
    });
    return {
        type: 'query',
        attrs: {
            style: speak.style_cluster_idx,
            speed: speak.speed_x,
            silence: query.silence_sec,
        },
    };
};
export { TypeGuards };
