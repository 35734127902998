import AnimationSceneItem from '@/model/animations/AnimationSceneItem';
export default class FadeOverlayAnimationSceneItem extends AnimationSceneItem {
    constructor(animationPosition, slideDurationWithoutTransition, half, overlayColor) {
        super(animationPosition, `fade${overlayColor}`, slideDurationWithoutTransition, half);
        this.overlayColor = overlayColor;
        this.set(this.getKeyFrame());
    }
    getKeyFrame() {
        if (this.animationPosition === 'start') {
            return {
                '50%': {
                    display: 'block',
                    opacity: 1,
                    'background-color': this.overlayColor,
                },
                '100%': {
                    opacity: 0,
                },
            };
        }
        return {
            '0%': {
                display: 'block',
                opacity: 0,
                'background-color': this.overlayColor,
            },
            '50%': {
                opacity: 1,
            },
        };
    }
}
