import config from '@/config/config'
import backend from '../backend/backend-api'
import CONSTANTS from '@/config/constants'

export function mlBaseSplit(http, script) {
  return backend.splitText(http, script).then(res => res.data)
}

export function mlBaseSplitTexts(http, {paragraphs}) {
  return backend.splitTextList(http, paragraphs).then(res => res.data)
}

function preTrim(script, lang) {
  script = script.trim()
  script = script.replace(/[\u201C\u201D]/g, '"')
  const last = script[script.length - 1]
  if (!CONSTANTS.DELIMITER_SET.includes(last)) {
    switch (lang) {
      case 'zh-cn':
      case 'ja-jp':
        script += '。'
        break
      default:
        script += '.'
        break
    }
  }
  return script
}

function findSpacesBackward(chunk, src) {
  const chunkStr = chunk.join('')

  const result = chunkStr.match(/\S+$/)
  if (result && result.index !== 0) {
    const backward = chunk.splice(result.index)
    backward.reverse()
    Array.prototype.push.apply(src, backward)
  }
}

function findCommaBackward(chunk, src) {
  const chunkStr = chunk.join('')

  const result = chunkStr.match(/(,)[^,]*$/)
  if (result && result.index !== 0) {
    const backward = chunk.splice(result.index + 1)
    backward.reverse()
    Array.prototype.push.apply(src, backward)
    return true
  } else {
    return false
  }
}

function checkDelimiter(src) {
  if (src.length < 1) {
    return false
  }
  const next = src.slice(-1).join('')
  if (next === '.' || next === '!' || next === '?' || next === '"' || next === '｡' || next === '。') {
    return true
  } else {
    return false
  }
}

function checkDecimalPoint(chunk, src) {
  if (chunk.slice(-1).join('') === '.' && chunk.length < 2 && src.length < 1) {
    return false
  }

  // src's last character & chunk's seconds character from the end
  const next = src.slice(-1).join('')
  const before = chunk.slice(-2, -1).join('')
  if (!/\s/.test(next) && !/\s/.test(before) && !isNaN(next) && !isNaN(before)) {
    return true
  } else {
    return false
  }
}

function push(item, dest, length) {
  const chunk = item.splice(0, length).join('').trim()
  let pattern = /^[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]+$/
  if (config.feature.otherLanguage) {
    pattern =
      /^[^a-zA-ZÀ-ÿäöüÄÖÜß0-9ㄱ-ㅎㅏ-ㅣ가-힣\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f\u3005\u30F6\u3002\u3006\u3007\u4e00-\u9fff\u3400-\u4dbf\ud840-\udb7f\udb80-\udbef\udbc0-\udc4f\uff1f\uff01\u003f\u0021\u002e\u003b\uff1b\u002c\uff0c\u3001\u003a]+$/
  }

  if (chunk.length && !pattern.test(chunk)) {
    dest.push(chunk)
  }
}

export function ruleBaseSplit({s, ignoreDq, lang}) {
  const chunk = []
  const parsed = []
  let startedDq = false
  s = preTrim(s, lang)
  const src = s.split('').reverse()

  while (0 < src.length) {
    const poped = src.pop()
    chunk.push(poped)
    if (!ignoreDq && startedDq === false && poped === '"') {
      startedDq = true
      push(chunk, parsed, chunk.length - 1)
    } else if (!ignoreDq && startedDq === true && poped === '"') {
      startedDq = false
      push(chunk, parsed, chunk.length)
    } else if (CONSTANTS.DELIMITER_SET.includes(poped)) {
      if (!checkDelimiter(src) && !checkDecimalPoint(chunk, src)) {
        push(chunk, parsed, chunk.length)
      }
    } else if (chunk.length >= CONSTANTS.MAX_QUERY_LENGTH) {
      if (!findCommaBackward(chunk, src)) {
        findSpacesBackward(chunk, src)
      }
      push(chunk, parsed, chunk.length)
    }
  }
  if (chunk.length > 0) {
    push(chunk, parsed, chunk.length)
  }

  return parsed
}
