import watermarkSrc from '@/assets/video-download-preview-watermark.svg?static';
import { config } from '@/config';
import { Flags } from 'million';
import { createVElementId, objectStyleToString } from '../virtualDOM';
export const addWatermark = (rootVElement) => {
    const { isShortsProject } = config;
    const watermarkVElement = createWatermarkVElement(watermarkSrc, isShortsProject);
    rootVElement.children.push(watermarkVElement);
    return rootVElement;
};
const createWatermarkVElement = (watermarkSrc, isShortsProject) => {
    const top = isShortsProject ? '170px' : '48px';
    const width = isShortsProject ? '314px' : '295.5px';
    const watermarkVElement = {
        tag: 'img',
        props: {
            id: createVElementId(),
            src: watermarkSrc,
            style: objectStyleToString({
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top,
                width,
                height: 'auto',
                zIndex: 9999,
            }),
        },
        flag: Flags.ELEMENT,
    };
    return watermarkVElement;
};
