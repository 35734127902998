import { config } from '@/config';
import { getCachedQuery, getCurrentCachedQuery, } from '@/share/render/getCachedQuery';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import Vue from 'vue';
/** @public */
export const toggleSubtitleVisibility = (queryId) => {
    const query = getCachedQuery(queryId);
    if (!query) {
        return;
    }
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex } = editorOptionsStore;
    const videoDomStore = useVideoDomStore();
    const { subtitleMap } = videoDomStore.slideList[slideIndex];
    const subtitle = subtitleMap[queryId];
    if (!subtitle) {
        Vue.set(subtitleMap, queryId, { invisible: true });
        return;
    }
    if (subtitle.invisible) {
        Vue.delete(subtitle, 'invisible');
        return;
    }
    Vue.set(subtitle, 'invisible', true);
};
/** @public */
export const toggleCurrentSubtitleVisibility = () => {
    const query = getCurrentCachedQuery();
    if (!query) {
        // @ts-ignore
        Vue.notify({
            group: 'main',
            title: config.i18nConverter('video_subtitle.empty_subtitle'),
            ignoreDuplicates: true,
        });
        return;
    }
    toggleSubtitleVisibility(query.id);
};
