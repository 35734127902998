import {Howl} from '@/libs/modules/player/tcHowler'
import {getAudioUrl, getPresignedAudioUrl} from '@/store/modules/queryCache'
import Vue from 'vue'
import {getSrcByAssetId} from '@/share/virtualDOM/getSrcByAssetId'

export const AUDIO_PLAY_TYPE = {
  WATER_MARK: 'waterMark',
  VOICE: 'voice',
  VIDEO: 'video',
  BGM: 'bgm',
}
class AudioPlayer {
  #id
  #audioUrl
  #volume
  #offset
  #loop
  #duration

  #soundInstance
  #onloadCallback
  #onplayCallback
  #onendCallback
  #onstopCallback
  #onBufferingCallback

  #playStartTimeout
  #playerbarTime
  #silenceTimeout
  #silenceTime
  #isPlaying
  #isPause

  #isRequestPrepareAudioLock
  #isAddListenerForBuffering
  #isFirstOnLoaded
  #type
  #isWaiting
  #soundId = 'default'

  constructor(type = null, options) {
    this.#type = type
    if (options) {
      const {onload, onplay, onend, onstop, buffering} = options
      this.#onloadCallback = onload
      this.#onplayCallback = onplay
      this.#onendCallback = onend
      this.#onstopCallback = onstop
      this.#onBufferingCallback = buffering
    }
    this.#reset()
  }

  getId() {
    return this.#id
  }

  setPlayerbarTime = time => {
    this.#playerbarTime = time * 1000
  }

  seek() {
    return this.#soundInstance?.seek() || 0
  }

  duration() {
    return this.#soundInstance?.duration() || 0
  }

  isWaiting() {
    return this.#isWaiting
  }

  #reset = () => {
    this.#id = null
    this.#audioUrl = null
    this.#volume = 0
    this.#offset = 0
    this.#loop = false
    this.#duration = 0
    this.#soundInstance?.unload()
    this.#soundInstance = null
    this.#playStartTimeout = null
    this.#playerbarTime = 0
    this.#silenceTimeout = null
    this.#silenceTime = 0
    this.#isPlaying = false
    this.#isPause = false
    this.#isFirstOnLoaded = true
    this.#isRequestPrepareAudioLock = false
    this.#isWaiting = false
    this.#soundId = 'default'

    this.#removeListenerForBuffering()
  }

  isPlaying = () => {
    return this.#isPlaying || this.#soundInstance?.playing()
  }

  isPause = () => {
    return this.#isPause
  }

  #onWaiting = () => {
    this.#onBufferingCallback(true)
  }

  #onCanplay = () => {
    this.#onBufferingCallback(false)
  }

  #addListenerForBuffering = () => {
    this.#soundInstance?._sounds[0]._node.addEventListener('waiting', this.#onWaiting)
    this.#soundInstance?._sounds[0]._node.addEventListener('canplay', this.#onCanplay)
    this.#isAddListenerForBuffering = true
  }

  #removeListenerForBuffering = () => {
    if (!this.#isAddListenerForBuffering) {
      return
    }

    this.#soundInstance?._sounds[0]._node.removeEventListener('waiting', this.#onWaiting)
    this.#soundInstance?._sounds[0]._node.removeEventListener('canplay', this.#onCanplay)
    this.#isAddListenerForBuffering = false
  }

  requestSoundInstance = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        src: this.#audioUrl ? [this.#audioUrl] : [require('@/assets/void.wav')],
        html5: true,
        volume: this.#volume ?? 1,
        format: ['wav', 'mp3', 'mp4'],
        sprite: {
          default: [this.#offset, this.#duration, false],
        },
      }
      const soundInstance = new Howl({
        ...options,
        onload: () => {
          try {
            this.#soundInstance = soundInstance
            this.#onloadCallback && this.#onloadCallback()
            if (!this.#id) {
              // INFO: onStop > reset을 하더라도 setTimeout에서 처리한 onPlay 때문에 soundInstance가 재 생성되면서 플레이가 되는 이슈가 있음
              return
            }
            if (!this.#isFirstOnLoaded) {
              return
            }
            this.#onBufferingCallback && this.#addListenerForBuffering()

            if (this.#type !== AUDIO_PLAY_TYPE.VIDEO) {
              this.#isFirstOnLoaded = false
              resolve(this.#id)
              return
            }

            soundInstance.seek(this.#offset + 500)
            const audioNode = soundInstance._sounds[0]._node
            const resolveByCanPlayed = () => {
              soundInstance.seek(this.#offset)
              soundInstance.stop()
              audioNode.removeEventListener('canplay', resolveByCanPlayed)
              this.#isFirstOnLoaded = false
              resolve(this.#id)
            }
            audioNode.addEventListener('canplay', resolveByCanPlayed)
          } catch (error) {
            reject(error)
          }
        },
        onloaderror: (id, errorCode) => {
          if (this.#isPlaying) {
            //INFO: playing 도중 TTL만료시 에러가 된다면 1번 재시도 함
            this.#retry()
            return
          }

          this.#isPlaying = false
          let message = ''
          switch (errorCode) {
            case 1:
              message =
                "The fetching process for the media resource was aborted by the user agent at the user's request."
              break
            case 2:
              message =
                'A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.'
              break
            case 3:
              message =
                'An error of some description occurred while decoding the media resource, after the resource was established to be usable.'
              break
            case 4:
              message =
                'The media resource indicated by the src attribute or assigned media provider object was not suitable.'
              break
          }
          reject(new Error(message))
        },
        onend: () => {
          //INFO: autoplay: true, loop: true, 의 경우만 loop가 되어서 이렇게 처리함
          if (this.#loop && this.#soundInstance) {
            this.#play()
            return
          }
          this.#isPlaying = false
          this.#onBufferingCallback && this.#removeListenerForBuffering()
          if (this.#type !== AUDIO_PLAY_TYPE.VOICE || !this.#silenceTime) {
            this.#onendCallback && this.#onendCallback(this.#type)
            return
          }
          const CALIBRATION_SUBTRACTION_TIME = 0.1 //차감보정처리: 딜레이 체감이 느껴져서 0.1초는 처리하지 않음
          const delayTime = this.#silenceTime - CALIBRATION_SUBTRACTION_TIME
          const onend = () => this.#onendCallback && this.#onendCallback()
          if (delayTime > 0) {
            this.#silenceTimeout = setTimeout(onend, this.#silenceTime)
          } else {
            onend()
          }
        },
        onplay: () => {
          this.#isPlaying = true
          this.#onplayCallback && this.#onplayCallback()
        },
        onstop: () => {
          this.#isPlaying = false
          this.#onstopCallback && this.#onstopCallback()
        },
      })
    })
  }

  #retry = () => {
    this.#isPlaying = false
    const seek = this.seek()
    this.#offset += seek
    this.#duration -= seek
    if (this.#duration < 0.5) {
      // INFO: 0.5 이하면 플레이 안함
      return
    }

    const params = {
      id: this.#id,
      volume: this.#volume,
      offset: this.#offset,
      loop: this.#loop,
      duration: this.#duration,
    }
    this.prepareAudio(params).then(this.#play).catch(this.#reset)
  }

  requestPreSingedUrl = async params => {
    const http = Vue.prototype.$http

    const HOUR_5 = 5 * 60 * 60 * 1000
    const isInvalidTTL = Date.now() - params.audio.createAt > HOUR_5
    let ret = params.audio.audioSource
    if (isInvalidTTL) {
      const url = getAudioUrl(params.quality, params.speak)
      ret = await getPresignedAudioUrl(http, url)
    }

    if (Array.isArray(ret)) {
      return ret[0]
    }

    return ret
  }

  prepareAudio = async ({id, volume, loop, offset = 0, duration = Infinity, voiceExtraData, assetFrom}) => {
    this.isRequestPrepareAudioLock = true
    if (this.#soundInstance) {
      this.isRequestPrepareAudioLock = false
      return this.#id
    }
    /**
     * INFO
     * - AUDIO_PLAY_TYPE.VOICE 경우 id는 queryId를 사용함
     * - AUDIO_PLAY_TYPE.VIDEO의 경우 id는 assetsId를 사용함
     * - AUDIO_PLAY_TYPE.WATER_MARK 경우 id는 의미 없음
     */
    let audioUrl = ''
    switch (this.#type) {
      case AUDIO_PLAY_TYPE.VOICE:
        audioUrl = await this.requestPreSingedUrl(voiceExtraData)
        break
      case AUDIO_PLAY_TYPE.VIDEO:
      case AUDIO_PLAY_TYPE.BGM:
        audioUrl = await getSrcByAssetId(id, this.#type, assetFrom)
        break
      case AUDIO_PLAY_TYPE.WATER_MARK:
        audioUrl = require('@/assets/audio/typecast_allages_leveled_low.mp3')
        break
    }
    this.#id = id
    this.#audioUrl = audioUrl
    this.#volume = volume
    this.#offset = offset
    this.#loop = loop
    this.#duration = duration
    this.#silenceTime = voiceExtraData?.silence
    const result = await this.requestSoundInstance()
    this.#isRequestPrepareAudioLock = false
    return result
  }

  onPlay = async params => {
    if (params.id !== this.#id) {
      this.#reset()
    }
    return await this.prepareAudio(params).then(this.#play).catch(this.#reset)
  }

  onPlayWithDelayTime = async (params, delay, playerbarTime) => {
    if (!this.#soundInstance && !this.isRequestPrepareAudioLock) {
      this.prepareAudio(params)
    }
    if (playerbarTime) {
      this.setPlayerbarTime(playerbarTime)
    }

    const preTime = this.#type === AUDIO_PLAY_TYPE.VIDEO ? 300 : 0 //INFO: 비디오의 경우 0.3초 준비시간 단축
    const newDelay = Math.min(delay - this.#playerbarTime - preTime, 30 * 1000)
    if (newDelay > 0) {
      this.#isWaiting = true
      this.#playStartTimeout = setTimeout(() => this.onPlayWithDelayTime(params, delay), newDelay)
    } else {
      this.#isWaiting = false
      return await this.onPlay(params)
    }
  }

  #play = () => {
    this.#soundId = this.#soundInstance.play(this.#soundId)
  }

  onStop = () => {
    clearTimeout(this.#playStartTimeout)
    clearTimeout(this.#silenceTimeout)
    if (this.#soundInstance) {
      this.#soundInstance.stop()
      this.#soundInstance.unload()
    }
  }

  onReset = () => {
    this.#reset()
  }

  onPause() {
    this.#isPause = true
    this.#soundInstance?.pause()
  }

  onRestart() {
    if (this.#isPause && this.#isPlaying) {
      this.#isPause = false
      this.#play()
    }
  }
}

export default AudioPlayer
