/*
 * Generated type guards for "ProjectV4.ts".
 * WARNING: Do not manually change this file.
 */
import { isProject } from "../Project.guard";
import { isDetailV4 } from "./DetailV4.guard";
export function isProjectV4(obj) {
    const typedObj = obj;
    return (isProject(typedObj) &&
        typedObj["version"] === 4 &&
        isDetailV4(typedObj["v4"]));
}
