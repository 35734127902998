import { UNSELECTABLE_ELEMENT_CLASS_NAME } from '@/constants';
import { emitEvent } from '@/emitEvent';
import { moveable } from '.';
import { getSelectedVPropsList } from '@/virtualDOM/get';
export const selectoDragStartHandler = (event) => {
    const { inputEvent, currentTarget } = event;
    const target = inputEvent.target;
    const clickUnselectableElement = target.classList.contains(UNSELECTABLE_ELEMENT_CLASS_NAME);
    if (clickUnselectableElement) {
        event.stop();
        return;
    }
    const selectedTargets = currentTarget.getSelectedTargets();
    const isAlreadySelected = moveable.isMoveableElement(target) || selectedTargets.includes(target);
    if (isAlreadySelected) {
        event.stop();
    }
};
export const selectoSelectHandler = ({ selected }) => {
    // @ts-ignore
    moveable.target = selected;
};
export const selectoSelectEndHandler = ({ isDragStart, inputEvent, }) => {
    emitEvent('select', {
        data: getSelectedVPropsList(),
        originEvent: inputEvent,
    });
    if (!isDragStart) {
        return;
    }
    inputEvent.preventDefault();
    moveable.dragStart(inputEvent);
};
