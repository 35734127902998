/*
 * Generated type guards for "DetailV10.ts".
 * WARNING: Do not manually change this file.
 */
import { isTiptapDetail } from "../TiptapDetail.guard";
export function isDetailV10(obj) {
    const typedObj = obj;
    return (isTiptapDetail(typedObj) &&
        (typeof typedObj["slide_list"] === "undefined" ||
            typedObj["slide_list"] === null ||
            Array.isArray(typedObj["slide_list"]) &&
                typedObj["slide_list"].every((e) => (e !== null &&
                    typeof e === "object" ||
                    typeof e === "function") &&
                    (typeof e["slideId"] === "undefined" ||
                        e["slideId"] === null ||
                        typeof e["slideId"] === "string") &&
                    (typeof e["characterId"] === "undefined" ||
                        e["characterId"] === null ||
                        typeof e["characterId"] === "string") &&
                    (typeof e["characterSkinType"] === "undefined" ||
                        e["characterSkinType"] === null ||
                        e["characterSkinType"] === "A" ||
                        e["characterSkinType"] === "B" ||
                        e["characterSkinType"] === "C" ||
                        e["characterSkinType"] === "D" ||
                        e["characterSkinType"] === "E" ||
                        e["characterSkinType"] === "F" ||
                        e["characterSkinType"] === "G") &&
                    (typeof e["charPosType"] === "undefined" ||
                        e["charPosType"] === null ||
                        e["charPosType"] === "LEFT" ||
                        e["charPosType"] === "CENTER" ||
                        e["charPosType"] === "RIGHT") &&
                    (typeof e["charPercent"] === "undefined" ||
                        e["charPercent"] === null ||
                        typeof e["charPercent"] === "number") &&
                    (typeof e["charTransFlag"] === "undefined" ||
                        e["charTransFlag"] === null ||
                        e["charTransFlag"] === false ||
                        e["charTransFlag"] === true) &&
                    (typeof e["charFlag"] === "undefined" ||
                        e["charFlag"] === null ||
                        e["charFlag"] === false ||
                        e["charFlag"] === true) &&
                    (typeof e["charAnimation"] === "undefined" ||
                        e["charAnimation"] === null ||
                        (e["charAnimation"] !== null &&
                            typeof e["charAnimation"] === "object" ||
                            typeof e["charAnimation"] === "function") &&
                            (e["charAnimation"]["buildinType"] === null ||
                                e["charAnimation"]["buildinType"] === "FADE" ||
                                e["charAnimation"]["buildinType"] === "NONE") &&
                            (e["charAnimation"]["buildoutType"] === null ||
                                e["charAnimation"]["buildoutType"] === "FADE" ||
                                e["charAnimation"]["buildoutType"] === "NONE")) &&
                    (typeof e["chromakey"] === "undefined" ||
                        e["chromakey"] === null ||
                        typeof e["chromakey"] === "string") &&
                    (typeof e["subtitleFlag"] === "undefined" ||
                        e["subtitleFlag"] === null ||
                        e["subtitleFlag"] === false ||
                        e["subtitleFlag"] === true) &&
                    (typeof e["subtitleList"] === "undefined" ||
                        e["subtitleList"] === null ||
                        Array.isArray(e["subtitleList"]) &&
                            e["subtitleList"].every((e) => (e !== null &&
                                typeof e === "object" ||
                                typeof e === "function") &&
                                typeof e["text"] === "string" &&
                                typeof e["originalText"] === "string" &&
                                typeof e["id"] === "string")) &&
                    (typeof e["transitionType"] === "undefined" ||
                        e["transitionType"] === null ||
                        e["transitionType"] === "none" ||
                        e["transitionType"] === "fade" ||
                        e["transitionType"] === "fadeblack" ||
                        e["transitionType"] === "fadewhite" ||
                        e["transitionType"] === "smoothleft" ||
                        e["transitionType"] === "smoothright" ||
                        e["transitionType"] === "smoothup" ||
                        e["transitionType"] === "smoothdown" ||
                        e["transitionType"] === "fade-black" ||
                        e["transitionType"] === "fade-white" ||
                        e["transitionType"] === "smooth-left" ||
                        e["transitionType"] === "smooth-right" ||
                        e["transitionType"] === "smooth-up" ||
                        e["transitionType"] === "smooth-down") &&
                    (typeof e["charMotionFlag"] === "undefined" ||
                        e["charMotionFlag"] === null ||
                        e["charMotionFlag"] === false ||
                        e["charMotionFlag"] === true) &&
                    (typeof e["canvasData"] === "undefined" ||
                        e["canvasData"] === null ||
                        typeof e["canvasData"] === "string") &&
                    (typeof e["bodyType"] === "undefined" ||
                        e["bodyType"] === null ||
                        e["bodyType"] === "UPPER" ||
                        e["bodyType"] === "WHOLE"))) &&
        (typeof typedObj["additional_data"] === "undefined" ||
            typedObj["additional_data"] === null ||
            (typedObj["additional_data"] !== null &&
                typeof typedObj["additional_data"] === "object" ||
                typeof typedObj["additional_data"] === "function") &&
                (typeof typedObj["additional_data"]["videoDomSlide"] === "undefined" ||
                    Array.isArray(typedObj["additional_data"]["videoDomSlide"]) &&
                        typedObj["additional_data"]["videoDomSlide"].every((e) => (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                            typeof e["id"] === "string" &&
                            e["type"] === "slide" &&
                            (typeof e["slideId"] === "undefined" ||
                                e["slideId"] === null ||
                                typeof e["slideId"] === "string") &&
                            (typeof e["characterId"] === "undefined" ||
                                e["characterId"] === null ||
                                typeof e["characterId"] === "string") &&
                            (typeof e["characterSkinType"] === "undefined" ||
                                e["characterSkinType"] === null ||
                                e["characterSkinType"] === "A" ||
                                e["characterSkinType"] === "B" ||
                                e["characterSkinType"] === "C" ||
                                e["characterSkinType"] === "D" ||
                                e["characterSkinType"] === "E" ||
                                e["characterSkinType"] === "F" ||
                                e["characterSkinType"] === "G") &&
                            (e["characterPosition"] === "left" ||
                                e["characterPosition"] === "center" ||
                                e["characterPosition"] === "right") &&
                            typeof e["characterScale"] === "number" &&
                            (typeof e["characterFlip"] === "undefined" ||
                                e["characterFlip"] === null ||
                                e["characterFlip"] === false ||
                                e["characterFlip"] === true) &&
                            typeof e["characterVisibility"] === "boolean" &&
                            (e["characterBuildInAnimation"] === "none" ||
                                e["characterBuildInAnimation"] === "fade") &&
                            (e["characterBuildOutAnimation"] === "none" ||
                                e["characterBuildOutAnimation"] === "fade") &&
                            (typeof e["transition"] === "undefined" ||
                                e["transition"] === null ||
                                e["transition"] === "none" ||
                                e["transition"] === "fade" ||
                                e["transition"] === "fadeblack" ||
                                e["transition"] === "fadewhite" ||
                                e["transition"] === "smoothleft" ||
                                e["transition"] === "smoothright" ||
                                e["transition"] === "smoothup" ||
                                e["transition"] === "smoothdown" ||
                                e["transition"] === "fade-black" ||
                                e["transition"] === "fade-white" ||
                                e["transition"] === "smooth-left" ||
                                e["transition"] === "smooth-right" ||
                                e["transition"] === "smooth-up" ||
                                e["transition"] === "smooth-down") &&
                            (e["style"] !== null &&
                                typeof e["style"] === "object" ||
                                typeof e["style"] === "function") &&
                            Object.entries(e["style"])
                                .every(([key, _value]) => (typeof key === "string")) &&
                            (typeof e["children"] === "undefined" ||
                                e["children"] === null ||
                                Array.isArray(e["children"]) &&
                                    e["children"].every((e) => (e !== null &&
                                        typeof e === "object" ||
                                        typeof e === "function") &&
                                        e["type"] === "image" &&
                                        typeof e["assetId"] === "string" &&
                                        (e["style"] !== null &&
                                            typeof e["style"] === "object" ||
                                            typeof e["style"] === "function") &&
                                        Object.entries(e["style"])
                                            .every(([key, _value]) => (typeof key === "string")) &&
                                        typeof e["id"] === "string")) &&
                            (typeof e["bodyType"] === "undefined" ||
                                e["bodyType"] === null ||
                                e["bodyType"] === "UPPER" ||
                                e["bodyType"] === "WHOLE") &&
                            (typeof e["speakTextVisibility"] === "undefined" ||
                                e["speakTextVisibility"] === null ||
                                e["speakTextVisibility"] === false ||
                                e["speakTextVisibility"] === true))) &&
                (typeof typedObj["additional_data"]["timeline"] === "undefined" ||
                    (typedObj["additional_data"]["timeline"] !== null &&
                        typeof typedObj["additional_data"]["timeline"] === "object" ||
                        typeof typedObj["additional_data"]["timeline"] === "function") &&
                        (typeof typedObj["additional_data"]["timeline"]["video"] === "undefined" ||
                            typedObj["additional_data"]["timeline"]["video"] === null ||
                            Array.isArray(typedObj["additional_data"]["timeline"]["video"]) &&
                                typedObj["additional_data"]["timeline"]["video"].every((e) => (e !== null &&
                                    typeof e === "object" ||
                                    typeof e === "function") &&
                                    typeof e["id"] === "string" &&
                                    typeof e["name"] === "string" &&
                                    (typeof e["startTrim"] === "undefined" ||
                                        e["startTrim"] === null ||
                                        typeof e["startTrim"] === "number") &&
                                    (typeof e["endTrim"] === "undefined" ||
                                        e["endTrim"] === null ||
                                        typeof e["endTrim"] === "number") &&
                                    (e["fillType"] === "stretch" ||
                                        e["fillType"] === "width" ||
                                        e["fillType"] === "height") &&
                                    typeof e["offset"] === "number" &&
                                    typeof e["volume"] === "number" &&
                                    typeof e["totalDuration"] === "number")) &&
                        (typeof typedObj["additional_data"]["timeline"]["bgm"] === "undefined" ||
                            typedObj["additional_data"]["timeline"]["bgm"] === null ||
                            Array.isArray(typedObj["additional_data"]["timeline"]["bgm"]) &&
                                typedObj["additional_data"]["timeline"]["bgm"].every((e) => (e !== null &&
                                    typeof e === "object" ||
                                    typeof e === "function") &&
                                    typeof e["id"] === "string" &&
                                    (typeof e["name"] === "undefined" ||
                                        e["name"] === null ||
                                        typeof e["name"] === "string") &&
                                    (e["endTrim"] === null ||
                                        typeof e["endTrim"] === "number") &&
                                    (e["startTrim"] === null ||
                                        typeof e["startTrim"] === "number") &&
                                    typeof e["offset"] === "number" &&
                                    typeof e["totalDuration"] === "number" &&
                                    typeof e["volume"] === "number"))) &&
                (typeof typedObj["additional_data"]["version"] === "undefined" ||
                    typeof typedObj["additional_data"]["version"] === "number") &&
                (typeof typedObj["additional_data"]["slide_list_v2"] === "undefined" ||
                    Array.isArray(typedObj["additional_data"]["slide_list_v2"]) &&
                        typedObj["additional_data"]["slide_list_v2"].every((e) => (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                            typeof e["id"] === "string" &&
                            e["type"] === "slide" &&
                            (typeof e["slideId"] === "undefined" ||
                                e["slideId"] === null ||
                                typeof e["slideId"] === "string") &&
                            (typeof e["characterId"] === "undefined" ||
                                e["characterId"] === null ||
                                typeof e["characterId"] === "string") &&
                            (typeof e["characterSkinType"] === "undefined" ||
                                e["characterSkinType"] === null ||
                                e["characterSkinType"] === "A" ||
                                e["characterSkinType"] === "B" ||
                                e["characterSkinType"] === "C" ||
                                e["characterSkinType"] === "D" ||
                                e["characterSkinType"] === "E" ||
                                e["characterSkinType"] === "F" ||
                                e["characterSkinType"] === "G") &&
                            (e["characterPosition"] === "left" ||
                                e["characterPosition"] === "center" ||
                                e["characterPosition"] === "right") &&
                            typeof e["characterScale"] === "number" &&
                            (typeof e["characterFlip"] === "undefined" ||
                                e["characterFlip"] === null ||
                                e["characterFlip"] === false ||
                                e["characterFlip"] === true) &&
                            typeof e["characterVisibility"] === "boolean" &&
                            (e["characterBuildInAnimation"] === "none" ||
                                e["characterBuildInAnimation"] === "fade") &&
                            (e["characterBuildOutAnimation"] === "none" ||
                                e["characterBuildOutAnimation"] === "fade") &&
                            (typeof e["transition"] === "undefined" ||
                                e["transition"] === null ||
                                e["transition"] === "none" ||
                                e["transition"] === "fade" ||
                                e["transition"] === "fadeblack" ||
                                e["transition"] === "fadewhite" ||
                                e["transition"] === "smoothleft" ||
                                e["transition"] === "smoothright" ||
                                e["transition"] === "smoothup" ||
                                e["transition"] === "smoothdown" ||
                                e["transition"] === "fade-black" ||
                                e["transition"] === "fade-white" ||
                                e["transition"] === "smooth-left" ||
                                e["transition"] === "smooth-right" ||
                                e["transition"] === "smooth-up" ||
                                e["transition"] === "smooth-down") &&
                            (e["style"] !== null &&
                                typeof e["style"] === "object" ||
                                typeof e["style"] === "function") &&
                            Object.entries(e["style"])
                                .every(([key, _value]) => (typeof key === "string")) &&
                            (typeof e["children"] === "undefined" ||
                                e["children"] === null ||
                                Array.isArray(e["children"]) &&
                                    e["children"].every((e) => (e !== null &&
                                        typeof e === "object" ||
                                        typeof e === "function") &&
                                        e["type"] === "image" &&
                                        typeof e["assetId"] === "string" &&
                                        (e["style"] !== null &&
                                            typeof e["style"] === "object" ||
                                            typeof e["style"] === "function") &&
                                        Object.entries(e["style"])
                                            .every(([key, _value]) => (typeof key === "string")) &&
                                        typeof e["id"] === "string")) &&
                            (typeof e["bodyType"] === "undefined" ||
                                e["bodyType"] === null ||
                                e["bodyType"] === "UPPER" ||
                                e["bodyType"] === "WHOLE") &&
                            (typeof e["speakTextVisibility"] === "undefined" ||
                                e["speakTextVisibility"] === null ||
                                e["speakTextVisibility"] === false ||
                                e["speakTextVisibility"] === true) &&
                            (typeof e["backgroundColor"] === "undefined" ||
                                e["backgroundColor"] === null ||
                                typeof e["backgroundColor"] === "string") &&
                            (typeof e["subtitleMap"] === "undefined" ||
                                e["subtitleMap"] === null ||
                                (e["subtitleMap"] !== null &&
                                    typeof e["subtitleMap"] === "object" ||
                                    typeof e["subtitleMap"] === "function") &&
                                    Object.entries(e["subtitleMap"])
                                        .every(([key, value]) => ((value !== null &&
                                        typeof value === "object" ||
                                        typeof value === "function") &&
                                        (typeof value["invisible"] === "undefined" ||
                                            value["invisible"] === false ||
                                            value["invisible"] === true) &&
                                        (typeof value["text"] === "undefined" ||
                                            typeof value["text"] === "string") &&
                                        typeof key === "string"))) &&
                            (typeof e["subtitleList"] === "undefined" ||
                                e["subtitleList"] === null ||
                                Array.isArray(e["subtitleList"]) &&
                                    e["subtitleList"].every((e) => (e !== null &&
                                        typeof e === "object" ||
                                        typeof e === "function") &&
                                        typeof e["text"] === "string" &&
                                        typeof e["originalText"] === "string" &&
                                        typeof e["id"] === "string"))))));
}
