// 기본값을 반환할 조건을 명확히 하기 위해 getValue 함수를 사용한다.
// value || defaultValue는 의도대로 동작하지 않는 경우가 많다.
// 예) value가 명시적으로 0일 때는 0이 반환되는게 의도이지만 Boolean(0)은 false이므로 0 대신 defaultValue가 반환된다.
export const getValue = (value, defaultValue) => {
    if (value === null || value === undefined) {
        return defaultValue;
    }
    if (typeof value === 'string' && value === '') {
        return defaultValue;
    }
    return value;
};
