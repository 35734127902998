import {useVideoDomStore} from 'store/editor'
import {rerenderDOM} from 'video-dom/src/share/render/rerenderDOM'

export default {
  showLoading(state) {
    state.loadingCnt += 1
  },
  hideLoading: state => (state.loadingCnt = Math.max(0, state.loadingCnt - 1)),
  resetLoading: state => (state.loadingCnt = 0),
  setIsProjectInitialized: (state, isProjectInitialized) => (state.isProjectInitialized = isProjectInitialized),
  setImageAssetList: (state, imageAssetList) => (state.imageAssetList = imageAssetList),
  setImageAssetTotalCount: (state, imageAssetTotalCount) => (state.imageAssetTotalCount = imageAssetTotalCount),
  setAssetList: (state, assetList) => (state.assetList = assetList),
  setAssetTotalCount: (state, assetTotalCount) => (state.assetTotalCount = assetTotalCount),
  setTimelineAssetLists: (state, {list, type}) => {
    type ? (state.timelineAssetLists[type] = list) : (state.timelineAssetLists = list)
  },
  setCachedAssetUrls: (state, assetList) =>
    assetList.forEach(({_id, assetPath, assetUrl}) => (state.cachedAssetUrls[_id || assetPath] = assetUrl)),
  updateCachedAssetUrl: (state, {id, url}) => (state.cachedAssetUrls[id] = url),
  setCachedTimelineAssetLists: (state, {list, type}) => {
    type ? (state.cachedTimelineAssetLists[type] = list) : (state.cachedTimelineAssetLists = list)
  },
  updateAssetListItem: (state, {index, id, asset}) => {
    let targetIndex

    if (id) {
      targetIndex = state.assetList.findIndex(assetItem => assetItem._id === id)
    } else if (index >= 0) {
      targetIndex = index
    } else {
      targetIndex = state.assetList.findIndex(assetItem => assetItem._id === asset._id)
    }

    state.assetList.splice(targetIndex, 1, asset)
  },
  addTimelineAsset: (state, {asset, type}) => state.timelineAssetLists[type].push(asset),
  removeTimelineAsset: (state, {id, type}) => {
    const index = state.timelineAssetLists[type].findIndex(asset => asset.id === id)
    if (index > -1) {
      state.timelineAssetLists[type].splice(index, 1)
    }
  },
  updateTimelineAsset: (state, {id, newProperties, type}) => {
    const index = state.timelineAssetLists[type].findIndex(asset => asset.id === id)
    if (index > -1) {
      const prevAsset = state.timelineAssetLists[type][index]
      state.timelineAssetLists[type].splice(index, 1, {...prevAsset, ...newProperties})
    }
  },
  setIsBlockEditorUpdate: (state, isBlocked) => (state.isBlockEditorUpdate = isBlocked),
  setIsForceRoute: (state, isForceRoute) => (state.isForceRoute = isForceRoute),
  setProjectUid: (state, projectUid) => (state.projectUid = projectUid),
  resetVideoProject: state => {
    state.projectUid = ''
    state.isBlockEditorUpdate = true
    state.showCanvas = false
    state.canvasWideMode = false
    state.timelineAssetLists = {video: [], bgm: []}

    const videoDomStore = useVideoDomStore()
    videoDomStore.resetVideoProject()
  },
  setShowCanvas: (state, isShow) => {
    state.showCanvas = isShow
    rerenderDOM()
  },
  setCanvasWideMode: (state, mode) => (state.canvasWideMode = mode),
  setLastDisplaySubtitle: (state, subtitle) => (state.lastDisplaySubtitle = subtitle),

  setIsShortsProject: (state, isShortsProject) => (state.isShortsProject = isShortsProject),
  setIsProjectLoading: (state, isProjectLoading) => (state.isProjectLoading = isProjectLoading),
  setIsTimelineOpen(state, value) {
    state.isTimelineOpen = value
  },
  setShowTimeline: (state, isShow) => (state.isTimelineOpen = isShow),
}
