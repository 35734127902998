const NaverEventStore = {
  event({category, label}) {
    if (window.wcs) {
      if (category === 'purchase') {
        window._nasa['cnv'] = window.wcs.cnv(1, label.amount)
      } else if (category === 'register') {
        window._nasa['cnv'] = window.wcs.cnv(2, 1)
      } else {
        return
      }
      window.wcs_do(window._nasa)
    } else {
      return
    }
  },
}

const NaverEventPlugin = {
  install(Vue, {env, err}) {
    try {
      if (!err) {
        if (!window.wcs_add) window.wcs_add = {}
        if (!window._nasa) window._nasa = {}
        window.wcs_add['wa'] = 's_16e3c7dc7865'
        const _env = env.replace(/(https:\/\/)|(http:\/\/)/g, '')
        window.wcs.inflow(_env)
        window.wcs_do(window._nasa)
      }
    } finally {
      Object.defineProperty(Vue.prototype, '$naverEvent', {
        get() {
          return NaverEventStore
        },
      })
    }
  },
}

export default NaverEventPlugin
