import { StateHistory } from 'undo-redo-history';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import { cloneDeep, debounce } from 'utils';
// @ts-ignore
import store from '@/store';
import { resetTargets } from '@/context';
import { isTiptapTextNode } from 'utils/typecast/isTiptapTextNode';
export const stateHistory = new StateHistory();
export const undoHistory = () => {
    if (!stateHistory.canUndo) {
        return;
    }
    const history = stateHistory.undo();
    if (!history) {
        return;
    }
    applyHistory(history);
    resetTargets();
};
export const redoHistory = () => {
    if (!stateHistory.canRedo) {
        return;
    }
    const history = stateHistory.redo();
    if (!history) {
        return;
    }
    applyHistory(history);
    resetTargets();
};
export const pushHistory = () => {
    const videoDomStore = useVideoDomStore();
    const videoDomState = videoDomStore.$state;
    const editorOptionsStore = useEditorOptionsStore();
    const history = {
        state: cloneDeep({
            subtitle: videoDomState.subtitle,
            slideList: videoDomState.slideList,
            globalBackgroundColor: videoDomState.globalBackgroundColor,
        }),
        slideIndex: editorOptionsStore.slideIndex,
        slideId: editorOptionsStore.currentSlideId,
    };
    stateHistory.push(history);
};
const APPLY_HISTORY_DEBOUNCE_WAIT = 50;
const applyHistory = debounce((history) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const { state } = cloneDeep(history);
    const matchedState = getMatchedStateForSlideList(state);
    const stateWithoutCharacterId = getStateWithoutCharacterId(matchedState, slideList);
    videoDomStore.$patch(stateWithoutCharacterId);
    moveTiptapFocus(history);
}, APPLY_HISTORY_DEBOUNCE_WAIT);
const getMatchedStateForSlideList = (state) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const { slideList: historySlideList } = state;
    const matchedHistorySlideList = slideList.map((slideVProp, index) => {
        const historySlide = historySlideList[index];
        const historySlideId = historySlide === null || historySlide === void 0 ? void 0 : historySlide.id;
        const slideId = slideVProp.id;
        const isSameSlide = historySlideId === slideId;
        if (isSameSlide) {
            return historySlide;
        }
        const emptySlide = {
            id: slideId,
            characterId: slideVProp.characterId,
            characterSkinType: slideVProp.characterSkinType,
        };
        return emptySlide;
    });
    return Object.assign(Object.assign({}, history.state), { slideList: matchedHistorySlideList });
};
const getStateWithoutCharacterId = (state, slideVPropsList) => {
    const slideList = state.slideList.map((historySlideVProps, index) => {
        const slideVProps = slideVPropsList[index];
        return Object.assign(Object.assign({}, slideVProps), { children: historySlideVProps.children });
    });
    return Object.assign(Object.assign({}, state), { slideList });
};
const moveTiptapFocus = ({ slideIndex, slideId }) => {
    // @ts-ignore
    const { content: tiptapContent } = store.state.typecast.editor.tiptapContent;
    const firstQueryId = getFirstQueryId(tiptapContent, slideId);
    if (!firstQueryId) {
        return;
    }
    const editorOptionsStore = useEditorOptionsStore();
    editorOptionsStore.setCurrentSlideIndexAndId(slideIndex, slideId);
};
const getFirstQueryId = (tiptapContent, slideId) => {
    var _a;
    const currentParagraph = tiptapContent.find(({ attrs }) => attrs.id === slideId);
    if (!currentParagraph) {
        return;
    }
    const firstQuery = (_a = currentParagraph.content) === null || _a === void 0 ? void 0 : _a[0];
    if (!firstQuery) {
        return;
    }
    if (!isTiptapTextNode(firstQuery)) {
        return;
    }
    const firstQueryId = firstQuery.marks[0].attrs.id;
    return firstQueryId;
};
