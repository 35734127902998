import { LanguageCodeMap } from '@/locales/i18nMap/language_code';
import { i18n } from '@/i18n';
export function getWrongLanguageErrorMessage(actorName, language) {
    const params = {
        language: LanguageCodeMap[language],
        actor_name: actorName,
    };
    return language === 'en-us'
        ? i18n.t('editor.only_eng_wrong_language_error', params)
        : i18n.t('editor.wrong_language_error', params);
}
