/*
 * Generated type guards for "ProjectV10.ts".
 * WARNING: Do not manually change this file.
 */
import { isProject } from "../Project.guard";
import { isDetailV10 } from "./DetailV10.guard";
export function isProjectV10(obj) {
    const typedObj = obj;
    return (isProject(typedObj) &&
        typedObj["version"] === 10 &&
        isDetailV10(typedObj["v10"]));
}
