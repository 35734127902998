/*
 * Generated type guards for "ProjectV0.ts".
 * WARNING: Do not manually change this file.
 */
import { isProject } from "../Project.guard";
export function isProjectV0(obj) {
    const typedObj = obj;
    return (isProject(typedObj) &&
        Array.isArray(typedObj["query_list"]) &&
        typedObj["query_list"].every((e) => (e !== null &&
            typeof e === "object" ||
            typeof e === "function") &&
            typeof e["text"] === "string" &&
            (typeof e["style_idx"] === "string" ||
                typeof e["style_idx"] === "number") &&
            (typeof e["speak_list"] === "undefined" ||
                e["speak_list"] === null ||
                Array.isArray(e["speak_list"]) &&
                    e["speak_list"].every((e) => (typeof e === "undefined" ||
                        e === null ||
                        typeof e === "string" ||
                        (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                            (typeof e["speak_url"] === "undefined" ||
                                e["speak_url"] === null ||
                                typeof e["speak_url"] === "string") &&
                            (typeof e["speed_x"] === "undefined" ||
                                e["speed_x"] === null ||
                                typeof e["speed_x"] === "number") &&
                            (typeof e["style_cluster_idx"] === "undefined" ||
                                e["style_cluster_idx"] === null ||
                                typeof e["style_cluster_idx"] === "number")))) &&
            (typeof e["speed_x"] === "undefined" ||
                e["speed_x"] === null ||
                typeof e["speed_x"] === "number") &&
            (typeof e["style_cluster_idx"] === "undefined" ||
                e["style_cluster_idx"] === null ||
                typeof e["style_cluster_idx"] === "number") &&
            (typeof e["silence_sec"] === "undefined" ||
                e["silence_sec"] === null ||
                typeof e["silence_sec"] === "number")) &&
        Array.isArray(typedObj["style_list"]) &&
        typedObj["style_list"].every((e) => (e !== null &&
            typeof e === "object" ||
            typeof e === "function") &&
            typeof e["actor_id"] === "string"));
}
