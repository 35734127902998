import {queryChannelSummary, queryChannelAnalytics} from '@/libs/modules/Youtube/information'
import * as dayjs from 'dayjs'
import {getOffsetTime} from '@/utils/time'
import backend from '@/backend/backend-api'
const YOUTUBE_SCOPES = 'https://www.googleapis.com/auth/youtube'

const CONSTANTS = {
  TOKEN: 'token',
  CHANNEL_NAME: 'channelName',
  CHANNEL_THUMBNAIL: 'channelThumbnail',
  PUBLISHED_AT: 'publishedAt',
  STATISTICS: 'statistics',
  LIKES_COUNT: 'likesCount',
  COMMENTS_COUNT: 'commentsCount',
  ANALYTICS: 'analytics',
}

const state = {
  loading: true,
  token: {},
  channelName: '',
  channelThumbnail: '',
  publishedAt: '',
  statistics: [],
  likesCount: '',
  commentsCount: '',
  analytics: {},
}

const getters = {
  isTokenValid(state) {
    const nowTimestamp = dayjs().add(5, 'minute').unix()
    return nowTimestamp * 1000 < getOffsetTime(parseInt(state.token.expires_at) * 1000)
  },
  hasToken(state, getters) {
    return Object.keys(state.token).length > 0 && getters.isTokenValid
  },
}

const mutations = {
  setStates(state, {key, value}) {
    state[key] = value
  },
  setLoading(state, value) {
    state.loading = value
  },
}

const actions = {
  async getAccessToken({commit}) {
    const $http = this._vm.$typecast.$http
    const token = await backend.getYoutubeToken($http)
    commit('setStates', {key: CONSTANTS.TOKEN, value: token})
  },
  async deleteAccessToken({dispatch}) {
    const $http = this._vm.$typecast.$http
    await backend.deleteYoutubeAuth($http)

    dispatch('reset')
  },

  async getChannelSummary({commit, state, getters}) {
    if (!getters.isTokenValid) {
      throw new Error('getChannelSummary: invalid token')
    }
    const {channelName, channelThumbnail, statistics, publishedAt} = await queryChannelSummary(state.token.access_token)
    commit('setStates', {key: CONSTANTS.CHANNEL_NAME, value: channelName})
    commit('setStates', {key: CONSTANTS.CHANNEL_THUMBNAIL, value: channelThumbnail})
    commit('setStates', {key: CONSTANTS.STATISTICS, value: statistics})
    commit('setStates', {key: CONSTANTS.PUBLISHED_AT, value: publishedAt})
  },
  async getChannelAnalytics({commit, state, getters}, {dates, isStatistics}) {
    if (!getters.isTokenValid) {
      throw new Error('getChannelAnalytics: invalid token')
    }
    const result = await queryChannelAnalytics(state.token.access_token, dates, isStatistics)
    if (isStatistics) {
      commit('setStates', {key: CONSTANTS.COMMENTS_COUNT, value: result[0]})
      commit('setStates', {key: CONSTANTS.LIKES_COUNT, value: result[1]})
      return
    }
    commit('setStates', {key: CONSTANTS.ANALYTICS, value: result})
  },
  async postAccessToken({dispatch, commit}, {code, error}) {
    commit('setLoading', true)
    const $http = this._vm.$typecast.$http

    if (error === 'access_denied') {
      return
    }
    await backend.postYoutubeAuth($http, {
      authorization_code: code,
      scopes: [YOUTUBE_SCOPES],
    })
    await dispatch('getAccessToken')
  },

  reset({commit}) {
    const objects = {keys: [CONSTANTS.TOKEN, CONSTANTS.ANALYTICS], value: {}}
    const strings = {
      keys: [
        CONSTANTS.CHANNEL_NAME,
        CONSTANTS.CHANNEL_THUMBNAIL,
        CONSTANTS.PUBLISHED_AT,
        CONSTANTS.LIKES_COUNT,
        CONSTANTS.COMMENTS_COUNT,
        CONSTANTS.ANALYTICS,
      ],
      value: '',
    }
    const types = [objects, strings]

    types.forEach(type => {
      type.keys.forEach(key => {
        commit('setStates', {key, value: type.value})
      })
    })
    commit('setStates', {key: CONSTANTS.STATISTICS, value: []})
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
