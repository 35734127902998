import AnimationSceneItem from '@/model/animations/AnimationSceneItem';
import { needNextStartTransition } from '@/share/render';
import Big from 'big.js';
export const createSlideDurationDictList = (slideVPropsList) => {
    const slideDurationDictList = slideVPropsList.map(slideVProps => {
        var _a;
        const speakList = slideVProps.speakList;
        const duration = speakList.reduce((slideDuration, { duration: speakDuration, silence: speakSilence }) => slideDuration.plus(speakDuration).plus(speakSilence), new Big(0));
        const preSilence = (_a = speakList[0].preSilence) !== null && _a !== void 0 ? _a : new Big(0);
        const durationWithPreSilence = duration.plus(preSilence);
        const speakDurationDictList = speakList.reduce((speakDurationDictList, { duration, silence }, speakIndex) => {
            const durationWithSilence = duration.plus(silence);
            const prevSpeakDurationDict = speakDurationDictList[speakIndex - 1];
            if (!prevSpeakDurationDict) {
                speakDurationDictList.push({
                    duration: durationWithSilence,
                    delay: new Big(0),
                });
                return speakDurationDictList;
            }
            const delay = prevSpeakDurationDict.delay.plus(prevSpeakDurationDict.duration);
            speakDurationDictList.push({
                duration: durationWithSilence,
                delay,
            });
            return speakDurationDictList;
        }, []);
        return {
            duration: durationWithPreSilence,
            durationWithoutTransition: durationWithPreSilence,
            preSilence,
            speakDurationDictList,
            delay: new Big(0),
            startTransitionDuration: new Big(0),
            endTransitionDuration: new Big(0),
        };
    });
    const slideDurationDictListWithTransition = slideVPropsList.reduce(useSlideDurationDictListWithTransitionReducer(slideDurationDictList), []);
    return slideDurationDictListWithTransition;
};
const useSlideDurationDictListWithTransitionReducer = (slideDurationDictList) => (slideDurationDictListWithTransition, slideVProps, slideIndex, slideVPropsList) => {
    const { transition } = slideVProps;
    const isLastSlide = slideVPropsList.length - 1 === slideIndex;
    const unwrappedEndTransitionDuration = isLastSlide
        ? 0
        : AnimationSceneItem.getTransitionDuration(transition);
    const endTransitionDuration = new Big(unwrappedEndTransitionDuration);
    const slideDurationDict = slideDurationDictList[slideIndex];
    const { duration, speakDurationDictList, durationWithoutTransition, preSilence, } = slideDurationDict;
    const durationWithEndTransition = duration.plus(endTransitionDuration);
    const prevSlideDurationDict = slideDurationDictListWithTransition[slideIndex - 1];
    if (!prevSlideDurationDict) {
        slideDurationDictListWithTransition.push({
            delay: new Big(0),
            startTransitionDuration: new Big(0),
            duration: durationWithEndTransition,
            durationWithoutTransition,
            endTransitionDuration,
            speakDurationDictList,
            preSilence,
        });
        return slideDurationDictListWithTransition;
    }
    const { endTransitionDuration: startTransitionDuration, delay: prevDelay, duration: prevDuration, } = prevSlideDurationDict;
    const { transition: prevTransition } = slideVPropsList[slideIndex - 1];
    const delay = prevDelay.plus(prevDuration);
    const durationWithAllTransition = durationWithEndTransition.plus(startTransitionDuration);
    if (!needNextStartTransition(prevTransition)) {
        slideDurationDictListWithTransition.push({
            duration: durationWithAllTransition,
            startTransitionDuration,
            delay: delay.minus(startTransitionDuration),
            durationWithoutTransition,
            endTransitionDuration,
            speakDurationDictList,
            preSilence,
        });
        return slideDurationDictListWithTransition;
    }
    slideDurationDictListWithTransition.push({
        duration: durationWithAllTransition,
        delay,
        durationWithoutTransition,
        startTransitionDuration,
        endTransitionDuration,
        speakDurationDictList,
        preSilence,
    });
    return slideDurationDictListWithTransition;
};
