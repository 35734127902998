<template>
  <label
    class="t-toggle-button-container t-body2"
    v-on="checkboxListeners"
    role="checkbox"
    :class="{disabled: disabled, 'is-checked': checked, 'is-fixed-color': fixedColor}"
  >
    <input
      type="checkbox"
      class="checkbox"
      :name="name"
      :disabled="disabled"
      :checked="checked"
      tabindex="-1"
      @change.stop="toggle"
    />
    <div class="toggle-button-core">
      <div class="toggle-button"></div>
    </div>
  </label>
</template>

<script>
import {defineComponent, isVue2} from 'vue-demi'

export default defineComponent({
  name: 'TToggleButton',
  model: {
    prop: 'checked',
    event: 'custom-change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    fixedColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkboxListeners() {
      if (isVue2) {
        return this.$listeners
      }

      return this.$attrs
    },
  },
  methods: {
    toggle() {
      this.$emit('custom-change', !this.checked)
      this.$emit('change', !this.checked)
    },
  },
})
</script>
