import * as TypeGuards from './ProjectV3.guard';
import Project from '@/projects/Project';
import TiptapDetail from '@/projects/TiptapDetail';
import ProjectV10 from '@/projects/v10/ProjectV10';
export default class ProjectV3 extends Project {
    constructor(project) {
        super(project);
        this.version = project.version;
        this.v3 = new TiptapDetail(project.v3);
    }
    isProject(project) {
        return TypeGuards.isProjectV3(project);
    }
    migrate() {
        return new ProjectV10(Object.assign(Object.assign({}, this), { version: 10, v10: this.v3 }), []).migrate();
    }
}
export { TypeGuards };
