import {isValidEditorText} from '@/utils/isValidEditorText'
import {createQueryHash} from '@/utils/nsHash'
import getEstimateDurationByText from '@/utils/getEstimateDurationByText'
import {isTiptapTextNode} from 'utils/typecast/isTiptapTextNode'

export default {
  _store: null,
  cachedData: {},
  taskList: [],
  taskHandle: null,

  setStore(store) {
    this._store = store
  },

  _queries() {
    return this._store.getters['typecast/queryCache/queries']
  },

  _actors() {
    return this._store.getters['typecast/actor/actors']
  },

  _actorVersionList() {
    return this._store.getters['typecast/queryCache/styleLabelVersionList']
  },

  _tiptapContent() {
    return this._store.getters['typecast/editor/tiptapContent']
  },

  _candidateActorList() {
    return this._store.getters['typecast/actor/candidateActor']
  },

  _queryCache() {
    return this._store.getters['typecast/queryCache/queryCacheItems']
  },

  _creditRemaining() {
    return this._store.state.typecast.user.creditRemaining
  },

  _projectType() {
    return this._store.state.typecast.videoEditor.isShortsProject ? 'shorts' : 'wide'
  },

  _createQueryDataSkeleton() {
    return {
      num_sentence_emotion: 0,
      num_sentence_emotion_styletag: 0,
      num_sentence_speed: 0,
      num_sentence_speed_manual: 0,
      num_sentence_tempo: 0,
      num_sentence_pitch: 0,
      num_sentence_dirty: 0,
      num_sentence_last_pitch: 0,
      num_sentence: 0,
      total_download_duration: 0,
    }
  },

  _getActorInformation(query) {
    const actorId = query.actor
    const findActor = actor => actor.actor_id === actorId
    const actor = this._actors().find(findActor)
    const actorVersion = this._actorVersionList()[actorId] || 'v2'
    const actorIndex = this._candidateActorList().findIndex(findActor)
    let actorMoods
    if (actor.tag_v2.mood) {
      actorMoods = Array.from(new Set(actor.tag_v2.mood.map(mood => mood.title)))
    }

    return {
      actor_version: actorVersion,
      actor_unique_id: actor.unique_id,
      actor_name: actor.name['en'],
      actor_lang: actor.language,
      actor_index: actorIndex,
      actor_filter_video: actor.character_flags.includes('video-real'),
      actor_filter_gender: actor.sex[0],
      actor_filter_age: actor.age,
      actor_filter_content: actor.tag_v2.content,
      actor_filter_mood: actorMoods,
      actor_duration: 0,
    }
  },

  _queryParseTask({query, actorResult}) {
    if (!actorResult[query.actor]) {
      actorResult[query.actor] = {
        ...this._getActorInformation(query),
        ...this._createQueryDataSkeleton(),
        used_sentence_emotion_styletag: false,
        used_sentence_speed_manual: false,
      }
    }
    const target = actorResult[query.actor]
    let isChanged = false

    target.num_sentence++
    target.actor_duration += this._getQueryDuration(query)

    if (query.style !== 'normal-1' && query.style !== 0 && query.style !== '0') {
      target.num_sentence_emotion++
      isChanged = true
    }
    if (query.styleTag) {
      target.num_sentence_emotion_styletag++
      target.used_sentence_emotion_styletag = true
      isChanged = true
    }
    if (query.speed !== 1) {
      target.num_sentence_speed++
      isChanged = true
    }
    if (query.customSpeed !== 0) {
      target.num_sentence_speed_manual++
      target.used_sentence_speed_manual = true
      isChanged = true
    }
    if (query.pitch !== 0) {
      target.num_sentence_pitch++
      isChanged = true
    }
    if (query.tempo !== 1) {
      target.num_sentence_tempo++
      isChanged = true
    }
    if (query.lastPitch !== undefined && query.lastPitch !== null) {
      target.num_sentence_last_pitch++
      isChanged = true
    }
    if (isChanged) {
      target.num_sentence_dirty++
    }
  },

  _getQueryDuration(query) {
    const hash = createQueryHash({...query, styleVersion: this._actorVersionList()[query.actor]})
    const cachedQuery = this._queryCache()[hash]
    let time = 0
    if (cachedQuery && cachedQuery.speak && cachedQuery.speak.duration) {
      time += cachedQuery.speak.duration
      time += query.silence / 1000
    } else {
      const actor = this._candidateActorList().find(actor => actor.actor_id === query.actor)
      time += getEstimateDurationByText({
        actor,
        text: query.text,
        silence: query.silence,
        tempo: query.tempo,
        customSpeed: query.customSpeed,
      })
    }
    return time
  },

  _parsrActorResult({actorResult, queries, isSpectificQuery}) {
    const querySummeryResult = this._createQueryDataSkeleton()
    const actorResultValue = Object.values(actorResult)
    actorResultValue.forEach(actorInformation => {
      querySummeryResult.num_sentence_emotion += actorInformation.num_sentence_emotion
      querySummeryResult.num_sentence_emotion_styletag += actorInformation.num_sentence_emotion_styletag
      querySummeryResult.num_sentence_speed += actorInformation.num_sentence_speed
      querySummeryResult.num_sentence_speed_manual += actorInformation.num_sentence_speed_manual
      querySummeryResult.num_sentence_pitch += actorInformation.num_sentence_pitch
      querySummeryResult.num_sentence_tempo += actorInformation.num_sentence_tempo
      querySummeryResult.num_sentence_dirty += actorInformation.num_sentence_dirty
      querySummeryResult.num_sentence_last_pitch += actorInformation.num_sentence_last_pitch
      querySummeryResult.num_sentence += actorInformation.num_sentence
      querySummeryResult.total_download_duration += actorInformation.actor_duration
    })

    querySummeryResult.num_actor = actorResultValue.length
    querySummeryResult.num_paragraph = this._getParagraphCount(isSpectificQuery, queries)
    return querySummeryResult
  },

  _getFlatParagraph() {
    const flatParagraphInfo = []
    this._tiptapContent()
      .content.filter(paragraph => paragraph.content)
      .forEach((paragraph, index) => {
        paragraph.content
          .filter(query => {
            const hasText = isTiptapTextNode(query) && isValidEditorText(query.text)
            const hasMarks = query.marks && query.marks.length
            return hasText && hasMarks
          })
          .forEach(query => {
            flatParagraphInfo.push({
              ...query.marks[0].attrs,
              paragraphIndex: index,
            })
          })
      })
    return flatParagraphInfo
  },

  _getParagraphCount(isSpectificQuery, queries) {
    if (!isSpectificQuery) {
      return this._tiptapContent().content.length
    }

    const queriesWithParagraphIndex = this._getFlatParagraph()
    const usedParagraphList = Object.values(queries).map(query => {
      const currentQuery = queriesWithParagraphIndex.find(_query => _query.id === query.id)
      return currentQuery.paragraphIndex
    })
    const usedParagraphSet = new Set(usedParagraphList)
    return usedParagraphSet.size
  },

  _completeParseQueries({actorResult, dataKey, callback, queries, isSpectificQuery}) {
    const querySummeryResult = this._parsrActorResult({actorResult, queries, isSpectificQuery})
    const resultData = {
      actorBy: actorResult,
      summary: querySummeryResult,
    }
    Object.values(actorResult).forEach(actorData => {
      actorData.actor_duration = parseFloat(actorData.actor_duration.toFixed(1), 10)
    })
    querySummeryResult.total_download_duration = Math.ceil(querySummeryResult.total_download_duration)
    querySummeryResult.plan_left_duration = this._creditRemaining()
    querySummeryResult.project_type = this._projectType()

    if (dataKey) {
      this.cachedData[dataKey] = resultData
    }

    callback(resultData)
  },

  _enqueueTask(taskHandler, taskData) {
    this.taskList.push({
      handler: taskHandler,
      data: taskData,
    })

    if (!this.taskHandle) {
      this.taskHandle = requestIdleCallback(this._runTaskQueue.bind(this), {timeout: 1000})
    }
  },

  _runTaskQueue(deadline) {
    while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.taskList.length) {
      const task = this.taskList.shift()
      task.handler(task.data)
    }

    if (this.taskList.length) {
      this.taskHandle = requestIdleCallback(this._runTaskQueue.bind(this), {timeout: 1000})
    } else {
      this.taskHandle = 0
    }
  },

  parseEditorDocument({key, callback, queries, keyOverride}) {
    const _queries = queries || this._queries()
    const queryList = Object.values(_queries)
    const actorResult = {}
    if (key && this.cachedData[key] && !keyOverride) {
      return callback(this.cachedData[key])
    }

    queryList.forEach(query => {
      this._enqueueTask(this._queryParseTask.bind(this), {
        query,
        actorResult,
      })
    })
    this._enqueueTask(this._completeParseQueries.bind(this), {
      actorResult,
      callback,
      dataKey: key,
      queries: _queries,
      isSpectificQuery: queries ? true : false,
    })
  },
}
