import * as TypeGuards from './TiptapDetail.guard';
import Detail from '@/projects/Detail';
import NodeFactory from '@/projects/node/NodeFactory';
import { nanoid } from 'nanoid';
import { getValue } from '@/utils';
export default class TiptapDetail extends Detail {
    constructor(detail) {
        super(detail);
        this.tiptap = createTiptap(detail.tiptap);
    }
    isDetail(detail) {
        return TypeGuards.isTiptapDetail(detail);
    }
}
const createTiptap = (tiptap) => {
    const paragraphs = getValue(tiptap === null || tiptap === void 0 ? void 0 : tiptap.content, []);
    const content = paragraphs.map(paragraph => {
        const nodes = getValue(paragraph.content, []);
        return Object.assign(Object.assign({}, paragraph), { content: nodes.map(node => NodeFactory.factory(node)), attrs: Object.assign(Object.assign({}, paragraph.attrs), { id: getValue(paragraph.attrs.id, nanoid()), actor: getValue(paragraph.attrs.actor, ''), rest: getValue(paragraph.attrs.rest, 0) }) });
    });
    return { type: 'doc', content };
};
export { TypeGuards };
