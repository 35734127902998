import * as TypeGuards from './DetailV4.guard';
import Detail from '@/projects/Detail';
export default class DetailV4 extends Detail {
    constructor(detail) {
        super(detail);
        this.paragraphs = detail.paragraphs;
    }
    isDetail(detail) {
        if (!TypeGuards.isDetailV4(detail)) {
            return false;
        }
        detail.paragraphs.some(paragraph => paragraph.data.some(node => {
            if (node.type === 'sentence') {
                return !TypeGuards.isSentence(node);
            }
            return false;
        }));
        return true;
    }
}
export { TypeGuards };
