export function getSavedDefaultQueryAttrs(paragraphActorId) {
  let savedQueryAttr = localStorage.getItem('DEFAULT_QUERY_ATTR')
  if (savedQueryAttr) {
    savedQueryAttr = JSON.parse(savedQueryAttr)
  } else {
    savedQueryAttr = {}
  }
  let savedQuerySilence
  let savedQuerySpeed
  if (savedQueryAttr.hasOwnProperty(paragraphActorId)) {
    savedQuerySilence = savedQueryAttr[paragraphActorId].silence
    savedQuerySpeed = savedQueryAttr[paragraphActorId].speed
  }

  return {
    savedQuerySilence,
    savedQuerySpeed,
  }
}

export function getSavedDefaultQueryAttrsFromTextNode($textNode) {
  const parent = $textNode.parent.type.name === 'paragraph' ? $textNode.parent : null
  const paragraphActorId = parent ? parent.attrs.actor : null
  return getSavedDefaultQueryAttrs(paragraphActorId)
}
