var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cloneDeep } from 'utils';
import { addElement } from '../virtualDOM/add/addElement';
import { removeElement } from '../virtualDOM/remove/removeElement';
import { getSelectedVPropsList } from '../virtualDOM/get/getSelectedVPropsList';
export let copiedData;
export const paste = () => {
    if (!(copiedData === null || copiedData === void 0 ? void 0 : copiedData.length)) {
        return;
    }
    cloneDeep(copiedData).forEach(addElementWithoutId);
};
const whenSelected = (callback) => () => {
    const selectedVPropsList = getSelectedVPropsList();
    if (!selectedVPropsList.length) {
        return;
    }
    callback(selectedVPropsList);
};
export const copy = whenSelected(vPropsList => (copiedData = vPropsList));
export const duplicate = whenSelected(vPropsList => vPropsList.forEach(addElementWithoutId));
export const remove = whenSelected(vPropsList => {
    const selectedIdList = vPropsList
        .map(({ id }) => id)
        .filter(id => id);
    selectedIdList.forEach(removeElement);
});
const addElementWithoutId = (vProps) => {
    // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-unused-vars
    const { id } = vProps, rest = __rest(vProps, ["id"]);
    addElement(rest, { noShiftOffset: true });
};
