// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderTickCallbackMap = new Map();
export const nextRenderTick = (vProps, renderTickCallback, options) => {
    const { id } = vProps;
    if (!id) {
        console.warn('Please set the id property to use the nextRenderTick function.');
        return;
    }
    if (!renderTickCallbackMap.has(id)) {
        renderTickCallbackMap.set(id, []);
    }
    if (options === null || options === void 0 ? void 0 : options.label) {
        renderTickCallbackMap.get(id).push(
        // @ts-ignore
        bindOnceForElement(renderTickCallback, { label: options.label }));
        return;
    }
    renderTickCallbackMap.get(id).push(renderTickCallback);
};
export const flushRenderTickCallback = () => {
    [...renderTickCallbackMap.entries()].forEach(([id, callbacks]) => {
        const element = document.getElementById(id);
        if (!element) {
            return;
        }
        callbacks.forEach(callback => callback(element));
    });
    renderTickCallbackMap.clear();
};
const bindOnceForElement = (callback, { label } = {}) => (element) => {
    const fieldName = `__once_${label !== null && label !== void 0 ? label : ''}`;
    // @ts-ignore
    if (element[fieldName]) {
        return;
    }
    // @ts-ignore
    element[fieldName] = true;
    callback(element);
};
