var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { END_TRANSITION_DURATION_PROPERTY_NAME, FOR_SUBTITLE_STYLE_DATASET_NAME, MODULE_PREFIX, PLAY_ID, PRE_SILENCE_PROPERTY_NAME, SENTENCE_INDEX_PROPERTY_NAME, SLIDE_INDEX_PROPERTY_NAME, START_TRANSITION_DURATION_PROPERTY_NAME, } from '@/constants';
import { playContainer, scene } from '@/context';
import { addWatermark, getCharacterId, getCorrectedTimelineMediaAsset, getSpeakId, getTimelineVideoId, getTransitionOverlayId, handleMediaBuffering, } from '@/share/render';
import { createVElementId, getCharacterObjectStyle, getSpeakTextObjectStyle, objectStyleToString, } from '@/share/virtualDOM';
import { state } from '@/state';
import { Flags, render } from 'million';
import { useVideoDomStore } from 'store/editor';
import { config } from '@/config';
import { createAssetVElement } from './transforms/createAssetVElement';
import { createSubtitleVElement } from './transforms/createSubtitleVElement';
import { createCharacterVElement, getCharacterInfo, } from './transforms/createCharacterVElement';
import { createVideoVElement } from './transforms/createVideoVElement';
import { createSlideDurationDictList } from './transforms/createSlideDurationDictList';
import { getBackgroundColor } from '@/share/render/getBackgroundColor';
const SLIDE_CLASS_NAME = `${MODULE_PREFIX}play-container__slide`;
export const renderPlayContainer = () => __awaiter(void 0, void 0, void 0, function* () {
    const playContainerVElement = yield createPlayContainerVElement();
    addWatermark(playContainerVElement);
    // NOTE: playDOM 렌더 이후 가상 돔 라이브러리(millionJS) 외부에서 DOM 조작이
    // 필요하기에(model/index.ts의 toggle() 함수) 아래와 같이 실제 DOM으로 변환하는 작업을 거침
    const tmp = document.createElement('div');
    render(tmp, playContainerVElement);
    const playContainerDOM = tmp.innerHTML;
    // @ts-ignore
    playContainer.innerHTML = playContainerDOM;
    handleMediaBuffering(playContainer, scene);
});
export const createPlayContainerVElement = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const filteredSlideList = slideList.filter(({ speakList }) => speakList === null || speakList === void 0 ? void 0 : speakList.length);
    const slideDurationDictList = createSlideDurationDictList(filteredSlideList);
    const slideVElementList = filteredSlideList.map(createSlideVElement(slideDurationDictList, options));
    const { timelineAssetLists } = state;
    const promisedTimelineVideoVElementList = timelineAssetLists.video
        .map(getCorrectedTimelineMediaAsset)
        // NOTE: startTrim과 endTrim이 같은 경우 비디오 길이가 0인 것
        .filter(({ startTrim, endTrim }) => startTrim !== endTrim)
        .map(createTimelineVideoVElement);
    const timelineVideoVElementList = yield Promise.all(promisedTimelineVideoVElementList);
    slideVElementList.push(...timelineVideoVElementList);
    return {
        tag: 'div',
        props: {
            id: PLAY_ID,
            style: objectStyleToString({
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
                backgroundColor: videoDomStore.globalBackgroundColor,
            }),
        },
        children: slideVElementList,
        flag: Flags.ELEMENT,
    };
});
const createSlideVElement = (slideDurationDictList, options) => (slideVProps, slideIndex, { length: slideLength }) => {
    const subtitleContainerVElement = createSubtitleContainerVElement(slideVProps);
    const characterContainerVElement = createCharacterContainerVElement(slideVProps, options === null || options === void 0 ? void 0 : options.isDownload);
    const assetVElement = createAssetVElement(slideVProps, {
        isNotSelectable: false,
    });
    const transitionOverlayVElement = createTransitionOverlayVElement(slideVProps);
    const children = [
        subtitleContainerVElement,
        characterContainerVElement,
        assetVElement,
        transitionOverlayVElement,
    ];
    const originStyle = Object.assign(Object.assign({}, slideVProps.style), { backgroundColor: getBackgroundColor({
            slideVProps,
            isPlayContainer: true,
        }) });
    const stringStyle = objectStyleToString(originStyle);
    const zIndex = (slideLength - slideIndex) * 10;
    const stringStyleWithZIndex = `${stringStyle}; z-index: ${zIndex}; position: absolute;`;
    const props = {
        id: slideVProps.id,
        style: stringStyleWithZIndex,
        class: SLIDE_CLASS_NAME,
    };
    if (options === null || options === void 0 ? void 0 : options.isDownload) {
        const slideDurationDict = slideDurationDictList[slideIndex];
        const { startTransitionDuration, endTransitionDuration, preSilence } = slideDurationDict;
        props.isRealCharacter = true;
        props[SLIDE_INDEX_PROPERTY_NAME] = slideIndex;
        props[START_TRANSITION_DURATION_PROPERTY_NAME] = startTransitionDuration;
        props[END_TRANSITION_DURATION_PROPERTY_NAME] = endTransitionDuration;
        props[PRE_SILENCE_PROPERTY_NAME] = preSilence;
    }
    return {
        tag: 'div',
        props,
        children,
        flag: Flags.ELEMENT,
    };
};
const createSubtitleContainerVElement = (slideVProps) => {
    const { containerStyle } = getSpeakTextObjectStyle();
    const subtitleContainerVElement = {
        tag: 'div',
        props: {
            id: createVElementId(),
            style: objectStyleToString(containerStyle),
        },
        children: [],
        flag: Flags.ELEMENT,
    };
    const { id: slideId, speakList } = slideVProps;
    return speakList.reduce((subtitleContainerVElement, { text, queryId }, speakIndex) => {
        const subtitleToggleVElement = createSubtitleVElement(slideVProps, {
            setEmptySubtitle: true,
            queryId,
        });
        const speakId = getSpeakId(slideId, speakIndex);
        subtitleToggleVElement.props.id = speakId;
        subtitleToggleVElement.props.style = 'width: 100%';
        const wrapper = subtitleToggleVElement.children[0];
        const subtitleTextVElement = wrapper.children[0];
        subtitleTextVElement.props[FOR_SUBTITLE_STYLE_DATASET_NAME] = text;
        subtitleTextVElement.props.textContent = text;
        subtitleContainerVElement.children.push(subtitleToggleVElement);
        return subtitleContainerVElement;
    }, subtitleContainerVElement);
};
const createCharacterContainerVElement = (slideVProps, isDownload = false) => {
    const { isRealCharacter, bodyType, characterType } = getCharacterInfo(slideVProps);
    const { characterContainerStyle } = getCharacterObjectStyle(slideVProps, {
        isRealCharacter,
        bodyType,
    });
    const characterContainerVElement = {
        tag: 'div',
        props: {
            id: createVElementId(),
            style: objectStyleToString(characterContainerStyle),
        },
        children: [],
        flag: Flags.ELEMENT,
    };
    if (!isDownload && isRealCharacter && characterType !== 'LIVE2D') {
        const mouthMaskVElement = createMouthMaskVElement(slideVProps);
        characterContainerVElement.children.push(mouthMaskVElement);
    }
    const { id: slideId, speakList } = slideVProps;
    return speakList.reduce((characterContainerVElement, _, speakIndex) => {
        const characterToggleVElement = createCharacterVElement(slideVProps, {
            useVideoTagForRealCharacter: true,
            isDownload,
        });
        const characterId = getCharacterId(slideId, speakIndex);
        characterToggleVElement.props.style = 'height: 100%';
        // TODO: characterId는 renderPlayScene(SceneJS)에서
        // Toggle 하는 요소의 id로 사용되고 있음
        characterToggleVElement.props.id = characterId;
        if (isDownload && isRealCharacter) {
            const characterVideoVElement = characterToggleVElement.children[0];
            // NOTE: 프런트엔드에서는 `#${characterId} video` 요소를 play 하지만
            // 백엔드에서는 `#${characterId}` 요소를 video 요소로 판단하고 play 함
            // renderPlayModeScene의 createMediaScene() 내에 존재하는
            // realCharacter videoElement 가져오는 로직에서 이 차이를 볼 수 있음
            characterVideoVElement.props.id = characterId;
            characterToggleVElement.props.id = createVElementId();
            characterVideoVElement.props[SENTENCE_INDEX_PROPERTY_NAME] = speakIndex;
        }
        characterContainerVElement.children.push(characterToggleVElement);
        return characterContainerVElement;
    }, characterContainerVElement);
};
const createMouthMaskVElement = (slideVProps) => {
    var _a;
    const { bodyType } = getCharacterInfo(slideVProps);
    const characterScale = (_a = slideVProps.characterScale) !== null && _a !== void 0 ? _a : 1;
    const scale = 1.5 + (0.5 - characterScale) * 0.75;
    const vProps = {
        style: {
            position: 'absolute',
            width: '450px',
            backgroundColor: '#262626',
            borderRadius: '16px',
            fontSize: '27px',
            padding: '36px',
            textAlign: 'center',
            transform: `scale(${scale})`,
            zIndex: 9999,
        },
        class: 't-head8 bold',
    };
    if (bodyType === 'UPPER') {
        const topPx = 410;
        const topScale = 1.1 + (0.5 - characterScale) * 0.15;
        vProps.style.top = `calc(${topPx}px * ${topScale})`;
    }
    else {
        const topPx = 192;
        const topScale = 1.2 + (0.5 - characterScale) * 0.25;
        vProps.style.top = `calc(${topPx}px * ${topScale})`;
    }
    const { i18nConverter } = config;
    return {
        tag: 'div',
        children: [
            i18nConverter('video_download_preview_modal.virtual_actor_masking_msg'),
        ],
        props: {
            id: createVElementId(),
            style: objectStyleToString(vProps.style),
            class: vProps.class,
        },
        flag: Flags.ELEMENT,
    };
};
const createTransitionOverlayVElement = (slideVProps) => {
    const { id: slideId } = slideVProps;
    const transitionOverlayId = getTransitionOverlayId(slideId);
    const vProps = {
        style: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 9999,
        },
    };
    return {
        tag: 'div',
        props: {
            id: transitionOverlayId,
            style: objectStyleToString(vProps.style),
        },
        flag: Flags.ELEMENT,
    };
};
const createTimelineVideoVElement = (timelineVideo) => __awaiter(void 0, void 0, void 0, function* () {
    const timelineVideoVElement = (yield createVideoVElement(timelineVideo));
    timelineVideoVElement.props.id = getTimelineVideoId(timelineVideo, true);
    return {
        tag: 'div',
        props: {
            id: getTimelineVideoId(timelineVideo),
            style: objectStyleToString({
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0',
                left: '0',
            }),
        },
        children: [timelineVideoVElement],
        flag: Flags.ELEMENT,
    };
});
