var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { editContainer } from '@/context';
import { Flags, batch, render } from 'million';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
import { flushRenderTickCallback } from './nextRenderTick';
import { objectStyleToString } from '@/share/virtualDOM';
import { createVideoVElement } from './transforms/createVideoVElement';
import { createSubtitleVElement, } from './transforms/createSubtitleVElement';
import { createCharacterVElement } from './transforms/createCharacterVElement';
import { createAssetVElement } from './transforms/createAssetVElement';
import { getBackgroundColor } from '@/share/render/getBackgroundColor';
const queuedRender = batch();
export const renderEditContainer = () => queuedRender(() => __awaiter(void 0, void 0, void 0, function* () {
    const videoDomStore = useVideoDomStore();
    const { slideList, slideTimelineDurationList } = videoDomStore;
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex } = editorOptionsStore;
    const currentSlide = slideList[slideIndex];
    if (!currentSlide) {
        return;
    }
    const timelineDuration = slideTimelineDurationList[slideIndex];
    const slideVElement = yield createSlideVElement(currentSlide, timelineDuration, {
        editable: true,
    });
    render(editContainer, slideVElement);
    flushRenderTickCallback();
}));
export const createSlideVElement = (slideVProps, timelineDuration, options) => __awaiter(void 0, void 0, void 0, function* () {
    setDefaultSize(slideVProps);
    const characterVElement = createCharacterVElement(slideVProps);
    const assetVElement = createAssetVElement(slideVProps);
    const children = [characterVElement, assetVElement];
    if (!(options === null || options === void 0 ? void 0 : options.skipRenderSubtitleVElement)) {
        const subtitleVElement = createSubtitleVElement(slideVProps, options);
        children.unshift(subtitleVElement);
    }
    const slideId = slideVProps.id;
    const style = Object.assign(Object.assign({}, slideVProps.style), { backgroundColor: getBackgroundColor({
            slideVProps,
            isPlayContainer: false,
        }) });
    const props = {
        id: slideId,
        class: slideVProps.class,
        style: objectStyleToString(style),
    };
    if (!timelineDuration) {
        return {
            tag: 'div',
            flag: Flags.ELEMENT,
            children,
            props,
        };
    }
    const { offset, duration } = timelineDuration;
    const videoThumbnailVElement = yield createVideoVElement([
        offset,
        offset + duration,
    ]);
    if (videoThumbnailVElement) {
        children.unshift(videoThumbnailVElement);
    }
    return {
        tag: 'div',
        flag: Flags.ELEMENT,
        children,
        props,
    };
});
const setDefaultSize = (slideVProps) => {
    if (!slideVProps.style) {
        slideVProps.style = {};
    }
    const { style } = slideVProps;
    const { width, height } = style;
    if (!width) {
        style.width = '100%';
    }
    if (!height) {
        style.height = '100%';
    }
    return slideVProps;
};
