import Scene from 'scenejs';
import Media from './Media';
export default class MediaScene extends Scene {
    constructor() {
        super();
    }
    /**
     * add video or audio mediaInfo
     * @param - unique id
     * @param - audio
     */
    addMedia(id, url = id) {
        const media = new Media(url);
        this.setItem(id, media);
        return media;
    }
    getInfo() {
        const info = {
            duration: 0,
            medias: [],
        };
        this.forEach(media => {
            if (media instanceof Media) {
                info.medias.push(media.getInfo());
            }
        });
        info.duration = this.getDuration();
        return info;
    }
    preloadResource() {
        this.forEach(item => {
            if (item instanceof Media) {
                item.preloadResource();
            }
        });
    }
}
