import { stringTransformToObject } from '@/share/virtualDOM';
export const transformToFrame = (transform) => {
    const frame = {
        translate: [0, 0],
        scale: [1, 1],
        rotate: 0,
    };
    if (!transform || !transform.length) {
        return frame;
    }
    const parsedTransform = stringTransformToObject(transform);
    if (parsedTransform.translate) {
        frame.translate = parsedTransform.translate;
    }
    if (parsedTransform.scale) {
        frame.scale = parsedTransform.scale;
    }
    if (parsedTransform.rotate) {
        const rotate = parseFloat(parsedTransform.rotate.toString().replace(/[^\d.-]/g, ''));
        frame.rotate = rotate;
    }
    return frame;
};
