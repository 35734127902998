import isEmpty from './is-empty'

const pixelDataTable = {
  payment: {
    add_card: {
      event: 'AddPaymentInfo',
      paramKeys: ['content_category'],
    },
    change_card: {
      event: 'AddPaymentInfo',
      paramKeys: ['content_category'],
    },
    change_plan: {
      event: 'InitiateCheckout',
      paramKeys: ['content_category', 'currency', 'value'],
    },
    charge_character: {
      event: 'InitiateCheckout',
      paramKeys: ['content_category', 'currency', 'value'],
    },
    charge_character_complete: {
      event: 'Purchase',
      paramKeys: ['content_type', 'currency', 'value'],
    },
    select_plan: {
      event: 'AddToCart',
      paramKeys: ['content_name', 'content_type', 'currency', 'value'],
    },
    start_payment: {
      event: 'InitiateCheckout',
      paramKeys: ['content_category', 'currency', 'value'],
    },
    success_payment: {
      event: 'Subscribe',
      paramKeys: ['currency', 'value'],
    },
  },
  before_login: {
    send_issue: {
      event: 'Contact',
    },
  },
  before_outboundlink: {
    link_free_trial: {
      event: 'StartTrial',
    },
  },
  before_signup: {
    register_email_verified: {
      event: 'CompleteRegistration',
      paramKeys: ['content_name'],
    },
    send_email_contact_us: {
      event: 'Contact',
    },
  },
}

function noPixelEvent({category, action}) {
  return !(Object.keys(pixelDataTable).includes(category) && pixelDataTable[category].hasOwnProperty(action))
}
function facebookPixelEvent({category, action, pixelData}) {
  if (noPixelEvent({category, action})) {
    return
  }

  const pixelItem = pixelDataTable[category][action]
  const pixelEvent = pixelItem.event
  const pixelParamKeys = pixelItem.paramKeys
  if (!pixelEvent) {
    console.error('This event is not in the pixelDataTable.')
    return
  }

  if (pixelParamKeys) {
    if (!pixelData) {
      console.error(`Pixel data are required. (ga event: category=${category}, action=${action}`)
      return
    }

    const trackedPixelKeys = Object.keys(pixelData)
    const pixelKeysOfIntersection = trackedPixelKeys.filter(key => pixelParamKeys.includes(key))
    if (pixelKeysOfIntersection.length !== pixelParamKeys.length) {
      console.error(`It doesn't matched between Pixel data table's parameters and tracked pixel parameters`)
      return
    }

    const trackedPixelValues = Object.values(pixelData)
    const pixelParams = pixelParamKeys.reduce((acc, cur, i) => {
      const key = cur
      const value = trackedPixelValues[i]
      acc[key] = value
      return acc
    }, {})

    this.$analytics.fbq.event(pixelEvent, pixelParams)
  } else {
    this.$analytics.fbq.event(pixelEvent)
  }
}

const nsEvent = {
  /**
   * @memberof nsEvent
   * @method event
   * @param {Object} param
   * @param {string} param.action
   * @param {string} [param.category]
   * @param {unknown} [param.label]
   * @param {unknown} [param.pixelData]
   */
  event({category, action, label, pixelData}) {
    const me = this.$store.state.typecast.user.me
    const isVideoPage = this.$store._vm.$typecast.$editor._isVideoPage

    if (isVideoPage && category === 'after_edit') {
      category = 'after_video_edit'
    } else if (!isVideoPage && category === 'after_video') {
      category = 'after_edit_video'
    }

    let userParam = {}
    if (category) {
      userParam.category = category
    }
    if (label) {
      userParam = {...userParam, ...label}
    }
    if (!isEmpty(me)) {
      userParam = {...userParam, uid: me.uid}
      if (me.plan.current.name !== 'free_id') {
        userParam.payment = 'paid'
      } else {
        userParam.payment = 'free'
      }
    }

    if (!isEmpty(userParam)) {
      this.$gtag?.event(action, userParam)
    } else {
      this.$gtag?.event(action)
    }
    facebookPixelEvent.call(this, {category, action, pixelData})
  },
  conversion({typeOfAction}) {
    const conversionActionIds = {
      basic_id: 'hIgiCJ2Ll4oCEL-8nN8C',
      pro_id: 'ytwICOGNl4oCEL-8nN8C',
      business_id: 'Y0B7CM_amooCEL-8nN8C',
      register_complete: 'sBkGCLiQ3v8BEL-8nN8C',
    }
    const conversionActionId = conversionActionIds[typeOfAction]
    this.$gtag.event('conversion', {send_to: `AW-736566847/${conversionActionId}`})
  },
}

const VueEventPlugin = {
  install(Vue, params) {
    nsEvent.$analytics = params.$analytics
    nsEvent.$store = params.$store
    nsEvent.$gtag = params.$gtag
    Object.defineProperty(Vue.prototype, '$nsEvent', {
      get() {
        return nsEvent
      },
    })
  },
}

export {nsEvent, VueEventPlugin}
