export const state = {
    mode: 'edit',
    characterList: [],
    realCharacterList: [],
    timelineAssetLists: { video: [], bgm: [] },
};
export const setState = (key, value) => {
    if (typeof value === 'function') {
        value = value(state[key]);
    }
    state[key] = value;
};
