import CONSTANTS from '@/config/constants'
import downloadData from '@/libs/modules/mixpanel/downloadData'
import {useVideoDomStore} from 'store/editor'

export default {
  _store: null,

  setStore(store) {
    this._store = store
  },

  _queries() {
    return this._store.getters['typecast/queryCache/queries']
  },

  _actors() {
    return this._store.getters['typecast/actor/actors']
  },

  _slideList() {
    return this._videoDomStore().slideList
  },

  _timelineVideoList() {
    return this._store.state.typecast.videoEditor.timelineAssetLists.video
  },

  _timelineBgmList() {
    return this._store.state.typecast.videoEditor.timelineAssetLists.bgm
  },

  _videoDomStore() {
    return useVideoDomStore()
  },

  _tiptapContent() {
    return this._store.state.typecast.editor.tiptapContent
  },

  _createVideoDataSkeleton() {
    return {
      num_slide: 0,
      num_character_outfits: 0,
      num_character_display: 0,
      num_character_scale: 0,
      num_character_flip: 0,
      num_character_position: 0,
      num_slide_transition: 0,
      num_animation_buildin: 0,
      num_animation_buildout: 0,
      num_background_color: 0,
      num_caption_used: 0,
      num_caption_edited: 0,
      num_caption_style: 0,
      num_caption_font: 0,
      num_caption_size: 0,
      num_caption_position: 0,
    }
  },

  _countEditedSubtitle(slide) {
    if (!slide.subtitleMap) {
      return 0
    }

    return Object.keys(slide.subtitleMap).length
  },

  _checkIsSlideSubtitleVisible(slide, tiptapParagraph) {
    if (!tiptapParagraph?.content) {
      return false
    }

    const slideQueryList = tiptapParagraph.content.filter(({type}) => type === 'text')
    const slideQueryLength = slideQueryList.length
    const slideSubtitleLength = this._countEditedSubtitle(slide)
    if (slideQueryLength !== slideSubtitleLength) {
      return true
    }

    return !Object.values(slide.subtitleMap || {}).every(({invisible}) => invisible)
  },

  _isVideoRealActor(actorId) {
    const actor = this._actors().find(actor => actorId === actor.actor_id)
    const isVideoReal = actor.character_flags.includes('video-real')
    return isVideoReal
  },

  _getQueriesPerSlide() {
    const table = {}
    const queryList = Object.values(this._queries())
    queryList.forEach(query => {
      const {paragraphIndex: idx, id} = query
      if (!table[idx]) {
        table[idx] = {}
      }
      table[idx][id] = query
    })

    return table
  },

  _getUsedAssetData() {
    const usedImages = !!this._slideList().find(
      slide => slide.children && slide.children.find(child => child.type === 'image'),
    )
    const usedVideos = this._timelineVideoList().length > 0
    const usedBgm = this._timelineBgmList().length > 0
    return {used_images: usedImages, used_videos: usedVideos, used_bgm: usedBgm}
  },

  parseVideoData(data, slide, tiptapParagraph) {
    const actorId = slide.characterId
    const isVideoReal = this._isVideoRealActor(actorId)

    data.num_slide += 1
    data.num_character_outfits += convertToNum(slide.characterSkinType === 'A')
    data.num_character_display += isVideoReal ? convertToNum(!slide.characterVisibility) : 0
    data.num_character_scale += convertToNum(slide.characterScale === 1)
    data.num_character_flip += convertToNum(!slide.characterFlip)
    data.num_character_position += convertToNum(slide.characterPosition === 'left')
    data.num_slide_transition += convertToNum(!slide.transition)
    data.num_animation_buildin += convertToNum(slide.characterBuildInAnimation === 'none')
    data.num_animation_buildout += convertToNum(slide.characterBuildOutAnimation === 'none')
    data.num_background_color += convertToNum(slide.backgroundColor === '#00000000')
    data.num_caption_used += convertToNum(this._checkIsSlideSubtitleVisible(slide, tiptapParagraph))
    data.num_caption_edited += this._countEditedSubtitle(slide)

    return data
  },

  /**
   * @param {ReturnType<typeof useVideoDomStore>['subtitle']} subtitleState
   */
  parseVideoSubtitleData(data, subtitleState) {
    const {fontFamily, style, size, position} = subtitleState

    data.num_caption_style += convertToNum(style === 'bg_short')
    data.num_caption_font += convertToNum(fontFamily === 'Noto Sans KR')
    data.num_caption_size += convertToNum(size === 'medium')
    data.num_caption_position += convertToNum(position === 'bottom')

    return data
  },

  parseVideo({key, sendEvent}) {
    const videoData = this._createVideoDataSkeleton()
    const tiptapContent = this._tiptapContent().content
    this._slideList().forEach((slide, slideIndex) => this.parseVideoData(videoData, slide, tiptapContent[slideIndex]))

    const {subtitle} = this._videoDomStore()
    this.parseVideoSubtitleData(videoData, subtitle)

    const assetData = this._getUsedAssetData()

    const callback = editorData => {
      sendEvent({...videoData, ...assetData, ...editorData.summary})
    }

    return downloadData.parseEditorDocument({key, callback})
  },

  parseVideoSlide({key, sendEvent}) {
    const querySlideTable = this._getQueriesPerSlide()
    const slideIndexList = Object.keys(querySlideTable)

    slideIndexList.forEach(idx => {
      const slide = this._slideList()[idx]
      const queries = querySlideTable[idx]
      const actorId = Object.values(queries)[0].actor
      const isVideoReal = this._isVideoRealActor(actorId)
      const videoData = {
        character_outfits: isVideoReal ? slide.characterSkinType : 'None',
        character_display: slide.characterVisibility,
        character_scale: slide.characterScale,
        character_flip: slide.characterFlip,
        character_position: slide.characterPosition,
        slide_transition: slide.transition || 'None',
        animation_buildin: !!slide.characterBuildInAnimation,
        animation_buildout: !!slide.characterBuildOutAnimation,
        caption_used: this._countEditedSubtitle(slide) > 0,
        num_caption_edited: this._countEditedSubtitle(slide),
        background_color: CONSTANTS.COLOR[slide.backgroundColor],
      }

      const callback = editorData => {
        sendEvent({download_id: key, ...videoData, ...editorData.summary, ...editorData.actorBy[actorId], slide})
      }

      downloadData.parseEditorDocument({callback, queries})
    })
  },
}

const convertToNum = isDefaultData => (isDefaultData ? 0 : 1)
