import { useVideoDomStore } from 'store/editor';
import { state } from '@/state';
const TRANSPARENT_HEX = '#00000000';
export const getBackgroundColor = ({ slideVProps: { backgroundColor }, isPlayContainer, }) => {
    const videoDomStore = useVideoDomStore();
    const { globalBackgroundColor } = videoDomStore;
    const { timelineAssetLists } = state;
    const useTimelineVideoList = timelineAssetLists.video.length > 0;
    const isSlideBackgroundColorTransparent = !backgroundColor || backgroundColor === TRANSPARENT_HEX;
    const useGlobalBackgroundColor = useTimelineVideoList || isSlideBackgroundColorTransparent;
    if (useGlobalBackgroundColor) {
        return isPlayContainer ? TRANSPARENT_HEX : globalBackgroundColor;
    }
    return backgroundColor;
};
