var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pause, play, playContainer, setSeekTime } from '@/context';
import { autoResizeWithParent } from '@/context/container/autoResizeWithParent';
import { resizeWithParentElement } from '@/share/context';
/** @public */
export const exportPreviewDOM = (targetElement) => __awaiter(void 0, void 0, void 0, function* () {
    if (!playContainer || !targetElement) {
        return;
    }
    const playRootContainer = playContainer.parentElement;
    targetElement.appendChild(playRootContainer);
    autoResizeWithParent(playRootContainer);
    resizeWithParentElement(playRootContainer);
    yield renderPlayContainerAndSetTime0();
});
const renderPlayContainerAndSetTime0 = () => __awaiter(void 0, void 0, void 0, function* () {
    yield play();
    pause();
    setSeekTime(0);
});
