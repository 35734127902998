import * as TypeGuards from './Project.guard';
import { getValue } from '@/utils';
export default class Project {
    constructor(project) {
        var _a, _b;
        this.validate(project);
        // 기존과 동일한 동작을 보장하기 위해 모든 키를 일단 주입
        Object.keys(project).forEach((key) => {
            // @ts-ignore
            this[key] = project[key];
        });
        if (typeof project._id === 'string') {
            this._id = { $oid: project._id, created_date: Date.now() };
        }
        else {
            this._id = getValue(project._id, null);
        }
        this.clientId = getValue(project.clientId, null);
        this.uid = getValue(project.uid, null);
        this.name = project.name;
        this.version = getValue(project.version, 0);
        this.media_type = getValue(project.media_type, 'audio');
        this.project_url = getValue(project.project_url, '');
        this.project_v2_url = getValue(project.project_v2_url, '');
        this.revision = getValue(project.revision, 1);
        this.share = Object.assign(Object.assign({}, project.share), { user_list: getValue((_a = project.share) === null || _a === void 0 ? void 0 : _a.user_list, []), history: getValue((_b = project.share) === null || _b === void 0 ? void 0 : _b.history, []) });
        this.last_modified_date = getValue(project.last_modified_date, Date.now());
        this.actor_ids = getValue(project.actor_ids, []);
        this.folder = getValue(project.folder, { _id: null, name: null });
        this.subtitle = getValue(project.subtitle, this.getDefaultSubtitle());
        this.bg_color = getValue(project.bg_color, '#ffffff');
    }
    validate(project) {
        if (process.env.NODE_ENV === 'production') {
            return;
        }
        if (!TypeGuards.isProject(project) || !this.isProject(project)) {
            throw new Error();
        }
    }
    isShortsProject() {
        return this.media_type === 'shorts';
    }
    isOldVideoProject() {
        return this.media_type === 'video';
    }
    getDefaultSubtitle() {
        return {
            font_family: getInitialFontFamily(),
            style: 'bg_short',
            size: 'medium',
            position: 'bottom',
        };
    }
}
export { TypeGuards };
const getInitialFontFamily = () => {
    const language = navigator.language;
    const normalizedLang = language.split('-')[0];
    if (normalizedLang === 'ko') {
        return 'Noto Sans KR';
    }
    if (normalizedLang === 'ja') {
        return 'Noto Sans JP';
    }
    return 'Noto Sans';
};
