import AnimationSceneItem from '@/model/animations/AnimationSceneItem';
export default class FadeAnimationSceneItem extends AnimationSceneItem {
    constructor(animationPosition, slideDurationWithoutTransition, half) {
        super(animationPosition, 'fade', slideDurationWithoutTransition, half);
        this.set(this.getKeyFrame());
    }
    getKeyFrame() {
        if (this.animationPosition === 'start') {
            return {
                '0%': {
                    opacity: '0',
                },
                '100%': {
                    opacity: '1',
                },
            };
        }
        return {
            '0%': {
                opacity: '1',
            },
            '100%': {
                opacity: '0',
            },
        };
    }
    calculateDuration() {
        const duration = super.calculateDuration();
        return Math.min(duration, this.slideDurationWithoutTransition / 2);
    }
}
