import NoneAnimationSceneItem from '@/model/animations/NoneAnimationSceneItem';
import FadeAnimationSceneItem from '@/model/animations/FadeAnimationSceneItem';
import FadeOverlayAnimationSceneItem from '@/model/animations/FadeOverlayAnimationSceneItem';
import SmoothAnimationSceneItem from '@/model/animations/SmoothAnimationSceneItem';
export const createAnimationSceneItem = (position, animation = 'none', half = false, slideDurationWithoutTransition = Number.MAX_SAFE_INTEGER) => {
    switch (animation) {
        case 'fade':
            return new FadeAnimationSceneItem(position, slideDurationWithoutTransition, half);
        case 'fadeblack':
            return new FadeOverlayAnimationSceneItem(position, slideDurationWithoutTransition, half, 'black');
        case 'fadewhite':
            return new FadeOverlayAnimationSceneItem(position, slideDurationWithoutTransition, half, 'white');
        case 'smoothup':
            return new SmoothAnimationSceneItem(position, slideDurationWithoutTransition, half, 'up');
        case 'smoothdown':
            return new SmoothAnimationSceneItem(position, slideDurationWithoutTransition, half, 'down');
        case 'smoothleft':
            return new SmoothAnimationSceneItem(position, slideDurationWithoutTransition, half, 'left');
        case 'smoothright':
            return new SmoothAnimationSceneItem(position, slideDurationWithoutTransition, half, 'right');
        default:
            // Deprecated
            return new NoneAnimationSceneItem(position);
    }
};
