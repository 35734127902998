var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _StateHistory_currentStateIndex, _StateHistory_maxLenOfStates, _StateHistory_states, _StateHistory_canUndo, _StateHistory_canRedo, _StateHistory_clearAfterCurrentStates, _StateHistory_decreaseIndex, _StateHistory_increaseIndex, _StateHistory_updateCanMoveHistory;
const DEFAULT_MAX_LEN_STATE = 40;
const MIN_LEN_STATE = 2;
export class StateHistory {
    get canUndo() {
        return __classPrivateFieldGet(this, _StateHistory_canUndo, "f");
    }
    get canRedo() {
        return __classPrivateFieldGet(this, _StateHistory_canRedo, "f");
    }
    constructor(options) {
        var _a;
        _StateHistory_currentStateIndex.set(this, 0);
        _StateHistory_maxLenOfStates.set(this, DEFAULT_MAX_LEN_STATE);
        _StateHistory_states.set(this, []);
        _StateHistory_canUndo.set(this, false);
        _StateHistory_canRedo.set(this, false);
        _StateHistory_clearAfterCurrentStates.set(this, () => __classPrivateFieldGet(this, _StateHistory_states, "f").splice(__classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") + 1));
        _StateHistory_decreaseIndex.set(this, () => {
            __classPrivateFieldSet(this, _StateHistory_currentStateIndex, __classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") - 1, "f");
            __classPrivateFieldGet(this, _StateHistory_updateCanMoveHistory, "f").call(this);
        });
        _StateHistory_increaseIndex.set(this, () => {
            __classPrivateFieldSet(this, _StateHistory_currentStateIndex, __classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") + 1, "f");
            __classPrivateFieldGet(this, _StateHistory_updateCanMoveHistory, "f").call(this);
        });
        _StateHistory_updateCanMoveHistory.set(this, () => {
            __classPrivateFieldSet(this, _StateHistory_canUndo, __classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") > 0, "f");
            __classPrivateFieldSet(this, _StateHistory_canRedo, __classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") < __classPrivateFieldGet(this, _StateHistory_states, "f").length - 1, "f");
        });
        const depth = (_a = options === null || options === void 0 ? void 0 : options.depth) !== null && _a !== void 0 ? _a : DEFAULT_MAX_LEN_STATE;
        if (typeof depth !== 'number') {
            throw new Error('`depth` must be `number` type');
        }
        if (depth < MIN_LEN_STATE) {
            throw new Error('`depth` cannot be less than 1');
        }
        __classPrivateFieldSet(this, _StateHistory_maxLenOfStates, depth, "f");
    }
    undo() {
        if (!__classPrivateFieldGet(this, _StateHistory_canUndo, "f")) {
            return;
        }
        __classPrivateFieldGet(this, _StateHistory_decreaseIndex, "f").call(this);
        return __classPrivateFieldGet(this, _StateHistory_states, "f")[__classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f")];
    }
    redo() {
        if (!__classPrivateFieldGet(this, _StateHistory_canRedo, "f")) {
            return;
        }
        __classPrivateFieldGet(this, _StateHistory_increaseIndex, "f").call(this);
        return __classPrivateFieldGet(this, _StateHistory_states, "f")[__classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f")];
    }
    peek() {
        if (__classPrivateFieldGet(this, _StateHistory_states, "f").length === 0) {
            return;
        }
        return __classPrivateFieldGet(this, _StateHistory_states, "f")[__classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f")];
    }
    push(state) {
        if (!state) {
            return;
        }
        const isStateFull = __classPrivateFieldGet(this, _StateHistory_currentStateIndex, "f") + 1 >= __classPrivateFieldGet(this, _StateHistory_maxLenOfStates, "f");
        if (isStateFull) {
            __classPrivateFieldGet(this, _StateHistory_states, "f").shift();
            __classPrivateFieldGet(this, _StateHistory_decreaseIndex, "f").call(this);
        }
        __classPrivateFieldGet(this, _StateHistory_clearAfterCurrentStates, "f").call(this);
        __classPrivateFieldGet(this, _StateHistory_states, "f").push(state);
        if (__classPrivateFieldGet(this, _StateHistory_states, "f").length === 1) {
            return;
        }
        __classPrivateFieldGet(this, _StateHistory_increaseIndex, "f").call(this);
    }
}
_StateHistory_currentStateIndex = new WeakMap(), _StateHistory_maxLenOfStates = new WeakMap(), _StateHistory_states = new WeakMap(), _StateHistory_canUndo = new WeakMap(), _StateHistory_canRedo = new WeakMap(), _StateHistory_clearAfterCurrentStates = new WeakMap(), _StateHistory_decreaseIndex = new WeakMap(), _StateHistory_increaseIndex = new WeakMap(), _StateHistory_updateCanMoveHistory = new WeakMap();
