<template>
  <label class="t-radio-container" v-on="radioListeners" :for="id" :class="[{disabled: disabled}, radioSizeClass]">
    <slot name="default"></slot>
    <input
      type="radio"
      :id="id"
      :name="name"
      :disabled="disabled"
      :checked="isChecked"
      :value="value"
      :class="radioSizeClass"
      @change="updateRadioState"
    />
    <span class="radio-mark" :class="radioSizeClass"></span>
  </label>
</template>

<script>
import {defineComponent, isVue2} from 'vue-demi'

export default defineComponent({
  name: 'TRadio',
  model: {
    prop: 'checked',
    event: 'custom-change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: [String, Number, Boolean],
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    radioListeners() {
      if (isVue2) {
        return this.$listeners
      }

      return this.$attrs
    },
    isChecked() {
      return this.value === this.checked
    },
    radioSizeClass() {
      if (this.medium) {
        return 'medium'
      }
      if (this.large) {
        return 'large'
      }
      return this.size
    },
  },
  methods: {
    updateRadioState(e) {
      this.$emit('custom-change', e.target.value)
    },
  },
})
</script>
