/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore EventHookOn 타입을 export하기 위해 EventHookOn을 import
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createEventHook } from '@vueuse/core';
import { markRaw, ref } from 'vue';
const IS_DEV = process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost';
const modalList = ref([]);
const { on: onOpenModal, trigger: triggerOpenModal } = createEventHook();
export { onOpenModal };
export const useModal = () => {
    const openModal = (modal, props, emits) => {
        if (IS_DEV) {
            if (!modal.name) {
                console.warn('Modal name is required');
            }
            if (emits && Array.isArray(emits)) {
                console.warn('Emits should be an object');
            }
        }
        const maybeExistsModal = modalList.value.find(({ id }) => id === modal.name);
        if (maybeExistsModal) {
            return;
        }
        triggerOpenModal(undefined);
        return new Promise((resolve, reject) => modalList.value.push({
            id: modal.name,
            component: markRaw(modal),
            props,
            resolve,
            reject,
            emits,
        }));
    };
    const closeModal = (id) => {
        modalList.value = modalList.value.filter(m => m.id !== id);
    };
    return { modalList, openModal, closeModal };
};
