import { config } from '@/config';
import { EDIT_CONTAINER_ID } from '@/constants';
import { setZoom } from '@/context/moveable';
import { resizeWithParentElement } from '@/share/context';
import { debounce } from 'utils';
export const autoResizeWithParent = (container) => {
    let outerContainer = container.parentElement;
    new ResizeObserver(debounce((_, observer) => {
        const currentOuterContainer = container.parentElement;
        if (!currentOuterContainer) {
            observer.disconnect();
            return;
        }
        if (outerContainer !== currentOuterContainer) {
            observer.disconnect();
            observer.observe(currentOuterContainer);
            outerContainer = currentOuterContainer;
        }
        resizeWithParentElement(container);
        updateMoveableZoom(container, currentOuterContainer);
    })).observe(outerContainer);
};
const updateMoveableZoom = (container, currentOuterContainer) => {
    if (!isEditRootContainer(container)) {
        return;
    }
    const { isShortsProject } = config;
    let resolution;
    if (isShortsProject) {
        const { offsetHeight: outerContainerHeight } = currentOuterContainer;
        const { offsetHeight: containerHeight } = container;
        resolution = outerContainerHeight / containerHeight;
    }
    else {
        const { offsetWidth: outerContainerWidth } = currentOuterContainer;
        const { offsetWidth: containerWidth } = container;
        resolution = outerContainerWidth / containerWidth;
    }
    setZoom(1 / resolution);
};
const isEditRootContainer = (container) => { var _a; return ((_a = container.firstElementChild) === null || _a === void 0 ? void 0 : _a.id) === EDIT_CONTAINER_ID; };
