import { createVElementId, getCharacter, getCharacterObjectStyle, getRealCharacter, objectStyleToString, } from '@/share/virtualDOM';
import { Flags } from 'million';
export const createCharacterVElement = (slideVProps, options) => {
    const { characterSrc, characterEyeSrc, characterLipSrc, isRealCharacter, characterVideoSrc, bodyType, } = getCharacterInfo(slideVProps);
    const { characterContainerStyle, characterBaseElementStyle, characterEyeElementStyle, characterLipElementStyle, } = getCharacterObjectStyle(slideVProps, { isRealCharacter, bodyType });
    const containerVElement = {
        tag: 'div',
        props: {
            id: createVElementId(),
            class: slideVProps.class,
            style: objectStyleToString(characterContainerStyle),
        },
        flag: Flags.ELEMENT,
    };
    if (isRealCharacter && (options === null || options === void 0 ? void 0 : options.useVideoTagForRealCharacter)) {
        const baseVElement = {
            tag: 'video',
            props: {
                id: createVElementId(),
                style: objectStyleToString(characterBaseElementStyle),
                poster: characterSrc,
                src: characterVideoSrc,
                loop: !(options === null || options === void 0 ? void 0 : options.isDownload),
                muted: true,
            },
            flag: Flags.ELEMENT,
        };
        containerVElement.children = [baseVElement];
        return containerVElement;
    }
    const eyeVElement = {
        tag: 'img',
        props: {
            id: createVElementId(),
            style: objectStyleToString(characterEyeElementStyle),
            src: characterEyeSrc,
        },
        flag: Flags.ELEMENT,
    };
    const lipVElement = {
        tag: 'img',
        props: {
            id: createVElementId(),
            style: objectStyleToString(characterLipElementStyle),
            src: characterLipSrc,
        },
        flag: Flags.ELEMENT,
    };
    const baseVElement = {
        tag: 'img',
        props: {
            id: createVElementId(),
            style: objectStyleToString(characterBaseElementStyle),
            src: characterSrc,
        },
        flag: Flags.ELEMENT,
    };
    containerVElement.children = [eyeVElement, lipVElement, baseVElement];
    return containerVElement;
};
export const getCharacterInfo = ({ characterId, characterSkinType, bodyType, }) => {
    var _a, _b;
    const result = {
        characterSrc: '',
        characterVideoSrc: '',
        characterEyeSrc: '',
        characterLipSrc: '',
        bodyType: bodyType !== null && bodyType !== void 0 ? bodyType : 'WHOLE',
        isRealCharacter: false,
        characterType: 'NORMAL_2D',
    };
    const character = getCharacter(characterId);
    if (character) {
        const { base_image_url, origin_image_url, eye_image_url, lip_image_url } = character.dist;
        result.characterSrc = base_image_url || origin_image_url;
        result.characterEyeSrc = eye_image_url || origin_image_url;
        result.characterLipSrc = lip_image_url || origin_image_url;
    }
    const realCharacter = getRealCharacter(characterId);
    if (!realCharacter) {
        // NOTE: 일반 캐릭터는 언제나 전신만 지원
        result.bodyType = 'WHOLE';
        return result;
    }
    result.isRealCharacter = true;
    const { previewList, characterType } = realCharacter;
    result.characterType = characterType;
    const preview = previewList.find(({ type }) => type === characterSkinType);
    if (!preview) {
        throw new Error('character skin type not found');
    }
    result.characterSrc = preview.wholeImage;
    result.characterVideoSrc = (_a = preview.wholeVideo) !== null && _a !== void 0 ? _a : '';
    const supportOnlyUpperBodyType = preview.bodyType === 'UPPER';
    if (supportOnlyUpperBodyType) {
        result.bodyType = 'UPPER';
    }
    if (result.characterType === 'LIVE2D') {
        //FIXME: LIVE2D 캐릭터는 임시적으로 전신만 지원
        result.bodyType = 'WHOLE';
        //FIXME: LIVE2D 중에서 whole, upper 리소스를 모두 가지고 있는 케이스는 upper 리소스를 우선시함
        if (preview.upperImage && preview.upperVideo) {
            result.bodyType = 'UPPER';
            result.characterSrc = preview.upperImage;
            result.characterVideoSrc = preview.upperVideo;
        }
        if (bodyType === 'UPPER' && preview.upperImage && preview.upperVideo) {
            result.bodyType = 'UPPER';
            result.characterSrc = preview.upperImage;
            result.characterVideoSrc = preview.upperVideo;
        }
        if (bodyType === 'WHOLE') {
            result.bodyType = 'WHOLE';
            result.characterSrc = preview.wholeImage;
            result.characterVideoSrc = (_b = preview.wholeVideo) !== null && _b !== void 0 ? _b : '';
        }
    }
    return result;
};
