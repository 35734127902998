var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { objectStyleToString } from '@/share/virtualDOM';
import { Flags, render } from 'million';
import { config } from '@/config';
import { createSlideVElement } from './renderEditContainer';
import { flushRenderTickCallback } from './nextRenderTick';
const THUMBNAIL_CLASS_NAME = 'video-dom__thumbnail';
/** @public */
export const renderThumbnail = (slideVProps, timelineDuration, renderTargetElement, options) => __awaiter(void 0, void 0, void 0, function* () {
    if (!renderTargetElement) {
        return;
    }
    const vElement = yield createSlideVElement(slideVProps, timelineDuration, Object.assign(Object.assign({}, options), { skipRenderSubtitleVElement: options === null || options === void 0 ? void 0 : options.skipRenderSubtitleVElement }));
    const { originWidth, originHeight } = config;
    const resolution = updateRenderTargetElementSizeAndGetResolution(renderTargetElement);
    const thumbnailVElement = {
        tag: 'div',
        props: {
            id: `${slideVProps.id}-thumbnail`,
            style: objectStyleToString({
                transform: `scale(${resolution})`,
                transformOrigin: 'left top',
                width: `${originWidth}px`,
                height: `${originHeight}px`,
                overflow: 'hidden',
                textAlign: 'initial',
            }),
            class: THUMBNAIL_CLASS_NAME,
        },
        flag: Flags.ELEMENT,
        children: [vElement],
    };
    if (options === null || options === void 0 ? void 0 : options.hideSubtitle) {
        thumbnailVElement.props.class += ` hide-subtitle`;
    }
    flushRenderTickCallback();
    render(renderTargetElement, thumbnailVElement);
});
const updateRenderTargetElementSizeAndGetResolution = (renderTargetElement) => {
    const { originWidth, originHeight, isShortsProject } = config;
    if (isShortsProject) {
        const { offsetHeight: thumbnailHeight } = renderTargetElement;
        const resolution = thumbnailHeight / originHeight;
        const thumbnailWidth = resolution * originWidth;
        renderTargetElement.style.setProperty('width', `${thumbnailWidth}px`);
        return resolution;
    }
    const { offsetWidth: thumbnailWidth } = renderTargetElement;
    const resolution = thumbnailWidth / originWidth;
    const thumbnailHeight = resolution * originHeight;
    renderTargetElement.style.setProperty('height', `${thumbnailHeight}px`);
    return resolution;
};
