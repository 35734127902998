import { useVideoDomStore } from 'store/editor';
export const getVPropsById = (queryId) => {
    const videoDomStore = useVideoDomStore();
    const { slideList } = videoDomStore;
    const vPropsFromSlide = slideList.find(({ id }) => id === queryId);
    if (vPropsFromSlide) {
        return vPropsFromSlide;
    }
    const flattenSlideListChildren = slideList.flatMap(({ children }) => children);
    const vPropsFromChildren = flattenSlideListChildren.find(({ id }) => id === queryId);
    if (!vPropsFromChildren) {
        throw new Error('Cannot find the slideItem');
    }
    return vPropsFromChildren;
};
