import {Mark} from 'tiptap'
import {updateQueryAttrs} from 'prosemirror-commands'
import {i18n} from '../i18n'
import CONSTANTS from '@/config/constants'
export default class Query extends Mark {
  constructor(options = {}) {
    super(options)
    this.getDomByQueryId = this.getDomByQueryId.bind(this)

    this.handleParseDom = options.handleParseDom
    if (!this.handleParseDom) {
      this.handleParseDom = () => ({})
    }
  }

  get name() {
    return 'query'
  }

  get defaultOptions() {
    return {
      queryClass: 'query',
    }
  }

  get schema() {
    const schema = {
      attrs: {
        id: {
          default: null,
        },
        style: {
          default: CONSTANTS.DEFAULT_STYLE_NAME,
        },
        styleTag: {
          default: null,
        },
        speed: {
          default: 1,
        },
        customSpeed: {
          default: 0,
        },
        silence: {
          default: CONSTANTS.DEFAULT_SILENCE_MILLI_SEC,
        },
        pitch: {
          default: 0,
        },
        tempo: {
          default: 1,
        },
        lastPitch: {
          default: null,
        },
        takeNumber: {
          default: 0,
        },
      },
      toDOM: node => {
        let queryStatus = ''
        let queryAttribute = null
        const beforeQueryDom = this.getDomByQueryId(node.attrs.id)
        if (beforeQueryDom) {
          beforeQueryDom.classList.remove('query')
          queryStatus = ' ' + beforeQueryDom.className
          queryAttribute = beforeQueryDom.getAttribute('contenteditable')
        }

        let dataQuerySet = {
          class: this.options.queryClass + queryStatus,
          'data-query-id': node.attrs.id,
          'data-query-style': node.attrs.style,
          'data-query-speed': node.attrs.speed,
          'data-query-silence': node.attrs.silence,
          'data-query-pitch': node.attrs.pitch,
          'data-query-tempo': node.attrs.tempo,
          'data-query-take': node.attrs.takeNumber,
          ...(queryAttribute && {contenteditable: queryAttribute}),
        }

        if (node.attrs.lastPitch) {
          dataQuerySet = {
            ...dataQuerySet,
            'data-query-last-pitch': node.attrs.lastPitch,
          }
        }

        const isCustomStyleTagEnable = node.attrs.style.startsWith && node.attrs.style.startsWith('styletag-')
        if (isCustomStyleTagEnable) {
          dataQuerySet = {
            ...dataQuerySet,
            'data-query-styletag': node.attrs.styleTag,
          }
        }
        if (node.attrs.speed === CONSTANTS.CUSTOM_SPEED && node.attrs.customSpeed > 0) {
          const isNormalStyleTag = CONSTANTS.DEFAULT_STYLE_TAGS.includes(node.attrs.style)
          if (isCustomStyleTagEnable || isNormalStyleTag) {
            dataQuerySet = {
              ...dataQuerySet,
              'data-query-custom-speed': node.attrs.customSpeed + i18n.t('초'),
            }
          } else {
            dataQuerySet = {
              ...dataQuerySet,
              'data-query-custom-speed-left-shift': node.attrs.customSpeed + i18n.t('초'),
            }
          }
        }
        return ['span', dataQuerySet]
      },
      parseDOM: [
        {
          tag: 'span.query',
          getAttrs: dom => {
            const id = dom.getAttribute('data-query-id')
            let style = dom.getAttribute('data-query-style')
            style = CONSTANTS.DEFAULT_STYLE_TAGS.includes(style) ? CONSTANTS.DEFAULT_STYLE_NAME : style
            let speed = dom.getAttribute('data-query-speed')
            let customSpeed =
              dom.getAttribute('data-query-custom-speed') ||
              dom.getAttribute('data-query-custom-speed-left-shift') ||
              '0'

            const silence = dom.getAttribute('data-query-silence')
            const pitch = dom.getAttribute('data-query-pitch')
            const tempo = dom.getAttribute('data-query-tempo')
            const lastPitch = dom.getAttribute('data-query-last-pitch')
            const actorId = dom.parentNode.getAttribute('data-actor-id')
            const styleTag = dom.getAttribute('data-query-styletag')
            const takeNumber = dom.getAttribute('data-query-take')
            let hasStyle = true
            let isDisabledSpeed = false
            let isDisabledCustomSpeed = false
            if (actorId && actorId !== 'null') {
              const parseResult = this.handleParseDom(actorId, {style})
              hasStyle = parseResult.hasStyle
              isDisabledSpeed = parseResult.isDisabledSpeed
              isDisabledCustomSpeed = parseResult.isDisabledCustomSpeed
            }

            const i18nSec = i18n.t('초')
            customSpeed = customSpeed.replace(i18nSec, '')

            if (isDisabledCustomSpeed) {
              customSpeed = 0
              if (+speed === CONSTANTS.CUSTOM_SPEED) {
                speed = 1
              }
            }

            return {
              id: id,
              style: style && hasStyle ? style : CONSTANTS.DEFAULT_STYLE_NAME,
              speed: isDisabledSpeed ? 1 : Number(speed ? speed : 1),
              customSpeed: Number(customSpeed ? customSpeed : 0),
              silence: Number(silence ? silence : CONSTANTS.DEFAULT_SILENCE_MILLI_SEC),
              pitch: Number(pitch ? pitch : 0),
              tempo: Number(tempo ? tempo : 1),
              lastPitch: lastPitch !== null ? Number(lastPitch) : null,
              styleTag: styleTag ? styleTag : null,
              takeNumber: Number(takeNumber ?? 0),
            }
          },
        },
      ],
    }
    schema.getDomByQueryId = this.getDomByQueryId
    return schema
  }

  getDomByQueryId(queryId) {
    return document.querySelector(`span[data-query-id="${queryId}"]`)
  }

  commands({type}) {
    return {
      query: (attrs, options) => updateQueryAttrs(type, attrs, options),
      findAndUpdateQuery: attrs => this.findAndUpdateQuery(type, attrs),
      findAndUpdateQueryByActorId: attrsWithActorId => {
        const actorId = attrsWithActorId.actor_id
        const attrs = attrsWithActorId
        delete attrs.actor_id
        return this.findAndUpdateQueryByActorId(type, attrs, actorId)
      },
      updateTakeNumber: (attrs, options) => this.updateTakeNumber(type, attrs, options),
    }
  }

  findAndUpdateQuery(type, attrs) {
    return ({tr}, dispatch) => {
      tr.doc.descendants((node, pos) => {
        if (node.marks.length > 0 && type === node.marks[0].type && node.marks[0].attrs.id === attrs.id) {
          delete attrs.id
          tr.updateQueryAttrs(pos, pos + node.text.length, type.create(attrs), attrs)
        }
      })
      dispatch(tr)
    }
  }

  findAndUpdateQueryByActorId(type, attrs, actorId) {
    return ({tr}, dispatch) => {
      tr.doc.descendants((node, pos) => {
        if (node.type.name === 'paragraph' && node.attrs.actor === actorId) {
          tr.updateQueryAttrs(pos, pos + node.nodeSize, type.create(attrs), attrs)
        }
      })
      dispatch(tr)
    }
  }

  updateTakeNumber(type, {takeNumber}, {queryId}) {
    return (state, dispatch) => {
      const tr = state.tr
      let isChanged = false
      state.doc.descendants((node, pos) => {
        if (node.type.name !== 'text') {
          return
        }
        if (node.marks.length > 0 && node.marks[0].attrs.id === queryId) {
          tr.updateQueryAttrs(
            pos,
            pos + node.text.length,
            state.schema.marks.query.create({...node.marks[0].attrs, takeNumber}),
            {takeNumber},
          )
          isChanged = true
        }
      })
      if (isChanged && dispatch) {
        dispatch(tr)
      }
    }
  }
}
