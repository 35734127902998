import * as TypeGuards from './DetailV10.guard';
import TiptapDetail from '@/projects/TiptapDetail';
import { uniqueId } from 'utils';
import { getValue } from '@/utils';
import { nanoid } from 'nanoid';
export default class DetailV10 extends TiptapDetail {
    constructor(detail, oldVideoSlideList) {
        var _a, _b, _c;
        super(detail);
        this.slide_list = createSlideList(getValue(detail.slide_list, []), oldVideoSlideList);
        const additionalData = detail.additional_data;
        const videoDomSlide = getValue(additionalData === null || additionalData === void 0 ? void 0 : additionalData.videoDomSlide, []);
        const timelineVideoList = getValue((_a = additionalData === null || additionalData === void 0 ? void 0 : additionalData.timeline) === null || _a === void 0 ? void 0 : _a.video, []);
        const timelineBgmList = getValue((_b = additionalData === null || additionalData === void 0 ? void 0 : additionalData.timeline) === null || _b === void 0 ? void 0 : _b.bgm, []);
        const version = getValue(additionalData === null || additionalData === void 0 ? void 0 : additionalData.version, 1);
        this.additional_data = Object.assign(Object.assign({}, detail.additional_data), { videoDomSlide: videoDomSlide.map(slide => createVideoDomSlide(slide)), timeline: {
                video: timelineVideoList,
                bgm: timelineBgmList,
            }, version, slide_list_v2: getValue((_c = detail.additional_data) === null || _c === void 0 ? void 0 : _c.slide_list_v2, []).map(slide => createSlideV2(slide)) });
    }
    isDetail(detail) {
        return TypeGuards.isDetailV10(detail);
    }
}
const createSlideList = (slideList, oldVideoSlideList) => {
    let newSlideList = [];
    if (slideList.length) {
        newSlideList = slideList;
    }
    else if (oldVideoSlideList.length) {
        newSlideList = oldVideoSlideList;
    }
    return newSlideList.map(slide => createSlide(slide));
};
export const createSlide = (slide) => (Object.assign(Object.assign({}, slide), { slideId: getValue(slide.slideId, nanoid()), characterId: getValue(slide.characterId, ''), characterSkinType: getValue(slide.characterSkinType, 'A'), charPosType: getValue(slide.charPosType, 'LEFT'), charPercent: getValue(slide.charPercent, 100), charTransFlag: getValue(slide.charTransFlag, false), charFlag: getValue(slide.charFlag, true), charAnimation: getValue(slide.charAnimation, {
        buildinType: null,
        buildoutType: null,
    }), chromakey: getValue(slide.chromakey, '#00000000'), subtitleFlag: getValue(slide.subtitleFlag, true), subtitleList: getValue(slide.subtitleList, []), transitionType: getTransitionType(slide.transitionType), charMotionFlag: getValue(slide.charMotionFlag, false), canvasData: getValue(slide.canvasData, '[]'), bodyType: getValue(slide.bodyType, 'UPPER') }));
const getTransitionType = (transitionType) => {
    if (!transitionType) {
        return 'none';
    }
    return transitionType.replace('-', '');
};
const MODULE_PREFIX = 'vd__';
const createSlideV2 = (slide) => (Object.assign(Object.assign({}, createVideoDomSlide(slide)), { backgroundColor: getValue(slide.backgroundColor, '#00000000'), subtitleList: getValue(slide.subtitleList, []), subtitleMap: getValue(slide.subtitleMap, {}) }));
export const createVideoDomSlide = (slide) => ({
    id: getValue(slide.id, `${MODULE_PREFIX}${uniqueId()}`),
    type: getValue(slide.type, 'slide'),
    slideId: getValue(slide.slideId, ''),
    characterId: getValue(slide.characterId, ''),
    characterSkinType: getValue(slide.characterSkinType, 'A'),
    characterPosition: getValue(slide.characterPosition, 'left'),
    characterScale: getValue(slide.characterScale, 1),
    characterFlip: getValue(slide.characterFlip, false),
    characterVisibility: getValue(slide.characterVisibility, true),
    characterBuildInAnimation: getValue(slide.characterBuildInAnimation, 'none'),
    characterBuildOutAnimation: getValue(slide.characterBuildOutAnimation, 'none'),
    transition: getTransition(slide.transition),
    style: getValue(slide.style, {}),
    children: getValue(slide.children, []),
    bodyType: getValue(slide.bodyType, 'UPPER'),
    speakTextVisibility: getValue(slide.speakTextVisibility, true),
});
const getTransition = (transition) => {
    if (!transition || transition === 'none') {
        return null;
    }
    return transition.replace('-', '');
};
export { TypeGuards };
