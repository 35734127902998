const loadGoogleScript = function () {
  return new Promise((resolve, reject) => {
    if (document.getElementById('gsi')) {
      return resolve(window.google)
    }
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://accounts.google.com/gsi/client'
    scriptTag.onload = () => {
      scriptTag.setAttribute('id', 'gsi')
      if (window.google) {
        resolve(window.google)
      } else {
        reject()
      }
    }
    scriptTag.onerror = () => {
      reject('GSI_SCRIPT.FAILED_TO_LOADING')
    }
    document.body.appendChild(scriptTag)
  })
}

export {loadGoogleScript}
