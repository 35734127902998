export function getAvailableStyleLabelVersionList(actor) {
    if (!(actor === null || actor === void 0 ? void 0 : actor.actor_id)) {
        return null;
    }
    const catsStyleLabelList = actor.style_label_v2.filter(isStyleLabelCATS);
    const isKorean = actor.language === 'ko-kr';
    const catsVersionCount = catsStyleLabelList.length;
    if (isKorean && catsVersionCount > 0) {
        return catsStyleLabelList.slice(-2);
    }
    return actor.style_label_v2.slice(-2);
}
export const getLastActorStyleLabel = (actor) => {
    const styleLabelList = getAvailableStyleLabelVersionList(actor);
    if (!(styleLabelList === null || styleLabelList === void 0 ? void 0 : styleLabelList.length)) {
        return null;
    }
    return styleLabelList[styleLabelList.length - 1];
};
export const getLastActorVersion = (actor) => {
    var _a, _b;
    return (_b = (_a = getLastActorStyleLabel(actor)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null;
};
export const getCurrentActorStyleLabel = (styleLabelVersionList, actor) => {
    var _a;
    if (!(actor === null || actor === void 0 ? void 0 : actor.actor_id)) {
        return null;
    }
    const currentVersion = getCurrentActorVersion(styleLabelVersionList, actor);
    if (!currentVersion) {
        return null;
    }
    return (_a = getActorStyleLabel(actor, currentVersion)) !== null && _a !== void 0 ? _a : null;
};
export const getCurrentActorVersion = (styleLabelVersionList, actor) => {
    if (!(actor === null || actor === void 0 ? void 0 : actor.actor_id)) {
        return null;
    }
    const currentVersion = styleLabelVersionList[actor.actor_id];
    if (currentVersion) {
        return currentVersion;
    }
    return getLastActorVersion(actor);
};
export const checkOldActorVersion = (actor, styleLabelVersionList) => styleLabelVersionList[actor.actor_id] !== undefined &&
    getLastActorVersion(actor) !== getCurrentActorVersion(styleLabelVersionList, actor);
export const getActorStyleLabel = (actor, version) => {
    if (!(actor === null || actor === void 0 ? void 0 : actor.style_label_v2) || !version) {
        return null;
    }
    return actor.style_label_v2.find(label => label.name === version);
};
export function checkIfDotsModel(actor, styleLabelVersionList) {
    const currentActorVersion = getCurrentActorVersion(styleLabelVersionList, actor);
    const currentActorStyleLabel = getActorStyleLabel(actor, currentActorVersion);
    return !!(currentActorStyleLabel === null || currentActorStyleLabel === void 0 ? void 0 : currentActorStyleLabel.flags.includes('modeltype-ssfm'));
}
export function isStyleLabelSSFM(label) {
    return !!(label === null || label === void 0 ? void 0 : label.flags.includes('modeltype-ssfm'));
}
export function isStyleLabelCATS(label) {
    return !isStyleLabelSSFM(label);
}
export const CUSTOM_ACTOR_TAG = '커스텀';
export const MVM_ACTOR_TAG = '마보메';
export const VOICEHUB_ACTOR_TAG = '보이스허브';
export function isCustomActor(actor, type) {
    const isCustomActor = actor.tag.includes(CUSTOM_ACTOR_TAG);
    const isMVMActor = actor.tag.includes(MVM_ACTOR_TAG);
    const isVoiceHubActor = actor.tag.includes(VOICEHUB_ACTOR_TAG);
    if (type === 'mvm') {
        return isCustomActor && isMVMActor;
    }
    if (type === 'voicehub') {
        return isCustomActor && isVoiceHubActor;
    }
    return isCustomActor;
}
