import typecastAuthApi from '../backend/auth-api'
import {getAuth, getIdToken} from 'firebase/auth'
import ProactiveAuth from './modules/Account/proactiveAuth'
import jwt_decode from 'jwt-decode'

/**
 * @module typecast/$auth
 */
export default function ($http) {
  return {
    ...typecastAuthApi($http),
    proactiveAuth: new ProactiveAuth(),
    refreshCustomToken() {
      return new Promise((resolve, reject) => {
        if (!getAuth().currentUser) {
          return reject()
        }
        getIdToken(getAuth().currentUser, true)
          .then(idToken => {
            const {exp} = jwt_decode(idToken)
            this.setAccessToken(idToken)
            this.proactiveAuth.setTokenExpiredAt(exp * 1000)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}
