var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from '@/config';
import isUrlExpired from './isUrlExpired';
import store from '@/store';
const ALLOW_CACHE_ASSET_TYPE = ['image', 'video', 'bgm'];
export const getSrcByAssetId = (assetId, assetIdType, assetFrom, forcedUpdate = false) => __awaiter(void 0, void 0, void 0, function* () {
    const { assetIdToUrl } = config;
    if (!ALLOW_CACHE_ASSET_TYPE.includes(assetIdType)) {
        return yield assetIdToUrl(assetId, assetIdType, assetFrom);
    }
    const { cachedAssetUrls } = store.state.typecast.videoEditor;
    const cachedAssetUrlOrPromise = cachedAssetUrls[assetId];
    const cachedAssetUrl = yield cachedAssetUrlOrPromise;
    if (cachedAssetUrl) {
        store.commit('typecast/videoEditor/updateCachedAssetUrl', {
            id: assetId,
            url: cachedAssetUrl,
        });
        if (!forcedUpdate && !isUrlExpired(cachedAssetUrl, assetIdType)) {
            return cachedAssetUrl;
        }
    }
    const newAssetPromise = assetIdToUrl(assetId, assetIdType, assetFrom);
    store.commit('typecast/videoEditor/updateCachedAssetUrl', {
        id: assetId,
        url: newAssetPromise,
    });
    const assetUrl = yield newAssetPromise;
    return assetUrl;
});
