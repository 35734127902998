// NOTE: model 모듈은 백엔드에서도 사용되기에 `@/context`와 같은 모듈을 명시적으로 import 하는 방식을 사용하면 안됨
// 이 대신, 실제로 필요한 경우에만 import() 하는 방식으로 구현이 필요 (CharacterSceneItem 생성자 부분 참고)
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _RootScene_instances, _RootScene_getTimelineVideoMediaSceneList, _RootScene_getSlideList, _RootScene_getSlideSpeakMediaSceneFlatList, _CharacterSceneItem_sceneTime, _Character2DSceneItem_containerElement, _Character2DSceneItem_eyeElement, _Character2DSceneItem_lipElement;
import Scene, { Frame, SceneItem } from 'scenejs';
import { default as BaseMediaScene, Media } from '@scenejs/media';
import { toFixed2, toTimeline } from 'utils';
import TransitionSceneItem from '@/model/TransitionSceneItem';
import { PLAY_ID } from '@/constants';
const IS_BACKEND = process.env.__buildMode === '__isBuildCharacterModel';
const MEDIA_SCENE_PRELOAD_TIME_SEC = 60;
export class MediaScene extends BaseMediaScene {
    constructor(properties, options) {
        super();
        this.element = null;
        this.load(properties, options);
    }
    setTime(time, isTick, isParent, parentEasing) {
        this.element = toggle(this.getId().toString(), time, this.state.duration, this.element);
        if (this.element && !this.element.isConnected) {
            return this;
        }
        return super.setTime(time, isTick, isParent, parentEasing);
    }
    setVolume(volume) {
        const mediaItems = Object.values(this.items).filter(item => item instanceof Media);
        mediaItems.forEach(media => {
            if (media.originVolume !== undefined) {
                media.setVolume(media.originVolume * volume);
                return;
            }
            media.setVolume(volume);
        });
    }
}
export class Slide extends Scene {
    constructor() {
        super(...arguments);
        this.element = null;
        this.startTransitionDuration = 0;
        this.endTransitionDuration = 0;
        this.durationWithoutTransition = 0;
        this.mediaScenePreloaded = false;
    }
    setTime(time, isTick, isParent, parentEasing) {
        if (!IS_BACKEND) {
            this.tryPreloadMediaScene(time);
        }
        this.element = toggle(this.getId().toString(), time, this.state.duration, this.element);
        if (this.element && !this.element.isConnected) {
            return this;
        }
        return super.setTime(time, isTick, isParent, parentEasing);
    }
    tryPreloadMediaScene(time) {
        if (this.mediaScenePreloaded) {
            return;
        }
        const timeLeft = Number(time) + MEDIA_SCENE_PRELOAD_TIME_SEC;
        if (timeLeft < 0) {
            return;
        }
        this.mediaScenePreloaded = true;
        this.forEach(item => {
            if (item instanceof MediaScene) {
                item.preloadResource();
            }
        });
    }
}
export class RootScene extends Scene {
    constructor() {
        super(...arguments);
        _RootScene_instances.add(this);
    }
    setVolume(volume) {
        const mediaSceneList = [
            ...__classPrivateFieldGet(this, _RootScene_instances, "m", _RootScene_getTimelineVideoMediaSceneList).call(this),
            ...__classPrivateFieldGet(this, _RootScene_instances, "m", _RootScene_getSlideSpeakMediaSceneFlatList).call(this),
        ];
        mediaSceneList.forEach(mediaScene => mediaScene.setVolume(volume));
    }
}
_RootScene_instances = new WeakSet(), _RootScene_getTimelineVideoMediaSceneList = function _RootScene_getTimelineVideoMediaSceneList() {
    return Object.values(this.items).filter(item => item instanceof MediaScene);
}, _RootScene_getSlideList = function _RootScene_getSlideList() {
    return Object.values(this.items).filter(item => item instanceof Slide);
}, _RootScene_getSlideSpeakMediaSceneFlatList = function _RootScene_getSlideSpeakMediaSceneFlatList() {
    return __classPrivateFieldGet(this, _RootScene_instances, "m", _RootScene_getSlideList).call(this).flatMap(slide => Object.values(slide.items).filter(item => item instanceof MediaScene));
};
const toggle = (id, time, duration, cachedElement) => {
    if (typeof time === 'string') {
        return null;
    }
    const root = document.getElementById(PLAY_ID);
    if (!root) {
        return null;
    }
    time = toTimeline(time);
    duration = toTimeline(duration);
    const visible = time > 0 && time <= duration;
    const element = root.querySelector(`#${id}`);
    if (visible && !element && cachedElement) {
        root.append(cachedElement);
    }
    if (!visible && element) {
        element.remove();
    }
    return element || cachedElement;
};
class CharacterSceneItem extends SceneItem {
    static factory(id) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define -- circular dependency
        return new Character2DSceneItem(id);
    }
    constructor(properties) {
        super(properties);
        _CharacterSceneItem_sceneTime.set(this, 0);
        if (typeof properties === 'string') {
            const id = properties;
            this.setElement(`#${id}`);
        }
        else if (properties === null || properties === void 0 ? void 0 : properties.options.selector) {
            const id = properties.options.selector;
            this.setElement(id);
        }
        // NOTE: 백엔드로 전달하는 HTML과 video-dom 모듈에 두 개의 scene이 존재
        // 실제로 사용하는 scene을 지정하는 작업
        const promisedCurrentScene = new Promise(resolve => {
            if (IS_BACKEND) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                resolve(window.scene);
                return;
            }
            // NOTE: 컴파일 타임에서 import 하지 않도록 함
            // 언제인지부터는 모르겠으나, 문자열을 직접 넣으면 컴파일 타임에 해당 모듈 서치됨
            const path = IS_BACKEND ? '' : 'index';
            import(`../context/${path}`).then(({ scene }) => resolve(scene));
        });
        promisedCurrentScene.then(currentScene => {
            this.on('animate', ({ time: frameTime }) => {
                const sceneTime = toTimeline(currentScene.getTime());
                const noNeedAnimate = __classPrivateFieldGet(this, _CharacterSceneItem_sceneTime, "f") === sceneTime;
                if (noNeedAnimate) {
                    return;
                }
                this.animate(sceneTime, frameTime);
            });
        });
    }
    getContainerElement() {
        return this.elements[0];
    }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    animate(sceneTime, frameTime) {
        __classPrivateFieldSet(this, _CharacterSceneItem_sceneTime, sceneTime, "f");
    }
}
_CharacterSceneItem_sceneTime = new WeakMap();
export class Character2DSceneItem extends CharacterSceneItem {
    constructor(properties) {
        super(properties);
        _Character2DSceneItem_containerElement.set(this, void 0);
        // @ts-ignore
        _Character2DSceneItem_eyeElement.set(this, void 0);
        // @ts-ignore
        _Character2DSceneItem_lipElement.set(this, void 0);
        __classPrivateFieldSet(this, _Character2DSceneItem_containerElement, this.getContainerElement(), "f");
        // guard when invoke without `renderPlayContainer` (e.g. `exportScene`)
        if (!__classPrivateFieldGet(this, _Character2DSceneItem_containerElement, "f")) {
            return;
        }
        const containerElementChildren = __classPrivateFieldGet(this, _Character2DSceneItem_containerElement, "f").children;
        __classPrivateFieldSet(this, _Character2DSceneItem_eyeElement, containerElementChildren[0], "f");
        __classPrivateFieldSet(this, _Character2DSceneItem_lipElement, containerElementChildren[1], "f");
    }
    animate(sceneTime, frameTime) {
        this.animateEye(sceneTime);
        this.animateLip(sceneTime);
        super.animate(sceneTime, frameTime);
    }
    animateEye(sceneTime) {
        const closeEye = Math.floor(sceneTime) % 2 === 1; // each 1 second
        if (closeEye) {
            setDisplayStyle(__classPrivateFieldGet(this, _Character2DSceneItem_eyeElement, "f"), 'block');
        }
        else {
            setDisplayStyle(__classPrivateFieldGet(this, _Character2DSceneItem_eyeElement, "f"), 'none');
        }
    }
    animateLip(sceneTime) {
        const currentStep = Math.floor(toFixed2(sceneTime / 0.3)); // each 0.3 seconds
        const closeLip = currentStep % 2 === 0;
        if (closeLip) {
            setDisplayStyle(__classPrivateFieldGet(this, _Character2DSceneItem_lipElement, "f"), 'block');
        }
        else {
            setDisplayStyle(__classPrivateFieldGet(this, _Character2DSceneItem_lipElement, "f"), 'none');
        }
    }
}
_Character2DSceneItem_containerElement = new WeakMap(), _Character2DSceneItem_eyeElement = new WeakMap(), _Character2DSceneItem_lipElement = new WeakMap();
export class CharacterScene extends Scene {
    addCharacter(id) {
        const character2dSceneItem = CharacterSceneItem.factory(id);
        this.setItem(`${id}_sceneItem`, character2dSceneItem);
        return character2dSceneItem;
    }
}
const setDisplayStyle = (htmlElement, value) => (htmlElement.style.display = value);
export { Frame, Media, Scene, SceneItem, TransitionSceneItem };
// NOTE: 빌드 시 클래스 이름이 난독화되는데, 이 경우 백엔드 인코딩 시 videoDom.${name} 형태로 접근할 수 없기에 이와 같이 헬퍼 함수를 구현
// videoDom.${name} 형태는 항상 model/index.ts에서 export 되고, 따라서 비교적 안전하지만 자동화되지 않았기에 여기서 새로이 클래스가 추가되면 아래에도 추가해야 함
// TODO: 자동화(model/index.ts에 대해서만 난독화 해제하기)
export const getConstructorName = (instance) => {
    // 순서 중요 (가장 구체적인 것부터)
    if (instance instanceof RootScene) {
        return 'RootScene';
    }
    if (instance instanceof Slide) {
        return 'Slide';
    }
    if (instance instanceof MediaScene) {
        return 'MediaScene';
    }
    if (instance instanceof CharacterScene) {
        return 'CharacterScene';
    }
    if (instance instanceof Character2DSceneItem) {
        return 'Character2DSceneItem';
    }
    if (instance instanceof Frame) {
        return 'Frame';
    }
    if (instance instanceof Media) {
        return 'Media';
    }
    if (instance instanceof Scene) {
        return 'Scene';
    }
    if (instance instanceof TransitionSceneItem) {
        return 'TransitionSceneItem';
    }
    if (instance instanceof SceneItem) {
        return 'SceneItem';
    }
    return name;
};
