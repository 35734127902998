import TButton from './components/Button/Button.vue'
import TCheckbox from './components/Checkbox/Checkbox.vue'
import TRadio from './components/Radio/Radio.vue'
import TToggleButton from './components/ToggleButton/ToggleButton.vue'
import TIcon from './components/Icons/Icon.vue'
import TTag from './components/Tag/Tag.vue'

const install = Vue => {
  Vue.component('TButton', TButton)
  Vue.component('TCheckbox', TCheckbox)
  Vue.component('TRadio', TRadio)
  Vue.component('TToggleButton', TToggleButton)
  Vue.component('TIcon', TIcon)
  Vue.component('TTag', TTag)
}

export default {
  install,
}

export {TButton}
export {TCheckbox}
export {TRadio}
export {TIcon}
export {TTag}
