export default {
  bind: function (el, _, vNode) {
    el.transformLower = function () {
      el.value = el.value.toLowerCase()
      vNode.componentInstance.$emit('input', el.value)
    }
    el.addEventListener('input', el.transformLower)
  },
  unbind: function (el) {
    el.removeEventListener('input', el.transformLower)
  },
}
