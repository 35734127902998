import { config } from '@/config';
import { getCachedQuery, getCurrentCachedQuery } from './getCachedQuery';
import { useEditorOptionsStore, useVideoDomStore } from 'store/editor';
export const getSubtitle = (queryId) => {
    var _a;
    const { i18nConverter } = config;
    const placeholderText = i18nConverter('place_holder');
    const query = getCachedQuery(queryId);
    if (!query) {
        return { text: placeholderText };
    }
    const editorOptionsStore = useEditorOptionsStore();
    const { slideIndex } = editorOptionsStore;
    const videoDomStore = useVideoDomStore();
    const { subtitleMap } = videoDomStore.slideList[slideIndex];
    return (_a = subtitleMap[query.id]) !== null && _a !== void 0 ? _a : {};
};
/** @public */
export const getCurrentSubtitle = () => {
    const currentQuery = getCurrentCachedQuery();
    return getSubtitle(currentQuery === null || currentQuery === void 0 ? void 0 : currentQuery.id);
};
