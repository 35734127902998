import { SceneItem } from 'scenejs';
import { TRANSITION_TIME, VIDEO_UNIT_LENGTH } from '@/constants';
export default class AnimationSceneItem extends SceneItem {
    constructor(animationPosition, animationType, slideDurationWithoutTransition, half) {
        super();
        this.animationPosition = animationPosition;
        this.animationType = animationType;
        this.slideDurationWithoutTransition = slideDurationWithoutTransition;
        this.half = half;
        // 최소 Duration
        this.setDuration(this.calculateDuration() || VIDEO_UNIT_LENGTH);
    }
    static getTransitionDuration(transition, half = false) {
        let transitionDuration = 0;
        // getTransitionDuration을 호출하는 곳의 타입이 제대로 정의되어있지 않아 undefined 뿐만 아니라 null도 전달이 된다.
        // 따라서 기본값을 사용하지 않고 명시적으로 초기화를 해준다.
        if (!transition) {
            transition = 'none';
        }
        // fade보다 먼저 검사해야한다.
        if (transition.startsWith('fade')) {
            transitionDuration = TRANSITION_TIME;
        }
        if (transition.startsWith('smooth')) {
            transitionDuration = TRANSITION_TIME * 2;
        }
        if (transition === 'fade') {
            transitionDuration = TRANSITION_TIME * 2;
        }
        if (half) {
            transitionDuration /= 2;
        }
        return transitionDuration;
    }
    calculateDuration() {
        return AnimationSceneItem.getTransitionDuration(this.animationType, this.half);
    }
}
