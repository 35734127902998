export function isProject(obj) {
    const typedObj = obj;
    return ((typedObj !== null &&
        typeof typedObj === "object" ||
        typeof typedObj === "function") &&
        (typeof typedObj["_id"] === "undefined" ||
            typedObj["_id"] === null ||
            typeof typedObj["_id"] === "string" ||
            (typedObj["_id"] !== null &&
                typeof typedObj["_id"] === "object" ||
                typeof typedObj["_id"] === "function") &&
                typeof typedObj["_id"]["$oid"] === "string" &&
                typeof typedObj["_id"]["created_date"] === "number") &&
        (typeof typedObj["uid"] === "undefined" ||
            typedObj["uid"] === null ||
            typeof typedObj["uid"] === "string") &&
        typeof typedObj["name"] === "string" &&
        (typeof typedObj["version"] === "undefined" ||
            typedObj["version"] === null ||
            typeof typedObj["version"] === "number") &&
        (typeof typedObj["media_type"] === "undefined" ||
            typedObj["media_type"] === null ||
            typedObj["media_type"] === "audio" ||
            typedObj["media_type"] === "shorts" ||
            typedObj["media_type"] === "video") &&
        (typeof typedObj["project_url"] === "undefined" ||
            typedObj["project_url"] === null ||
            typeof typedObj["project_url"] === "string") &&
        (typeof typedObj["project_v2_url"] === "undefined" ||
            typedObj["project_v2_url"] === null ||
            typeof typedObj["project_v2_url"] === "string") &&
        (typeof typedObj["revision"] === "undefined" ||
            typedObj["revision"] === null ||
            typeof typedObj["revision"] === "number") &&
        (typeof typedObj["share"] === "undefined" ||
            typedObj["share"] === null ||
            (typedObj["share"] !== null &&
                typeof typedObj["share"] === "object" ||
                typeof typedObj["share"] === "function") &&
                (typeof typedObj["share"]["user_list"] === "undefined" ||
                    typedObj["share"]["user_list"] === null ||
                    Array.isArray(typedObj["share"]["user_list"]) &&
                        typedObj["share"]["user_list"].every((e) => (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                            typeof e["uid"] === "string" &&
                            typeof e["email"] === "string")) &&
                (typeof typedObj["share"]["history"] === "undefined" ||
                    typedObj["share"]["history"] === null ||
                    Array.isArray(typedObj["share"]["history"]) &&
                        typedObj["share"]["history"].every((e) => (e !== null &&
                            typeof e === "object" ||
                            typeof e === "function") &&
                            typeof e["last_modified_date"] === "number" &&
                            (typeof e["email"] === "undefined" ||
                                e["email"] === null ||
                                typeof e["email"] === "string")))) &&
        (typeof typedObj["last_modified_date"] === "undefined" ||
            typedObj["last_modified_date"] === null ||
            typeof typedObj["last_modified_date"] === "number") &&
        (typeof typedObj["actor_ids"] === "undefined" ||
            typedObj["actor_ids"] === null ||
            Array.isArray(typedObj["actor_ids"]) &&
                typedObj["actor_ids"].every((e) => typeof e === "string")) &&
        (typeof typedObj["folder"] === "undefined" ||
            typedObj["folder"] === null ||
            (typedObj["folder"] !== null &&
                typeof typedObj["folder"] === "object" ||
                typeof typedObj["folder"] === "function") &&
                (typeof typedObj["folder"]["_id"] === "undefined" ||
                    typedObj["folder"]["_id"] === null ||
                    typeof typedObj["folder"]["_id"] === "string") &&
                (typeof typedObj["folder"]["name"] === "undefined" ||
                    typedObj["folder"]["name"] === null ||
                    typeof typedObj["folder"]["name"] === "string")) &&
        (typeof typedObj["clientId"] === "undefined" ||
            typedObj["clientId"] === null ||
            typeof typedObj["clientId"] === "string") &&
        (typeof typedObj["subtitle"] === "undefined" ||
            typedObj["subtitle"] === null ||
            (typedObj["subtitle"] !== null &&
                typeof typedObj["subtitle"] === "object" ||
                typeof typedObj["subtitle"] === "function") &&
                typeof typedObj["subtitle"]["font_family"] === "string" &&
                (typedObj["subtitle"]["style"] === "bg_short" ||
                    typedObj["subtitle"]["style"] === "bg_wide" ||
                    typedObj["subtitle"]["style"] === "shadow" ||
                    typedObj["subtitle"]["style"] === "outline" ||
                    typedObj["subtitle"]["style"] === "none") &&
                (typedObj["subtitle"]["size"] === "small" ||
                    typedObj["subtitle"]["size"] === "medium" ||
                    typedObj["subtitle"]["size"] === "large") &&
                (typedObj["subtitle"]["position"] === "top" ||
                    typedObj["subtitle"]["position"] === "middle" ||
                    typedObj["subtitle"]["position"] === "bottom")) &&
        (typeof typedObj["bg_color"] === "undefined" ||
            typedObj["bg_color"] === null ||
            typeof typedObj["bg_color"] === "string"));
}
